import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';
import create_icon_colors from 'projectLibrary/session_tools/global_data/create_icon_colors.js';



function create_icon_list(){
    var location_id = window.cmState.group.current_location.id;
    if (window.cmState.current_location){
        location_id = window.cmState.current_location.id;
    }

    var icons = [];

    var sorted_keys = [];
    for (var key in window.cmState.instances) {
        if (window.cmState.instances[key]['active'] == false){
            continue;
        }

        var date = new Date(window.cmState.instances[key]['created_at']);
        sorted_keys.push({'key':key, 'date': date});
    }

    sorted_keys.sort((a, b) => (a.date > b.date) ? 1 : -1);

    var icons_added = 0;
    for (var i=0;i<sorted_keys.length;i++){
        var key = sorted_keys[i]['key'];
        var instance = window.cmState.instances[key];;

        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
        var icon = load_icon_from_monster(instance, monster);


        if (instance.location_id == location_id) {
            icon['colors'] = create_icon_colors(icons_added);
            icons.push(icon);

            icons_added += 1;
        }
    }

    console.log("Load Monster Icons", icons);
    window.cmState.setGlobalState('icon_list', icons);
}

export default create_icon_list;
