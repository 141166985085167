import React, { Component } from 'react';
import {ajaxWrapper} from 'functions';
import settings from 'base/settings.js';
import {Button} from 'library';
import SpellCard from 'projectLibrary/spell_card.js';
import ActionCard from 'projectLibrary/action_card.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import replace_roll_vars from 'projectLibrary/monsters/replace_roll_vars.js';

class AOECard extends React.Component {
    constructor(props) {
        super(props);

        this.remove = this.remove.bind(this);
        this.use = this.use.bind(this);

    }

    remove() {
        this.props.remove_monster_instance(this.props.action.id);
    }

    use() {
        var action = window.cmState.global_state_lookup(this, 'actions', this.props.action.action_id);
        var cast_level = this.props.action.cast_level;
        var instance = window.cmState.global_state_lookup(this, 'instances', this.props.action.instance_id);
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var hits = null;

        if (action.has_attack_roll) {
            var attack_roll_bonus = replace_roll_vars(action.attack_roll.attack_roll, monster);
            var hit_one = parse_roll("1d20 " + attack_roll_bonus);
            var hit_two = parse_roll("1d20 " + attack_roll_bonus);
            hits = [hit_one, hit_two];
        }

        var spell_save_dc = null;
        if (action.has_dc) {
            var spell_save_dc = 8 + parse_roll(replace_roll_vars(action.action_dc.dc_roll, monster))['total'];
        }

        var damages = [];
        for (var key_that_should_be_index in action.action_damage) {
            var damage = action.action_damage[key_that_should_be_index];

            if (damage.damage_scaling) {
                if (damage.damage_scaling == 'cast_level') {
                    var damage_roll = damage.damage_by_level[cast_level]
                    if (damage_roll.damage_roll) {
                        damage_roll = damage_roll.damage_roll;
                    }
                }
                else if (damage.damage_scaling == 'level') {
                    var last_key = 1;
                    for (var key in damage.damage_by_level) {
                        if (key > monster.level) {
                            var damage_roll = damage.damage_by_level[last_key]
                            break;
                        }
                        else {
                            last_key = key;
                        }
                    }
                    if (damage_roll.damage_roll) {
                        damage_roll = damage_roll.damage_roll;
                    }
                }
                else {
                    var damage_roll = damage.damage_roll;
                }



                damages.push({damage:parse_roll(replace_roll_vars(damage_roll, monster)), type: damage.damage_type, note:damage.note})
            }
        }

        var global_instance = Object.assign({}, instance);
        console.log("Global Instance", global_instance)
        window.cmState.setGlobalState('selected_monster_instance', global_instance);

        var action_use =  {
            hits: hits,
            dc: spell_save_dc,
            damages: damages,
            action_id: action.id,
            instance_id: instance.id,
            cast_level: cast_level
        }

        //this.props.select_monster_instance(instance);
        this.props.use_action(action_use);

    }

    render() {
        var action = window.cmState.global_state_lookup(this, 'actions', this.props.action.action_id);

        var re_use = null;
        if (action.has_dc || action.has_damage || action.has_attack_roll) {
            re_use = <Button text='Re-Use Action' type='primary' onClick={this.use} />
        }

        var card = <SpellCard spell={action} action_use={this.props.action} modal={true} />
        if (action.action_type != 'Spell') {
            card = <ActionCard action={action} modal={true} />
        }

        return (
            <div>
                <Button onClick={this.remove} text='Remove' type='danger' />
                {card}
            </div>
        );
    }
}


export default AOECard;
