import React, { Component } from 'react';
import { Checkbox, NumberInput, FormWithChildren } from 'library';
import stat_modifier from 'projectLibrary/stat_modifier.js';


class StatValue extends React.Component {
    render() {
        return (
            <div className='stat-value' >
                <div className='rollable stat-name' onClick={() => this.props.roll_skill(this.props.name)} >{this.props.name}</div>
                <NumberInput handleChange={this.props.handleChange} className='stat-number' sneaky_input={true} name={this.props.name} value={this.props.value} />
                <div className='stat-mod' >{stat_modifier(this.props.value)}</div>
            </div>
        );
    }
}

export default class StatValueBlock extends React.Component {
    render (){
        var monster = this.props.monster;

        return (
            <FormWithChildren className='stat-values' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'stat-values'} defaults={monster} >
                <StatValue name='strength' roll_skill={this.props.roll_skill} />
                <StatValue name='dexterity' roll_skill={this.props.roll_skill} />
                <StatValue name='constitution' roll_skill={this.props.roll_skill} />
                <StatValue name='intelligence' roll_skill={this.props.roll_skill} />
                <StatValue name='wisdom' roll_skill={this.props.roll_skill} />
                <StatValue name='charisma' roll_skill={this.props.roll_skill} />
            </FormWithChildren>
        );
    }
}
