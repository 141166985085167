import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class NumberInput extends Component {
    static component_name = 'NumberInput';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <TextInput label={'placeholder'} name={'placeholder'} />,
                <TextInput label={'default'} name={'default'} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'label css'} name={'label_style'} default={{}} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }
    }

    render() {
        var value = this.props.value;
        if (!(value) && this.props.default) {
            value = this.props.default;
        }

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        if (this.props.label) {
          var label = <label style={this.props.label_style || {}} >{this.props.label}</label>
        }

        var input = <input type="number" className={"form-control "  + this.props.className} name={this.props.name}
            onChange={this.props.handleChange} value={value}
            placeholder={this.props.placeholder}
            onKeyPress={this.props.handleKeyPress}
            onBlur={this.props.onBlur}
        />;

        var content = <div className={"form-group " + this.props.className} style={this.props.style || {}}>
            {label}
            {input}
        </div>;

        if (this.props.sneaky_input){
            content = <input type="number" className={"sneaky-input " + this.props.className} name={this.props.name}
                onChange={this.props.handleChange} value={value} style={this.props.style || {}}
                placeholder={this.props.placeholder}
                onKeyPress={this.props.handleKeyPress}
                onBlur={this.props.onBlur}
            />;
        }

        return (
            content
        );
    }
}

export default NumberInput;
