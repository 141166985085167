import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';
import create_icon_list from 'projectLibrary/session_tools/global_data/create_icon_list.js';
import create_icon_colors from 'projectLibrary/session_tools/global_data/create_icon_colors.js';


function update_instance_from_data(instance, data) {
    for (var key in data) {
        if (key.endsWith('[]')) {
            if (key == 'action_spells[]') {
                var temp_list = [];
                for (var item_id of data[key]) {
                    var item = {'actionspell':window.cmState.global_state_lookup(this, 'actions', item_id)};
                    temp_list.push(item);
                }
            }
            else if (key == 'conditions[]') {
                var temp_list = [];
                for (var item_id of data[key]) {
                    var item = {'condition':window.cmState.global_state_lookup(this, 'conditions', item_id)};
                    temp_list.push(item);
                }
            }
            var instance_key = key.replace('[]','');
            instance[instance_key] = temp_list;
        }
        else if (key == 'concentrating_on_id') {
            if (data[key] && data[key].id) {
                instance['concentrating_on_id'] = data[key].id;
            }
        }
        else {
            instance[key] = data[key];
        }
    }

    return instance;
}


function update_monster_instance(updated_instance_data) {
    var id = updated_instance_data.id;

    if (id in window.cmState.action_instances || updated_instance_data.action_id) {
        //Find existing local data and apply changes
        //Or insert new information into an empty object
        var local_data = {};
        local_data = window.cmState.global_state_lookup(this, 'action_instances', id);
        var updated_instance = Object.assign(local_data, updated_instance_data);

        if (updated_instance.action_id == null){
            console.error('NO ACTION ID', updated_instance);
        }

        console.log("Action Instance Id", id);
        window.cmState.action_instances[id] = updated_instance;

        window.cmState.setGlobalState('action_instances', window.cmState.action_instances);
    }
    else {
        console.log("Update Monster Instance", updated_instance_data);
        var icon_list = window.cmState.icon_list;

        var new_instance = false;
        var instance_dict = window.cmState.instances;
        if (!(id in instance_dict)){
            new_instance = true;
        }

        //Find existing local data and apply changes
        //Or insert new information into an empty object
        var local_data = window.cmState.global_state_lookup(this, 'instances', id);
        var updated_instance = update_instance_from_data(local_data, updated_instance_data);

        if (window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.id == updated_instance.id) {
            window.cmState.setGlobalState('selected_monster_instance', updated_instance);
        }

        var monster = window.cmState.global_state_lookup(this, 'monsters', updated_instance.monster_id);
        var icon = load_icon_from_monster(updated_instance, monster);
        icon.current_loc = [
            icon.coords[0] * window.cmState.zoom,
            icon.coords[1] * window.cmState.zoom
        ];

        updated_instance.current_loc = [
            updated_instance.coords[0] * window.cmState.zoom,
            updated_instance.coords[1] * window.cmState.zoom
        ];

        instance_dict[id] = updated_instance;

        var icons = [];
        var icons_added = 0;
        for (var temp_icon of icon_list) {
            if (temp_icon.id == id) {
                icon['colors'] = create_icon_colors(icons_added);
                icons.push(icon);
            }
            else {
                icons.push(temp_icon);
            }

            icons_added ++;
        }

        var player_characters = window.cmState.player_characters;
        if (player_characters[updated_instance.id]) {
            player_characters[updated_instance.id] = updated_instance;
        }

        window.cmState.setGlobalState('player_characters', player_characters);
        window.cmState.setGlobalState('instances', window.cmState.instances);
        if (new_instance){
            create_icon_list();
        }
        else{
            window.cmState.setGlobalState('icon_list', icons);
        }
    }
}


export default update_monster_instance;
