import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { FormWithChildren, TextInput, Select } from 'library';
import Monster from 'projectLibrary/monsters/monster.js';


export default class MonsterSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            race:'',
            monsters: [],
        }

        this.setGlobalState = this.setGlobalState.bind(this);
        this.monsters_callback = this.monsters_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/compendium/monster/?only=id,name,type,image', {}, this.monsters_callback);
    }

    monsters_callback(value){
        var monsters = value;
        monsters.sort((a, b) => (a['monster'].name > b['monster'].name) ? 1 : -1);

        this.setState({monsters: value});
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    render() {
        var monsters = this.state.monsters;

        var races = [];
        var monster_display = []
        for (var item of monsters) {
            var monster = item['monster'];
            var race = monster['type'];
            if (races.indexOf(race) == -1) {
                races.push(race);
            }

            if (this.state.name == '' || monster.name.toLowerCase().indexOf(this.state.name.toLowerCase()) > -1) {
                if (this.state.race == '' || monster.type == this.state.race) {
                    monster_display.push(<Monster key={'monster_' + monster.id}
                        select_monster={this.props.select_monster} unselect_monster={this.props.unselect_monster}
                        monster={monster} selected_monster={this.props.selected_monster} />);
                }
            }
        }

        var race_options = [];
        for (var race of races) {
            race_options.push({'text':race, 'value':race});
        }

        return (
            <div>
                <FormWithChildren autoSetGlobalState={true} row={true} setGlobalState={this.setGlobalState} globalStateName={'monster_search'} defaults={this.state}>
                    <TextInput name='name' label='Name' className="col-6" />
                    <Select label='Monster Race' options={race_options} name='race' className="col-6" />
                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Image</th>
                        <th>Name</th>
                        <th>CR</th>
                        <th>Add</th>
                        <th>Edit</th>
                    </tr>
                    {monster_display}
                </table>
            </div>
        )
    }
}
