import React, { Component } from 'react';
import { Text, Rect } from 'react-konva';


export default class CanvasText extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name('screen_size')
    }


    render() {
        var scaleX = this.props.zoom;
        var scaleY = this.props.zoom;
        var on_click = null;

        var icon = this.props.icon;
        var scale_multiplier = 1;
        if (icon && icon.monster) {
            if (icon.size == 'Large') {
                var scale_multiplier = 2;
            }
            else if (icon.size == 'Huge') {
                var scale_multiplier = 3;
            }
            else if (icon.size == 'Gargantuan') {
                var scale_multiplier = 4;
            }
        }

        var image_size = this.props.image_size;


        scaleX = (this.props.pixels_between_lines * this.props.zoom)/image_size[0] * scale_multiplier
        scaleY = (this.props.pixels_between_lines * this.props.zoom)/image_size[1] * scale_multiplier
        on_click = this.toggle_popup;

        var pixel_grid_size = this.props.zoom * this.props.pixels_between_lines;

        var text_width = pixel_grid_size * 1.2;
        var text_height = pixel_grid_size;
        if (window.cmState.screen_size) {
            text_width = window.cmState.screen_size.width * .05;
            text_height = window.cmState.screen_size.width * .02;
        }

        return (
            [<Rect
            x={this.props.x}
            y={this.props.y + pixel_grid_size * scale_multiplier}
            width={text_width}
            height={text_height * 2}
            fill={'white'}
            opacity={0.5} />,
            <Text
                x={this.props.x}
                y={this.props.y + pixel_grid_size * scale_multiplier}
                text={this.props.text}
                width={text_width}
                fontSize={text_height}
                align={'center'}
            />,
            ]
        );

    }
}
