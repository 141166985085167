import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, Select, CSSInput} from 'library';

var BOOLEANS = [
  {'text':'True', value:true},
  {'text':'False', value:false},
];

class TextArea extends Component {
    static component_name = 'TextArea';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'default'} name={'default'} />,
                <TextInput label={'placeholder'} name={'placeholder'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} options={BOOLEANS} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <CSSInput label={'label css'} name={'label_style'} default={{}} />,
            ],
        }

    }

    render() {

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var label = null;
        if (this.props.label && this.props.label != ''){
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        var value = this.props.default;
        if (this.props.value){
            value = this.props.value;
        }

        return (
              <div className={"form-group " + layout}>
                {label}
                <textarea className="form-control"
                    name={this.props.name}
                    rows={this.props.rows}
                    onChange={this.props.handleChange}
                    onBlur={this.props.onBlur}
                    value={value}
                    placeholder={this.props.placeholder}
                    style={this.props.style || {}}>
                </textarea>
              </div>
        )


    }
}

export default TextArea;
