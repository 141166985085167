import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren,
    PageBreak, Tab, EmptyModal, Link } from 'library';

import ActionsForm from 'projectLibrary/forms/actions.js';
import SpellsForm from 'projectLibrary/forms/spells.js';
import MagicalAttributesForm from 'projectLibrary/forms/magical_attributes.js';

import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';


class EquipmentActionCost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };

        this.refresh = this.refresh.bind(this);
    }

    refresh(){
        this.setState({edit: false});
        this.props.refresh();
    }

    render() {
        var content = null;
        var edit_form = null;

        if (!(this.props.id) || this.state.edit){
            var edit_header = 'Add New';
            var submit_url = '/api/compendium/EquipmentActionCost/';
            if (this.props.id) {
                edit_header = 'Edit Action';
                submit_url = '/api/compendium/EquipmentActionCost/'+ this.props.id +'/';
            }

            var defaults = {
                equipment: this.props.equipment_id,
                action_spell: this.props.action_spell_id,
                resource: this.props.resource_id,
                cost: this.props.cost,
            };

            edit_form = <FormWithChildren submitUrl={submit_url} defaults={defaults} redirect={this.refresh} >
                <h5>{edit_header}</h5>
                <Select name='action_spell' label='Action/Spell' options={this.props.action_options} />
                <Select name='resource' label='Resource' options={this.props.resource_options} />
                <NumberInput name='cost' label='Cost' />
                <TextInput style={{display: 'none'}} name='equipment' />
            </FormWithChildren>
        }
        else {
            var cost = null;
            if (this.props.resource_id){
                cost = <div>{'Costs ' + this.props.cost + ' ' + this.props.resource_lookup[this.props.resource_id]}</div>;
            }
            content = <div style={{position: 'relative'}} >
                <div><h5 style={{display:'inline-block', margin:'0px'}} >{this.props.action_lookup[this.props.action_spell_id]}</h5> {cost}</div>
                <Button text={<i class="fas fa-edit"></i>} type='info' style={{position:'absolute', right:'0px', top:'-5px'}} onClick={() => this.setState({edit: true})} />
            </div>;
        }

        return (<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} >
            {content}
            {edit_form}
        </div>);
    }
}


class EquipmentResource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };

        this.refresh = this.refresh.bind(this);
    }

    refresh(){
        this.setState({edit: false});
        this.props.refresh();
    }

    render() {
        var content = null;
        var edit_form = null;

        if (!(this.props.id) || this.state.edit){
            var edit_header = 'Add New';
            var submit_url = '/api/compendium/EquipmentResource/';
            if (this.props.id) {
                edit_header = 'Edit Resource';
                submit_url = '/api/compendium/EquipmentResource/'+ this.props.id +'/';
            }

            var defaults = {
                equipment: this.props.equipment_id,
                resource: this.props.resource_id,
                quantity: this.props.quantity,
            };

            edit_form = <FormWithChildren submitUrl={submit_url} defaults={defaults} redirect={this.refresh} >
                <h5>{edit_header}</h5>
                <Select name='resource' label='Resource' options={this.props.resource_options} />
                <NumberInput name='quantity' label='Quantity' />
                <TextInput style={{display: 'none'}} name='equipment' />
            </FormWithChildren>
        }
        else {
            content = <div style={{position: 'relative'}} >
                <div><h5 style={{display:'inline-block', margin:'0px'}} >{this.props.quantity + ' ' + this.props.resource_lookup[this.props.resource_id]}</h5></div>
                <Button text={<i class="fas fa-edit"></i>} type='info' style={{position:'absolute', right:'0px', top:'-5px'}} onClick={() => this.setState({edit: true})} />
            </div>;
        }

        return (<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} >
            {content}
            {edit_form}
        </div>);
    }
}


export default class EditEquipment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: 'form',
            name:'',
            description:'',
            spellcasting_modifer: 0,

            actions:[],
            action_lookup: [],
            action_options: [],

            resources:[],
            resource_lookup:[],
            resource_options:[],

            magical_attributes:[],

            saving: false,
            saved: false,
            loaded:false,
        };

        this.refresh_equipment = this.refresh_equipment.bind(this)
        this.equipment_callback = this.equipment_callback.bind(this);
        this.resource_callback = this.resource_callback.bind(this);
        this.action_callback = this.action_callback.bind(this);

        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);

        this.add_magical_attribute = this.add_magical_attribute.bind(this);
        this.remove_magical_attribute = this.remove_magical_attribute.bind(this);

        this.setGlobalState = this.setGlobalState.bind(this);
        this.redirect_to_equipment = this.redirect_to_equipment.bind(this);
    }

    componentDidMount() {
        if (this.props.equipment_id) {
            this.refresh_equipment();
        }
        else {
            this.setState({loaded:true});
        }

        ajaxWrapper('GET','/api/compendium/resource/?only=name', {}, this.resource_callback);
        ajaxWrapper('GET','/api/compendium/actionspell/?only=name', {}, this.action_callback);
    }

    refresh_equipment(){
        ajaxWrapper('GET','/api/compendium/equipment/' + this.props.equipment_id + '/?related=actions,resources,magical_attributes', {}, this.equipment_callback);
    }

    equipment_callback(result) {
        var equipment = result[0].equipment;
        equipment.loaded = true;

        console.log("Equipment", equipment);

        this.setState(equipment);
    }

    resource_callback(value){
        var resource_options = [];
        var resource_lookup = {};

        for (var item of value){
            var resource = item['resource'];
            resource_lookup[resource['id']] = resource['name'];
            resource_options.push({'text': resource['name'], 'value': resource['id']});
        }

        resource_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        this.setState({
            resource_lookup: resource_lookup,
            resource_options: resource_options,
        });
    }

    action_callback(value){
        var action_options = [];
        var action_lookup = {};

        for (var item of value){
            var action = item['actionspell'];
            action_lookup[action['id']] = action['name'];
            action_options.push({'text': action['name'], 'value': action['id']});
        }

        action_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        this.setState({
            action_lookup: action_lookup,
            action_options: action_options,
        });
    }

    add_magical_attribute(attribute) {
        console.log("Add attribute", attribute);
        var magical_attributes = this.state.magical_attributes;
        magical_attributes.push({'magicalattribute':attribute});
        this.setState({magical_attributes:magical_attributes});
    }

    remove_magical_attribute(attribute) {
        console.log("Remove attribute", attribute);
        var magical_attributes = this.state.magical_attributes;
        var index = magical_attributes.indexOf({'magicalattribute':attribute});
        magical_attributes.splice(index, 1);
        this.setState({magical_attributes:magical_attributes});
    }

    setGlobalState(name, state) {
        this.setState(state)
    }

    submit() {
        var data = {
            name: this.state.name,
            description:this.state.description,
            spellcasting_modifer: this.state.spellcasting_modifer
        }

        if (this.props.equipment_id) {
            var id = this.props.equipment_id;

            var magical_attributes = [];
            for (var item of this.state.magical_attributes) {
                var action_id = item.magicalattribute.id;
                magical_attributes.push(action_id);
            }
            data['magical_attributes[]'] = magical_attributes;

            ajaxWrapper('POST','/api/compendium/equipment/' + id + '/', data, this.submit_callback);
        }
        else {
            ajaxWrapper('POST','/api/compendium/equipment/', data, this.redirect_to_equipment);
        }

        this.setState({saving:true, saved:false});
    }

    submit_callback() {
        this.setState({
            saving: false,
            saved: true,
        });
    }

    redirect_to_equipment(result) {
        var id = result[0].equipment.id;
        window.location = '/edit_equipment/'+ id +'/';
    }

    render() {
        var submitUrl = '/api/compendium/equipment/';
        var defaults = this.state;
        var delete_button = null;

        if (this.props.equipment_id) {
            submitUrl = '/api/compendium/equipment/' + this.props.equipment_id + '/';
            delete_button = <Button type='danger' text='Delete Equipment' dangerType={true} href={'/api/compendium/equipment/' + this.props.equipment_id + '/delete/'} />;
        }

        var saving = null;
        var saved = null;
        if (this.state.saving) {
            saving = <Alert type='warning' text='Saving...' />
        }
        if (this.state.saved) {
            saved = <Alert type='success' text='Saved' />
        }

        var content = null;
        if (this.state.loaded) {
            var actions = [];
            
            for (var item of this.state.actions) {
                var action_index = this.state.actions.indexOf(item);
                actions.push(<EquipmentActionCost index={action_index} {...item.equipmentactioncost} refresh={this.refresh_equipment}
                    action_options={this.state.action_options} action_lookup={this.state.action_lookup}
                    resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup} />);
            }

            var resources = [];
            for (var resource of this.state.resources) {
                var resource_index = this.state.resources.indexOf(resource);
                resources.push(<EquipmentResource index={resource_index} {...resource.equipmentresource} refresh={this.refresh_equipment}
                    resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup} />);
            }

            var attributes = [];
            for (var item of this.state.magical_attributes) {
                var attribute = item.magicalattribute;
                attributes.push(<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} ><h5 style={{margin:'0px'}}>{attribute.name}</h5></div>);
            }

            var actions_card = null;
            var resources_card = null;
            var magical_attributes = null;
            if (this.props.equipment_id){
                actions_card = <div className="col-12 card">
                    <h3>Action/Spells</h3>
                    {actions}
                    <EquipmentActionCost index={-1} refresh={this.refresh_equipment} equipment_id={this.props.equipment_id}
                        action_options={this.state.action_options} action_lookup={this.state.action_lookup}
                        resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup}
                        reset_state_on_submit={true} />
                </div>;

                resources_card = <div className="col-12 card">
                    <h3>Resources</h3>
                    {resources}
                    <EquipmentResource index={-1} refresh={this.refresh_equipment} equipment_id={this.props.equipment_id}
                        resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup}
                        reset_state_on_submit={true} />
                </div>;

                magical_attributes = <div className="col-12 card">
                    <h3>Magical Attributes</h3>
                    {attributes}
                    <Button type='primary' text='Add Magical Attribute' onClick={() => this.setState({show: 'magical_attributes'})} />
                </div>;
            }

            content = <div className="container">
                <div className='card-container row'>
                    <div className='card col-12' >
                        <Link href='/dm_tools/equipment_list/' text='<- Equipment List' />
                    </div>

                    <div className="col-12 card">
                        <h1>{'Edit Equipment: ' + defaults['name']}</h1>

                        <FormWithChildren setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} defaults={defaults}>
                            <TextInput name='name' label='Name' />
                            <TextArea name='description' label='Description' />
                            <ImageInput name='image' label='Image' multiple={false} preview={true} preview_height={'150px'}
                                bucket_name="mapquest-public" is_public={true} />
                            <NumberInput name='spellcasting_modifer' label='Spellcasting Modifier' />
                        </FormWithChildren>
                    </div>

                    {actions_card}
                    {resources_card}
                    {magical_attributes}

                    <div className='col-12'><br/></div>
                    <div className='card col-12'>
                        {saving}
                        {saved}
                        <div><Button style={{padding: '15px 30px', fontSize:'1.2em'}} type='success' text='Save Equipment' onClick={this.submit} /></div>
                        <br/>
                        <div>{delete_button}</div>
                    </div>
                </div>
            </div>;
        }

        var form_modal = null;
        if (this.state.show == 'magical_attributes') {
            form_modal = <EmptyModal show={true} onHide={() => this.setState({show: 'form'})} >
                <Button type='danger' text='Back To Item' onClick={() => this.setState({show: 'form'})} />
                <MagicalAttributesForm magical_attributes={this.state.magical_attributes}
                    add_magical_attribute={this.add_magical_attribute} remove_magical_attribute={this.remove_magical_attribute} />
            </EmptyModal>;
        }


        return (
            <div>
                {content}
                {form_modal}
                <br/><br/>
            </div>
        )
    }
}
