import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, EmptyModal } from 'library';
import EquipmentCard from 'projectLibrary/equipment_card.js';
import EditEquipment from 'projectLibrary/forms/edit_equipment.js';


class Equipment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    render() {
        var equipment = this.props.equipment;
        var edit = <Button type='secondary' text='Edit Equipment' href={'/edit_equipment/'+ equipment.id +'/'} target='_blank' />;

        var floating_card = null;
        if (this.state.show) {
            floating_card = <div style={{position: 'absolute', top:'100%', left:'0px', width:'100%', zIndex:'10', cursor: 'pointer'}} onClick={() => this.setState({show:!this.state.show})} >
                <EquipmentCard equipment={this.props.equipment} />
            </div>;
        }

        var ac = null;
        if ('base' in equipment.armor_class){
            ac = equipment.armor_class.base;
        }
        var magic = null;
        if (equipment.magical_attributes || equipment.magic){
            magic = 'Yes';
        }

        var content = [];
        content.push(
            <tr style={{position: 'relative'}} >
                <td><a onClick={() => this.setState({show:!this.state.show})} >{equipment.name}</a></td>
                <td>{equipment.equipment_category}</td>
                <td>{magic}</td>
                <td>{ac}</td>
                <td>{edit}</td>
                {floating_card}
            </tr>
        );



        return (content);
    }
}


export default class EquipmentList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_name:'',
            is_magic: null,
            equipment_list:[],
            loaded: false,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.equipment_callback = this.equipment_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data(){
        ajaxWrapper('GET','/api/compendium/equipment/?related=magical_attributes__count&order_by=name', {}, this.equipment_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    equipment_callback(result) {
        var equipment_list = [];
        for (var item of result) {
            var equipment = item['equipment'];
            equipment_list.push(equipment);
        }

        this.setState({'equipment_list':equipment_list, loaded:true});
    }

    render() {
        var equipment_list = [];

        for (var equipment of this.state.equipment_list) {
            if (this.state.is_magic == false && (equipment.magical_attributes || equipment.magic)){
                continue;
            }
            if (this.state.is_magic == true && !(equipment.magical_attributes || equipment.magic)){
                continue;
            }
            if (this.state.search_name != '' && equipment.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) == -1) {
                continue;
            }

            equipment_list.push(<Equipment equipment={equipment} />);
        }

        var content = <div>
            <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}} >
                <div>
                    <h2>Equipment</h2>
                    <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                        <TextInput name='search_name' label='Search by Name' className="col-4" />
                        <Select name='is_magic' label='Is Magic' boolean={true} className="col-4" />
                    </FormWithChildren>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Magic</th>
                            <th>AC</th>
                            <th>Edit</th>
                        </tr>
                        {equipment_list}
                    </table>
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
