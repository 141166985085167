import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextArea} from 'library';

class HTMLDisplay extends React.Component {
    static component_name = 'HTMLDisplay';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextArea label={'html'} name={'html'} default={'<p>HTML</p>'} />,
            ],
        }
    }

    render() {

        var html = null;
        if (this.props.html) {
            var regExp = /\{([^{}]+)\}/g;
            var matches = [...this.props.html.matchAll(regExp)];
            var html = this.props.html;
            console.log("HTML", html)
            for (var index in matches) {
                console.log("Match", matches[index])
                html.replace(matches[index])
            }

            html = {'__html': html}
        }

        return (
            <div dangerouslySetInnerHTML={html}></div>
        );
    }
}

export default HTMLDisplay;
