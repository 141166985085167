import React, { Component } from 'react';


class BrokenComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var get_undefined_value = this.props.doesnt_exist.nothing;

        return this.props.children;
    }
}

export default BrokenComponent;
