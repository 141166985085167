import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';
import create_icon_colors from 'projectLibrary/session_tools/global_data/create_icon_colors.js';


function update_monster(monster) {
    var id = monster.id;

    if (!(id in window.cmState.monsters)){
        window.cmState.monsters[id] = monster;
        window.cmState.setGlobalState('monsters', window.cmState.monsters);
    }
    else {
        if (window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.monster_id == id) {
            var selected_monster_instance = window.cmState.selected_monster_instance;

            selected_monster_instance.monster = monster;
            window.cmState.setGlobalState('selected_monster_instance', selected_monster_instance);
        }

        var icon_list = window.cmState.icon_list;
        var instance_dict = window.cmState.instances;

        var instances = [];
        var icons = [];
        var icons_added = 0;
        for (var temp_icon of icon_list) {
            var temp_instance = window.cmState.global_state_lookup(this, 'instances', temp_icon.id);
            var temp_monster = window.cmState.global_state_lookup(this, 'monsters', temp_instance.monster_id);

            if (temp_monster.id == id) {
                temp_instance.monster = monster;
                instance_dict[temp_instance.id] = temp_instance;
                var icon = load_icon_from_monster(temp_instance, monster);
                icon['colors'] = create_icon_colors(icons_added);

                icons.push(icon);
            }
            else {
                icons.push(temp_icon);
            }

            icons_added += 1;
        }

        window.cmState.setGlobalState('monsters', window.cmState.monsters);
        window.cmState.setGlobalState('instances', instance_dict);
        window.cmState.setGlobalState('icon_list', icons);
    }
}

export default update_monster;
