import React, { Component } from 'react';
import {LogInForm, Button} from 'library';


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render () {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12"><br/><br/></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <h1>Log In</h1>
                            <br/>
                            <LogInForm />

                            <div>
                                <br/>
                                <div>Forgot your password?</div>
                                <Button type='link' text='Reset Password' href='/passwordresetrequest/' style={{padding:'0px'}} />
                            </div>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login;
