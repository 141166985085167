import React, { Component } from 'react';
import { Button, PageBreak } from 'library';

class PC extends React.Component {
    constructor(props) {
        super(props);

        this.select_pc = this.select_pc.bind(this);
    }

    select_pc() {
        if (this.props.pc == this.props.selected_pc) {
            this.props.select_pc(null)
        }
        else {
            this.props.select_pc(this.props.pc)
        }
    }

    render() {
        var select = <td><Button type='primary' text='Select PC' onClick={this.select_pc}/></td>
        if (this.props.pc == this.props.selected_pc) {
            select = <td><Button type='danger' text='DeSelect PC' onClick={this.select_pc}/></td>
        }

        return (
            <tr>
                <td>{this.props.pc.monster.name}</td>
                {select}
            </tr>
        )
    }
}

export default class PCList extends React.Component {
    render() {
        var pcs = [];
        for (var item of this.props.player_characters) {
            var pc = item['playercharacter']['main_instance'];
            if (pc.id) {
                pcs.push(<PC pc={pc} select_pc={this.props.select_pc} selected_pc={this.props.selected_pc}/>);
            }
        }

        return (
            <div >
                <h2>Player Characters</h2>
                <PageBreak />
                <table className="table">
                    <tr>
                        <th>Character</th>
                        <th>Add</th>
                    </tr>
                    {pcs}
                </table>
            </div>
        );
    }
}
