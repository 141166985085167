import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, PageBreak } from 'library';


export default class MyGroups extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dm_group_list:[],
            player_group_list: [],
            loaded: false
        };

        this.dm_group_callback = this.dm_group_callback.bind(this);
        this.player_group_callback = this.player_group_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/home/adventuringgroup/?dm=' + window.cmState.user.id, {}, this.dm_group_callback);
        ajaxWrapper('GET','/api/home/adventuringgroup/?player_characters__user=' + window.cmState.user.id, {}, this.player_group_callback);
    }


    dm_group_callback(result) {
        var group_list = [];
        for (var item of result) {
            var group = item['adventuringgroup'];
            group_list.push(group);
        }

        this.setState({'dm_group_list':group_list, loaded:true});
        //ajaxWrapper('GET','/api/home/adventuringgroup/?player_characters__user=' + window.cmState.user.id, {}, this.player_group_callback)
    }

    player_group_callback(result) {
        var group_list = [];
        for (var item of result) {
            var group = item['adventuringgroup'];
            group_list.push(group);
        }

        this.setState({'player_group_list':group_list, loaded:true});
    }

    render() {
        var dm_groups = [];
        for (var group of this.state.dm_group_list) {
            if (group) {
                dm_groups.push(<div className="card col-3" style={{}}>
                    <div className="card-body">
                        <h3 className="card-title">{group['name']}</h3>
                        <Button type='secondary' text='Play' href={'/session/' + group.id + '/'} />
                        <Button type='secondary' text='Edit' href={'/group/' + group.id + '/'} />
                    </div>
                </div>)
            }
        }

        var player_groups = [];
        for (var group of this.state.player_group_list) {
            if (group) {
                player_groups.push(<div className="card col-3" style={{}}>
                    <div className="card-body">
                        <h3 className="card-title">{group['name']}</h3>
                        <Button type='secondary' text='Play' href={'/session/' + group.id + '/'} />
                    </div>
                </div>);
            }
        }

        var content = <div className="container">
            <div>
                <br/>
                <h1>Groups</h1>
                <div className="row">
                    <div className='col-12'><br/><br/></div>
                    <h3 className="col-12">Groups You Play In</h3>
                    <br/>
                    <div className='card-container row col-12'>
                        {player_groups}
                    </div>

                    <div className='col-12'><br/><br/></div>

                    <h3 className="col-12">Groups You DM</h3>
                    <br/>
                    <div className='card-container row col-12'>
                        {dm_groups}

                        <div className="card col-3" style={{}}>
                            <div className="card-body">
                                <Button type='primary' className='big-btn' text='Add New Group' href='/group/' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
