import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, Image } from 'library';

class LocationItem extends React.Component {
    constructor(props) {
        super(props);

        this.change_location = this.change_location.bind(this);
        this.center_map = this.center_map.bind(this);
    }

    change_location() {
        this.props.change_location(this.props.location.id);
    }

    center_map(event){
        var data = Object.assign({}, this.props.location);
        window.cmState.setGlobalState('selected_monster_instance', data, true);

        if (this.props.details){
            this.props.center_map(event, this.props.details['coords']);
        }
    }

    render() {
        var location = this.props.location;

        var parent_tag = null;
        if (this.props.is_parent){
            parent_tag = <span className='tag' >Parent</span>;
        }

        var center = <Button text={[<i class="far fa-compass"></i>,' View Details']} type='info' onClick={this.center_map} />;

        var travel = <Button text={[
                <Image src='https://mapquest-public.s3.amazonaws.com/22_05_11_19_56_55_wagon_icon.png' style={{verticalAlign: 'top', height: '14px', width:'auto', margin: '2px 5px 0px 0px'}} />,
                <span>Travel</span>
            ]} type='success' onClick={this.change_location} />;
        if (this.props.no_travel){
            travel = <Button text='You Are Here' type='primary' onClick={this.change_location} disabled={'disabled'} />;
        }
        else if (!(location.location_image)){
            travel = <Button text='No Map Found :(' type='outline-danger' onClick={this.change_location} disabled={'disabled'} />;
        }

        var edit = null;
        var has_permission = false;
        var current_user = window.cmState.user;
        if (current_user && (current_user.is_staff || current_user['id'] == location.user_who_added_id)){
            has_permission = true;
        }
        if (has_permission){
            edit = <Button text='Edit' type='warning' href={'/edit_location/'+ location.id +'/'} target='_blank' style={{marginLeft:'10px'}} />;
        }

        return (
            <tr>
                <td>{location.name}{parent_tag}</td>
                <td>{center} {travel} {edit}</td>
            </tr>
        )
    }
}

export default class LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: [],
            loaded: true,
            filter:'',
            all: false,
        };

        this.setGlobalState = this.setGlobalState.bind(this);
        this.locations_callback = this.locations_callback.bind(this);
        this.travel = this.travel.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET', '/api/home/location/?related=parent&only=id,name,parent,location_image,user_who_added&related__parent__only=id&related__user_who_added__only=id', {}, this.locations_callback);
    }

    locations_callback(value) {
        var locations = value;
        for (var location of locations){
            location['location']['parent_id'] = location['location']['parent']['id'];
            delete location['location']['parent'];
        }

        locations.sort((a, b) => (a['location'].name > b['location'].name) ? 1 : -1);

        this.setState({locations: value});
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    travel(id) {
        if (this.props.change_location) {
            this.props.change_location(id);
        }
        else {
            window.location.href = '/?id=' + id;
        }
    }

    render() {
        var current_location = this.props.display_location;
        var parent_id = current_location.parent_id;

        var locations = [];

        for (var item of this.state.locations) {
            var location = item['location'];

            var no_travel = false;
            if (location.id == this.props.map_location['id']){
                no_travel = true;
            }

            if (parent_id == location.id){
                locations.unshift(<LocationItem location={location} change_location={this.travel} no_travel={no_travel} is_parent={true} />);
            }

            var location_parent = '';
            if ('parent_id' in location){
                location_parent = location['parent_id'];
            }

            var location_details = null;
            var is_on_map = this.props.map_location.id == location_parent;
            if (is_on_map) {
                for (var child of this.props.map_location.children){
                    if (child['location']['id'] == location['id']){
                        location_details = child['location'];
                    }
                }
            }

            var is_relative = current_location.id == location_parent;
            if (is_relative || this.state.all) {
                if (this.state.filter == '' || location.name.toLowerCase().indexOf(this.state.filter.toLowerCase()) > -1) {
                    locations.push(<LocationItem location={location} details={location_details} no_travel={no_travel}
                        change_location={this.travel} center_map={this.props.center_map}  />);
                }
            }
        }

        var content = <div className="container">
            <div>
                <h3>Additional Locations</h3>
                <FormWithChildren className='row' autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'location_filter'}>
                    <TextInput className='col-lg-6' label='Name' name='filter' label='Name' />
                    <Select className='col-lg-6' label='All' boolean={true} radio={true} name='all' />
                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th>Go There</th>
                    </tr>
                    {locations}
                </table>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
