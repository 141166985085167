import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import Link from 'projectLibrary/proc_gen/link.js';


export default class BuildingContent extends React.Component {

    render() {
        var building = this.props.building;

        var extra_content = null;

        if (building.occupied == false) {
            extra_content = <p>This property is currently abandoned.</p>
        }
        else if (building.type == 'House') {
            extra_content = [<p>{building.occupant.length} person(s) live here.</p>];
            for (var item of building.occupant) {
                var person = item.person;
                extra_content.push(<Link type='person' select_item={this.props.select_item} text={person.name} id={person.id} />)
            }
        }
        else {
            extra_content = []
            for (var item of building.business) {
                var business = item.business;
                var business_index = building.business.indexOf(item);

                if (business_index == building.business.length - 1) {
                    extra_content.unshift(<p>This building is a {business.profession} store called <Link text={business.name} type='business' select_item={this.props.select_item} id={business.id} /></p>)
                }
                else {
                    extra_content.unshift(<p>This building use to hold a {business.profession} store called <Link text={business.name} type='business' select_item={this.props.select_item} id={business.id} /></p>)
                }
            }

        }

        return (
            <div>
                <p>This Building is a {building.type}, it was built {building.age} years ago.</p>
                {extra_content}
            </div>
        )
    }
}
