import {resolveVariables, ajaxWrapper, ga_event} from 'functions';


function run_functions(functions, setState, setGlobalState) {
    for (var index in functions) {
        console.log("Running Function", functions[index], functions);
        var temp_function = functions[index];
        var values = resolveVariables({values:temp_function[1]}, window.cmState.getGlobalState())['values'];

        if (temp_function[0] == 'ajaxWrapper') {
            for (var i = 0; i <= index; i++) {
                functions.shift();
            }
            ajaxWrapper(values['type'],values['url'], values['data'], run_functions, null, [functions, setState, setGlobalState]);
            break;
        }
        else if (temp_function[0] == 'setState') {
            setState(values);
        }
        else if (temp_function[0] == 'ga_event') {
            ga_event(values['category'], values['action']);
        }
        else if (temp_function[0] == 'setGlobalState') {
            for (var key in values) {
                window.cmState.setGlobalState(key, values[key]);
            }
        }
        else if (temp_function[0] == 'redirect') {
            window.location = values;
        }
    }
}

export default run_functions;
