import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Container, Checkbox, FormWithChildren, NumberInput, EmptyModal, Button } from 'library';

import stat_modifier from 'projectLibrary/stat_modifier.js';
import StatValueBlock from 'projectLibrary/monsters/fancy_char_sheet/stat_values.js';
import SavingThrowBlock from 'projectLibrary/monsters/fancy_char_sheet/saving_throws.js';
import SkillProficiencyBlock from 'projectLibrary/monsters/fancy_char_sheet/skill_proficiencies.js';

import PlayerMetaData from 'projectLibrary/monsters/fancy_char_sheet/player_metadata.js';
import ActionList from 'projectLibrary/monsters/fancy_char_sheet/actions_list.js';
import FeatureList from 'projectLibrary/monsters/fancy_char_sheet/features_list.js';
import ResourceList from 'projectLibrary/monsters/fancy_char_sheet/resources_list.js';
import EquipmentList from 'projectLibrary/monsters/fancy_char_sheet/equipment_list.js';
import ActionsForm from 'projectLibrary/forms/actions.js';
import EquipmentForm from 'projectLibrary/forms/equipment.js';


export default class FancyCharSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0};

        this.updateDimensions = this.updateDimensions.bind(this);
        this.show_actions = this.show_actions.bind(this);
        this.show_abilities = this.show_abilities.bind(this);
        this.show_equipment = this.show_equipment.bind(this);
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    updateDimensions() {
      var width = document.getElementById('fancy-char-sheet').getBoundingClientRect().width;
      this.setState({'width':width});
    }

    show_actions(){
        this.props.push_modal_content(
            <ActionsForm monster={this.props.monster} update_monster={this.props.update_monster} only_attacks={true} card_inline={true} />
        );
    }

    show_abilities(){
        this.props.push_modal_content(
            <ActionsForm monster={this.props.monster} update_monster={this.props.update_monster} only_abilities={true} push_modal_content={this.props.push_modal_content} card_inline={true} />
        );
    }

    show_equipment(){
        this.props.push_modal_content(
            <EquipmentForm monster={this.props.monster} update_monster={this.props.update_monster} push_modal_content={this.props.push_modal_content} />
        );
    }

    render() {
        var font_size = (this.state.width * .013) + 'px';
        var instance = this.props.instance;
        var monster = this.props.monster;
        var pb = monster.proficiency_bonus;

        var props_to_pass = {
            instance: instance,
            monster: monster,

            push_modal_content: this.props.push_modal_content,
            use_action: this.props.use_action,
            add_action_token: this.props.add_action_token
        };

        return (
            <div id='fancy-char-sheet' className='fancy-sheet fancy-char-sheet row' style={{fontSize: font_size}} >
                <PlayerMetaData monster={monster} update_monster={this.props.update_monster} />

                <div className='body col-12 row' >
                    <div className='col-4' >
                        <div className='stats row' >
                            <div className='col-4'>
                                <StatValueBlock monster={monster} roll_skill={this.props.roll_skill} update_monster={this.props.update_monster} />
                            </div>
                            <div className='col-8'>
                                <div className='inspiration' >INSPIRATION</div>
                                <div className='proficiency-bonus' >
                                    PROFICIENCY BONUS
                                    <div className='bonus-value' >{monster.proficiency_bonus}</div>
                                </div>

                                <SavingThrowBlock monster={monster} roll_skill={this.props.roll_skill} update_monster={this.props.update_monster} />
                                <SkillProficiencyBlock monster={monster} roll_skill={this.props.roll_skill} update_monster={this.props.update_monster} />

                            </div>
                        </div>
                        <div className='passive-perception' >
                            PASSIVE PERCEPTION
                            <div className='passive-value' >{monster.passive_perception}</div>
                        </div>
                        <div className='proficiencies' >
                            <div className='bottom-label bordered'>PROFICIENCIES</div>
                            <div>Coming Soon!</div>
                        </div>
                    </div>
                    <div className='col-4' >
                        <div className='health'>
                        <FormWithChildren className='row' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'health'} defaults={monster} >
                            <div className='col-4'>
                                <div className='armor' >
                                    <div className='armor-label'>ARMOR<br/>CLASS</div>
                                    <NumberInput className='armor-value' sneaky_input={true} name={'armor_class'} />
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='initiative-mod' >
                                    <div className='bottom-label'>INITIATIVE</div>
                                    <div className='initiative-value'>{stat_modifier(monster.dexterity)}</div>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='speed' >
                                    <div className='bottom-label'>SPEED</div>
                                    <NumberInput className='speed-value' sneaky_input={true} name={'walk_speed'} />
                                </div>
                            </div>
                        </FormWithChildren>

                        <div className='row'>
                            <div className='col-12'>
                                <div>
                                    <div className='hp' >
                                        <div style={{padding:'1% 10%'}} >
                                            <div>Hit Point Maximum </div>
                                            <FormWithChildren className='hp-max' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'health'} defaults={monster} >
                                                <NumberInput sneaky_input={true} name={'hit_points'} style={{textAlign:'center'}} />
                                            </FormWithChildren>
                                            <FormWithChildren className='hp-current' setGlobalState={this.props.update_instance} autoSetGlobalState={true} globalStateName={'health'} defaults={instance} >
                                                <NumberInput sneaky_input={true} name={'current_hp'} style={{textAlign:'center'}} />
                                            </FormWithChildren>
                                        </div>
                                        <div className='bottom-label'>CURRENT HIT POINTS</div>
                                    </div>
                                    <div className='temp-hp' >
                                        <FormWithChildren className='hp-temp' setGlobalState={this.props.update_instance} autoSetGlobalState={true} globalStateName={'health'} defaults={instance} >
                                            <NumberInput sneaky_input={true} name={'temp_hp'} style={{textAlign:'center'}} />
                                        </FormWithChildren>
                                        <div className='bottom-label'>TEMP HIT POINTS</div>
                                    </div>
                                </div>
                            </div>
                            <FormWithChildren className='col-12 row' setGlobalState={this.props.update_instance} autoSetGlobalState={true} globalStateName={'health'} defaults={instance} >
                                <div className='col-6'>
                                    <div className='hit-dice' >
                                        <div style={{padding:'1% 10%'}} >
                                            <div>Total </div>
                                            <div className='hit-dice-max'>{monster.level}</div>
                                            <NumberInput className='hit-dice-current' sneaky_input={true} name={'current_hit_dice'} />
                                        </div>
                                        <div className='bottom-label'>HIT DICE</div>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='death-saves' >
                                        <div className='death-save-success'>
                                            SUCCESSES
                                            <div>
                                                <Checkbox name={'death_success_1'} style={{display:'inline-block'}} secret_value={1} />
                                                <Checkbox name={'death_success_2'} style={{display:'inline-block'}} secret_value={1} />
                                                <Checkbox name={'death_success_3'} style={{display:'inline-block'}} secret_value={1} />
                                            </div>
                                        </div>
                                        <div className='death-save-failure'>
                                            FAILURES
                                            <div>
                                                <Checkbox name={'death_failure_1'} style={{display:'inline-block'}} secret_value={1} />
                                                <Checkbox name={'death_failure_2'} style={{display:'inline-block'}} secret_value={1} />
                                                <Checkbox name={'death_failure_3'} style={{display:'inline-block'}} secret_value={1} />
                                            </div>
                                        </div>
                                        <div className='bottom-label'>DEATH SAVES</div>
                                    </div>
                                </div>
                            </FormWithChildren>
                        </div>
                        </div>
                        <div className='bordered-box attacks' >
                            <ActionList {...props_to_pass} />
                            <Button type={'default'} text={'Edit'} onClick={this.show_actions} />
                            <div className='bottom-label bordered'>ATTACKS</div>
                        </div>
                        <div className='bordered-box equipment' >
                            <EquipmentList {...props_to_pass} update_instance={this.props.update_instance} />
                            <Button type={'default'} text={'Edit'} onClick={this.show_equipment} />
                            <div className='bottom-label bordered'>EQUIPMENT</div>
                        </div>
                    </div>
                    <div className='col-4' >
                        <div className='resources row' >
                            <ResourceList {...props_to_pass} update_instance={this.props.update_instance} update_monster={this.props.update_monster} />
                        </div>
                        <div className='bordered-box features' >
                            <FeatureList {...props_to_pass} />
                            <Button type={'default'} text={'Edit'} onClick={this.show_abilities} />
                            <div className='bottom-label bordered'>FEATURES & TRAITS</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
