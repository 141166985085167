import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, PageBreak } from 'library';


export default class LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: true
        }
    }

    componentDidMount() {

    }

    render() {
        var content = <div className="container">
            <div>
                <br/>
                <h1>DM Tools</h1>

                <div className="row">
                    <div className='col-12'><br/><br/></div>
                    <div className='col-12'><Button href='/dm_tools/action_list/' text='Action List' type='info' /></div>
                    <div className='col-12'><Button href='/dm_tools/equipment_list/' text='Equipment List' type='info' /></div>
                    <div className='col-12'><Button href='/dm_tools/monster_list/' text='Monster List' type='info' /></div>
                    <div className='col-12'><Button href='/dm_tools/magical_attribute_list/' text='Magical Attribute List' type='info' /></div>
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
