function stat_modifier(number) {
    return Math.floor(number/2) - 5;
}

function replace_roll_vars(roll, monster) {
    if (typeof(roll) == "number") {
        roll = " + " + String(roll)
    }
    
    roll = roll.replace('{PROFICIENCY_BONUS}', monster.proficiency_bonus)
    roll = roll.replace('{STR_MOD}', stat_modifier(monster.strength))
    roll = roll.replace('{DEX_MOD}', stat_modifier(monster.dexterity))
    if (monster.strength > monster.dexterity) {
        roll = roll.replace('{STR_MOD/DEX_MOD}', stat_modifier(monster.strength))
    }
    else {
        roll = roll.replace('{STR_MOD/DEX_MOD}', stat_modifier(monster.dexterity))
    }

    roll = roll.replace('{CON_MOD}', stat_modifier(monster.constitution))
    roll = roll.replace('{INT_MOD}', stat_modifier(monster.intelligence))
    roll = roll.replace('{WIS_MOD}', stat_modifier(monster.wisdom))
    roll = roll.replace('{CHA_MOD}', stat_modifier(monster.charisma))
    roll = roll.replace('{SPELL_MOD}', monster.spellcasting_modifier - monster.proficiency_bonus)
    roll = roll.replace('{LEVEL}', monster.level)
    return roll
}

export default replace_roll_vars;
