import React, { Component } from 'react';
import { Button, FormWithChildren, ImageInput, NumberInput, Select, TextInput, Tab, Container } from 'library';
import {ajaxWrapper} from 'functions';
import EditDamageType from 'projectLibrary/forms/edit_damage_type.js';
import EditCondition from 'projectLibrary/forms/edit_condition.js';
import EditLanguage from 'projectLibrary/forms/edit_language.js';
import EditAction from 'projectLibrary/forms/edit_action.js';
import Spells from 'projectLibrary/forms/spells.js';
import save_monster from 'projectLibrary/session_tools/global_data/save_monster.js';

class ActionItem extends React.Component {
    constructor(props) {
        super(props);

        this.edit = this.edit.bind(this);
        this.remove = this.remove.bind(this);
    }

    edit() {
        this.props.setGlobalState({
            'add':'action',
            action:this.props.action
        });
    }

    remove() {
        this.props.remove_action(this.props.action);
    }

    render() {
        return (
            <tr>
                <td>{this.props.action.name}</td>
                <td><Button text='Edit' type='primary' onClick={this.edit} /></td>
                <td><Button text='Remove' type='danger' deleteType={true} onClick={this.remove} /></td>
            </tr>
        );
    }
}

export default class EditMonster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {tab: 'Image', monster: this.props.monster, add: null, action:null}

        this.select_tab = this.select_tab.bind(this);
        this.redirect = this.redirect.bind(this);
        this.open_edit_form = this.open_edit_form.bind(this);
        this.remove_action = this.remove_action.bind(this);
        this.add_action = this.add_action.bind(this);
        this.submit = this.submit.bind(this);
    }

    open_edit_form(state) {
        if (state.add) {
            var edit_form = null;
            if (state.add == 'damagetype') {
                edit_form = <EditDamageType />;
            }
            else if (state.add == 'condition') {
                edit_form = <EditCondition />;
            }
            else if (state.add == 'language') {
                edit_form = <EditLanguage />;
            }
            else if (state.add == 'action') {
                edit_form = <EditAction action={state['action']} add_action={this.add_action} />;
            }

            this.props.push_modal_content(edit_form);
        }
    }

    add_action(action, callback) {
        console.log("Add Action", action[0]);
        var monster = this.state.monster;
        monster.action_spells.push(action[0]);
        var actions = [];
        for (var item of monster.action_spells) {
            actions.push(item.action.id);
        }

        this.setState({monster:monster});

        ajaxWrapper('POST','/api/compendium/monster/' + monster.id + '/', {'action_spells[]': actions}, console.log);
        callback();
    }

    remove_action(action) {
        var monster = this.state.monster;
        var actions = [];
        var monster_actions = [];
        for (var item of monster.action_spells) {
            if (item.id != action.id) {
                actions.push(item.id);
                monster_actions.push(item);
            }
        }
        monster['action_spells'] = monster_actions;
        this.setState({monster:monster});

        ajaxWrapper('POST','/api/compendium/monster/' + monster.id + '/', {'action_spells[]': actions}, console.log);
    }

    componentDidMount() {
        this.setState({monster: this.props.monster});
    }

    componentDidUpdate() {
        if (this.props.monster && (!this.state.monster || this.state.monster.id != this.props.monster.id)) {
            this.setState({monster: this.props.monster});
        }
    }

    select_tab(name) {
        this.setState({tab:name});
    }

    redirect(result, callback) {
        console.log("Edit Monster Result", result);
        this.setState({monster: result[0]['monster']});
        callback();
    }

    submit(name, state) {
        state['id'] = this.state.monster.id;
        save_monster(state)
    }

    render() {
        var tabs = [];
        var tab_names = ['Image', 'Basics', 'Actions', 'Spells', 'Damages/Conditions/Languages'];

        for (var tab_name of tab_names) {
            tabs.push(<Tab name={tab_name} select_tab={this.select_tab} selected_tab={this.state.tab} />);
        }

        var monster_form = null;
        var monster = this.state.monster;
        var defaults = {
            id:'new_monster',
            size:'Medium',
            character:false,
            damage_immunities:[]
        };

        var submitUrl = '/api/compendium/monster/';
        if (monster) {
            defaults = monster;
            defaults['damage_immunities[]'] = [];
            for (var item of monster.damage_immunities) {
                defaults['damage_immunities[]'].push(item.damagetype.id);
            }

            defaults['damage_resistances[]'] = [];
            for (var item of monster.damage_resistances) {
                defaults['damage_resistances[]'].push(item.damagetype.id);
            }

            defaults['damage_vulnerabilities[]'] = [];
            for (var item of monster.damage_vulnerabilities) {
                defaults['damage_vulnerabilities[]'].push(item.damagetype.id);
            }

            defaults['condition_immunities[]'] = [];
            for (var item of monster.condition_immunities) {
                defaults['condition_immunities[]'].push(item.condition.id);
            }

            defaults['languages[]'] = [];
            for (var item of monster.languages) {
                defaults['languages[]'].push(item.language.id);
            }

            submitUrl = '/api/compendium/monster/' + monster.id + '/';
            console.log("Monster Found!");
        }

        if (this.state.tab == 'Image') {
            monster_form = <FormWithChildren key={defaults.id + '_edit_form'} setGlobalState={this.submit} autoSetGlobalState={true} globalStateName="monster-image" defaults={defaults}>
                <ImageInput style={{maxWidth:'300px'}} name='image' label='Image' multiple={false} preview={true} bucket_name="mapquest-public" is_public={true} />
            </FormWithChildren>;
        }
        else if (this.state.tab == 'Basics') {
            monster_form = <FormWithChildren key={defaults.id + '_edit_form'} submit={submitUrl} redirect={this.redirect} defaults={defaults}>
                <TextInput name='name' label='Name' />
                <TextInput name='size' label='Size' default={'Medium'} />
                <TextInput name='type' label='Race'  />
                <TextInput name='subtype' label='Sub-Race' />
                <TextInput name='alignment' label='Alignment'  />
                <NumberInput name='armor_class' label='AC' />
                <NumberInput name='hit_points' label='Max Hit Points' />
                <TextInput name='hit_dice' label='Hit Dice (if monster, no modifiers)' />
                <NumberInput name='challenge_rating' label='Challenge Rating' />

                <NumberInput name='walk_speed' label='Walk Speed' />
                <NumberInput name='fly_speed' label='Fly Speed' />
                <NumberInput name='swim_speed' label='Swim Speed' />
                <NumberInput name='climb_speed' label='Climb Speed' />
                <NumberInput name='burrow_speed' label='Burrow Speed' />

                <NumberInput name='blindsight' label='Blindsight' />
                <NumberInput name='darkvision' label='Darkvision' />
                <NumberInput name='truesight' label='Trusight' />
                <NumberInput name='tremorsense' label='Tremorsense' />
                <NumberInput name='passive_perception' label='Passive Perception' />

                <NumberInput name='strength' label='Strength' />
                <NumberInput name='dexterity' label='Dexterity' />
                <NumberInput name='constitution' label='Constitution' />
                <NumberInput name='intelligence' label='Intelligence' />
                <NumberInput name='wisdom' label='Wisdom' />
                <NumberInput name='charisma' label='Charisma' />

                <NumberInput name='strength_saving_throw' label='Strength Saving Throw' default={0} />
                <NumberInput name='dexterity_saving_throw' label='Dexterity Saving Throw' default={0}/>
                <NumberInput name='constitution_saving_throw' label='Constitution Saving Throw' default={0}/>
                <NumberInput name='intelligence_saving_throw' label='Intelligence Saving Throw' default={0}/>
                <NumberInput name='wisdom_saving_throw' label='Wisdom Saving Throw' default={0}/>
                <NumberInput name='charisma_saving_throw' label='Charisma Saving Throw' default={0}/>

                <NumberInput name='acrobatics' label='Acrobatics (DEX)' default={0}/>
                <NumberInput name='animal_handling' label='Animal Handling (WIS)' default={0}/>
                <NumberInput name='arcana' label='Arcana (INT)' default={0}/>
                <NumberInput name='athletics' label='Athletics (STR)' default={0}/>
                <NumberInput name='deception' label='Deception (CHA)' default={0}/>
                <NumberInput name='history' label='History (INT)' default={0}/>
                <NumberInput name='insight' label='Insight (WIS)' default={0}/>
                <NumberInput name='intimidation' label='Intimidation (CHA)' default={0}/>
                <NumberInput name='investigation' label='Investigation (INT)' default={0}/>
                <NumberInput name='medicine' label='Medicine (WIS)' default={0}/>
                <NumberInput name='nature' label='Nature (INT)' default={0}/>
                <NumberInput name='perception' label='Perception (WIS)' default={0}/>
                <NumberInput name='performance' label='Performance (CHA)' default={0}/>
                <NumberInput name='persuasion' label='Persuasion (CHA)' default={0}/>
                <NumberInput name='religion' label='Religion (INT)' default={0}/>
                <NumberInput name='sleight_of_hand' label='Sleight Of Hand (DEX)' default={0}/>
                <NumberInput name='stealth' label='Stealth (DEX)' default={0}/>
                <NumberInput name='survival' label='Survival (WIS)' default={0}/>

                <Select boolean={true} name='character' default={false} label='Character?' />
                <NumberInput name='level' label='Level' default={0}/>

            </FormWithChildren>;
        }
        else if (this.state.tab == 'Damages/Conditions/Languages') {
            monster_form = [
                <FormWithChildren key={defaults.id + '_edit_form'} submitUrl={submitUrl} redirect={this.redirect} defaults={defaults}>
                    <Select optionsUrl='/api/compendium/damagetype/' optionsUrlMap={{text:'{damagetype.name}', value:'{damagetype.id}'}} name='damage_immunities[]' label='Damage Immunities' multiple={true} />
                    <Select optionsUrl='/api/compendium/damagetype/' optionsUrlMap={{text:'{damagetype.name}', value:'{damagetype.id}'}} name='damage_resistances[]' label='Damage Resistances' multiple={true} />
                    <Select optionsUrl='/api/compendium/damagetype/' optionsUrlMap={{text:'{damagetype.name}', value:'{damagetype.id}'}} name='damage_vulnerabilities[]' label='Damage Vulnerabilities' multiple={true} />
                    <Select optionsUrl='/api/compendium/condition/' optionsUrlMap={{text:'{condition.name}', value:'{condition.id}'}} name='condition_immunities[]' label='Condition Immunities' multiple={true} />
                    <Select optionsUrl='/api/compendium/language/' optionsUrlMap={{text:'{language.name}', value:'{language.id}'}} name='languages[]' label='Languages' multiple={true} />
                </FormWithChildren>,
                <Button text='Add New Damage Type' type='primary' onClick={() => this.open_edit_form({add: 'damagetype'})} />,
                <Button text='Add New Condition' type='primary' onClick={() => this.open_edit_form({add: 'condition'})} />,
                <Button text='Add New Language' type='primary' onClick={() => this.open_edit_form({add: 'language'})} />,
            ];
        }
        else if (this.state.tab == 'Actions') {
            var current_actions = [];
            for (var item of this.state.monster.action_spells) {
                current_actions.push(<ActionItem action={item.actionspell} setGlobalState={this.open_edit_form} remove_action={this.remove_action} />);
            }

            monster_form = <div>
                <h3>Current Actions</h3>
                <Button text='Add New Action' type='primary' onClick={() => this.open_edit_form({add: 'action'})} />
                <table className="table">
                    <tr>
                        <th>Action Name</th>
                        <th>Edit Action</th>
                        <th>Remove Action</th>
                    </tr>
                    {current_actions}
                </table>
            </div>;
        }
        else if (this.state.tab == 'Spells') {
            monster_form = [
                <Spells monster={monster} push_modal_content={this.props.push_modal_content} />,
                <Button text='Add New Action' type='primary' onClick={() => this.open_edit_form({add: 'action'})} />,
            ];
        }

        var overall_form = <div style={{height:'1200px', overflowY:'scroll'}}>
            <ul className="nav nav-tabs">
                {tabs}
            </ul>
            {monster_form}
        </div>

        return (
            overall_form
        )
    }
}
