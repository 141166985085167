import React, { Component } from 'react';
import { Image } from 'react-konva';


// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
export default class CanvasImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
        };

        this.loadImage = this.loadImage.bind(this);
        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount() {
        this.loadImage();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }

    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }

    loadImage() {
        // save to "this" to remove "load" handler on unmount
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }

    handleLoad = () => {
        // after setState react-konva will update canvas and redraw the layer
        // because "image" property is changed
        this.setState({
            image: this.image
        });
    };

    render() {
        var scaleX = this.props.zoom;
        var scaleY = this.props.zoom;
        var on_click = null;
        var image_size = this.props.image_size;
        if (typeof(image_size) == 'string'){
            image_size = JSON.parse(image_size);
        }

        var opacity = this.props.opacity || 1;

        var icon = this.props.icon;
        var scale_multiplier = 1;
        if (icon && icon.monster) {
            if (icon.size == 'Large') {
                var scale_multiplier = 2;
            }
            else if (icon.size == 'Huge') {
                var scale_multiplier = 3;
            }
            else if (icon.size == 'Gargantuan') {
                var scale_multiplier = 4;
            }
        }


        if (this.props.type == 'pin') {
            var generic_scale = (this.props.pixels_between_lines * this.props.zoom)/Math.max(image_size[0], image_size[1]) * scale_multiplier;

            return (
                <Image
                    icon_id={this.props.icon.id}
                    draggable={this.props.draggable}
                    x={this.props.x}
                    y={this.props.y}
                    scaleX={generic_scale}
                    scaleY={generic_scale}
                    image={this.state.image}
                    onDragStart={this.props.onMouseDown} onDragMove={this.props.onMouseMove}
                    opacity={opacity}
                />
            );
        }
        if (this.props.type == 'icon') {
            scaleX = (this.props.pixels_between_lines * this.props.zoom)/image_size[0] * scale_multiplier
            scaleY = (this.props.pixels_between_lines * this.props.zoom)/image_size[1] * scale_multiplier

            return (
                <Image
                    icon_id={this.props.icon.id}
                    draggable={this.props.draggable}
                    x={this.props.x}
                    y={this.props.y}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    image={this.state.image}
                    onDragStart={this.props.onMouseDown} onDragMove={this.props.onMouseMove}
                    opacity={opacity}
                />
            );
        }
        else if (this.props.type == 'highlight') {
            scaleX = (this.props.pixels_between_lines * this.props.zoom)/image_size[0] * 1.1 * scale_multiplier
            scaleY = (this.props.pixels_between_lines * this.props.zoom)/image_size[1] * 1.1 * scale_multiplier

            return (
                <Image
                    icon_id={this.props.icon.id}
                    x={this.props.x - this.props.zoom * scale_multiplier * 5}
                    y={this.props.y - this.props.zoom * scale_multiplier * 4.5}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    image={this.state.image}
                    onMouseDown={this.props.onMouseDown} onMouseUp={this.props.onMouseUp} onMouseMove={this.props.onMouseMove}
                    opacity={opacity}
                />
            );
        }
        else if (this.props.type == 'status') {
            var index = this.props.index;
            scaleX = (this.props.pixels_between_lines * this.props.zoom)/image_size[0] * scale_multiplier
            scaleY = (this.props.pixels_between_lines * this.props.zoom)/image_size[1] * scale_multiplier
            var x = this.props.x + scaleX/4 * (index % 4) * image_size[0];
            var y = this.props.y + scaleY/4 * Math.floor(index/4) * image_size[1];

            return (
                <Image
                    x={x}
                    y={y}
                    scaleX={scaleX/4}
                    scaleY={scaleY/4}
                    image={this.state.image}
                    opacity={opacity}
                />
            );
        }
        else {
            return (
                <Image
                    draggable={this.props.draggable}
                    onClick={on_click}
                    x={this.props.x}
                    y={this.props.y}
                    scaleX={scaleX}
                    scaleY={scaleY}
                    image={this.state.image}
                    opacity={opacity}
                />
            );
        }


    }
}
