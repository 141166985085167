

function get_spells_by_level(component, spells, monster, instance, dont_repeat){
    var spell_slots = [];
    spell_slots.push({
        level: 0,
        max: 0,
        current: 0,
        spells:[],
        resource_id: '',
        name: 'Cantrips'
    });

    for (var key in window.cmState.resources){
        var resource = window.cmState.resources[key];
        if (resource.name.indexOf("Spell Slot Level ") > -1 && resource.name.indexOf("Pact") == -1) {
            var level = parseInt(resource.name.replace('Spell Slot Level ', ''));
            spell_slots.push({
                level: level,
                max: 0,
                current: 0,
                spells:[],
                resource_id: key,
                name: resource.name
            });
        }
    }
    spell_slots.sort((a, b) => (a.level > b.level) ? 1 : -1);

    for (var item of monster.monster_resources) {
        var monster_resource = item.monsterresource;
        var resource = window.cmState.global_state_lookup(this, 'resources', monster_resource.resource_id);

        if (resource.name && resource.name.indexOf("Spell Slot Level") > -1) {
            for (var slot_level = 1; slot_level <= 9; slot_level++) {
                if (resource.name.indexOf(slot_level.toString()) > -1) {
                    spell_slots[slot_level]['max'] = monster_resource.quantity;
                    
                    //Overwrite normal spell slot for warlock
                    spell_slots[slot_level]['resource_id'] = monster_resource.resource_id;
                }
            }
        }
    }

    if (instance){
        for (var item of instance.monster_instance_resources) {
            var monster_resource = item.monsterinstanceresource;
            var resource = window.cmState.global_state_lookup(this, 'resources', monster_resource.resource_id);

            if (resource) {
                if (resource.name && resource.name.indexOf("Spell Slot Level") > -1) {
                    for (var slot_level = 1; slot_level <= 9; slot_level++) {
                        if (resource.name.indexOf(slot_level.toString()) > -1) {
                            spell_slots[slot_level]['current'] = monster_resource.quantity;
                        }
                    }
                }
            }
        }
    }

    for (var item of spells) {
        var spell = window.cmState.global_state_lookup(this, 'actions', item.id);
        var starting_level = spell.level;
        if (!(dont_repeat) && ((spell.spell_specifics.higher_level_description && spell.spell_specifics.higher_level_description != "") || spell.name == 'Divine Smite')) {
            for (var i = starting_level; i < 10; i++) {
                spell_slots[i]['spells'].push(spell);
            }
        }
        else {
            spell_slots[starting_level]['spells'].push(spell);
        }
    }

    return spell_slots;
}

export default get_spells_by_level;
