import React, { Component } from 'react';
import {Button} from 'library';
import SpellCard from 'projectLibrary/spell_card.js';
import RollSave from 'projectLibrary/monsters/roll_save.js';
import TakeDamage from 'projectLibrary/monsters/take_damage.js';

export default class SpellUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roll: null,
            show_spell: false
        };

        this.roll_damage = this.roll_damage.bind(this);
        this.roll_crit = this.roll_crit.bind(this);
        this.show_spell = this.show_spell.bind(this);
    }

    roll_damage() {
        this.props.roll_damage(this.props.id, false)
    }

    roll_crit() {
        this.props.roll_damage(this.props.id, true)
    }

    show_spell(){
        this.props.push_modal_content(<SpellCard spell={this.props.spell} />);
    }

    render() {
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var dc_check = null;
        if (this.props.spell.action_dc && this.props.spell.action_dc.dc_type) {
            dc_check = <div className='dc-check' >
                {'DC ' + this.props.dc + ' ' + this.props.spell.action_dc.dc_type.name}
                <br/>
                <RollSave dc_type={this.props.spell.action_dc.dc_type.name} roll_skill={this.props.roll_skill} />
            </div>

        }

        var parsed_hits = [];
        for (var hit of this.props.hits){
            if (hit) {
                var resolved_roll_strings = [];
                var roll_function = ['1d20'];
                var parsed_color = 'black';


                for (var dice of hit.rolls) {
                    for (var die of dice) {
                        resolved_roll_strings.push(die.roll);

                        if (die.die == die.roll) {
                            parsed_color = 'green';
                        }
                        else if (die.roll == 1) {
                            parsed_color = 'red';
                        }
                    }

                    resolved_roll_strings.push(...hit.modifiers);
                    roll_function.push(...hit.modifiers);
                }

                if (this.props.full_display) {
                    parsed_hits.push(<div className={'col-6 action-hit ' + parsed_color}>
                        <div className='action-hit-total' >{hit.total}</div>
                        <div className='action-hit-breakdown' >
                            <div>{roll_function.join(' + ')}</div>
                            <div>{resolved_roll_strings.join(' + ')}</div>
                        </div>
                    </div>);
                }
            }
        }

        var hit_section = <div className='row'>
            {dc_check}
            {parsed_hits}
        </div>;

        var damages = [];
        var total_damage = 0
        for (var damage of this.props.damages) {
            total_damage += damage.damage.total;
            if (this.props.show_damage == 'critical') {
                total_damage += damage.damage.crit.total;
            }

            var resolved_damage_strings = [];
            var damage_function = [];

            for (var rolls of damage.damage.rolls) {
                for (var die of rolls) {
                    resolved_damage_strings.push(die.roll);
                }

                resolved_damage_strings.push(...damage.damage.modifiers);
            }

            damage_function = this.props.spell.action_damage;
            if (damage_function.damage_at_slot_level) {
                damage_function = damage_function.damage_at_slot_level[this.props.cast_level];
            }
            else if (damage_function.damage_at_character_level) {
                var spell_cast_level = 0;
                for (var level_index = 0; level_index < damage_function.damage_at_character_level.length; level_index++) {
                    if (level_index < monster.level && level_index > spell_cast_level) {
                        spell_cast_level = level_index;
                    }
                }
                damage_function = damage_function.damage_at_character_level[spell_cast_level];
            }
            else if (damage_function.damage_dice) {
                damage_function = damage_function.damage_dice;
            }
            else {
                damage_function = null;
            }

            var crit = null;
            if (this.props.hits && this.props.hits[0] && this.props.show_damage == 'critical') {
                crit = <span style={{fontSize:'12px'}} >(On Crit: +{damage.damage.crit.total})</span>;
            }

            var damage_breakdown = null;

            if (this.props.full_display) {
                var damage_breakdown = <div className='action-damage-breakdown' >
                    <div>{damage_function}</div>
                    <div>{resolved_damage_strings.join(' + ')}</div>
                </div>;
            }

            damages.push(<div className='col-12 action-damage'>
                <div className='action-damage-total' >
                    <span style={{fontSize:'16px', fontWeight:'bold'}} >{damage.damage.total}</span>
                    <span style={{fontSize:'12px'}} > {damage.type}</span>
                    <span style={{fontSize:'12px'}} >{' damage.'}</span>
                    {crit}
                </div>
                {damage_breakdown}
            </div>);
        }

        var take_damage = null;
        if (total_damage != 0) {
            take_damage = <div style={{textAlign:'center', paddingTop:'3px'}}>
                <TakeDamage take_damage={this.props.take_damage} point_type='current_hp' text='Take Damage' damage={total_damage} current_instance={this.props.current_instance} />
                <TakeDamage take_damage={this.props.take_damage} point_type='current_hp' text='Take Damage' damage={Math.floor(total_damage/2)} half={true} current_instance={this.props.current_instance} />
            </div>;
        }

        var damage_section = null;
        if (this.props.full_display && this.props.damages.length > 0 && (this.props.current_instance && this.props.instance.id == this.props.current_instance.id)) {
            var roll_crit = null;
            if (parsed_hits.length > 0) {
                roll_crit = <Button type='danger' onClick={this.roll_crit} text='Crit'/>
            }

            var damage_section = <div className='damage-section' >
                <div style={{textAlign:'center', paddingTop:'3px'}}>
                    <Button type='danger' onClick={this.roll_damage} text='Roll Damage'/>
                    {roll_crit}
                </div>
            </div>;
        }

        if (this.props.show_damage) {
            var damage_section = <div className='damage-section' >
                <div className='row'>
                    {damages}
                </div>
                {take_damage}
            </div>;
        }

        var spell_name = this.props.spell.name;
        var usage = null;
        if (this.props.cast_level > 0) {
            var num_mapping = {1:'one', 2:'two', 3:'three',4:'four',5:'five',6:'six',7:'seven',8:'eight',9:'nine'}
            spell_name = 'Level ' + this.props.cast_level + ' ' + spell_name;

            if (this.props.current_instance && this.props.instance.id == this.props.current_instance.id) {
                usage = <div style={{textAlign:'center', paddingTop:'3px'}}>
                    <TakeDamage take_damage={this.props.take_damage} point_type={'spell_slots_level_' + num_mapping[this.props.cast_level]} text={'Use Level ' + this.props.cast_level + ' Spell Slot'} damage={1} current_instance={this.props.current_instance} />
                </div>
            }

        }

        var name = null;
        if (this.props.full_display) {
            name = <span style={{fontWeight:'bold'}} ><a onClick={this.show_spell} style={{color:'blue', cursor:'pointer'}}>{spell_name}</a></span>
        }

        return(
            <div className='chat-entry' >
                <div className='chat-header' >
                    <span>{monster.name}</span>
                    <span>{' : '}</span>
                    {name}
                 </div>
                <div className='hit-section' >{hit_section}</div>
                {damage_section}
                {usage}
            </div>
        )
    }
}
