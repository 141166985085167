import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { FormWithChildren, TextInput, Button, PageBreak } from 'library';
import Monster from 'projectLibrary/monsters/monster.js';


export default class MonsterSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            monsters:[],
            name: ''
        }

        this.setGlobalState = this.setGlobalState.bind(this);
        this.monsters_callback = this.monsters_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/monster/?character=False&order_by=name',{}, this.monsters_callback)
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    monsters_callback(result) {
        var monsters = [];
        for (var item of result) {
            var monster = item.monster;
            monsters.push(monster);
        }

        this.setState({monsters:monsters})
    }

    render() {
        var monsters = this.state.monsters;
        var monster_display = []
        for (var monster of monsters) {
            if (this.state.name == '' || monster.name.toLowerCase().indexOf(this.state.name.toLowerCase()) > -1) {
                monster_display.push(
                    <tr>
                        <td>{monster.name}</td>
                        <td><Button text='View' type='primary' href={'/dnd_5e_monster_stat_block/' + monster.name + '/'} target='_blank' /></td>
                    </tr>
                );
            }
        }

        return (
            <div>
                <div className='container'>
                    <h2>DnD 5e Monsters</h2>
                    <Button href='/contribute/' target='_blank' type='secondary' text='Add Your Own Monster' />
                    <PageBreak />
                    <FormWithChildren autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'monster_search'} defaults={this.state}>
                        <TextInput name='name' label='Name' />
                    </FormWithChildren>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>View</th>
                        </tr>
                        {monster_display}
                    </table>

                    <small>Open	Game License v1.0a Copyright 2000,	Wizards	of the Coast, LLC.</small>
                    <br />
                    <small>System Reference	Document 5.1 Copyright 2016,
                    Wizards	of	the	Coast, Inc.; Authors Mike Mearls,
                    Jeremy Crawford, Chris Perkins,	Rodney Thompson,
                    Peter Lee, James Wyatt,	Robert J. Schwalb, Bruce R. Cordell,
                    Chris Sims,	and	Steve Townshend, based on original material	by E. Gary Gygax and Dave Arneson.</small>
                </div>
            </div>
        )
    }
}
