import React, { Component } from 'react';
import { Wrapper, PasswordResetRequest, Container, TextInput, Navbar, NavLink,
    Div, Image, Span, Link, Paragraph } from 'library';


class PasswordResetRequestPage extends Component {
    render() {
        return (<div>
				<Container order={"3"} style={{'padding-top': '20px'}} min_height={true} >
					<PasswordResetRequest >
						<TextInput name={"email"} required={true} placeholder={"your_email@domain.com"}  />
					</PasswordResetRequest>
				</Container>

        </div>);
    }
}

export default PasswordResetRequestPage;
