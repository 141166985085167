import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';


export default class LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {loc_list:[], loaded: false}

        this.location_callback = this.location_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/home/location/?related=tags&user_who_added=' + window.cmState.user.id, {}, this.location_callback)
    }

    location_callback(result) {
        var loc_list = [];
        for (var item of result) {
            var loc = item['location'];
            loc_list.push(loc);
        }

        this.setState({'loc_list':loc_list, loaded:true});
    }

    render() {
        var locations = [];
        for (var loc of this.state.loc_list) {
            var img = <img style={{height:'200px', objectFit:'contain'}} class="card-img-top" src={loc['location_image']} alt="Card image cap" />;

            var view_button = <Button type='primary' text='View' href={'/?id=' + loc.id} />;
            if (loc['location_image'] == '') {
                img = null;
                view_button = null;
            }

            var tags = '';
            for (var item of loc.tags) {
                tags += item.tag.name + ', ';
            }
            if (tags != '') {
                tags = tags.substring(0, tags.length - 1);
            }
            if (loc.parent_id != '1c670db3-d4e0-4089-af10-3287a01af85b') {
                locations.push(<div className="card col-3" style={{}}>
                    {img}
                    <div className="card-body">
                        <h3 className="card-title">{loc['name']}</h3>
                        <p className="card-text">{tags}</p>
                        {view_button}
                        <Button type='secondary' text='Edit' href={'/edit_location/' + loc.id + '/'} />
                    </div>
                </div>);
            }
        }

        var content = <div className="container">
            <div>
                <br/>
                <h2>Locations</h2>
                <br/>

                <div className="card-container row">
                    <div className="card col-3" style={{}}>
                        <div className="card-body">
                            <Button className='big-btn' type='primary' text='Add New Location' href='/edit_location/' />
                        </div>
                    </div>
                    {locations}
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
