import React, { Component } from 'react';
import { Group, Rect, Circle, Line, Text } from 'react-konva';
import CanvasImage from 'projectLibrary/canvas/image.js';
import CanvasText from 'projectLibrary/canvas/text.js';
import Health from 'projectLibrary/session_tools/health.js';


const CONDITION_IMAGES = {
    "blinded": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/blind.png", size: [200,200]}, //blinded
    "charmed": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/charmed.png", size: [512,512]}, //charmed
    "dead": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/dead.png", size: [320,367]}, //dead
    "deafened": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/deaf.png", size: [512,512]}, //deafened
    "frightened": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/fear.png", size: [200,200]}, //frightened
    "grappled": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/grappled.png", size: [512,512]}, //grappled
    "incapacitated": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/incapacitated.png", size: [512,512]}, //incapacitated
    "invisible": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/invisible.ico", size: [256,256]}, //invisible
    "paralyzed": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/paralyzed.png", size: [512,512]}, //paralyzed
    "petrified": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/petrified.jpg", size: [1600,1600]}, //petrified
    "poisoned": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/poisoned.png", size: [330,512]}, //poisoned
    "prone": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/crawl.png", size: [200,200]}, //prone
    "restrained": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/restrained.png", size: [320,320]}, //restrained
    "stunned": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/stunned.png", size: [512,512]}, //stunned
    "unconcious": {url:"https://mapquest-public.s3.us-east-2.amazonaws.com/icons/unconcious.jpg", size: [512,512]}, //unconcious
}


class MarkerHighlight extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var scale = 1.2;

        var red = 'red';//'rgb(209,72,66)';
        var size = this.props.size / 2;
        var width_shift = this.props.width_shift;
        var height_shift = this.props.height_shift;

        var widest_percent = .36;
        var indent_percent = .9;
        var height_indent_percent = .45;

        var circle_position = [
            width_shift,
            height_shift * widest_percent,
        ];
        var line_points = [
            -1 * circle_position[0] * indent_percent, width_shift * height_indent_percent,
            0, height_shift - circle_position[1],
            circle_position[0] * indent_percent, width_shift * height_indent_percent,
        ];


        var lines = [
            <Circle scale={{ x: scale, y: scale }} x={circle_position[0]} y={circle_position[1]} radius={width_shift} fill={red} />,
            <Line scale={{ x: scale, y: scale }} x={circle_position[0]} y={circle_position[1]} closed={true} key={this.props.id} fill={red} points={line_points} />,
        ];

        return (
            lines
        );
    }
}


class AnimatedTarget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            starting_timestamp: new Date().valueOf(),
            mode: 0,
        };

        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update(){
        var elapsed = (new Date().valueOf() - this.state.starting_timestamp) / 1000;
        if (elapsed > 2){
            this.setState({mode: -1});
            return false;
        }

        var mode_flag = parseInt(elapsed / .2);
        if (mode_flag % 4 == 0){
            this.setState({mode: 1});
        }
        else if (mode_flag % 4 == 1){
            this.setState({mode: 2});
        }
        else if (mode_flag % 4 == 2){
            this.setState({mode: 3});
        }
        else {
            this.setState({mode: 0});
        }

        setTimeout(() => this.update(), 100);
    }

    render() {
        var size = this.props.size / 2;
        var width_shift = this.props.width_shift;
        var height_shift = this.props.height_shift;
        var size_offset = 0;

        var colors = {
            'red': 'rgb(209,72,66)',
            'white': 'rgb(250,250,251)',
        };

        var outer = colors['red'];
        var inner = colors['white'];
        if (this.state.mode == 1){
            size_offset = size * .125;
        }
        else if (this.state.mode == 2){
            outer = colors['white'];
            inner = colors['red'];
        }
        else if (this.state.mode == 3){
            size_offset = size * .125;
            outer = colors['white'];
            inner = colors['red'];
        }
        else if (this.state.mode == -1){
            return (null);
        }

        var circles = [
            <Circle icon_id={this.props.id} x={width_shift} y={height_shift} radius={size - size_offset} fill={outer} />,
            <Circle icon_id={this.props.id} x={width_shift} y={height_shift} radius={size * .75 - size_offset} fill={inner} />,
            <Circle icon_id={this.props.id} x={width_shift} y={height_shift} radius={size * .5 - size_offset} fill={outer} />,
            <Circle icon_id={this.props.id} x={width_shift} y={height_shift} radius={size * .25 - size_offset} fill={inner} />,
        ];

        return (
            circles
        );
    }
}


class TokenColors extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var pixel_grid_size = this.props.zoom * this.props.pixels_between_lines;
        var corner_size = pixel_grid_size / 8;

        var colors = [];

        var radius = 2 * this.props.zoom;
        var color_index = 0;
        for (var color of this.props.colors){
            if (this.props.circle_style){
                colors.push(<Circle
                    x={radius + (radius * color_index * 2)}
                    y={radius}
                    fill={color}
                    radius={radius}
                />);
            }
            else {
                if (color_index==0){
                    colors.push(<Line
                        x={0} y={0}
                        points={[
                            0, 0,
                            corner_size, 0,
                            0, corner_size
                        ]}
                        fill={color}
                        closed={true}
                    />);
                }
                else {
                    colors.push(<Line
                        x={0} y={0}
                        points={[
                            corner_size * color_index, 0,
                            corner_size + (corner_size * color_index), 0,
                            0, corner_size + (corner_size * color_index),
                            0, corner_size * color_index,
                        ]}
                        fill={color}
                        closed={true}
                    />);

                    colors.push(<Line
                        x={0} y={0}
                        points={[
                            corner_size * color_index, 0,
                            0, corner_size * color_index,
                        ]}
                        stroke={'#666'}
                        strokeWidth={1}
                    />);
                }
            }

            color_index += 1;
        }

        /*
        colors.push(<Text
            x={0}
            y={0}
            text={this.props.index}
            fontSize={corner_size}
            align={'center'}
        />);
        */

        return (
            colors
        );
    }
}


export default class Token extends Component {
    constructor(props) {
        super(props);

        this.mouse_down = this.mouse_down.bind(this);
        this.mouse_up = this.mouse_up.bind(this);
        this.mouse_move = this.mouse_move.bind(this);

        this.drag_start = this.drag_start.bind(this);
        this.drag_move = this.drag_move.bind(this);
        this.drag_end = this.drag_end.bind(this);

        this.on_click = this.on_click.bind(this);

        this.token_ref = React.createRef();
    }

    mouse_down(e) {

    }

    mouse_up(e) {

    }

    mouse_move(e) {

    }

    drag_start(e) {
        this.props.start_token_drag(e);
    }

    drag_move(e) {
        this.props.move_token_drag(e);
    }

    drag_end(e) {
        if (!(e.evt)){
            return false;
        }

        this.props.end_token_drag(e, this);
    }

    on_click() {
        if (this.props.dm || this.props.icon.player_controlled) {
            var data = Object.assign({}, this.props.icon);
            window.cmState.setGlobalState('selected_monster_instance', data, true);
        }
    }

    render() {
        var icon = this.props.icon;
        var type = this.props.type || 'icon';
        var pixels_between_lines = this.props.pixels_between_lines;

        var current_location = [...this.props.current_location];
        if (current_location.length < 2){
            console.error('INVALID COORDINATE LENGTH', current_location, icon);
            current_location = [0,0];
        }
        else if (isNaN(current_location[0]) || isNaN(current_location[1])){
            console.error('INVALID COORDINATE VALUES', current_location, icon);
            current_location = [0,0];
        }

        var icon_size = pixels_between_lines * this.props.zoom;
        var width_shift = 0;
        var height_shift = 0;
        if (this.props.anchor == 'bottom'){
            width_shift = (icon_size * (icon.image_size[0] / icon.image_size[1])) / 2;
            height_shift = icon_size;

            current_location[0] -= width_shift;
            current_location[1] -= height_shift;
        }

        var highlight = null;
        if (this.props.highlighted) {
            if (this.props.anchor == 'bottom'){
                //This means its a marker
                var size = pixels_between_lines * this.props.zoom;
                highlight = <MarkerHighlight key={icon['id']} size={size} width_shift={width_shift} height_shift={height_shift} />;
            }
            else {
                var scale_multiplier = 1;
                if (icon && icon.monster) {
                    if (icon.size == 'Large') {
                        var scale_multiplier = 2;
                    }
                    else if (icon.size == 'Huge') {
                        var scale_multiplier = 3;
                    }
                    else if (icon.size == 'Gargantuan') {
                        var scale_multiplier = 4;
                    }
                }
                var size = pixels_between_lines * this.props.zoom * scale_multiplier;
                var stroke_size = 2 * scale_multiplier;
                highlight = <Rect icon_id={icon.id} x={-1 * (stroke_size/2)} y={-1 * (stroke_size/2)} width={size + stroke_size} height={size + stroke_size} stroke={'red'} strokeWidth={stroke_size} />;
            }
        }

        var token_image = null;
        var colors = null;
        var name = null;
        var health_bar = null;

        var conditions = [];
        if (icon['conditions']) {
            for (var item of icon['conditions']) {
                var condition = window.cmState.global_state_lookup(this, 'conditions', item.condition.id).name.toLowerCase();
                var condition_index = icon['conditions'].indexOf(item);

                var img = CONDITION_IMAGES[condition];
                if (condition == "dead") {
                    conditions = [<CanvasImage x={0} y={0} src={img.url} zoom={this.props.zoom} type='icon'
                        image_size={img.size} icon={icon} pixels_between_lines={pixels_between_lines} opacity={0.75} />];
                    break;
                }

                if (img){
                    conditions.push(<CanvasImage x={0} y={0} src={img.url} zoom={this.props.zoom} draggable={false} type='status'
                        image_size={img.size} pixels_between_lines={pixels_between_lines} index={parseInt(condition_index)+1} />);
                }
            }
        }

        if (this.props.dm || icon.player_controlled) {
            if (!icon.share_with_players) {
                token_image = <CanvasImage x={0} y={0} src={icon.image} zoom={this.props.zoom} type={type}
                    image_size={icon.image_size} icon={icon} pixels_between_lines={pixels_between_lines} opacity={0.5} />;
            }
            else {
                token_image = <CanvasImage x={0} y={0} src={icon.image} zoom={this.props.zoom} type={type}
                    image_size={icon.image_size} icon={icon} pixels_between_lines={pixels_between_lines} />;
            }

            if (!icon.location) {
                colors = <TokenColors colors={icon.colors} pixels_between_lines={this.props.pixels_between_lines} zoom={this.props.zoom}
                    index={this.props.index} circle_style={this.props.circle_style} />

                health_bar = <Health x={0} y={0} src={icon.image} zoom={this.props.zoom} draggable={false} type={type}
                    image_size={icon.image_size} icon={icon} pixels_between_lines={pixels_between_lines} />;
            }
            else {
                /*
                name = <CanvasText x={0} y={0} src={icon.image} zoom={this.props.zoom} draggable={true} type={type}
                    image_size={icon.image_size} text={icon.name}
                    icon={icon} pixels_between_lines={pixels_between_lines} />;
                */
            }
        }
        else if (icon.share_with_players) {
            token_image = <CanvasImage x={0} y={0} src={icon.image} zoom={this.props.zoom} draggable={false} type={type}
                image_size={icon.image_size} icon={icon} pixels_between_lines={pixels_between_lines} />;

            colors = <TokenColors colors={icon.colors} pixels_between_lines={this.props.pixels_between_lines} zoom={this.props.zoom}
                index={this.props.index} circle_style={this.props.circle_style} />;
        }

        return (
            <Group key={icon.id} x={current_location[0]} y={current_location[1]} draggable={this.props.draggable} style={{cursor: 'pointer'}}
                onDragStart={this.drag_start} onDragMove={this.drag_move} onDragEnd={this.drag_end}
                onMouseDown={this.mouse_down} onMouseUp={this.mouse_up} onMouseMove={this.mouse_move}
                onClick={this.on_click} onTap={this.on_click}
                ref={node => {this.token_ref = node;}} >

                {highlight}
                {token_image}
                {colors}
                {name}
                {health_bar}
                {conditions}

            </Group>
        );
    }
}
