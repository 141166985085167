import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, EmptyModal } from 'library';
import ActionCard from 'projectLibrary/action_card.js';
import EditAction from 'projectLibrary/forms/edit_action.js';


class Action extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };

        this.show_action = this.show_action.bind(this);
        this.show_edit_action = this.show_edit_action.bind(this);
    }

    show_action() {
        this.setState({show: !this.state.show});
    }

    show_edit_action(){
        this.props.show_edit_action(this.props.action);
    }

    render() {
        var action = this.props.action;

        var edit = <Button type='secondary' text='Edit Action' href={'/edit_action/'+ action.id +'/'} target='_blank' />;//onClick={this.show_edit_action}

        var magic_school = null;
        if (action.spell_specifics && 'magic_school' in action.spell_specifics){
            magic_school = action.spell_specifics.magic_school;
        }

        var floating_card = null;
        if (this.state.show) {
            floating_card = <div style={{position: 'absolute', top:'100%', left:'0px', width:'100%', zIndex:'10', cursor: 'pointer'}} onClick={this.show_action} >
                <ActionCard action={this.props.action} />
            </div>;
        }

        var content = [];
        content.push(
            <tr style={{position: 'relative'}} >
                <td><a onClick={this.show_action}>{action.name}</a></td>
                <td>{action.action_type}</td>
                <td>{action.level}</td>
                <td>{magic_school}</td>
                <td>{edit}</td>
                {floating_card}
            </tr>
        );



        return (content);
    }
}


export default class ActionList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_name:'',
            search_class:'',
            search_level: '',
            action_list:[],
            loaded: false,
            edit_action: null,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.actions_callback = this.actions_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.show_edit_action = this.show_edit_action.bind(this);

    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data(){
        var url = '/api/compendium/actionspell/?related=dnd_classes&order_by=name';
        if (!(this.props.all)){
            url += '&available_to_pc=True';
        }

        ajaxWrapper('GET',url, {}, this.actions_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    actions_callback(result) {
        var action_list = [];
        for (var item of result) {
            var action = item['actionspell'];
            action_list.push(action);
        }

        this.setState({'action_list':action_list, loaded:true});
    }

    show_edit_action(action){
        this.setState({
            edit_action: action
        });
    }

    render() {
        var actions = [];
        for (var action of this.state.action_list) {
            if (this.props.only_attacks && (!(action.action_damage[0]) || Object.keys(action.action_damage[0]) == 0)) {
                continue;
            }
            else if (this.props.only_abilities && (action.action_damage[0] && Object.keys(action.action_damage[0]) != 0)) {
                continue;
            }

            var found = true;
            if (this.state.search_class) {
                found = false;
                for (var item of action.dnd_classes) {
                    var dnd_class = item.dndclass;
                    if (dnd_class.id == this.state.search_class) {
                        found = true;
                    }
                }
            }

            if (found) {
                if (this.state.search_name == '' || action.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) > -1) {
                    if (this.state.search_school == '' || action.magic_school == this.state.search_school) {
                        if (this.state.search_level == '' || action.level == this.state.search_level) {
                            //if (action.dnd_classes.length > 0){ continue; }
                            actions.push(<Action action={action} show_edit_action={this.show_edit_action} />);
                        }
                    }
                }
            }
        }

        var magic_schools = [
            {text:'Abjuration', value:'Abjuration'},
            {text:'Conjuration', value:'Conjuration'},
            {text:'Divinination', value:'Divinination'},
            {text:'Enchantment', value:'Enchantment'},
            {text:'Evocation', value:'Evocation'},
            {text:'Illusion', value:'Illusion'},
            {text:'Necromancy', value:'Necromancy'},
            {text:'Transmutation', value:'Transmutation'},
        ];

        var magic_levels = [
            {text:'Cantrip', value:0},
            {text:'1', value:1},
            {text:'2', value:2},
            {text:'3', value:3},
            {text:'4', value:4},
            {text:'5', value:5},
            {text:'6', value:6},
            {text:'7', value:7},
            {text:'8', value:8},
            {text:'9', value:9},
        ];

        var edit_action_modal = null;
        if (this.state.edit_action){
            edit_action_modal = <EmptyModal show={true} onHide={() => this.setState({edit_action: null})}>
                <EditAction action_id={this.state.edit_action.id} add_action={this.refresh_data} />
            </EmptyModal>;
        }


        var content = <div>
            <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}} >
                <div>
                    <h2>Actions</h2>
                    <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                        <TextInput name='search_name' label='Search by Name' className="col-4" />
                        <Select name='search_level' label='Level' options={magic_levels} className="col-4" />
                        <Select name='search_class' label='Class' className="col-4" optionsUrl={'/api/compendium/DnDClass/'}
                            optionsUrlMap={{'text':'{DnDClass.name}', value:'{DnDClass.id}'}} />
                    </FormWithChildren>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Level</th>
                            <th>School Of Magic</th>
                            <th>Prepare</th>
                        </tr>
                        {actions}
                    </table>
                </div>
            </div>

            {edit_action_modal}
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
