import React, { Component } from 'react';
import {FormWithChildren, CheckGroup, Button, Sidebar, Icon, Link,
    Image, Span, PageBreak } from 'library';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class MapControls extends React.Component {
    constructor(props) {
        super(props);
        var show = true;
        if (isMobile) {
            show = false;
        }

        this.state = {'show':show}
        this.toggle_add_marker = this.toggle_add_marker.bind(this);
		this.toggle_add_polygon = this.toggle_add_polygon.bind(this);
		this.update_filters = this.update_filters.bind(this);
        this.toggle_filters = this.toggle_filters.bind(this);
        this.view_current_location = this.view_current_location.bind(this);
        this.toggle_all = this.toggle_all.bind(this);
    }

    toggle_add_marker() {
        var mode = 'add_markers';
        if (this.props.map_mode == 'add_markers') { mode = ''; }

		this.props.set_map_mode(mode);
	}

	toggle_add_polygon() {
        var mode = 'add_polygon';
        if (this.props.map_mode == 'add_polygon'){
            mode = '';
        }

		this.props.set_map_mode(mode);
	}

    update_filters(name, state) {
		this.props.update_filters(state['filters']);
	}

    toggle_filters() {
        this.setState({show: !this.state.show})
    }

    view_current_location() {
        window.cmState.setGlobalState('selected_monster_instance', this.props.location);
    }

    toggle_all() {
        if (this.props.filters.length == this.props.available_filters.length) {
            this.props.update_filters([]);
        }
        else {
            this.props.update_filters(this.props.available_filters);
        }
    }

    render () {
        var all_filter_options = [
                {'text':'Temple/Religous Area','value':'ace12885-3c02-4774-a4e1-b863e656d003'}, //, style: {'marginLeft':'20px'}
                {'text':'Inn/Tavern','value':'a2037479-b655-47a3-80ea-f9b319537044'},
                {'text':'Point Of Interest','value':'d04f9d8a-908d-4c54-bad2-eef3e8be49dc'},
                {'text':'School','value':'7dce67a3-e36b-4b80-a20c-7613ee645dd9'},
                {'text': 'Cave', 'value': '0a7d3fc4-9b3b-4b22-beb8-76386627aba5'},
                {'text': 'City', 'value': 'eb6b62ba-32c7-4134-8699-0b56f9f33642'},
                {'text': 'Forest', 'value': '1878e734-91ac-49fb-8ab2-76cda5c32140'},
                {'text': 'Government', 'value': '0a1fd5bc-b625-42ba-ae40-adc007e39c52'},
                {'text': 'Lake', 'value': 'e237491e-00dd-4b02-9aa4-3a295bf3586e'},
                {'text': 'Mountain', 'value': '362b83a2-6361-4d8e-bc37-b6bd2a31f72d'},
                {'text': 'Ocean', 'value': 'd6e7a1c9-a868-411c-a6dc-9f6cc9dc469c'},
                {'text': 'River', 'value': 'd6f95032-d6db-451b-ab48-4a22bacb240b'},
                {'text': 'Town', 'value': 'a8ad8f5f-a96a-4738-ba52-3893c86a1600'},
                {'text': 'Blacksmith', 'value': 'f6331643-8d72-418d-b1ca-0c149399c363'},
                {'text': 'Fletcher', 'value': '5cbab56e-e283-41d5-93e6-839e0799a009'},
                {'text': 'Map Shop', 'value': '9cf70a1c-b21d-4ac6-8688-34bd3bd1fc4d'},
                {'text': 'Potions Shop', 'value': '12f86205-b698-40ff-b18a-d0d2aaa2e5cd'},
                {'text': 'Restaurant', 'value': '9a027802-3280-4267-b17c-4eb5f459f145'},
                {'text': 'General Store', 'value': '99deb797-c537-4c4b-9f38-f7bddf387931'},
                {'text': 'Quest', 'value': 'c25d52e9-d8fd-4c48-abb4-b4935c7c7e1a'},
                {'text': 'Trap', 'value': '3ba558e7-d08e-4823-bb32-0c18185eaf3e'},
                {'text': 'Encounter', 'value': 'f3ff0fad-8562-4570-b5f8-d8b8bc2d77a8'},
                {'text': 'NPC', 'value': 'de98ab98-a251-40a2-8132-04e94ff9dd0b'},
            {'text':'Region','value':'region'},
        ];

        var filter_options = [];
        for (var item of all_filter_options) {
            if (this.props.available_filters.indexOf(item['value']) > -1) {
                filter_options.push(item);
            }
        }

        var filters = null;
        var full_filters = null;
        if (filter_options.length > 0){
            filters = <div>
                <h5>Filters</h5>
                <FormWithChildren key={this.props.filter_key + '_filters'} setGlobalState={this.update_filters} autoSetGlobalState={true} globalStateName={'filters'}>
                    <CheckGroup name='filters' options={filter_options} default={this.props.filters} />
                </FormWithChildren>
            </div>;

            var filter_text = 'Clear Filters';
            if (this.props.filters.length != this.props.available_filters.length) {
                filter_text = 'Select All Filters';
            }

            full_filters = [
                <div style={{margin:'10px 0px'}}><PageBreak /></div>,
                <div style={spacing} >{filters}</div>,
                <Button style={{marginTop:'5px'}} type='outline-dark' text={filter_text} onClick={this.toggle_all} />,
                <div style={{margin:'10px 0px'}}><PageBreak /></div>,
            ];
        }

        var add_polygon_button = null;
        var add_markers_button = null;

        var button_spacing = {
            padding: "10px 0px"
        };
        add_markers_button = <Button type='primary' text={[
                <i class="fa fa-2x fa-map-marker" aria-hidden="true"></i>,
                <span style={{fontSize: '18px', lineHeight: '28px', verticalAlign: 'bottom', marginLeft: '8px'}} >{'Add Marker'}</span>
            ]} onClick={this.toggle_add_marker} />;
        add_polygon_button = <Button type='primary' text='Add Polygon' onClick={this.toggle_add_polygon} />;

		if (this.props.map_mode == 'add_markers') {
			add_markers_button = <Button type='danger' text='Stop Adding Marker' onClick={this.toggle_add_marker} />;
		}

		if (this.props.map_mode == 'add_polygon') {
			add_polygon_button = <Button type='danger' text='Stop Adding Polygon' onClick={this.toggle_add_polygon} />;
		}

        /*
        var parent_map = null;
		if (this.props.location.parent.name) {
            var redirect = '/?id=' + this.props.location.parent.id;
            if (this.props.group) {
                redirect = '/?id=' + this.props.location.parent.id + '&group_id=' + this.props.group;
            }
			var parent_map_button = <a className="btn btn-info" href={redirect} target="_blank" >Go To Map of {this.props.location.parent.name}</a>;
            parent_map = <div className="col-12" style={spacing} >{parent_map_button}</div>;
		}
        */

        var spacing = {
            padding: "10px 0px"
        };

        var join_quest = <Link href='https://discord.gg/cYvcWSUxTn' className='btn discord-btn' >
            <Image style={{width: '30px'}} src='https://mapquest-public.s3.amazonaws.com/21_01_05_20_04_13_Discord-logo.png' />
            <Span style={{fontSize: '1rem', padding: '0px 10px 0px 20px', position: 'relative', top: '3px'}} text={'Join Us On Discord'} />
        </Link>;

        if (window.cmState.user) {
            join_quest = <a className='btn btn-success' href="https://www.patreon.com/forgottenrealmmaps" style={{marginBottom:'20px'}} target='_blank'>Help Forgotten Maps Stay Alive!</a>;
        }

        return (
            <Sidebar left_side={true} loaded={true} opener={<Icon icon='filter' size={2} style={{padding: '10px 0px 4px 0px'}} />} headerHeight={150} width={'250px'} start_open={this.state.show} >
                <div style={{paddingLeft: '15px'}}>
                <div style={button_spacing} >{add_markers_button}</div>

                {full_filters}

                <div style={{height:'20px'}}></div>
                <Button type='primary' text={'View Location Details'} onClick={this.view_current_location} style={{marginBottom:'10px'}} />

                <div style={{height:'10px'}}></div>
                {join_quest}
                </div>
            </Sidebar>
        );
    }
}


export default MapControls;
