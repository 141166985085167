import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Div, Image, Span, Link, Paragraph, FormWithChildren} from 'library';


class Body extends Component {
    constructor(props) {
        super(props);
        this.state = {width: 0, height: 0}
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        console.log("Update Window Dimensions!")
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {

        return (<div style={{minHeight: (this.state.height - 325) + 'px'}}>
			{this.props.children}
        </div>);
    }
}

export default Body;
