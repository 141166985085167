import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab, ListInput } from 'library';

export default class EditDamage extends React.Component {
    constructor(props) {
        super(props);

        if (props.defaults) {
            this.state = props.defaults;
        }
        else {
            this.state = {
                damage_roll_type: '',
                dice: '',
                damage_bonus:'',
                damage_roll:'',
                damage_by_level:'',
                damage_scaling:'',
                damage_type:'',
            }
        }


        this.setGlobalState = this.setGlobalState.bind(this);
        this.set_form_state = this.set_form_state.bind(this);
    }

    setGlobalState(name, state) {
        if (state.damage_roll_type || state.damage_dice || state.damage_bonus) {
            var calc = '';

            if (state.damage_roll_type) {
                calc += ' + {' + state.damage_roll_type + '}'
            }
            else if (this.state.damage_roll_type){
                calc += ' + {' + this.state.damage_roll_type + '}'
            }

            if (state.damage_dice) {
                calc += ' + ' + state.damage_dice;
            }
            else if (this.state.damage_dice) {
                calc += ' + ' + this.state.damage_dice;
            }

            if (state.damage_bonus) {
                calc += ' + ' + state.damage_bonus
            }
            else if (this.state.damage_bonus) {
                calc += ' + {' + this.state.damage_bonus + '}'
            }

            state['damage_roll'] = calc;
        }

        if (state['damage_by_level']) {
            var damage_by_level = {}
            for (var key in state['damage_by_level']) {
                var damage = state['damage_by_level'][key];
                damage_by_level[damage['level']] = damage['damage_roll'];
            }
            state['damage_by_level'] = damage_by_level;
        }


        var current_state = {};
        for (var key in state) {
            current_state[key] = state[key];
        }

        for (var key in this.state) {
            if (!current_state[key]) {
                current_state[key] = this.state[key];
            }
        }

        this.set_form_state(current_state);
        this.setState(state);
    }

    set_form_state(state) {
        this.props.set_form_state(this.props.index, state);
    }

    render() {
        var defaults = Object.assign({},this.props.defaults);

        if (defaults['damage_by_level']) {
            var damage_by_level_list = [];
            for (var key in defaults['damage_by_level']) {
                var damage = defaults['damage_by_level'][key];
                damage_by_level_list.push({level:key, damage_roll:damage});
            }
            defaults['damage_by_level'] = damage_by_level_list;
        }

        var damage_scales = [
            {text:'With Stats or Flat',value:'stats'},
            {text:'With Character Level',value:'level'},
            {text:'With Casting Level',value:'cast_level'},
        ]

        var modifier_types = [
            {text:'Strength',value:'STR_MOD'},
            {text:'Dexterity',value:'DEX_MOD'},
            {text:'Constitution',value:'CON_MOD'},
            {text:'Intelligence',value:'INT_MOD'},
            {text:'Wisdom',value:'WIS_MOD'},
            {text:'Charisma',value:'CHA_MOD'},
            {text:'Spell',value:'SPELL_MOD'},
        ]

        var damage_details_form = null;
        if (this.state.damage_scaling == 'stats') {
            damage_details_form = <FormWithChildren  key={'damage_detail_' + this.props.index} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'damage_detail'} defaults={defaults}>
                <Select name='damage_roll_type' label='Damage Roll Modifier' options={modifier_types} />
                <TextInput name='damage_dice' label='Dice Rolls' />
                <TextInput name='damage_bonus' label='Bonus Damage' />
                <TextInput name='damage_roll' label='Damage Roll Calculation' />
            </FormWithChildren>
        }
        else if (this.state.damage_scaling == 'level' || this.state.damage_scaling == 'cast_level') {
            damage_details_form = <FormWithChildren  key={'damage_detail_' + this.props.index} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'damage_detail'} defaults={defaults}>
                <ListInput name='damage_by_level' row_inputs={[{name:'level', placeholder:'1', title:'Level'},{name:'damage_roll', title:'Damage Roll', placeholder:'2d4 + {STR_MOD} + 2'}]} />
            </FormWithChildren>
        }

        return (
            <div>
                <h4>Damage Type {this.props.index + 1}</h4>
                <FormWithChildren key={'damage_' + this.props.index} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'damage'} defaults={defaults}>
                    <Select name='damage_scaling' label='How Does Damage Scale?' options={damage_scales} />
                    <Select name='damage_type' label='Damage Type' optionsUrl={'/api/compendium/damagetype/'} optionsUrlMap={{text:'{damagetype.name}', value:'{damagetype.name}'}} />
                    <TextInput name='note' label='Special Note?' />
                </FormWithChildren>
                {damage_details_form}
            </div>
        )

    }

}
