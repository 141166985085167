
import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, ListWithChildren, Header, Button, Container, Div} from 'library';

class FileManager extends Component {
  constructor(props) {
      super(props);
  }

  render() {
      return (<div>
              <Container >
                  <Div >
                      <ListWithChildren dataUrl={"/api/modelWebsite/filegroup/"} objectName={"filegroup"} >
                          <Header size={3} text={resolveVariables({"text":"{props.name}"}, this)["text"]}  />
                          <Button href={resolveVariables({"text":"/filegroup/{props.id}/"}, this)["text"]} text={"View"} type={"primary"}  />
                      </ListWithChildren>
                  </Div>
              </Container>

      </div>);
  }
}
export default FileManager;
