import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab,
    ListInput, Link } from 'library';

import DamageForm from 'projectLibrary/forms/edit_damage.js';


export default class EditAction extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            description: '',
            time_to_use: '',
            attack_roll:{},
            action_damage: [{}],
            action_dc: {},
            action_type: '',
            area_of_effect: {},
            spell_specifics:{},
            costs:[],
            range:5,
            duration:'',
            level:0,
            available_to_pc: false,
            class:[],
            subclass:[],
            has_attack_roll:false,
            has_dc:false,
            has_aoe:false,
            has_damage:false,
            loaded:false,
            saved: false,
        };

        this.setGlobalState = this.setGlobalState.bind(this);
        this.submit = this.submit.bind(this);
        this.submit_followup = this.submit_followup.bind(this);
        this.add_damage_type = this.add_damage_type.bind(this);
        this.remove_damage_type = this.remove_damage_type.bind(this);
        this.load_resources = this.load_resources.bind(this);
        this.load_resource_options = this.load_resource_options.bind(this);
        this.load_costs = this.load_costs.bind(this);
        this.add_cost = this.add_cost.bind(this);
        this.remove_cost = this.remove_cost.bind(this);
        this.add_damage = this.add_damage.bind(this);
        this.action_callback = this.action_callback.bind(this);
    }

    componentDidMount() {
        if (this.props.action_id) {
            //Isolated Page Render
            ajaxWrapper('GET', '/api/compendium/actionspell/' + this.props.action_id + '/?related=dnd_classes,dnd_subclasses,resource_costs', {}, this.action_callback);
            ajaxWrapper('GET', '/api/compendium/resource/', {}, this.load_resources);
        }
        else {
            //Loaded inside another page's context
            var state = Object.assign({}, this.props.action);
            state['loaded'] = true;
            state = this.load_costs(state);
            this.setState(state);

            window.cmState.subscribe_by_name(this, 'resources');
            this.load_resource_options(window.cmState.resources);
        }
    }

    action_callback(result) {
        console.log("Action Callback", result);
        var action = result[0]['actionspell'];

        var dnd_classes = [];
        for (var item of action['dnd_classes']) {
            dnd_classes.push(item['dndclass'].id);
        }
        action['dnd_classes[]'] = dnd_classes;

        var dnd_subclasses = [];
        for (var item of action['dnd_subclasses']) {
            dnd_subclasses.push(item['dndsubclass'].id);
        }
        action['dnd_subclasses[]'] = dnd_subclasses;
        action['loaded'] = true;

        action = this.load_costs(action);

        var action_damage = [];
        if (!(Array.isArray(action.action_damage))){
            for (var key in action.action_damage){
                action_damage.push(action.action_damage[key]);
            }
            action.action_damage = action_damage;
        }

        this.setState(action);
    }

    load_resources(result) {
        var resources = {};
        for (var item of result) {
            var resource = item.resource;
            resources[resource.id] = resource;
        }
        this.load_resource_options(resources);
    }

    load_resource_options(resources){
        var resource_options = [{
            'text': '-- Uses Its Own Resource --',
            'value': ''
        }];

        for (var key in resources){
            resource_options.push({
                'text': resources[key].name,
                'value': key
            });
            resource_options.sort((a, b) => (a.text > b.text) ? 1 : -1);
        }

        this.setState({resource_options: resource_options});
    }

    load_costs(action){
        action['costs'] = [];
        if (action.resource_costs) {
            for (var item of action.resource_costs){
                action['costs'].push({
                    resource: item['resourcecost'].resource_id,
                    cost: item['resourcecost'].cost,
                });
            }
            delete action.resource_costs;
        }

        return action;
    }

    add_damage_type() {
        var action_damage = this.state.action_damage;
        action_damage.push({});
        this.setState({action_damage:action_damage});
    }

    remove_damage_type() {
        var action_damage = this.state.action_damage;
        action_damage.pop();
        this.setState({action_damage:action_damage});
    }

    add_cost(){
        var costs = this.state.costs;
        costs.push({});
        this.setState({costs:costs});
    }

    remove_cost() {
        var costs = this.state.costs;
        costs.pop();
        this.setState({costs:costs});
    }

    setGlobalState(name,state) {
        if ('attack_roll_type' in state || 'attack_roll_proficient' in state || 'attack_roll_bonus_num' in state) {
            var calc = '';
            if (state.attack_roll_type) {
                calc += ' + {' + state.attack_roll_type + '}'
            }
            else if (this.state.attack_roll.attack_roll_type){
                calc += ' + {' + this.state.attack_roll.attack_roll_type + '}'
            }

            if (state.attack_roll_proficient) {
                calc += ' + {PROFICIENCY_BONUS}'
            }
            else if (this.state.attack_roll.attack_roll_proficient) {
                calc += ' + {PROFICIENCY_BONUS}'
            }

            if (state.attack_roll_bonus_num) {
                calc += ' + ' + state.attack_roll_bonus_num
            }
            else if (this.state.attack_roll.attack_roll_bonus_num) {
                calc += ' + {' + this.state.attack_roll.attack_roll_bonus_num + '}'
            }

            state['attack_roll'] = calc
        }

        if (state.dc_modifier || state.dc_proficient || state.dc_bonus_num) {
            var calc = '';
            if (state.dc_modifier) {
                calc += ' + {' + state.dc_modifier + '}'
            }
            else if (this.state.action_dc.dc_modifier){
                calc += ' + {' + this.state.action_dc.dc_modifier + '}'
            }

            if (state.dc_proficient) {
                calc += ' + {PROFICIENCY_BONUS}'
            }
            else if (this.state.action_dc.dc_proficient) {
                calc += ' + {PROFICIENCY_BONUS}'
            }

            if (state.dc_bonus_num) {
                calc += ' + ' + state.dc_bonus_num
            }
            else if (this.state.action_dc.dc_bonus_num) {
                calc += ' + {' + this.state.action_dc.dc_bonus_num + '}'
            }

            state['dc_roll'] = calc
            console.log("State Update", state['dc_roll'], calc);
        }

        var current_state = {}
        var spell_specifics = this.state.spell_specifics;
        var attack_roll = this.state.attack_roll;
        var action_dc = this.state.action_dc;
        var area_of_effect = this.state.area_of_effect;

        if (this.state.costs.length == 0){
            current_state['costs'] = [];
        }

        if (name.startsWith('costs')){
            var index = parseInt(name.split('_')[1]);
            for (var key in state){
                this.state.costs[index][key] = state[key];
            }
            current_state['costs'] = this.state.costs;
        }
        else {
            //Convert forms into grouped JSON data
            for (var key in state) {
                var spell_specific_fields = ['ritual_cast','components','material','higher_level_description','concentration','magic_school'];
                var attack_roll_fields = ['attack_roll_type','attack_roll_proficient','attack_roll_bonus_num','attack_roll'];
                var dc_fields = ['dc_type','dc_modifier','dc_proficient','dc_bonus_num','dc_roll','success_type'];
                var aoe_fields = ['size','type'];

                if (spell_specific_fields.indexOf(key) > -1) {
                    spell_specifics[key] = state[key];
                }
                else if (attack_roll_fields.indexOf(key) > -1) {
                    attack_roll[key] = state[key];
                }
                else if (dc_fields.indexOf(key) > -1) {
                    action_dc[key] = state[key];
                }
                else if (aoe_fields.indexOf(key) > -1) {
                    area_of_effect[key] = state[key];
                }
                else {
                    current_state[key] = state[key];
                }
            }
        }

        current_state['spell_specifics'] = spell_specifics;
        current_state['action_dc'] = action_dc;
        current_state['attack_roll'] = attack_roll;
        current_state['area_of_effect'] = area_of_effect;

        this.setState(current_state);
    }

    add_damage(index, damage) {
        console.log("Add Damage", index, damage['damage_by_level']);
        var damages =this.state.action_damage;
        damages[index] = damage;
        console.log("Damages", damages[index]['damage_by_level']);
        this.setState({action_damage:damages});
    }

    submit() {
        var action_url = '/compendium/updateaction/';
        if (this.props.action) {
            action_url += this.props.action.id + '/';
        }
        else if (this.props.action_id) {
            action_url += this.props.action_id + '/';
        }
        action_url += '?related=dnd_classes,dnd_subclasses,resource_costs';
        var submission = Object.assign({}, this.state);
        delete submission['resource_options'];

        ajaxWrapper('POST', action_url, submission, this.submit_followup);
    }

    submit_followup(value){
        var action_response = value[0]['actionspell'];
        this.setState({saved:true});

        if (!(this.props.action_id) && !(this.props.action)){
            window.location = '/edit_action/'+ value[0]['ActionSpell']['id'] +'/';
        }
        else {
            this.props.add_action();
        }
    }

    render() {
        var key = 'action_form'
        var submitUrl = '/api/compendium/action/';
        var defaults = this.state;
        var delete_button = null;
        if (this.props.action_id) {
            key = 'action_form_' + this.props.id
            delete_button = <Button type='danger' text='Delete Action' dangerType={true} href={'/api/compendium/actionspell/' + this.props.action_id + '/delete/'} />;
        }

        var new_action = false;
        if (!(this.props.action_id) && !(this.props.action)){
            new_action = true;
        }

        var action_types = [
            {text:'Legendary Action',value:'Legendary'},
            {text:'Attack',value:'Attack'},
            {text:'Ability',value:'Ability'},
            {text:'Spell',value:'Spell'},
            {text:'Feat', value:'Feat'},
        ]

        var dc_types = [
            {text:'Strength',value:'STR'},
            {text:'Dexterity',value:'DEX'},
            {text:'Constitution',value:'CON'},
            {text:'Intelligence',value:'INT'},
            {text:'Wisdom',value:'WIS'},
            {text:'Charisma',value:'CHA'},
        ]

        var modifier_types = [
            {text:'Strength',value:'STR_MOD'},
            {text:'Dexterity',value:'DEX_MOD'},
            {text:'Strength/Dexterity',value:'STR_MOD/DEX_MOD'},
            {text:'Constitution',value:'CON_MOD'},
            {text:'Intelligence',value:'INT_MOD'},
            {text:'Wisdom',value:'WIS_MOD'},
            {text:'Charisma',value:'CHA_MOD'},
            {text:'Spell',value:'SPELL_MOD'},
        ]

        var dice_types = [
            {text:'d4',value:'d4'},
            {text:'d6',value:'d6'},
            {text:'d8',value:'d8'},
            {text:'d10',value:'d10'},
            {text:'d12',value:'d12'},
            {text:'d20',value:'d20'},
            {text:'d100',value:'d100'},
        ]

        var point_types = [
            {text:'Ki Points',value:'ki_points'},
            {text:'Sorcery Points',value:'sorcery_points'},
            {text:'Superiority Dice',value:'superiority_dice'},
            {text:'Channel Divinity',value:'channel_divinity'},
            {text:'Spell Slot',value:'spell_slot'},
            {text:'Health',value:'hp'},
        ]

        var success_types = [
            {text:'Nothing On Success',value:'none'},
            {text:'Half Damage on Success',value:'half'},
            {text:'Other',value:'other'},
        ]

        var aoe_types = [
            {text:'Line',value:'line'},
            {text:'Cube',value:'cube'},
            {text:'Sphere',value:'sphere'},
            {text:'Cylinder',value:'cylinder'},
            {text:'Aura',value:'aura'},
            {text:'Cone',value:'cone'},
        ]

        var time_to_use_options = [
            {text:'Passive Ability', value:'Passive'},
            {text:'1 Action',value:'1 Action'},
            {text:'1 Bonus Action',value:'1 Bonus Action'},
            {text:'1 Reaction',value:'1 Reaction'},
            {text:'Free Action', value:'Free Action'},
            {text:'1 Minute',value:'1 Minute'},
            {text:'10 Minutes',value:'10 Minutes'},
            {text:'1 Hour',value:'1 Hour'},
            {text:'8 Hours',value:'8 Hours'},
            {text:'12 Hours',value:'12 Hours'},
            {text:'24 Hours',value:'24 Hours'},
        ]

        var durations = [
            {text:'Instantaneous',value:'Instantaneous'},
            {text:'Up to 1 round', value:'Up to 1 round'},
            {text:'Up to 1 Minute',value:'Up to 1 Minute'},
            {text:'Up to 10 Minutes',value:'Up to 10 Minutes'},
            {text:'Up to 1 Hour',value:'Up to 1 Hour'},
            {text:'Up to 2 Hours',value:'Up to 2 Hours'},
            {text:'Up to 8 hours', value: 'Up to 8 hours'},
            {text:'Up to 24 hours', value: 'Up to 24 hours'},
            {text:'Up to 7 days', value: 'Up to 7 days'},
            {text:'Up to 10 days', value: 'Up to 10 days'},
            {text:'Up to 30 days', value: 'Up to 30 days'},
            {text:'1 round', value:'1 round'},
            {text:'1 Minute',value:'1 Minute'},
            {text:'10 Minutes',value:'10 Minutes'},
            {text:'1 Hour',value:'1 Hour'},
            {text:'2 Hours',value:'2 Hours'},
            {text:'8 hours', value: '8 hours'},
            {text:'24 hours', value: '24 hours'},
            {text:'7 days', value: '7 days'},
            {text:'10 days', value: '10 days'},
            {text:'30 days', value: '30 days'},
            {text:'Until dispelled', value: 'Until dispelled'},
            {text:'Special', value: 'Special'},
        ]

        var magic_schools = [
            {text:'Abjuration',value:'Abjuration'},
            {text:'Conjuration',value:'Conjuration'},
            {text:'Divination',value:'Divination'},
            {text:'Enchantment',value:'Enchantment'},
            {text:'Evocation',value:'Evocation'},
            {text:'Illusion',value:'Illusion'},
            {text:'Necromancy',value:'Necromancy'},
            {text:'Transmutation',value:'Transmutation'},
        ]

        var recovery_type_options = [
            {text:'long rest',value:'long rest'},
            {text:'short rest',value:'short rest'},
            {text:'at will',value:'at will'},
            {text:'per turn',value:'per turn'},
        ]

        var spell_form = null;
        if (this.state.action_type == 'Spell') {
            var spell_form = <div className='card col-12' >
                <h3>Spell Details</h3>
                <FormWithChildren  key={'spell_' + key} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'spell_basics'} defaults={defaults['spell_specifics']}>
                    <Select name='ritual_cast' label='Is this spell able to be cast as a ritual?' boolean={true} />
                    <TextInput name='components' label='Components' />
                    <TextInput name='material' label='Material' />
                    <TextArea name='higher_level_description' label='Higher Level Description' />
                    <Select name='concentration' label='Does this action require concentration?' boolean={true} />
                    <Select name='magic_school' label='Magic School' options={magic_schools} />
                </FormWithChildren>
            </div>
        }

        var attack_roll_form = <div className='card col-12'><div><Button type='success' text='Add Attack Roll' onClick={(e) => this.setState({has_attack_roll: true})} /></div></div>;
        if (this.state.has_attack_roll) {
            var attack_roll_form = <div className='card col-12' >
                <h3>Attack Roll Details</h3>
                <FormWithChildren  key={'attack_roll_' + key} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'attack_roll'} props_will_update={true} defaults={defaults['attack_roll']}>
                    <Select name='attack_roll_type' label='Attack Roll Modifier' options={modifier_types} />
                    <Select name='attack_roll_proficient' label='Proficiency Bonuses?' boolean={true} />
                    <NumberInput name='attack_roll_bonus_num' label='Attack Roll Bonus' />
                    <TextInput name='attack_roll' label='Attack Roll Calculation' />
                </FormWithChildren>
            </div>
        }

        var cost_form = <div className='card col-12'><div><Button type='success' text='Add Cost' /></div></div>;
        var cost_form_list = [
            <h3>Cost Details</h3>,
            <div><Button type='success' text='Add Cost' onClick={this.add_cost} /></div>
        ];
        for (var item of this.state.costs){
            var cost_index = this.state.costs.indexOf(item);

            var uses = <NumberInput name={'cost'} label='Cost' />;
            if (item['resource'] == ''){
                uses = <Select name='recovery_type' label='When does it recharge?' options={recovery_type_options} />;
            }
            cost_form_list.push(
                <div>
                    <FormWithChildren key={'cost_' + cost_index} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'costs_' + cost_index} defaults={item}>
                        <Select name={'resource'} label='Resource' options={this.state.resource_options} />
                        {uses}
                    </FormWithChildren>
                </div>
            );
        }

        if (this.state.costs.length > 0){
            cost_form_list.push(<div><Button type='danger' text='Remove Cost' onClick={this.remove_cost} /></div>);
        }
        
        cost_form = <div className='card col-12' >
            {cost_form_list}
        </div>;


        var damage_form = <div className='card col-12'><div><Button type='success' text='Add Damage' onClick={(e) => this.setState({has_damage: true})} /></div></div>;
        if (this.state.has_damage) {
            var damage_types = [];
            var counter = 0;
            for (var damage of this.state.action_damage) {
                damage_types.push(<DamageForm index={counter} set_form_state={this.add_damage} defaults={damage}/>);
                counter += 1;
            }

            var add_button = <Button type='success' text='Add Damage Type' onClick={this.add_damage_type} />
            var remove_button = null;

            if (this.state.action_damage.length > 1) {
                var remove_button = <Button type='danger' text='Remove Damage Type' onClick={this.remove_damage_type} />
            }

            var damage_form = <div className='card col-12' >
                <h3>Damage Details</h3>
                {damage_types}
                <div>{add_button}</div>
                <br/>
                <div>{remove_button}</div>
            </div>
        }

        var dc_form = <div className='card col-12'><div><Button type='success' text='Add Saving Throw' onClick={(e) => this.setState({has_dc: true})} /></div></div>;
        if (this.state.has_dc) {
            var dc_form = <div className='card col-12' >
                <h3>DC Details</h3>
                <FormWithChildren  key={'dc_' + key} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'dc'} defaults={defaults['action_dc']} props_will_update={true}>
                    <Select name='dc_type' label='DC Type (Aka "Succeed Against a DC 12 __")' options={dc_types} />
                    <Select name='dc_modifier' label='DC Modifier (Aka how is the DC Calculated)' options={modifier_types} />
                    <Select name='dc_proficient' label='Proficiency Bonus Added?' boolean={true} />
                    <NumberInput name='dc_bonus_num' label='DC Bonus' />
                    <TextInput name='dc_roll' label='DC Calculation' />
                    <Select name='success_type' label='Damage on Success' options={success_types} />
                </FormWithChildren>
            </div>;
        }

        var aoe_form = <div className='card col-12'><div><Button type='success' text='Add AOE' onClick={(e) => this.setState({has_aoe: true})} /></div></div>;
        if (this.state.has_aoe) {
            var aoe_form = <div className='card col-12' >
                <h3>Area of Effect Details</h3>
                <FormWithChildren  key={'aoe_' + key} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'aoe'} defaults={defaults['area_of_effect']}>
                    <NumberInput name='size' label='Size (ft.)' />
                    <Select name='type' label='Type of Area' options={aoe_types} />
                </FormWithChildren>
            </div>;
        }

        var saved = null;
        if (this.state.saved) {
            saved = <Alert type='success' text='Saved.' style={{marginBotton:'20px'}}/>
        }

        if (!this.state.loaded) {
            var content = null;
        }
        else {
            var classes = null;
            var sub_classes = null;

            if (!(new_action)){
                classes = <Select name='dnd_classes[]' label='What classes have this ability?' multiple={true}
                        optionsUrl={'/api/compendium/dndclass/'} optionsUrlMap={{text:'{dndclass.name}', value:'{dndclass.id}'}} />;
                sub_classes = <Select name='dnd_subclasses[]' label='What subclasses have this ability?' multiple={true}
                        optionsUrl={'/api/compendium/dndsubclass/'} optionsUrlMap={{text:'{dndsubclass.name}', value:'{dndsubclass.id}'}} />;
            }

            var content = <div className="container" style={{padding:'0px'}} >

                <div className="card-container row" style={{}}>
                    <div className='card col-12' >
                        <Link href='/dm_tools/action_list/' text='<- Action List' />
                    </div>
                    <div className='card col-12' >
                        <h1>{'Edit Action: ' + defaults['name']}</h1>
                        <br/>
                        <FormWithChildren key={key} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'action_basics'} defaults={defaults} >
                            <TextInput name='name' label='Name' />
                            <TextArea name='description' label='Description' />
                            <Select name='action_type' label='Action Type' options={action_types}/>
                            <Select name='time_to_use' label='Time To Use' options={time_to_use_options} />
                            <NumberInput name='range' label='Range (in ft. 0 is self)' />
                            <Select name='duration' label='Duration' options={durations} />
                            <NumberInput name='level' label='Level Unlocked (0 for always available)' />
                            <Select name='available_to_pc' label='Is this action available to PCs?' boolean={true} />
                            {classes}
                            {sub_classes}
                        </FormWithChildren>
                    </div>

                    {spell_form}
                    {attack_roll_form}
                    {dc_form}
                    {cost_form}
                    {aoe_form}
                    {damage_form}

                    <div className='col-12'><br/></div>
                    <div className='card col-12' >
                        {saved}
                        <div><Button style={{padding: '15px 30px', fontSize:'1.2em'}} type='success' text='Save Action' onClick={this.submit} /></div>
                        <br/>
                        <div>{delete_button}</div>
                    </div>
                    <div className='col-12'><br/></div>

                </div>
            </div>
        }
        return (content)
    }
}
