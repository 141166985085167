import React, { Component } from 'react';
import { TextInput, FormWithChildren } from 'library';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';


export default class ChatRoom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chat_socket:null,
            messages:[],
        }

        this.send_message = this.send_message.bind(this);
    }

    componentDidMount() {
        var chat_socket = new SocketRouter(this, {message: 'messages'});
        this.setState({chat_socket: chat_socket});
    }

    send_message(state, submit_finished){
        console.log("Send Message", state['text']);
        this.state.chat_socket.send_message({'message':state['text']});
        submit_finished();
    }

    render() {
        var messages = <p>No Messages Yet</p>;
        if (this.state.messages.length > 0) {
            var messages = [];
            for (var message of this.state.messages) {
                messages.push(<p>{message}</p>);
            }
        }

        return (
            <div>
                {messages}

                <FormWithChildren submit={this.send_message} reset_state_on_submit={true} redirect={console.log} submit_text={'Send'} globalStateName={'chatroom'} >
                    <TextInput name='text' />
                </FormWithChildren>
            </div>
        );
    }
}
