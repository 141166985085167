import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';
import { evaluate } from 'mathjs'


class Math extends React.Component {
    static component_name = 'Math';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'input'} name={'input'} default={''} />,
                <TextInput label={'preface'} name={'preface'} default={''} />,
                <TextInput label={'postface'} name={'postface'} default={''} />,
                <TextInput label={'className'} name={'className'} default={''} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
            can_have_children: false,
        }
    }


    render() {
        var className = this.props.className;

        var style = {}
        if (this.props.style) {
          style = Object.assign({}, this.props.style);
        }

        var output = this.props.input;
        if (output && output.indexOf('{') == -1){
            try {
                output = evaluate(output);
            }
            catch (e) {
                console.log(e);
            }
        }

        try {
            output = output.toFixed(2);
        }
        catch (e) {
           
        }

        var content = '';
        if (this.props.preface){
            content += this.props.preface;
        }
        if (output){
            content += String(output);
        }
        if (this.props.postface){
            content += this.props.postface;
        }

        return (
            <span style={style} className={className} >{content}</span>
        );
    }
}


export default Math;
