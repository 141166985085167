import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import BuildingContent from 'projectLibrary/proc_gen/building_content.js';
import BusinessContent from 'projectLibrary/proc_gen/business_content.js';
import PersonContent from 'projectLibrary/proc_gen/person_content.js';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Building extends React.Component {
    constructor(props) {
        super(props);

        this.select_building = this.select_building.bind(this);
    }

    select_building() {
        this.props.select_building(this.props.building.id, 'building');
    }

    render() {
        var building = this.props.building;
        var top = building.min_y/10 + 200
        var left = building.min_x/10 - 100

        var size = 20;
        var color = '#000'
        if (building.type == 'Farm') {
            size = 40
            color = '#aaa'
        }
        if (building.type == 'House') {
            color = '#fff'
        }
        if (building.occupied == false) {
            color = '#f00'
        }

        return (
            <div onClick={this.select_building} style={{position: 'absolute', top: top + 'px', left: left + 'px', height:size + 'px', width: size + 'px', backgroundColor:color}}></div>
        )
    }
}

class Road extends React.Component {
    constructor(props) {
        super(props);

        this.select_road = this.select_road.bind(this);
    }

    select_road() {
        //this.props.select_road(this.props.road.id, 'road');
    }

    render() {
        var road = this.props.road;
        var top = road.min_y/10 + 200
        var left = road.min_x/10 - 100
        var bottom = road.max_y/10 + 200
        var right = road.max_x/10 - 100

        var height = bottom - top;
        var width = right - left;
        console.log("Road", road)

        return (
            <div onClick={this.select_road} style={{position: 'absolute', top: top + 'px', left: left + 'px', height: height + 'px', width: width + 'px', backgroundColor:'#af8558'}}></div>
        )
    }
}

class RoadForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <FormWithChildren key={"Road_" + this.props.road.id} submitUrl={'/api/procedural_generation/road/' + this.props.road.id + '/'} defaults={this.props.road} redirect={this.props.refresh_roads}>
                <TextInput label='min_y' name='min_y' />
                <TextInput label='max_y' name='max_y' />
                <TextInput label='min_x' name='min_x' />
                <TextInput label='max_x' name='max_x' />
            </FormWithChildren>
        )
    }
}

export default class LocationList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {building_list:[],building_dict:{},
                    road_list:[], road_dict:{},
                    person_list:[], person_dict:{},
                    business_list:[], business_dict:{},
                    relationship_list:[], relationship_dict:{},
                    loaded: true, selected_item: null, selected_type:null}

        this.building_callback = this.building_callback.bind(this);
        this.road_callback = this.road_callback.bind(this);
        this.person_callback = this.person_callback.bind(this);
        this.business_callback = this.business_callback.bind(this);
        this.relationship_callback = this.relationship_callback.bind(this);
        this.select_item = this.select_item.bind(this);
        this.refresh_roads = this.refresh_roads.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/procedural_generation/building/?related=occupant,business', {}, this.building_callback)
        this.refresh_roads();
        ajaxWrapper('GET','/api/procedural_generation/person/?related=relationships_alpha,relationships_beta', {}, this.person_callback)
        ajaxWrapper('GET','/api/procedural_generation/business/?related=employee', {}, this.business_callback)
        //ajaxWrapper('GET','/api/procedural_generation/relationship/', {}, this.road_callback)
    }

    refresh_roads() {
        ajaxWrapper('GET','/api/procedural_generation/road/', {}, this.road_callback)
    }

    select_item(id, type) {
        var item = {};
        if (type == 'building') {
            item = this.state.building_dict[id];
        }
        else if (type == 'person') {
            item = this.state.person_dict[id];
        }
        else if (type == 'business') {
            item = this.state.business_dict[id];
        }
        else if (type == 'relationship') {
            item = this.state.relationship_dict[id];
        }
        else if (type == 'road') {
            item = this.state.road_dict[id];
        }

        console.log("Selected Item", id, item, type)

        this.setState({selected_item: item, selected_type: type})
    }


    building_callback(result) {
        var building_list = [];
        var building_dict = {}
        for (var item of result) {
            var building = item['building'];
            building_list.push(building);
            building_dict[building.id] = building;
        }

        this.setState({
            'building_list':building_list,
            'building_dict':building_dict,
            loaded:true
        });
    }

    person_callback(result) {
        var person_list = [];
        var person_dict = {}
        for (var item of result) {
            var person = item['person'];
            person_list.push(person);
            person_dict[person.id] = person;
        }

        this.setState({
            'person_list':person_list,
            'person_dict':person_dict
        });
    }

    relationship_callback(result) {
        var relationship_list = [];
        var relationship_dict = {}
        for (var item of result) {
            var relationship = item['relationship'];
            relationship_list.push(relationship);
            relationship_dict[relationship.id] = relationship;
        }

        this.setState({
            'relationship_list':relationship_list,
            'relationship_dict':relationship_dict,
            loaded:true
        });
    }

    business_callback(result) {
        var business_list = [];
        var business_dict = {}
        for (var item of result) {
            var business = item['business']
            business_list.push(business);
            business_dict[business.id] = business;
        }

        this.setState({'business_list':business_list, 'business_dict':business_dict, loaded:true})
    }

    road_callback(result) {
        var road_list = [];
        var road_dict = {}
        for (var item of result) {
            var road = item['road']
            road_list.push(road);
            road_dict[road.id] = road
        }

        this.setState({'road_list':road_list, 'road_dict':road_dict, loaded:true})
    }

    render() {
        var window_height = window.outerHeight;
        var window_width = window.outerWidth;

        var buildings = [];
        for (var building of this.state.building_list) {
            buildings.push(
                <Building building={building} select_building={this.select_item} />
            )
        }

        var roads = [];
        for (var road of this.state.road_list) {
            roads.push(
                <Road road={road} select_road={this.select_item} />
            )
        }

        var sidebar_content = [<p>This a procedural town generated via history. Each Building, House and Shop has a history to it, with founders, employees, sales, etc. Businesses can greatly succeed or fail, employees sometimes retire or die and will effect the world around them. People get married, have kids and their kids grow up and start new businesses, build new homes, or work for another successful business in town.</p>,
            <p>This was my first attempt and their are some issues. But I hope to iron them out, add some terrain and most interesting pathing/roads, vary up the building style and add in images instead of just colors to really make this town comes alive.</p>]

        if (this.state.selected_item) {
            if (this.state.selected_type == 'building') {
                sidebar_content = <BuildingContent building={this.state.selected_item} select_item={this.select_item} />
            }
            else if (this.state.selected_type == 'business') {
                sidebar_content = <BusinessContent business={this.state.selected_item} person_dict={this.state.person_dict} select_item={this.select_item} />
            }
            else if (this.state.selected_type == 'person') {
                sidebar_content = <PersonContent person={this.state.selected_item} person_dict={this.state.person_dict} business_dict={this.state.business_dict} select_item={this.select_item} />
            }
            else if (this.state.selected_type == 'road') {
                sidebar_content = <RoadForm road={this.state.selected_item} refresh_roads={this.refresh_roads} />
            }
            else {
                sidebar_content = <p>Item: {this.state.selected_item.id}</p>
            }

        }

        if (isMobile) {
            if (this.state.selected_item) {
                var content = <div className="container-fluid">
                    <div>
                        <Button text='Go Back' type='primary' onClick={() => this.setState({selected_item: null})} />
                        {sidebar_content}
                    </div>
                </div>;
            }
            else {
                var content = <div className="container-fluid">
                    <div style={{backgroundColor: '#0a0', height:window_height + 'px', width: window_width + 'px'}}>
                        {buildings}
                        {roads}
                    </div>
                </div>
            }
        }
        else {
            var content = <div className="container-fluid">
                <div style={{backgroundColor: '#0a0', height:window_height*2 + 'px', width: window_width + 'px'}}>
                    {buildings}
                    {roads}
                </div>
                <Sidebar loaded={true} no_opener={true} open={true} headerHeight={40} width={450} height={'100%'}>
                    {sidebar_content}
                </Sidebar>
            </div>;
        }


        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
