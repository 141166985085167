import React, { Component } from 'react';
import { Container, TextArea, FormWithChildren } from 'library';


export default class FancyBioSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0};
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    updateDimensions() {
      var width = document.getElementById('fancy-bio-sheet').getBoundingClientRect().width;
      this.setState({'width':width});
    }

    render() {
        var font_size = (this.state.width * .013) + 'px';
        var instance = this.props.instance;
        var monster = this.props.monster;

        var top_section = null;
        var body_section = null;
        if (monster){
            var pb = monster.proficiency_bonus;

            var top_section = <div className='header col-12' >
                <div className='player-name'>{monster.name}</div>
                <div className='top-block' >
                    <div className='top-block-text' >

                    </div>
                </div>
            </div>;

            var body_section = <FormWithChildren className='body col-12 row' setGlobalState={this.props.update_instance} autoSetGlobalState={true} globalStateName={'general_notes'} defaults={instance} >
                <div className='col-4' >
                    <div className='bordered-box' >
                        <TextArea name='appearance_text' rows={'12'} />
                        <div className='bottom-label bordered'>CHARACTER APPEARANCE</div>
                    </div>
                    <div className='bordered-box' >
                        <TextArea name='backstory_text' rows={'12'} />
                        <div className='bottom-label bordered'>CHARACTER BACKSTORY</div>
                    </div>
                </div>
                <div className='col-8' >
                    <div className='bordered-box large' >
                        <TextArea name='allies_and_organizations_text' rows={'12'} />
                        <div className='bottom-label bordered'>ALLIES & ORGANIZATIONS</div>
                    </div>
                    <div className='bordered-box large' >
                        <TextArea name='notes' rows={'12'} />
                        <div className='bottom-label bordered'>GENERAL NOTES</div>
                    </div>
                    <div className='bordered-box large' >
                        <TextArea name='inventory_text' rows={'12'} />
                        <div className='bottom-label bordered'>TREASURE</div>
                    </div>
                </div>
            </FormWithChildren>
        }

        return (
            <div id='fancy-bio-sheet' className='fancy-sheet fancy-bio-sheet row' style={{fontSize: font_size}} >
                {top_section}
                {body_section}
            </div>
        )
    }
}
