import React, { Component } from 'react';
import {resolveVariables, format_date_string} from 'functions';
import {TextInput, CSSInput} from 'library';

class DateFormat extends React.Component {
    static component_name = 'DateFormat';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'date string'} name={'date'} default={'YYYY-MM-DD HH:MM'} />,
                <TextInput label={'output format'} name={'output_format'} default={'dd-mm-yy'} />,
                <TextInput label={'input format'} name={'input_format'} default={'YYYY-MM-DD HH:MM'} />,
                <TextInput label={'timezone'} name={'timezone'} default={'America/New_York'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }
    }

    render() {
        var text = format_date_string(this.props.date, this.props.output_format, this.props.timezone);

        return (
            <span style={this.props.style || {}}>{text}</span>
        );
    }
}

export default DateFormat;
