import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Instance, Header, ListWithChildren, FormWithChildren, ImageInput, TextInput, Div, CopyToClipboard} from 'library';

class Viewfilegroups extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
				<Instance {...this.props} order={0} style={{}} delete={""} parent={null} dataUrl={"/api/modelWebsite/FileGroup/{params.1}/"} required={""} objectName={"FileGroup"} >
					<Header {...this.props} size={3} text={resolveVariables({"text":"{props.name}"}, this)["text"]}  />
					<Div {...this.props} className={"col-sm-4"} >
						<Div {...this.props} style={{'margin': '10px', 'padding': '20px'}} className={"card"} >
							<FormWithChildren {...this.props} style={{'': ''}} submitUrl={"/api/modelWebsite/StaticFile/"} objectName={"new_image_form"} submit_text={"Save"} autoSetGlobalState={false} form_child_update_key={null} reset_state_on_submit={true} >
								<Header {...this.props} size={3} text={"Add New Image"}  />
								<TextInput {...this.props} name={"filegroup"} style={{'display': 'none'}} default={resolveVariables({"text":"{props.id}"}, this)["text"]}  />
								<ImageInput {...this.props} name={"url"} preview={true} multiple={false}  />
							</FormWithChildren>
						</Div>
					</Div>
				</Instance>


				<Div {...this.props} order={11} style={{}} delete={""} parent={null} required={""} className={"row"} >
					<ListWithChildren {...this.props} dataUrl={"/api/modelWebsite/StaticFile/?filegroup_id={params.1}"} objectName={"StaticFile"} nowrapper={true}>
						<Div {...this.props} className={"col-sm-4"} >
							<Div {...this.props} style={{'margin': '10px 0px', 'padding': '20px'}} className={"card"} >
								<FormWithChildren {...this.props} defaults={resolveVariables({"text":{'url': '{props.url}', 'filegroup': '{props.filegroup_id}'}}, this)["text"]} deleteUrl={resolveVariables({"text":"/api/modelWebsite/StaticFile/{props.id}/delete/"}, this)["text"]} submitUrl={resolveVariables({"text":"/api/modelWebsite/StaticFile/{props.id}/"}, this)["text"]} submit_text={"Save"} >
									<ImageInput {...this.props} name={"url"} preview={true} multiple={false}  />
									<TextInput {...this.props} name={"filegroup"} style={{'display': 'none'}}  />
								</FormWithChildren>
								<CopyToClipboard {...this.props} text={resolveVariables({"text":"{props.url}"}, this)["text"]}  />
							</Div>
						</Div>
					</ListWithChildren>
				</Div>

        </div>);
    }
}

export default Viewfilegroups;
