import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { NumberInput, FormWithChildren, Button, TextInput, Select } from 'library';

import Action from 'projectLibrary/monsters/action.js';


export default class CreateResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_resource: false,
            show_create_new_resource: false
        };

        this.add_resource = this.add_resource.bind(this);
        this.add_monster_resource = this.add_monster_resource.bind(this);
        this.add_id_to_new_monster_resource = this.add_id_to_new_monster_resource.bind(this);
        this.add_id_to_new_instance_resource = this.add_id_to_new_instance_resource.bind(this);
        this.update_global_resources = this.update_global_resources.bind(this);
    }

    componentDidMount(){

    }

    add_resource(data){
        console.log(data);
        if ('name' in data){
            ajaxWrapper('POST', '/api/compendium/Resource/', {name: data['name']}, this.update_global_resources);
        }
        else if ('resource_id' in data){
            this.add_monster_resource(data['resource_id']);
        }
    }

    update_global_resources(value){
        var resource = value[0]['Resource'];
        window.cmState.resources[resource.id] = resource;

        this.add_monster_resource(resource.id);
    }

    add_monster_resource(id){
        var url = '/api/compendium/MonsterResource/';
        var data = {
            monster: this.props.monster.id,
            resource: id,
            quantity: 0
        }
        ajaxWrapper('POST', url, data, this.add_id_to_new_monster_resource);

        this.setState({show_resource: false});
    }

    add_id_to_new_monster_resource(value){
        var data = value[0]['MonsterResource'];
        var new_monster_resources = this.props.monster.monster_resources;
        new_monster_resources.push({monsterresource: data});
        this.props.update_monster('resource', {monster_resources: new_monster_resources}, true);

        var url = '/api/home/MonsterInstanceResource/';
        var data = {
            monster_instance: this.props.instance.id,
            resource: data.resource_id,
            quantity: 0
        }
        ajaxWrapper('POST', url, data, this.add_id_to_new_instance_resource);
    }

    add_id_to_new_instance_resource(value){
        var data = value[0]['MonsterInstanceResource'];
        var new_instance_resources = this.props.instance.monster_instance_resources;
        new_instance_resources.push({monsterinstanceresource: data});
        this.props.update_instance('resource', {monster_instance_resources: new_instance_resources}, true);
    }


    render (){
        var instance = this.props.instance;
        var monster = this.props.monster;
        var content = null;

        var small_size_class = this.props.small_size || 'col-6';

        var resource_options = [];
        for (var key in window.cmState.resources){
            var resource = window.cmState.resources[key];

            if (this.props.exclude && resource.name.indexOf(this.props.exclude) > -1) {
                continue;
            }
            if (this.props.only && resource.name.indexOf(this.props.only) == -1) {
                continue;
            }

            resource_options.push({
                value: resource.id,
                text: resource.name
            });
        }

        resource_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        if (this.state.show_resource && !(this.state.show_create_new_resource)){
            content = <div className='col-12' ><FormWithChildren className='resource large' submit={this.add_resource} submit_text={'Save'} globalStateName={'new_resource'} defaults={{}} >
                    <Select label='Resource' name={'resource_id'} options={resource_options} />
                    <Button type='default' text='Create New Resource' onClick={() => this.setState({show_create_new_resource: true})} />
                </FormWithChildren></div>;
        }
        else if (this.state.show_create_new_resource){
            content = <div className='col-12' ><FormWithChildren className='resource large' submit={this.add_resource} submit_text={'Save'} globalStateName={'new_resource'} defaults={{}} >
                    <TextInput label='New Resource Name' name={'name'} />
                    <Button type='default' text='Cancel' onClick={() => this.setState({show_resource: false, show_create_new_resource: false})} />
                </FormWithChildren></div>;
        }
        else {
            content = <div className={small_size_class} ><div className='resource' style={{textAlign: 'center', padding: '5% 2%'}} >
                    <Button type='default' text={this.props.text || 'Add Resource'} onClick={() => this.setState({show_resource: true})} />
                </div></div>;
        }

        return (
            content
        );
    }
}
