import React, { Component } from 'react';
import {Button} from 'library';
import save_monster_instance from 'projectLibrary/session_tools/global_data/save_monster_instance.js';
import get_instance_name from 'projectLibrary/monsters/get_instance_name.js';


export default class TakeDamage extends React.Component {
    constructor(props) {
        super(props);

        this.take_damage = this.take_damage.bind(this);
    }

    take_damage() {
        this.props.take_damage(this.props.point_type, this.props.damage);
        //if using slot and action takes concentration, add concentration to instance.
        if (this.props.action && this.props.action.spell_specifics.concentration) {
            var data = {
                id: this.props.current_instance.id,
                concentrating_on_id: this.props.action.id,
                concentration_level: this.props.cast_level
            };
            save_monster_instance(data);
        }

        var instance = window.cmState.selected_monster_instance;
        if (this.props.point_type == 'current_hp' && instance.concentrating_on_id) {
            //taking damage while concentrating calls for a CON Save of half the damage with a minimum of 10.
            var dc = 10;
            if (this.props.damage > 20) {
                dc = Math.floor(this.props.damage / 2);
            }

            this.props.send_concentration_roll(dc, instance.concentrating_on_id);
        }
    }

    render() {
        var button_type = 'danger';
        if (this.props.damage < 0) {
            button_type = 'success';
        }

        if (this.props.current_instance){
            if (this.props.damage < 0) {
                return (<Button type={button_type} text={'Heal ' + this.props.current_instance.name} onClick={this.take_damage} />);
            }
            else if (this.props.half) {
                return (<Button type={button_type} text={'1/2'} onClick={this.take_damage} />);
            }
            else {
                var text = this.props.text;
                if (this.props.current_instance) {
                    text = this.props.text + ': ' + get_instance_name(this.props.current_instance);
                }
                return (<Button type={button_type} text={text} onClick={this.take_damage} />);
            }
        }
        else {
            return (null);
        }
    }
}
