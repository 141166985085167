import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select,
    CheckGroup, ImageInput, Sidebar, Alert, PageBreak } from 'library';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import LocationMap from 'projectLibrary/map_components/location_map.js';
import MapControls from 'projectLibrary/map_components/controls.js';
import MapLocations from 'projectLibrary/nav/map_locations.js';
import LocationDetails from 'projectLibrary/display_components/location_details.js';
import MetaTags from 'react-meta-tags';
import Join from 'projectLibrary/display_components/join.js';

import load_dnd_constants from 'projectLibrary/session_tools/global_data/load_dnd_constants.js';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class LocationPage extends React.Component {
	constructor(props) {
		super(props)

        var close_join = true;
        if (window.cmState.user) {
            close_join = true;
        }

		this.state = {
			loaded: false,
            location: {},
			markers: [],
            close_join: close_join,

            map_mode: 'pan',
            details_sidebar_mode: '',

			polygon_position: [],
			polygon_key: 0,

			filters: [],
            filter_key: 0,
            date_key: Date.now(),
			contribute: false,
            refresh_key: 0,
            location_permissions:{},
            group: null,
            group_view: null,

            center_map_position: {x:0, y:0},
            center_map_timestamp: null,
		}

		//'https://media.wizards.com/2015/images/dnd/resources/Sword-Coast-Map_MedRes.jpg' 93, 250

		this.get_location_data = this.get_location_data.bind(this);
		this.get_location_data_callback = this.get_location_data_callback.bind(this);

		this.set_map_mode = this.set_map_mode.bind(this);
		this.update_marker_list = this.update_marker_list.bind(this);
		this.set_details_sidebar_mode = this.set_details_sidebar_mode.bind(this);

		this.handle_map_click = this.handle_map_click.bind(this);
		this.add_new_marker_callback = this.add_new_marker_callback.bind(this);
		this.update_filters = this.update_filters.bind(this);
		this.contribute = this.contribute.bind(this);
		this.roll_stealth = this.roll_stealth.bind(this);
        this.permissions_callback = this.permissions_callback.bind(this);

        this.set_global_state = this.set_global_state.bind(this);
        this.move_icon = this.move_icon.bind(this);
        this.close_sidebar = this.close_sidebar.bind(this);

        this.center_map = this.center_map.bind(this);
	}

	componentDidMount() {
        window.cmState.subscribe_by_name(this, 'selected_monster_instance');

        load_dnd_constants(this.get_location_data);
	}

    set_global_state(name, state) {
        this.setState(state)
    }

    get_location_data() {
        console.log("Refreshing Loaction Data");
		var id = this.props.id || window.cmState.params.id || '6527f3c7-fe59-4820-838b-ff1354181285';
		ajaxWrapper('GET', '/api/home/location/' + id + '/?related=children,children__tags,children__link', {}, this.get_location_data_callback);

        if (this.props.group) {
            ajaxWrapper('GET','/api/home/adventuringgroup/' + this.props.group + '/?related=location_permission',{}, this.permissions_callback);
            console.log('group permissions_callback');
        }
	}

    permissions_callback(result) {
        var group = result[0]['adventuringgroup'];

        var permissions = {}
        for (var item of group.location_permission) {
            var permission = item['grouplocationpermission'];
            permissions[permission.location_id] = permission;
        }

        this.setState({location_permissions: permissions, group: group})
        console.log("Permissions Callback", result)
    }

	get_location_data_callback(result) {
        console.log('get_location_data_callback');
		var location = result[0]['location'];
		console.log("Location Result", location);

		var markers = [];
		for (var item of location.children) {
			var marker = item['location'];
			markers.push(marker);
		}

		var new_state = {
            loaded: true,
			location: location,
			markers: markers,
		};

		this.setState(new_state);
	}

	contribute() {
		this.setState({contribute: !this.state.contribute})
	}

	set_map_mode(mode){
		if (this.state.map_mode == 'add_polygon' && mode == '') {
			var id = window.cmState.params.id || '6527f3c7-fe59-4820-838b-ff1354181285';
			if (this.state.polygon_position.length > 2) {
				var data = { 'lat_lng_list': this.state.polygon_position, parent: id}
				ajaxWrapper('POST', '/api/home/location/?related=tags', data, this.add_new_marker_callback);
			}
		}

		this.setState({map_mode: mode});
	}

	set_details_sidebar_mode(mode) {
		this.setState({details_sidebar_mode: mode});
	}

    move_icon(icon_id, new_loc, location) {
        console.log("Move Icon", icon_id, new_loc, location);

        if (location) {
            var markers = [];
            for (var marker of this.state.markers) {
                if (marker.id == icon_id) {
                    marker.coords = new_loc;
                    ajaxWrapper('POST','/api/home/location/' + icon_id + '/', {'coords':new_loc}, console.log)
                }
                markers.push(marker)
            }
            this.update_marker_list(markers)
        }

    }

    update_marker_list(markers) {
        var new_timestamp = Date.now();
        this.setState({markers: markers, date_key: new_timestamp});
    }

	update_filters(filters) {
		this.setState({filters: filters, filter_key: this.state.filter_key + 1});
	}

	handle_map_click(position, e) {
		if (this.state.map_mode == 'add_markers') {
            var user = null;
            if (window.cmState.user){
                user = window.cmState.user['id'];
            }

            var data = {
                'coords':{x: position[0], y: position[1]},
                'user_who_added': user,
            };
			var id = this.state.location.id || '6527f3c7-fe59-4820-838b-ff1354181285';
            data['parent'] = id;
            if (this.props.group) {
                data['group'] = this.props.group;
            }
			ajaxWrapper('POST', '/api/home/location/', data, this.add_new_marker_callback);

		} else if (this.state.map_mode == 'add_polygon') {
			var polygon_position = this.state.polygon_position;
			var lat_lng = e.latlng;
			polygon_position.push(lat_lng);
			this.setState({polygon_position: polygon_position});

		} else {
            if (window.cmState.selected_monster_instance) {
                window.cmState.setGlobalState('selected_monster_instance', null);
            }

		}
	}

    center_map(event, position){
        this.setState({
            center_map_position: position,
            center_map_timestamp: new Date().valueOf(),
        });
    }

	add_new_marker_callback(result) {
		var markers = this.state.markers;
		var new_loc = result[0]['location'];
		markers.push(new_loc);

        var new_timestamp = Date.now();
		this.setState({
            markers: markers,
            date_key: new_timestamp,
            map_mode:'pan'
        }, () => window.cmState.setGlobalState('selected_monster_instance', new_loc));
	}

	roll_stealth() {
		localStorage.setItem('hide_neverwinter', true);
		this.setState({hide_neverwinter:true});
	}

    close_sidebar(open){
        if (!(open)){
            window.cmState.setGlobalState('selected_monster_instance', null);
        }
        else {
            window.cmState.setGlobalState('selected_monster_instance', this.state.location);
        }
    }

	render() {
		var id = window.cmState.params.id || '6527f3c7-fe59-4820-838b-ff1354181285';
        var dm = false;
        if (this.props.group && window.cmState.user) {
            if (this.state.group && this.state.group.dm_id == window.cmState.user.id) {
                dm = true;
            }
        }
        console.log("Are you the dm?", dm)

        var available_filters = [];

        var markers = [];
        for (var marker of this.state.markers) {
            if (marker.id == '7c5e0d86-9197-4516-b3ed-aa502fa85788') {
                console.log("Marker Found!!!", marker);
            }

            if (marker.group_id != null && marker.group_id != this.props.group) {
                continue;
            }

            var group_permission = this.state.location_permissions[marker.id];

            if (this.props.group && (!dm || this.state.group_view) && !group_permission) {
                continue;
            }
            else if (this.props.group && (!dm || this.state.group_view) && group_permission && group_permission.show == false) {
                continue;
            }
            else if (this.props.group && group_permission && this.state.group_view && group_permission.show_players == false) {
                continue;
            }
            else if (this.props.group && this.state.group_view && !group_permission) {
                continue;
            }

            if (this.props.group && group_permission && group_permission.show == false) {
                continue;
            }

            if (this.props.group && group_permission) {
                marker['group_location_permission'] = group_permission
                if (group_permission['lat'] != 0) {
                    marker['lat'] = group_permission['lat']
                    marker['lng'] = group_permission['lng']
                }
            }

            if (marker['tags']){
                for (var tag of marker['tags']) {
                    if (available_filters.indexOf(tag['tag']['id']) == -1) {
                        available_filters.push(tag['tag']['id']);
                    }
                }
            }

            if (marker.type == 'region' && available_filters.indexOf('region') == -1) {
                available_filters.push('region');
            }

            if (this.state.filters.length == 0) {
                markers.push(marker);
            }
			else {
                if (marker.type == "") {
                    markers.push(marker);
                }
				else if (this.state.filters.indexOf(marker.type) > -1) {
					markers.push(marker);
				}
				else {
					for (var item of marker['tags']) {
						if (this.state.filters.indexOf(item['tag']['id']) > -1) {
							markers.push(marker);
						}
					}
				}
			}
        }

        var show_detail_sidebar = null;
        var location_to_show_details = this.state.location;
        var active_marker = window.cmState.selected_monster_instance;
        if (active_marker){
            location_to_show_details = active_marker;
            show_detail_sidebar = true;
            console.log("Active Marker", active_marker);
        }
        
        if (active_marker && active_marker.type == 'interaction') {
            var location_details = <LocationDetails map_mode={this.state.map_mode} set_map_mode={this.set_map_mode}
            center_map={this.center_map} date_key={this.state.date_key}
            key={location_to_show_details.id} display_location={location_to_show_details} map_location={this.state.location}
            refresh_data={this.get_location_data} dm={dm} group={this.props.group}
            details_sidebar_mode={this.state.details_sidebar_mode} set_details_sidebar_mode={this.set_details_sidebar_mode} />;

            var sidebar_height = window.innerHeight;
            var details_sidebar = <Sidebar loaded={true} start_open={false} open={show_detail_sidebar} toggleOpen={this.close_sidebar}
                openerText={'< Location List'} openerPosition={'40px'} height={sidebar_height} headerHeight={0} widthPercent={40} >
                <div style={{padding:'0px 0px 10px', margin:'0px 0px 10px', borderBottom: '2px solid #666'}}>
                    {location_details}
                </div>
            </Sidebar>;
        }
        else{
            var location_details = <LocationDetails map_mode={this.state.map_mode} set_map_mode={this.set_map_mode}
            center_map={this.center_map} date_key={this.state.date_key}
            key={location_to_show_details.id} display_location={location_to_show_details} map_location={this.state.location}
            refresh_data={this.get_location_data} dm={dm} group={this.props.group}
            details_sidebar_mode={this.state.details_sidebar_mode} set_details_sidebar_mode={this.set_details_sidebar_mode} />;

            var sidebar_height = window.innerHeight;
            var details_sidebar = <Sidebar loaded={true} start_open={false} open={show_detail_sidebar} toggleOpen={this.close_sidebar}
                openerText={'< Location List'} openerPosition={'40px'} height={sidebar_height} headerHeight={0} widthPercent={40} >
                <div style={{padding:'0px 0px 10px', margin:'0px 0px 10px', borderBottom: '2px solid #666'}}>
                    {location_details}
                    <div style={{margin:'20px 0px'}}><PageBreak /></div>
                    <MapLocations key={"maps_ " + this.state.current_location} map_location={this.state.location} display_location={location_to_show_details}
                        change_location={this.change_location} center_map={this.center_map} />
                </div>
            </Sidebar>;
        }
        

        /*
		if (active_marker) {
			details_sidebar = <LocationDetailsSidebar map_mode={this.state.map_mode} set_map_mode={this.set_map_mode}
				date_key={this.state.date_key} key={active_marker.id} location={active_marker}
				refresh_data={this.get_location_data} dm={dm} group={this.props.group}
				details_sidebar_mode={this.state.details_sidebar_mode} set_details_sidebar_mode={this.set_details_sidebar_mode} />;
		}
        */

        var admin_access = false;
        if (window.cmState.user) {
            if (window.cmState.user.is_staff) {
                admin_access = true;
            }
            else if (this.state.location && window.cmState.user.id == this.state.location.user_who_added_id) {
                admin_access = true;
            }
            else if (this.props.group && dm) {
                admin_access = true;
            }
        }

		var controls_sidebar = <MapControls filter_key={this.state.filter_key} map_mode={this.state.map_mode} set_map_mode={this.set_map_mode}
			filters={this.state.filters} update_filters={this.update_filters} contribute={this.contribute}
            location={this.state.location} admin_access={admin_access} group={this.props.group} available_filters={available_filters} />;

        var center_sidebar_width = 0;
        if (show_detail_sidebar){
            center_sidebar_width = 40;
        }
		var map = <LocationMap key={"fantasy_map_" + this.state.refresh_key} id={id} map_mode={this.state.map_mode} move_icon={this.move_icon}
            group={this.props.group} location={this.state.location} markers={markers} center_sidebar_width={center_sidebar_width}
            center_map_position={this.state.center_map_position} center_map_timestamp={this.state.center_map_timestamp}
            click_map={this.handle_map_click} set_details_sidebar_mode={this.set_details_sidebar_mode} admin_access={admin_access} />;

		var content = null;
        if (this.state.loaded) {
			var neverwinter_warning = null;
			if (localStorage.getItem('hide_neverwinter') != "true" && this.props.id == '4a82f848-1665-4a6c-8608-0da9636b3e74') {
				var neverwinter_warning = <div style={{position:'absolute', top:'0px', left:'0px', zIndex:10000, width:'100%'}}><Alert type='danger'>
					<div>
						<p>Neverwinter has banned all maps of the city for security reasons. Anyone with this map in their posession will be thrown in Mount Hotenow.</p>
						<Button text='Roll A Stealth Check' onClick={this.roll_stealth} />
					</div>
				</Alert></div>;
			}

            var dm_options = null;
            if (dm) {
                var toggle_dm_group = <Button type='primary' text='See Group View' onClick={() => this.setState({group_view: true})} />
                if (this.state.group_view) {
                    toggle_dm_group = <Button type='primary' text='See DM View' onClick={() => this.setState({group_view: null})} />
                }

                dm_options = <div style={{position:'absolute', top:'0px', left:'0px', zIndex:10000, width:'100%'}}>
					<div>
						{toggle_dm_group}
					</div>
                </div>
            }


			if (isMobile) {
				content = <div>
					<div>
                        {dm_options}
						{neverwinter_warning}
						{map}
						{details_sidebar}
						{controls_sidebar}
					</div>
	            </div>;

				if (this.state.details_sidebar_mode) {
					content = <div>
						<div>
							<Button type='primary' text='Back To Map' style={{width:'100%'}} onClick={() => this.set_details_sidebar_mode("")} />
							{details_sidebar}
						</div>
		            </div>;
				}
				if (this.state.contribute) {
					content = <div>
						<div>
							<Button type='primary' text='Back To Map' style={{width:'100%'}} onClick={this.contribute} />
							<Join />
						</div>
		            </div>;
				}

			}
			else {
                if (this.state.close_join) {
                    content = <div>
                        <MetaTags>
                        <title>Interactive Map of {this.state.location.name} in DnD 5e</title>
        					<meta name="description" content={this.state.location.description} />
        		            <meta property="og:title" content={"Interactive Map of " + this.state.location.name + " in DnD 5e"} />
        				</MetaTags>
        				<div>
                            {dm_options}
        					{neverwinter_warning}
        					{map}

        					{controls_sidebar}
                            {details_sidebar}
        				</div>
    	            </div>;
                }
                else {
                    content = <div>
                        <MetaTags>
                            <title>Interactive Map of {this.state.location.name} in DnD 5e</title>
        					<meta name="description" content={this.state.location.description} />
        		            <meta property="og:title" content={"Interactive Map of " + this.state.location.name + " in DnD 5e"} />
        				</MetaTags>
        				<div style={{padding:'0px'}}>
                            {dm_options}
        					{neverwinter_warning}
        					{map}

        					{controls_sidebar}
                            {details_sidebar}
                            <Join close_join={this.close_join} />
        				</div>
    	            </div>;
                }
			}
        }

		return (
			<Wrapper content={content} loaded={this.state.loaded} />
		);
	}
}

export default LocationPage;
