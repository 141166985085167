import React, { Component } from "react";
import Konva from "konva";
import { Stage, Layer, Rect, Transformer } from "react-konva";

export default class Handler extends React.Component {
  componentDidMount() {
    // not really "react-way". But it works.
    console.log("Transformer")
    console.log(this.transformer)
    const stage = this.transformer.getStage();
    const rectangle = stage.findOne("." + this.props.icon.id);
    if (rectangle) {
        this.transformer.attachTo(rectangle);
        this.transformer.getLayer().batchDraw();
    }
  }

  render() {
    return (
      <Transformer
        resizeEnabled={false}
        ref={node => {
          this.transformer = node;
        }}
      />
    );
  }
}
