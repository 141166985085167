import React, { Component } from 'react';
import {Wrapper} from 'library';

export default class Tab extends React.Component {
    constructor(props) {
        super(props);
        this.select_tab = this.select_tab.bind(this);
    }

    select_tab() {
        this.props.select_tab(this.props.name)
    }

    render() {
        if (this.props.name == this.props.selected_tab) {
            return (
                <li class="nav-item">
                  <a class="nav-link active" onClick={this.select_tab} href="#">{this.props.name}</a>
                </li>
            )
        }
        else {
            return (
                <li class="nav-item">
                  <a class="nav-link" onClick={this.select_tab} href="#">{this.props.name}</a>
                </li>
            )
        }
    }

}
