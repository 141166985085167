import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { NumberInput, FormWithChildren } from 'library';
import Action from 'projectLibrary/monsters/action.js';


export default class SpellLevel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.update = this.update.bind(this);
    }

    componentDidMount(){

    }

    update(name, state){
        console.log('Resource Update ', name, state);

        var resource_id = name.replace('resource_', '');
        var found = false;

        if ('current' in state){
            var instance_resource = this.get_instance_resource(resource_id);

            var id = instance_resource.id;
            var url = '/api/home/MonsterInstanceResource/'+ id +'/';
            ajaxWrapper('POST', url, {quantity: state['current']}, console.log);

            instance_resource.quantity = state['current'];

            //this.props.update_instance(name, {monster_instance_resources: new_instance_resources}, true);
        }
        else if ('max' in state){
            var monster_resource = this.get_monster_resource(resource_id);

            var id = monster_resource.id;
            var url = '/api/compendium/MonsterResource/'+ id +'/';
            ajaxWrapper('POST', url, {quantity: state['max']}, console.log);

            monster_resource.quantity = state['max'];

            //this.props.update_monster(name, {monster_resources: new_monster_resources}, true);
        }
    }

    get_monster_resource(resource_id){
        var resource = null;

        var monster = this.props.monster;
        for (var item of monster.monster_resources) {
            if (item.monsterresource.resource_id == resource_id){
                resource = item.monsterresource;
            }
        }

        return resource;
    }

    get_instance_resource(resource_id){
        var resource = null;

        var instance = this.props.instance;
        for (var item of instance.monster_instance_resources) {
            if (item.monsterinstanceresource.resource_id == resource_id){
                resource = item.monsterinstanceresource;
            }
        }

        return resource;
    }

    render() {
        var instance = this.props.instance;
        var monster = this.props.monster;
        var spells = this.props.spells[this.props.level];

        var max_input = null;
        if (this.get_monster_resource(spells.resource_id)){ max_input = <NumberInput sneaky_input={true} className='total-slots' name={'max'} />; }
        var current_input = null;
        if (this.get_monster_resource(spells.resource_id)){ current_input = <NumberInput sneaky_input={true} className='remaining-slots' name={'current'} />; }

        var resource_header = <FormWithChildren className={'spell-level-header'} setGlobalState={this.update} autoSetGlobalState={true}
            globalStateName={'resource_'+ spells.resource_id} defaults={spells} >
            <div className='spell-level-label'>{this.props.level}</div>
            {max_input}
            {current_input}
        </FormWithChildren>;

        var level_one_header = null;
        if (this.props.level == 0){
            resource_header = <div className={'spell-level-header cantrip'} >
                <div className='spell-level-label'>{this.props.level}</div>
                <div className='spell-level-label-extra'>CANTRIPS</div>
            </div>
        }
        else if (this.props.level == 1){
            level_one_header = [
                <div className='total' >Total</div>,
                <div className='remaining' >Remaining</div>
            ];
        }

        var spell_list = [];
        if (spells.max == 0 && this.props.level > 0){
            //Possible escape for spells that cant be cast
        }

        for (var spell of spells.spells){
            if (spell.level != this.props.level){
                continue;
            }

            spell_list.push(
                <Action instance={this.props.instance} action={spell} cast_level={this.props.level}
                    add_action_token={this.props.add_action_token} use_action={this.props.use_action} push_modal_content={this.props.push_modal_content} />
            );
        }

        return (
            <div className='spell-level' >
                {level_one_header}
                {resource_header}

                <div className={'spell-list'} >
                    {spell_list}
                </div>
                <div><br/><br/></div>
            </div>
        )
    }
}
