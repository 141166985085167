import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, Select, CSSInput, Button} from 'library'

var BOOLEANS = [
  {'text':'True', value:true},
  {'text':'False', value:false},
];

class PasswordInput extends Component {
    static component_name = 'PasswordInput';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [

                <TextInput label={'name'} name={'name'}  />,
                <TextInput label={'label'} name={'label'} default={'Password'} />,
                <TextInput label={'placeholder'} name={'placeholder'} />,
                <TextInput label={'default'} name={'default'} />,
                <Select label={'Confirm Password?'} name={'confirm_password'} boolean={true} />,
                <Select label={'Show Button'} name={'show_button'} boolean={true} />,
                <Select label={'required'} name={'required'} boolean={true} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
        }

        this.state = {
            password:'',
            password_confirm:'',
            show_value: false
        };

        this.verifyPassword = this.verifyPassword.bind(this);
        this.show_value = this.show_value.bind(this);
    }


    verifyPassword = (e) => {
       var name = e.target.getAttribute("name");
       if (name != 'password_confirm'){
           name = 'password';
       }

       var newState = {};
       newState[name] = e.target.value;
       console.log("handleChange", name, newState)

        this.setState(newState);
    }

    show_value(){
        this.setState({
            show_value: true
        });
    }

    render() {

        var layout = '';
        if (this.props.layout) {
            layout = this.props.layout;
        }

        var value = this.props.default;
        if (this.state.password){
            value = this.state.password;
        }


        var passwordConfirm = <div></div>;
        if (this.props.confirm_password) {
            var valid = ' is-valid';
            if (this.state.password == '') {
              valid = ' is-blank'
              passwordConfirm = <div className={"form-group " + this.props.layout}>
                  <label>Confirm Password</label>
                  <input type="password" className={"form-control" + valid} name="password_confirm" onChange={(e) => this.verifyPassword(e)} value={this.state.password_confirm} />

                </div>
            }

            else if (this.state.password != this.state.password_confirm) {
                valid = ' is-invalid';
                passwordConfirm = <div className={"form-group " + this.props.layout}>
                    <label>Confirm Password</label>
                    <input type="password" className={"form-control" + valid} name="password_confirm" onChange={(e) => this.verifyPassword(e)} value={this.state.password_confirm} />
                    <div className="invalid-feedback">
                      Passwords Do Not Match!
                    </div>
                  </div>
            }

            else {
              passwordConfirm = <div className={"form-group " + this.props.layout}>
                  <label>Confirm Password</label>
                  <input type="password" className={"form-control" + valid} name="password_confirm" onChange={(e) => this.verifyPassword(e)} value={this.state.password_confirm} />
                  <div className="valid-feedback">
                      Passwords Match!
                    </div>
                </div>
            }

        }

        var show_button = null;
        if (this.props.show_button && !(this.state.show_value)){
            var show_style = {
                'position': 'absolute',
                'top': '0px',
                'right': '0px'
            };
            show_button = <Button style={show_style} type={"link"} onClick={this.show_value} text={"Show"} />;
        }

        var type = 'password';
        if (this.state.show_value){
            type='text';
        }

        var name = 'password';
        if (this.props.name){
            name = this.props.name;
        }

        return (
            <div>
              <div className={"form-group " + this.props.layout}>
                <label>Password</label>
                <div style={{'position':'relative'}}>
                    <input type={type} className="form-control" name={name} placeholder={this.props.placeholder} onChange={(e) => {this.verifyPassword(e); this.props.handleChange(e)}} value={value} />
                    {show_button}
                </div>
              </div>
              {passwordConfirm}
            </div>
        )


    }
}

export default PasswordInput;
