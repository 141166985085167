import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { Helmet } from 'react-helmet';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Container, Select,
    CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, Tab, PageBreak, EmptyModal } from 'library';
import parse_roll from 'projectLibrary/monsters/roll.js';
import stat_modifier from 'projectLibrary/stat_modifier.js';
import StatBlockMini from 'projectLibrary/monsters/stat_block/stat_block_mini.js';
import ActionUse from 'projectLibrary/monsters/action_use.js';
import SpellUse from 'projectLibrary/monsters/spell_use.js';
import $ from 'jquery';
import CustomRoll from 'projectLibrary/monsters/custom_roll.js';

import load_dnd_constants from 'projectLibrary/session_tools/global_data/load_dnd_constants.js';


export default class MonsterLookUp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            actions:[],
            monster: null,
            roll_damage:{},
            instance: {},
            modal_content:null,
            resources:{},
            equipment: {}
        };


        this.load_local_context = this.load_local_context.bind(this);

        this.use_action = this.use_action.bind(this);
        this.get_monster = this.get_monster.bind(this);
        this.roll_damage = this.roll_damage.bind(this);
        this.push_modal_content = this.push_modal_content.bind(this);
        this.submit = this.submit.bind(this);
        this.get_resources = this.get_resources.bind(this);
        this.get_equipment = this.get_equipment.bind(this);
        this.take_damage = this.take_damage.bind(this);
        this.roll_skill = this.roll_skill.bind(this);
    }

    componentDidMount() {
        load_dnd_constants(this.load_local_context);
    }

    componentDidUpdate(){
        try {
            $(".chat-scroll-container").scrollTop($(".chat-scroll-container")[0].scrollHeight);
        }
        catch (e) {

        }
    }

    load_local_context(){
        ajaxWrapper('GET','/api/compendium/resource/', {}, this.get_resources);
    }

    get_resources(result) {
        var resources = {};
        for (var item of result) {
            var resource = item.resource;
            resources[resource.id] = resource;
        }

        window.cmState.setGlobalState('resources', resources);
        this.setState({resources: resources});

        ajaxWrapper('GET','/api/compendium/equipment/?related=actions', {}, this.get_equipment);
    }

    get_equipment(result) {
        var equipment = {};
        for (var item of result) {
            var equipment_item = item.equipment;
            equipment[equipment_item.id] = equipment_item;
        }

        window.cmState.setGlobalState('equipment', equipment);
        this.setState({equipment: equipment});

        ajaxWrapper('GET','/api/compendium/monster?name=' + this.props.name + '&related=links,action_spells,action_spells__resource_costs,languages,damage_immunities,damage_vulnerabilities,damage_resistances,condition_immunities,monster_resources,equipped_items', {}, this.get_monster);
    }

    roll_instance() {
        //health
        console.log("State Monster", this.state.monster)
        var monster = this.state.monster;
        var num_die = monster.hit_dice.split('d')[0];
        var con_mod = stat_modifier(monster.constitution);
        var added_health = num_die * con_mod;

        var hit_dice = monster.hit_dice + " + " + added_health

        var health = parse_roll(hit_dice).total

        var monster_instance_resources = [];
        for (var item of monster.monster_resources) {
            monster_instance_resources.push({
                'monsterinstanceresource': Object.assign({}, item.monsterresource)
            });
        }

        var instance = {
            name: monster.name,
            current_hp: health,
            max_hp:health,
            monster_id:monster.id,
            monster_instance_resources: monster_instance_resources,
            equipped_items: [],
            conditions: [],
        };
        window.cmState.setGlobalState('selected_monster_instance',instance);
        this.setState({instance:instance, loaded:true});
    }

    use_action(action_use) {
        this.setState({actions: [...this.state.actions, action_use]});
    }

    get_monster(result) {
        var monster = result[0]['monster'];
        var monsters = {};
        monsters[monster.id] = monster;

        var actions = {};
        for (var item of monster.action_spells) {
            var action = item.actionspell;
            actions[action.id] = action;
        }

        window.cmState.setGlobalState('default_objects', {});
        window.cmState.setGlobalState('actions', actions);
        window.cmState.setGlobalState('monsters', monsters);

        this.setState({monster:monster}, this.roll_instance);
    }

    roll_damage(id, crit) {
        var roll_damage = this.state.roll_damage;
        if (crit) {
            roll_damage[id] = 'critical';
        }
        else {
            roll_damage[id] = 'normal';
        }

        this.setState({roll_damage:roll_damage});
    }

    push_modal_content(content){
        var state = {'modal_content': content};
        this.setState(state);
    }

    submit(state,callback) {
        var instance = this.state.instance;
        instance['current_hp'] = instance['current_hp'] - state['damage'];
        this.setState({instance:instance}, callback);
    }

    take_damage(point_type, damage) {
        console.log("Take Damage", point_type, damage)
        var instance = this.state.instance;
        for (var item of instance.monster_instance_resources) {
            var resource = item.monsterinstanceresource;
            var resource_name = this.state.resources[resource.resource_id].name;
            if (resource.resource_id == point_type || resource_name == point_type) {
                resource.quantity -= damage;
            }
        }

        this.setState({instance: instance});
    }

    roll_skill(skill_type) {
        var selected_monster_instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', selected_monster_instance.monster_id);

        console.log("Item", skill_type);
        var equipment_modifiers = ''
        if (selected_monster_instance) {
            for (var item of selected_monster_instance.equipped_items) {
                var equipment_instance = item.instanceequippeditem;
                console.log("Equipment Instance", equipment_instance);
                var equipment = window.cmState.global_state_lookup(this, 'equipment', equipment_instance.equipment_id);

                for (var magic_item of equipment.magical_attributes) {
                    var magical_attribute = magic_item.magicalattribute;
                    if (magical_attribute.saving_throw_type == skill_type) {
                        equipment_modifiers += magical_attribute.saving_throw_bonus;
                    }
                }
            }
        }
        console.log("Equipment Modifiers", equipment_modifiers);

        if (monster) {
            if (['strength','dexterity','constitution','intelligence','wisdom','charisma'].indexOf(skill_type) > -1) {
                var modifier = stat_modifier(monster[skill_type]) + equipment_modifiers;
                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier);
                    var roll_result_two = parse_roll("1d20 - " + modifier);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier);
                    var roll_result_two = parse_roll("1d20 + " + modifier);
                }
            }
            else if (skill_type == 'initiative') {
                var modifier = stat_modifier(monster['dexterity']) + equipment_modifiers;
                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier);
                    var roll_result_two = parse_roll("1d20 - " + modifier);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier);
                    var roll_result_two = parse_roll("1d20 + " + modifier);
                }
            }
            else if (skill_type == 'death_saving_throw') {
                var roll_result_one = parse_roll("1d20");
                var roll_result_two = parse_roll("1d20");
            }
            else {
                var modifier = monster[skill_type] * monster.proficiency_bonus;
                if (['strength_saving_throw','athletics'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.strength);
                }
                else if (['dexterity_saving_throw','acrobatics', 'sleight_of_hand','stealth'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.dexterity);
                }
                else if (['constitution_saving_throw'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.constitution);
                }
                else if (['intelligence_saving_throw','arcana','history','investigation','nature','religion'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.intelligence);
                }
                else if (['wisdom_saving_throw','animal_handling','insight','medicine','perception','survival'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.wisdom);
                }
                else if (['charisma_saving_throw','deception','intimidation','performance','persuasion'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.charisma);
                }

                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier + equipment_modifiers);
                    var roll_result_two = parse_roll("1d20 - " + modifier + equipment_modifiers);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier + equipment_modifiers);
                    var roll_result_two = parse_roll("1d20 + " + modifier + equipment_modifiers);
                }
            }

            var check_name = (skill_type + ' check').replaceAll('_', ' ');

            this.use_action({
                instance_id: selected_monster_instance.id,
                hits: [roll_result_one, roll_result_two],
                damages: [],
                action: {'name': check_name}
            });
        }
    }


    render() {
        var stat_block = null;
        var links = [];
        var monster_name = '';
        if (this.state.loaded){
            monster_name = this.state.monster.name;

            stat_block = <StatBlockMini monster={this.state.monster} use_action={this.use_action} out_of_session={true}
                push_modal_content={this.push_modal_content} instance={this.state.instance} resources={this.state.resources}
                take_damage={this.take_damage} equipment={this.state.equipment} roll_skill={this.roll_skill} />;

            for (var item of this.state.monster.links) {
                var link = item.monsterlink;
                links.push(<h2><Button type='link' href={link.url} text={link.name} style={{fontSize:'15px'}}/></h2>);
            }
        }

        var actions_used = [];
        for (var action of this.state.actions) {
            var action_index = this.state.actions.indexOf(action);
            action.instance = this.state.instance;
            if (action.action_id){
                action.action = window.cmState.global_state_lookup(this, 'actions', action.action_id);
            }

            var id = action.id + '_' + action_index
            var show_damage = false;
            if (this.state.roll_damage[id]) {
                show_damage = this.state.roll_damage[id];
            }

            if (action.custom_roll) {
                actions_used.push(<CustomRoll key={action_index} id={id} {...action} push_modal_content={this.push_modal_content}
                    full_display={true} public={true} roll_damage={this.roll_damage} take_damage={this.take_damage}
                    show_damage={show_damage} resources={this.state.resources}/>);
            }
            else {
                actions_used.push(<ActionUse key={action_index} id={id} {...action} push_modal_content={this.push_modal_content}
                    full_display={true} public={true} roll_damage={this.roll_damage} take_damage={this.take_damage}
                    show_damage={show_damage} resources={this.state.resources}/>);
            }
        }

        var recent_actions = <div className='chat-container' style={{position:'fixed', bottom:"0", width:'100%', backgroundColor:'white', zIndex:"1000"}}>
            <div className="container">
                <h2>Recent Activity</h2>
                <PageBreak />
                <div className="chat-scroll-container" style={{maxHeight:'180px', overflowY:'scroll', paddingBottom:'30px'}}>
                    {actions_used}
                </div>
            </div>
        </div>;

        var modal = null;
        if (this.state.modal_content) {
            modal = <EmptyModal onHide={() => this.setState({modal_content: null})} show={true}>
                <div style={{padding:'20px'}}>
                    {this.state.modal_content}
                </div>
            </EmptyModal>;
        }

        var health_and_status = [
            <FormWithChildren row={true} submitButtonText={'Take Damage'}>
                <Select className="col-12" multiple={true} optionsUrl={'/api/compendium/condition/'} optionsUrlMap={{text:"{condition.name}", value: "{condition.id}"}}
                    name='conditions[]' no_blank_option={true} label='Current Conditions' default={[]}/>
                </FormWithChildren>,
                <FormWithChildren submit={this.submit} submit_text={'Take Damage'} reset_state_on_submit={true}>
                <NumberInput label='Damage' name='damage' />
            </FormWithChildren>
        ];

        var content = <div>
            {recent_actions}
            <div className="container" style={{marginBottom:"180px"}}>
            <Helmet>
              <title>{monster_name} Stat Block For DnD 5e</title>
              <meta name="description" content={"DnD 5e Monster Stat Block for " + monster_name} />
            </Helmet>
            <h2>{monster_name}</h2>
            <a href='/monster_list/'>Back To Monster List</a>
            <PageBreak />
            <div className="row">
                <div className="col-md-10 col-xs-12">
                    {stat_block}
                    {health_and_status}
                </div>
                <div className="col-md-2 col-xs-12">
                    <h2 style={{marginTop:'15px'}}>Extra Info</h2>
                    <PageBreak />
                    {links}
                    <Button href='/contribute/' target='_blank' type='secondary' text='Contribute A Link' />
                </div>
            </div>

            {modal}

            <small>Open	Game	License	v	1.0a	Copyright	2000,	Wizards	of	the	Coast,	LLC.</small>
            <br />
            <small>System Reference	Document 5.1 Copyright 2016,
            Wizards	of	the	Coast, Inc.; Authors Mike Mearls,
            Jeremy Crawford, Chris Perkins,	Rodney Thompson,
            Peter Lee, James Wyatt,	Robert J. Schwalb, Bruce R. Cordell,
            Chris Sims,	and	Steve Townshend, based on original material	by E. Gary Gygax and Dave Arneson.</small>
        </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
