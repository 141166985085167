import React, { Component } from 'react';
import {Wrapper, Navbar, NavLink, Div, Image, Span, Link, Paragraph, DisplaySize, Header} from 'library';


class AboutUs extends Component {
    render() {
        return (<div>
				<Div order={"1"} >
					<DisplaySize display_type={['browser', 'tablet']} >
						<Div style={{'height': '600px', 'text-align': 'center', 'background-color': '#ccc', 'background-image': 'url(https://organictrader-images.s3.amazonaws.com/19_08_23_16_14_36_kid-in-strawberry-farm-GUCSQBP.jpg)'}} >
							<Paragraph text={"ABOUT US"} style={{'color': '#fff', 'font-size': '72px', 'font-family': 'gotham', 'font-weight': '800', 'padding-top': '15%'}}  />
						</Div>
					</DisplaySize>
					<DisplaySize display_type={['mobile']} >
						<Div style={{'height': '600px', 'text-align': 'center', 'background-color': '#ccc', 'background-image': 'url(https://organictrader-images.s3.amazonaws.com/19_08_23_16_14_36_kid-in-strawberry-farm-GUCSQBP.jpg)'}} >
							<Paragraph text={"ABOUT US"} style={{'color': '#fff', 'font-size': '50px', 'font-weight': '800', 'padding-top': '15%'}}  />
						</Div>
					</DisplaySize>
					<Div style={{'margin': '20px auto'}} className={"container"} >
						<Header text={"About Us"}  />
						<Div className={"row"} >
							<Div className={"col-md-6 col-sm-12"} >
								<Paragraph text={"Organic Ag Trader is a place for buyers and producers of organic grains, produce, livestock, seed, inputs, and equipment, to connect. The demand for organic products continues to grow, but the ability to find an outlet for organic agricultural outputs, and to source those outputs, can be time consuming. Our goal is to provide a platform that allows buyers and sellers to connect quickly and easily."}  />
							</Div>
							<Div className={"col-md-6 col-sm-12"} >
								<Paragraph text={"As we grow and learn the best ways to help your operations succeed, there will be changes and improvements made to our platform. Please feel free to contact us if you have questions or suggestions."}  />
							</Div>
						</Div>
					</Div>
				</Div>
        </div>);
    }
}

export default AboutUs;
