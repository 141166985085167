import React, { Component } from 'react';
import {} from 'library';


export default class CustomRoll extends React.Component {
    render() {
        var hit = this.props.custom_roll;
        var parsed_hits = [];
        var resolved_roll_strings = [];

        if (hit && hit.rolls) {
            for (var dice of hit.rolls) {
                for (var die of dice) {
                    resolved_roll_strings.push(die.roll);
                }
            }
            resolved_roll_strings.push(...hit.modifiers);

            parsed_hits.push(<div className={'col-12 action-hit'}>
                <div className='action-hit-total' >{hit.total}</div>
                <div className='action-hit-breakdown' >
                    <div>{this.props.text}</div>
                    <div>{resolved_roll_strings.join(' + ')}</div>
                </div>
            </div>);
        }


        var name = "Custom Roll";
        if (this.props.action && this.props.action.name) {
            name = this.props.action.name;
        }

        var monster_name = '';
        if (this.props.instance) {
            var instance = this.props.instance;
            var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
            monster_name = monster.name
        }

        if (this.props.full_display) {
            return(
                <div className='chat-entry' >
                    <div className='chat-header' >
                        <span>{monster_name}</span>
                        <span>{' : '}</span>
                        {name}
                     </div>
                     <div className='hit-section row'>
                         {parsed_hits}
                     </div>
                </div>
            )
        }
        else {
            return (null);
        }

    }
}
