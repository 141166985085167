import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup,
    ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab, Container } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import { CRS } from 'leaflet';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';
import stat_modifier from 'projectLibrary/stat_modifier.js';
import StatBlock from 'projectLibrary/monsters/stat_block/stat_block.js';
import Spells from 'projectLibrary/monsters/stat_block/spells.js';

import update_monster_instance from 'projectLibrary/monsters/update_monster_instance.js';


export default class MacroBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_tab:'Actions',
            add: null
        }

        this.setGlobalState = this.setGlobalState.bind(this);
        this.toggle_full_block = this.toggle_full_block.bind(this);
        this.toggle_macro = this.toggle_macro.bind(this);
    }

    toggle_full_block() {
        this.props.push_modal_content(<StatBlock {...this.props} toggle_macro={this.toggle_macro} push_modal_content={this.props.push_modal_content} />);
    }

    setGlobalState(name, state) {
        console.log("Set Global State", name, state);
        update_monster_instance(this, state);
    }

    toggle_macro(type, item) {
        var monster = this.props.monster.monster;

        var macro_spells = [];
        for (var item of monster.macro_spells) {
            macro_spells.push(item.spell.id)
        }
        if (type == 'Spell' && macro_spells.indexOf(item.id) > -1) {
            macro_spells.splice(macro_spells.indexOf(item.id), 1);
        }
        else if (type == 'Spell') {
            macro_spells.push(item.id);
        }

        var macro_actions = [];
        for (var item of monster.macro_actions) {
            macro_actions.push(item.action.id);
        }
        if (type == 'Action' && macro_actions.indexOf(item.id) > -1) {
            macro_actions.splice(macro_actions.indexOf(item.id), 1);
        }
        else if (type == 'Action') {
            macro_actions.push(item.id);
        }

        ajaxWrapper('POST','/api/compendium/monster/' + monster.id + '/', {'macro_spells[]':macro_spells, 'macro_actions[]':macro_actions}, this.props.refresh_monsters);
    }

    render() {
        //cr to exp table https://www.dndbeyond.com/sources/basic-rules/monsters#Challenge

        var instance = this.props.monster;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var special_abilities = [];
        var actions = [];
        var legendary_actions = [];

        var macro_spells = [];
        for (var item of monster.macro_spells) {
            macro_spells.push(item.spell.id);
        }

        var macro_actions = [];
        for (var item of monster.macro_actions) {
            var action = item.action;
            var macro = macro_actions.indexOf(action.id) > -1;

            if (action.ability) {
                special_abilities.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.toggle_macro} macro={macro} />)
            }
            else if (action.legendary) {
                legendary_actions.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.toggle_macro} macro={macro} />)
            }
            else {
                if (action.name == "Multiattack") {
                    actions.unshift(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.toggle_macro} macro={macro} />)
                }
                else {
                    actions.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.toggle_macro} macro={macro} />)
                }
            }
        }

        var spells = <Spells add_action_token={this.props.add_action_token} spells={monster.macro_spells} instance={this.props.monster} push_modal_content={this.props.push_modal_content}
            use_action={this.props.use_action} toggle_macro={this.toggle_macro} macro_spells={macro_spells} />;

        var count = 0;
        if (special_abilities.length > 0) {
            count += 1
        }
        if (actions.length > 0) {
            count += 1
        }
        if (legendary_actions.length > 0) {
            count += 1
        }
        if (monster.macro_spells.length > 0) {
            count += 1
        }

        var columns = 12;
        if (count > 0) {
            var columns = 12 / count;
        }

        var special_abilities_display = null;
        var actions_display = null;
        var legendary_actions_display = null;
        var spells_display = null;

        if (special_abilities.length > 0) {
            special_abilities_display = <div className={"col-" + columns}>
                <h2>Abilities</h2>
                {special_abilities}
            </div>
        }
        if (actions.length > 0) {
            actions_display = <div className={"col-" + columns}>
                <h2>Actions</h2>
                {actions}
            </div>
        }
        if (legendary_actions.length > 0) {
            legendary_actions_display = <div className={"col-" + columns}>
                <h2>Legendary Actions</h2>
                {legendary_actions}
            </div>
        }
        if (monster.macro_spells.length > 0) {
            spells_display = <div className={"col-" + columns}>
                <h2>Spells</h2>
                {spells}
            </div>
        }

        var content = <div style={{position:'absolute', bottom:'0px', left:'0px', backgroundColor:'white', height:'25%', overflowY:'scroll'}}>
            <h2>{monster.name}</h2>
            <Button text='See Full Block' onClick={this.toggle_full_block} type='primary' />
            <div className="row">
                {special_abilities_display}
                {actions_display}
                {legendary_actions_display}
                {spells_display}
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }


}
