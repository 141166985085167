import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select,
    CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak } from 'library';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import get_spells_by_level from 'projectLibrary/monsters/get_spells_by_level.js';
import SpellUse from 'projectLibrary/monsters/spell_use.js';
import SpellCard from 'projectLibrary/spell_card.js';
import Action from 'projectLibrary/monsters/action.js';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';


export default class Spells extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chat_socket: null,
            upcast: false,
        }
        this.set_global_state = this.set_global_state.bind(this);
        this.toggle_upcast = this.toggle_upcast.bind(this);
    }

    componentDidMount() {

        window.cmState.subscribe_by_name(this, 'resources')

        if (window.location.host.indexOf('localhost') == -1 && this.props.mini) {
            document.addEventListener("contextmenu", (event) => {
              event.preventDefault();
            });
        }

        var socket_mapping = {
        };

        if (this.props.group) {
            var chat_socket = new SocketRouter(this, socket_mapping, this.props.group.id);
            this.setState({chat_socket: chat_socket});
        }

    }

    set_global_state(name, state) {
        ajaxWrapper('POST','/api/home/monsterinstance/' + this.props.instance.id + '/', state, console.log);

        var instance = this.props.instance;
        for (var key in state){
            instance[key] = state[key];
        }

        this.state.chat_socket.send_message({'message': {icon: instance}});
    }

    toggle_upcast(){
        this.setState({
            upcast: !(this.state.upcast)
        });
    }

    render() {
        var spells = this.props.spells;
        if (spells && spells.length == 0) {
            return (<p>
                Puny mortal. You have no spells!
            </p>);
        }
        else if (spells) {
            var monster = window.cmState.global_state_lookup(this, 'monsters', this.props.instance.monster_id);
            var spell_slots = get_spells_by_level(this, spells, monster, this.props.instance, !(this.state.upcast));

            var spell_entries = [];
            for (var i = 0; i < 10; i++) {
                var num_mapping = {1:'one', 2:'two', 3:'three',4:'four',5:'five',6:'six',7:'seven',8:'eight',9:'nine'};
                var spell_slot_name = 'spell_slots_level_' + num_mapping[i];
                if (spell_slots[i]['spells'].length > 0 && i == 0) {
                    if (this.props.mini) {
                        spell_entries.push(<h6><b>Cantrips (use at will)</b></h6>);
                    }
                    else {
                        spell_entries.push(<h2>Cantrips</h2>);
                    }
                }
                else if (spell_slots[i]['spells'].length > 0 && spell_slots[i] && spell_slots[i]['max'] > 0){
                    if (this.props.mini) {
                        spell_entries.push(<h6 style={{marginTop:'5px', marginBottom:'0px'}}><b>Level {i} Spells: ({spell_slots[i]['current']}/{spell_slots[i]['max']} slots.)</b></h6>);
                    }
                    else {
                        spell_entries.push(<h2>Level {i} Spells</h2>)
                        spell_entries.push(<FormWithChildren setGlobalState={this.set_global_state} autoSetGlobalState={true} globalStateName={spell_slot_name}>
                                <NumberInput name={spell_slot_name} label={"Level " + i + " Spell Slots"} default={this.props.instance[spell_slot_name]}/>
                        </FormWithChildren>);
                    }
                }

                if (i > 0 && spell_slots[i]['max'] == 0) {
                    //Possible escape for spells that cant be cast
                }

                var spell_list = [];
                for (var spell of spell_slots[i]['spells']) {
                    if (this.props.mini) {
                        spell_list.push(<div>
                            <Action add_action_token={this.props.add_action_token} action={spell} instance={this.props.instance} mini={true} use_action={this.props.use_action} cast_level={i} push_modal_content={this.props.push_modal_content} />
                        </div>);
                    }
                    else {
                        spell_list.push(<div className="col-6">
                            <Action add_action_token={this.props.add_action_token} action={spell} instance={this.props.instance} use_action={this.props.use_action} cast_level={i} push_modal_content={this.props.push_modal_content} />
                        </div>);
                    }
                }

                spell_entries.push(<div className="row">{spell_list}</div>);
            }

            var toggle = <Button type='danger' text='No Upcast' onClick={this.toggle_upcast} />;
            if (!(this.state.upcast)){
                toggle = <Button type='success' text='Upcast' onClick={this.toggle_upcast} />;
            }

            return (<div style={{padding:'10px'}}>
                {spell_entries}
                <br/>
                {toggle}
            </div>)
        }
        else {
            return (<div></div>)
        }
    }
}
