import React, { Component } from 'react';


class PageNotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            has_error: false
        };
    }

    render() {
        return <div>
            <br/><br/><br/><br/>

            <div style={{textAlign:'center'}} >
                <h2>Something isn't adding up!  The page you're looking for is nowhere to be found.</h2>
            </div>
        </div>;
    }
}

export default PageNotFound;
