import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren,
    PageBreak, Tab, EmptyModal, Link } from 'library';

import ActionsForm from 'projectLibrary/forms/actions.js';
import SpellsForm from 'projectLibrary/forms/spells.js';
import MagicalAttributesForm from 'projectLibrary/forms/magical_attributes.js';

import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';


class MonsterResource extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
        };

        this.refresh = this.refresh.bind(this);
    }

    refresh(){
        this.setState({edit: false});
        this.props.refresh();
    }

    render() {
        var content = null;
        var edit_form = null;

        if (!(this.props.id) || this.state.edit){
            var edit_header = 'Add New';
            var submit_url = '/api/compendium/MonsterResource/';
            if (this.props.id) {
                edit_header = 'Edit Resource';
                submit_url = '/api/compendium/MonsterResource/'+ this.props.id +'/';
            }

            var defaults = {
                monster: this.props.monster_id,
                resource: this.props.resource_id,
                quantity: this.props.quantity,
            };

            edit_form = <FormWithChildren submitUrl={submit_url} defaults={defaults} redirect={this.refresh} >
                <h5>{edit_header}</h5>
                <Select name='resource' label='Resource' options={this.props.resource_options} />
                <NumberInput name='quantity' label='Quantity' />
                <TextInput style={{display: 'none'}} name='monster' />
            </FormWithChildren>
        }
        else {
            content = <div style={{position: 'relative'}} >
                <div><h5 style={{display:'inline-block', margin:'0px'}} >{this.props.quantity + ' ' + this.props.resource_lookup[this.props.resource_id]}</h5></div>
                <Button text={<i class="fas fa-edit"></i>} type='info' style={{position:'absolute', right:'0px', top:'-5px'}} onClick={() => this.setState({edit: true})} />
            </div>;
        }

        return (<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} >
            {content}
            {edit_form}
        </div>);
    }
}


export default class EditMonster extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: 'form',
            name:'',
            description:'',

            actions:[],
            action_lookup: [],
            action_options: [],

            resources:[],
            resource_lookup:[],
            resource_options:[],

            magical_attributes:[],

            saving: false,
            saved: false,
            loaded:false,
        };

        this.refresh_monster = this.refresh_monster.bind(this)
        this.monster_callback = this.monster_callback.bind(this);

        this.resource_callback = this.resource_callback.bind(this);
        this.action_callback = this.action_callback.bind(this);

        this.submit = this.submit.bind(this);
        this.submit_callback = this.submit_callback.bind(this);

        this.add_action = this.add_action.bind(this);
        this.remove_action = this.remove_action.bind(this);

        this.add_magical_attribute = this.add_magical_attribute.bind(this);
        this.remove_magical_attribute = this.remove_magical_attribute.bind(this);

        this.setGlobalState = this.setGlobalState.bind(this);
        this.redirect_to_monster = this.redirect_to_monster.bind(this);
    }

    componentDidMount() {
        if (this.props.monster_id) {
            this.refresh_monster();
        }
        else {
            this.setState({loaded:true});
        }

        ajaxWrapper('GET','/api/compendium/resource/?only=name', {}, this.resource_callback);
        ajaxWrapper('GET','/api/compendium/actionspell/?only=name', {}, this.action_callback);
    }

    refresh_monster(){
        ajaxWrapper('GET','/api/compendium/monster/' + this.props.monster_id + '/?related=action_spells,monster_resources,magical_attributes', {}, this.monster_callback);
    }

    monster_callback(result) {
        var monster = result[0].monster;
        monster.loaded = true;
        console.log("Monster", monster);
        this.setState(monster);
    }

    resource_callback(value){
        var resource_options = [];
        var resource_lookup = {};

        for (var item of value){
            var resource = item['resource'];
            resource_lookup[resource['id']] = resource['name'];
            resource_options.push({'text': resource['name'], 'value': resource['id']});
        }

        resource_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        this.setState({
            resource_lookup: resource_lookup,
            resource_options: resource_options,
        });
    }

    action_callback(value){
        var action_options = [];
        var action_lookup = {};

        for (var item of value){
            var action = item['actionspell'];
            action_lookup[action['id']] = action['name'];
            action_options.push({'text': action['name'], 'value': action['id']});
        }

        action_options.sort((a, b) => (a.text > b.text) ? 1 : -1);

        this.setState({
            action_lookup: action_lookup,
            action_options: action_options,
        });
    }

    add_action(action) {
        console.log("Add action", action);
        var actions = this.state.actions;
        actions.push({'action':action});
        this.setState({actions:actions});
    }

    remove_action(action) {
        console.log("Remove action", action);
        var actions = this.state.actions;
        var index = actions.indexOf({'action':action});
        actions.splice(index, 1);
        this.setState({actions:actions});
    }

    add_magical_attribute(attribute) {
        console.log("Add attribute", attribute);
        var magical_attributes = this.state.magical_attributes;
        magical_attributes.push({'magicalattribute':attribute});
        this.setState({magical_attributes:magical_attributes});
    }

    remove_magical_attribute(attribute) {
        console.log("Remove attribute", attribute);
        var magical_attributes = this.state.magical_attributes;
        var index = magical_attributes.indexOf({'magicalattribute':attribute});
        magical_attributes.splice(index, 1);
        this.setState({magical_attributes:magical_attributes});
    }


    setGlobalState(name, state) {
        this.setState(state);
    }

    submit() {
        var data = {
            name: this.state.name,
            description:this.state.description,
        }

        if (this.props.monster_id) {
            var id = this.props.monster_id;

            var magical_attributes = [];
            for (var item of this.state.magical_attributes) {
                var action_id = item.magicalattribute.id;
                magical_attributes.push(action_id);
            }
            data['magical_attributes[]'] = magical_attributes;

            ajaxWrapper('POST','/api/compendium/monster/' + id + '/', data, this.submit_callback);
        }
        else {
            ajaxWrapper('POST','/api/compendium/monster/', data, this.redirect_to_monster);
        }

        this.setState({saving:true, saved:false});
    }

    submit_callback() {
        this.setState({
            saving: false,
            saved: true,
        });
    }

    redirect_to_monster(result) {
        var id = result[0].monster.id;
        window.location = '/edit_monster/'+ id +'/';
    }

    render() {
        var submitUrl = '/api/compendium/monster/';
        var defaults = this.state;
        var delete_button = null;

        if (this.props.monster_id) {
            submitUrl = '/api/compendium/monster/' + this.props.monster_id + '/';
            delete_button = <Button type='danger' text='Delete Monster' dangerType={true} href={'/api/compendium/monster/' + this.props.monster_id + '/delete/'} />;
        }

        var saving = null;
        var saved = null;
        if (this.state.saving) {
            saving = <Alert type='warning' text='Saving...' />
        }
        if (this.state.saved) {
            saved = <Alert type='success' text='Saved' />
        }

        var content = null;
        if (this.state.loaded) {
            var actions = [];

            for (var item of this.state.actions) {
                var action = item.actionspell;
                actions.push(<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} ><h5 style={{margin:'0px'}}>{action.name}</h5></div>);
            }

            var resources = [];
            for (var resource of this.state.resources) {
                var resource_index = this.state.resources.indexOf(resource);
                resources.push(<MonsterResource index={resource_index} {...resource.monsterresource} refresh={this.refresh_monster}
                    resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup} />);
            }

            var attributes = [];
            for (var item of this.state.magical_attributes) {
                var attribute = item.magicalattribute;
                attributes.push(<div style={{padding: '10px', borderBottom: 'thin solid #ccc'}} ><h5 style={{margin:'0px'}}>{attribute.name}</h5></div>);
            }

            var actions_card = null;
            var resources_card = null;
            var magical_attributes = null;
            if (this.props.monster_id){
                actions_card = <div className="col-12 card">
                    <h3>Action/Spells</h3>
                    {actions}
                    <Button type='primary' text='Add Action' onClick={() => this.setState({show: 'actions'})} />
                </div>;

                resources_card = <div className="col-12 card">
                    <h3>Resources</h3>
                    {resources}
                    <MonsterResource index={-1} refresh={this.refresh_equipment} equipment_id={this.props.equipment_id}
                        resource_options={this.state.resource_options} resource_lookup={this.state.resource_lookup}
                        reset_state_on_submit={true} />
                </div>;

                magical_attributes = <div className="col-12 card">
                    <h3>Magical Attributes</h3>
                    {attributes}
                    <Button type='primary' text='Add Magical Attribute' onClick={() => this.setState({show: 'magical_attributes'})} />
                </div>;
            }

            content = <div className="container">
                <div className='card-container row'>
                    <div className='card col-12' >
                        <Link href='/dm_tools/monster_list/' text='<- Monster List' />
                    </div>

                    <div className="col-12 card">
                        <h1>{'Edit Monster: ' + defaults['name']}</h1>

                        <FormWithChildren setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} defaults={defaults}>
                            <TextInput name='name' label='Name' />
                            <TextArea name='description' label='Description' />
                            <ImageInput name='image' label='Image' multiple={false} preview={true} preview_height={'150px'}
                                bucket_name="mapquest-public" is_public={true} />
                        </FormWithChildren>
                    </div>

                    {actions_card}
                    {resources_card}
                    {magical_attributes}

                    <div className='col-12'><br/></div>
                    <div className='card col-12'>
                        {saving}
                        {saved}
                        <div><Button style={{padding: '15px 30px', fontSize:'1.2em'}} type='success' text='Save Equipment' onClick={this.submit} /></div>
                        <br/>
                        <div>{delete_button}</div>
                    </div>
                </div>
            </div>;
        }

        var form_modal = null;
        if (this.state.show == 'magical_attributes') {
            form_modal = <EmptyModal show={true} onHide={() => this.setState({show: 'form'})} >
                <Button type='danger' text='Back To Item' onClick={() => this.setState({show: 'form'})} />
                <MagicalAttributesForm magical_attributes={this.state.magical_attributes}
                    add_magical_attribute={this.add_magical_attribute} remove_magical_attribute={this.remove_magical_attribute} />
            </EmptyModal>;
        }
        else if (this.state.show == 'actions') {
            form_modal = <EmptyModal show={true} onHide={() => this.setState({show: 'form'})} >
                <Button type='danger' text='Back To Item' onClick={() => this.setState({show: 'form'})} />
                <ActionsForm actions={this.state.actions} add_action={this.add_action} remove_action={this.remove_action} />
            </EmptyModal>;
        }


        return (
            <div>
                {content}
                {form_modal}
                <br/><br/>
            </div>
        )
    }
}
