
import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Container, Checkbox, FormWithChildren, NumberInput, EmptyModal } from 'library';

import FancySpellSheet from 'projectLibrary/monsters/fancy_char_sheet/spell_main.js';
import FancyBioSheet from 'projectLibrary/monsters/fancy_char_sheet/bio_main.js';
import FancyCharSheet from 'projectLibrary/monsters/fancy_char_sheet/main.js';


export default class FullPageFancyCharSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            instance: null,
            monster: null,
            width: 0
        };

        this.get_monster_url = this.get_monster_url.bind(this);
        this.get_instance_url = this.get_instance_url.bind(this);

        this.load_monster = this.load_monster.bind(this);
        this.load_monster_instance = this.load_monster_instance.bind(this);

        this.update_monster = this.update_monster.bind(this);
        this.update_instance = this.update_instance.bind(this);

        this.load_resources = this.load_resources.bind(this);
        this.load_equipment = this.load_equipment.bind(this);

        this.push_modal_content = this.push_modal_content.bind(this);
        this.hide_modal = this.hide_modal.bind(this);
    }

    get_monster_url(id){
        var actions = 'action_spells,action_spells__resource_costs';
        var resources_and_items = 'monster_resources,equipped_items';
        var resistance_and_immunities = 'damage_immunities,damage_vulnerabilities,damage_resistances,condition_immunities';
        var languages_and_proficiencies = 'languages'
        var url = '/api/compendium/monster/';
        if (this.state.monster){
            url += this.state.monster.id +'/';
        }
        url += '?monster_instances__id='+ this.props.id +'&related='+ actions +','+ resources_and_items +','+ languages_and_proficiencies +','+ resistance_and_immunities;

        return url;
    }

    get_instance_url(id){
        var resources_and_items = 'monster_instance_resources,equipped_items';
        var url = '/api/home/monsterinstance/'+ this.props.id +'/?related='+ resources_and_items +',conditions';
        return url;
    }

    componentDidMount(){
        var url = this.get_monster_url();
        ajaxWrapper('GET', url, {}, this.load_monster);

        var url = this.get_instance_url();
        ajaxWrapper('GET', url, {}, this.load_monster_instance);

        ajaxWrapper('GET', '/api/compendium/resource/', {}, this.load_resources);
        ajaxWrapper('GET', '/api/compendium/equipment/?related=actions', {}, this.load_equipment);
    }

    load_monster(value){
        var monster = value[0]['monster'];
        monster.dnd_class = monster.dnd_class_id;

        this.setState({monster: monster});
    }

    load_monster_instance(value){
        var instance = value[0]['monsterinstance'];
        this.setState({instance: instance});
    }

    load_resources(result) {
        var resources = {};
        for (var item of result) {
            var resource = item.resource;
            resources[resource.id] = resource;
        }
        this.setState({resources: resources});

        //TODO: Add this to global state data
    }

    load_equipment(result) {
        var equipment = {};
        for (var item of result) {
            var item = item.equipment;
            equipment[item.id] = item;
        }

        this.setState({equipment: equipment});

        //TODO: Add this to global state data
    }

    roll(name){
        console.log('Roll ', name);
    }

    update_monster(name, data, only_state){
        //only_state is used for related values that will have their
        //API updates sent through another component

        for (var key in data){
            if (typeof(data[key]) == 'undefined'){
                data[key] = 0;
            }

            if (key == 'hit_points'){
                var instance_data = {'max_hp': data[key]};
                this.update_instance('health', instance_data);
            }
        }

        var monster = this.state.monster;
        monster = Object.assign(monster, data);
        this.setState({monster: monster});

        if (!(only_state)){
            var url = this.get_monster_url();
            ajaxWrapper('POST', url, data, console.log);
        }
    }

    update_instance(name, data, only_state){
        for (var key in data){
            if (typeof(data[key]) == 'undefined'){
                data[key] = 0;
            }
        }

        var instance = this.state.instance;
        instance = Object.assign(instance, data);
        this.setState({instance: instance});

        if (!(only_state)){
            var url = this.get_instance_url();
            ajaxWrapper('POST', url, data, console.log);
        }
    }

    push_modal_content(content){
        var state = {'modal_content': content};
        if (this.state.modal_content){
            state = {'second_modal_content': content};
        }

        this.setState(state);
    }

    hide_modal(){
        var state = {modal_content: null};
        if (this.state.second_modal_content){
            state = {'second_modal_content': null};
        }

        this.setState(state);
    }

    render() {
        var instance = this.state.instance;
        var monster = this.state.monster;


        var modal_content = null;
        if (this.state.modal_content){
            var modal_content_style = {};
            if (this.state.second_modal_content){
                modal_content_style = {display: 'none'};
            }

            modal_content = <EmptyModal show={true} onHide={this.hide_modal}>
                <Container>
                    <div style={modal_content_style} >{this.state.modal_content}</div>
                    <div>{this.state.second_modal_content}</div>
                </Container>
            </EmptyModal>;
        }

        var data = {
            instance: instance,
            monster: monster,
            update_monster: this.update_monster,
            update_instance: this.update_instance,
            push_modal_content: this.push_modal_content,
        };

        var content = null;
        if (monster && instance && this.state.resources && this.state.equipment){

            content = <Container>
                <FancyCharSheet {...data} />

                <div ><br/><br/><br/></div>
                <div style={{borderTop: '2px solid black'}} ><br/><br/><br/></div>

                <FancySpellSheet {...data} />

                <div ><br/><br/><br/></div>
                <div style={{borderTop: '2px solid black'}} ><br/><br/><br/></div>

                <FancyBioSheet {...data} />

                {modal_content}
            </Container>;
        }

        return (
            content
        )
    }
}
