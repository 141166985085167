import React from 'react';
import {get_children} from 'functions';

function get_all_children(component, parent_functions, form_state) {
    var context = component.props;
    var top_children = get_children(context);
    var components = [];

    for (var index in top_children) {
        var child_component = top_children[index];
        var children = child_component.props.children;

        var data_mapping = parent_functions;
        if (form_state){
            data_mapping = get_form_props(form_state, child_component, data_mapping, index, component.props.autoFocus);
        }
        data_mapping['children'] = get_all_children(child_component, form_state);

        var componentInstance = React.cloneElement(child_component, data_mapping);
        components.push(componentInstance);
    }

    return components;
}


function get_form_props(state, component, data, index, autoFocus) {
    if (component.props) {
        var value = state[component.props.name];

        data['value'] = value;
        if (index == 0 && autoFocus) {
          data['autoFocus'] = true;
        }
    }

    return data
}

export default get_all_children;
