import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Container,
    Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, EmptyModal, Tab, PageBreak } from 'library';

import SessionMap from 'projectLibrary/session_tools/session_map.js';
import StatBlockMini from 'projectLibrary/monsters/stat_block/stat_block_mini.js';
import MacroBar from 'projectLibrary/monsters/stat_block/macro_bar.js';
import ChatRoom from 'projectLibrary/chat/chat_room.js';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';
import EditMonster from 'projectLibrary/monsters/edit_monster.js';
import InitiativeOrder from 'projectLibrary/chat/initiative_order.js';
import MapLocations from 'projectLibrary/nav/map_locations.js';
import SkillChecks from 'projectLibrary/chat/skill_checks.js';
import SelectMode from 'projectLibrary/select_mode.js';
import ChatContainer from 'projectLibrary/session_tools/chat_container.js';
import PCList from 'projectLibrary/session_tools/pc_list.js';
import MonsterSearch from 'projectLibrary/monster_search.js';
import AOECard from 'projectLibrary/aoe_card.js';

import LocationDetailsSidebar from 'projectLibrary/display_components/location_details.js';

import stat_modifier from 'projectLibrary/stat_modifier.js';
import parse_roll from 'projectLibrary/monsters/roll.js';

import save_monster_instance from 'projectLibrary/session_tools/global_data/save_monster_instance.js';
import remove_monster_instance from 'projectLibrary/session_tools/global_data/remove_monster_instance.js';
import update_monster_instance from 'projectLibrary/session_tools/global_data/update_monster_instance.js';
import save_location from 'projectLibrary/session_tools/global_data/save_location.js';

import load_adventure_data from 'projectLibrary/session_tools/global_data/load_adventure_data.js';
import load_dnd_constants from 'projectLibrary/session_tools/global_data/load_dnd_constants.js';

import get_scaled_position from 'projectLibrary/canvas/get_scaled_position.js';


export default class SessionDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adventure_loaded: false,

            object_loc: [[0,0],[10,10]],
            selected_monster: null,
            selected_monster_instance: null,
            edit_monster:null,
            map_mode: 'pan',
            start_reveal_position: null,
            grid_size:50000,
            ft_in_width: 5000000,
            sidebar_tab: 'Selection',
            dm:false,
            fog_of_war: true,
            group: {},
            location: {},
            actions: [],
            skill_checks: false,
            ask_for_roll:'',
            group_location:{},
            player_character: null,
            line_color: '#ff0000',
            line_thickness: 6,
            selected_pc: null,
            modal_content: null,
            second_modal_content: null,
            details_sidebar_mode: '',
            modal_timestamp: new Date().valueOf(),

            center_map_position: {x:0, y:0},
            center_map_timestamp: null,
            center_map_ping: false,

            zoom_control: 0,
        }

        this.setGlobalState = this.setGlobalState.bind(this);
        this.push_modal_content = this.push_modal_content.bind(this);
        this.hide_modal = this.hide_modal.bind(this);

        this.track_mouse_position = this.track_mouse_position.bind(this);
        this.handle_key_press = this.handle_key_press.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.move_icon = this.move_icon.bind(this);
        this.rotate_icon = this.rotate_icon.bind(this);
        this.select_monster = this.select_monster.bind(this);
        this.unselect_monster = this.unselect_monster.bind(this);

        this.add_icon = this.add_icon.bind(this);
        this.select_monster_instance = this.select_monster_instance.bind(this);

        this.use_action = this.use_action.bind(this);
        this.remove_monster_instance = this.remove_monster_instance.bind(this);

        this.roll_initiative = this.roll_initiative.bind(this);

        this.click_map_handler = this.click_map_handler.bind(this);

        this.select_tab = this.select_tab.bind(this);
        this.get_adventure = this.get_adventure.bind(this);
        this.get_adventure_callback = this.get_adventure_callback.bind(this);

        this.change_location = this.change_location.bind(this);
        this.change_group_location = this.change_group_location.bind(this);
        this.update_pc_locations = this.update_pc_locations.bind(this);
        this.change_group_location_callback = this.change_group_location_callback.bind(this);

        this.get_group_location = this.get_group_location.bind(this);
        this.select_pc = this.select_pc.bind(this);

        this.add_action_token = this.add_action_token.bind(this);
        this.added_action_token = this.added_action_token.bind(this);

        this.toggle_fog = this.toggle_fog.bind(this);

        this.ask_for_roll = this.ask_for_roll.bind(this);
        this.clear_roll = this.clear_roll.bind(this);
        this.roll_skill = this.roll_skill.bind(this);
        this.toggle_skill_checks = this.toggle_skill_checks.bind(this);

        this.set_main_instance = this.set_main_instance.bind(this);
        this.get_player_character = this.get_player_character.bind(this);

        this.added_icon = this.added_icon.bind(this);

        this.take_damage = this.take_damage.bind(this);
        this.roll_damage = this.roll_damage.bind(this);

        this.load_action_tokens = this.load_action_tokens.bind(this);

        this.take_rest = this.take_rest.bind(this);

        this.clear_fog_of_war = this.clear_fog_of_war.bind(this);
        this.center_map = this.center_map.bind(this);
        this.change_zoom = this.change_zoom.bind(this);
    }

    setGlobalState(name, state) {
        if (name == 'map_details') {
            state['id'] = this.state.current_location;
            save_location(state)
        }
        else {
            this.setState(state);
        }
    }

    componentDidMount() {
        var socket_mapping = {
            icon: 'icon',
            object_loc: 'object_loc',
            toggle_fog: 'fog_of_war',
            ask_for_roll: 'ask_for_roll',
            remove_icon:'remove_icon',
            icons:'icons',
            instance: 'instance',
            action: 'action',
            current_location: 'current_location',
            add_icon: 'add_icon',
            update_location: 'update_location',
            update_permission: 'update_permission',
            update_note: 'update_note',
            remove_location: 'remove_location',
        };

        var chat_socket = new SocketRouter(this, socket_mapping, this.props.group_id);
        this.setState({chat_socket: chat_socket});
        window.cmState.setGlobalState('chat_socket', chat_socket);

        load_dnd_constants(this.get_adventure);

        window.cmState.subscribe_by_name(this, 'monsters');
        window.cmState.subscribe_by_name(this, 'icon_list');
        window.cmState.subscribe_by_name(this, 'selected_monster_instance');
    }

    track_mouse_position(event) {
        var position = [
            event.pageX,
            event.pageY,
        ];

        window.cmState.setGlobalState('mouse_position', position);
    }

    handle_key_press(event){
        console.log(event.key);

        if (event.key == 'Delete' && window.cmState.selected_monster_instance){
            this.remove_monster_instance(window.cmState.selected_monster_instance.id);
        }
        else if (event.key == 'p'){
            var position = get_scaled_position(
                window.cmState.mouse_position,
                window.cmState.map_position,
                window.cmState.zoom,
            );

            this.click_map_handler(position, true, event);
        }
        else if (event.key == 'c'){
            this.center_map(event);
        }
    }

    get_adventure(location){
        var url = '/api/get_adventure/'+ this.props.group_id +'/';
        if (typeof(location) != 'undefined' && location){
            url += '?location=' + location.id;
        }

        ajaxWrapper('GET', url, {}, this.get_adventure_callback);
    }

    clear_fog_of_war() {
        ajaxWrapper('POST','/api/home/grouplocation/' + this.state.group_location.id + '/', {fog_of_war_rectangles: []}, console.log);
        this.state.chat_socket.send_message({'message': {'clear_fog':true}});
    }

    center_map(event, position){
        if (typeof(position) != 'undefined'){
            this.setState({
                center_map_position: position,
                center_map_timestamp: new Date().valueOf(),
                center_map_ping: true,
            });
        }
        else if (window.cmState.selected_monster_instance){
            var map_position = window.cmState.selected_monster_instance.coords;
            this.setState({
                center_map_position: map_position,
                center_map_timestamp: new Date().valueOf(),
                center_map_ping: false,
            });
        }
    }

    take_rest(rest_type) {
        var instances = window.cmState.instances;
        for (var key in instances) {
            var instance = instances[key];
            if (rest_type == 'long rest') {
                instance.current_hp = instance.max_hp;
            }

            var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
            for (var item of monster.monster_resources) {
                var monster_resource = item.monsterresource;
                var resource = window.cmState.global_state_lookup(this, 'resources', monster_resource.resource_id);
                if (resource && (resource.recovery_type == rest_type || (resource.recovery_type == 'short rest' && rest_type == 'long rest'))) {
                    //recover the resource!
                    for (var instance_item of instance.monster_instance_resources) {
                        var instance_resource = instance_item.monsterinstanceresource;
                        if (instance_resource.resource_id == monster_resource.resource_id && instance_resource.quantity != monster_resource.quantity) {
                            instance_resource.quantity = monster_resource.quantity;
                            ajaxWrapper('POST','/api/home/monsterinstanceresource/' + instance_resource.id + '/', {quantity: monster_resource.quantity}, console.log);
                        }
                    }
                }
            }

            for (var item of instance.equipped_items) {
                var equipment_instance = item.instanceequippeditem;
                for (var resource_item of equipment_instance.instance_equipment_resources) {
                    var equipment_resource = resource_item.instanceequippeditemresource;
                    var resource = window.cmState.global_state_lookup(this, 'resources', equipment_resource.resource_id);
                    if (resource && (resource.recovery_type == rest_type || (resource.recovery_type == 'short rest' && rest_type == 'long rest'))) {
                        equipment_resource.quantity = equipment_resource.max_quantity;
                        ajaxWrapper('POST','/api/home/instanceequippeditemresource/' + equipment_resource.id + '/', {quantity: equipment_resource.max_quantity}, console.log);
                    }
                }
            }

            save_monster_instance(instance);
            //var data = {'instance':instance};
            //this.state.chat_socket.send_message({'message':data});
        }
    }

    load_action_tokens(result) {
        var actions = [];
        for (var item of result) {
            var action = item['actioninstance'];
            actions.push(action)
        }

        this.setState({actions: actions});
    }

    get_adventure_callback(result){
        var group = result['group'][0].adventuringgroup;
        var new_state = {
            group: group,
            current_location: group.current_location.id,
            adventure_loaded: true,
        };
        if (group.dm_id == window.cmState.user.id) {
            new_state['dm'] = true;
        }

        var player_characters = {}
        for (var item of group.player_characters) {
            var pc = item.playercharacter;
            var main_instance = pc.main_instance;
            player_characters[main_instance.id] = main_instance;
            if (pc.user_id == window.cmState.user.id) {
                new_state['player_character'] = pc;
            }
        }
        window.cmState.setGlobalState('player_characters', player_characters);

        this.setState(new_state);

        window.cmState.group = group;

        load_adventure_data(result);

        ajaxWrapper('GET','/api/home/grouplocation/?group=' + group.id + '&location=' + group.current_location.id, {}, this.get_group_location);
    }

    get_group_location(result) {
        if (result.length > 0) {
            this.setState({
                group_location: result[0].grouplocation,
                fog_of_war: result[0].grouplocation.fog_of_war
            });
        }
        else {
            ajaxWrapper('POST','/api/home/grouplocation/', {group: this.state.group.id, location: this.state.current_location}, this.get_group_location);
        }
    }

    push_modal_content(content){
        var state = {'modal_content': content};
        if (this.state.modal_content){
            state = {'second_modal_content': content};
        }

        this.setState(state);
    }

    select_tab(name) {
        this.setState({sidebar_tab:name});
    }

    select_monster_instance(monster) {
        console.log("Selected Monster Instance", monster);
        var instance = Object.assign({}, monster);
        instance['monster'] = window.cmState.global_state_lookup(this, 'monsters', instance['monster_id']);
        window.cmState.setGlobalState('selected_monster_instance', instance);
        //this.setState({selected_monster_instance: monster});
    }

    roll_initiative(instance, action) {
        var initiative_order = this.state.initiative_order;
        initiative_order.push([instance, action]);
        this.setState({initiative_order: initiative_order});
    }

    remove_monster_instance(id) {
        if (id in window.cmState.instances){
            var monster_instance = window.cmState.global_state_lookup(this, 'instances', id);

            if (monster_instance.player_controlled){
                return false;
            }
            else if (monster_instance.is_player_instance){
                ajaxWrapper('POST','/api/home/monsterinstance/' + id + '/', {location: null}, console.log);
            }
            else {
                ajaxWrapper('POST','/api/home/monsterinstance/' + id + '/delete/', {}, console.log);
            }
        }
        else {
            ajaxWrapper('POST', '/api/home/actioninstance/' + id + '/delete/', {}, console.log);
        }

        remove_monster_instance(id);

        var data = {'remove_icon':id};
        this.state.chat_socket.send_message({'message':data});
    }

    move_icon(id, new_loc, location) {
        if (isNaN(new_loc.x) || isNaN(new_loc.y)){
            console.log("NaN Found in move_icon()!!!", id, new_loc);
            return false;
        }

        //Are these two things not different enough to warrent seperate function?
        if (location) {
            console.log("Move Location Icon!");
            var current_location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);

            var markers = [];
            for (var item of current_location['children']) {
                var marker = item['location'];
                if (marker.id == id) {
                    marker.coords = new_loc;
                    var data = {id: id, coords: new_loc}
                    console.log("Save Location After Move", data)
                    save_location(data)
                    //ajaxWrapper('POST','/api/home/location/' + id + '/', {'coords':new_loc}, console.log);
                }
                markers.push({'location':marker});
            }
            current_location['children'] = markers;

            this.setState({current_location: current_location.id});
        }
        else {
            var icon_data = {
                id:id,
                coords: new_loc
            };
            save_monster_instance(icon_data);
        }
    }

    rotate_icon(id, rotation, coords) {
        var data = {
            id: id,
            rotation: rotation,
            coords: coords
        }
        save_monster_instance(data);

        //ajaxWrapper('POST','/api/home/monsterinstance/' + icon.id + '/', {'coords': new_loc}, console.log);
        //var data = {'icon':icon};
        //this.state.chat_socket.send_message({'message':data});
    }

    handleClick(e) {
        var lat_lng = e.latlng;
        var data = {object_loc: [[lat_lng['lat'], lat_lng['lng']], [lat_lng['lat'] + 10, lat_lng['lng'] + 10]]};
        this.state.chat_socket.send_message({'message':data});
	}

    select_monster(type, monster_id) {
        var monster = window.cmState.global_state_lookup(this, 'monsters', monster_id);

        var new_state = {};
        new_state['selected_pc'] = null;
        new_state[type] = monster_id;
        this.setState(new_state);

        if (type == 'edit_monster'){
            this.push_modal_content(<EditMonster monster={monster} push_modal_content={this.push_modal_content} />);
        }
    }

    unselect_monster() {
        this.setState({selected_monster:null});
    }

    add_icon(coords) {
        if (this.state.map_mode == 'add_markers') {
            var data = {'coords':coords};
            var id = this.state.current_location;
            data['parent'] = id;
            data['group'] = window.cmState.group.id;
            save_location(data);
        }
        else if (this.state.selected_monster) {
            var monster = window.cmState.global_state_lookup(this, 'monsters', this.state.selected_monster);

            var data = {
                monster:monster.id,
                size: monster.size,
                coords: coords,
                group: this.props.group_id,
                location: this.state.current_location
            };
            save_monster_instance(data);
        }
        else if (this.state.selected_pc){
            data = {
                id: this.state.selected_pc.id,
                coords: coords,
                location: this.state.current_location
            };

            console.log("Add Selected PC", data);
            save_monster_instance(data);
        }
    }

    select_pc(pc) {
        this.setState({selected_pc: pc, selected_monster: null});
    }

    added_icon(result) {
        console.log("Added Icon", result)
        var icons = this.state.icons;
        var instance = result[0]['monsterinstance'];
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var icon = this.load_icon_from_monster(instance, monster);
        console.log("Added Icon", icon)

        this.state.chat_socket.send_message({'message':{'add_icon':icon}});
    }

    load_icon_from_monster(instance, monster){
        var name = instance.name;
        if (name == "") {
            name = monster.name;
        }

        var image = monster['image'];
        var image_size = monster['image_size'];
        if (instance.image != '') {
            image = instance.image;
            image_size = instance.image_size;
        }

        var is_player_instance = false;
        var players = this.state.group.player_characters;
        for (var item of players){
            var player = item['playercharacter'];
            if (player.main_instance && player.main_instance.id == instance.id){
                is_player_instance = true;
            }
        }

        var icon = instance;
        icon['image'] = image;
        icon['image_size'] = image_size;
        icon['type'] = 'icon';
        icon['name'] = name;
        icon['monster'] = monster;
        icon['is_player_instance'] = is_player_instance;

        return icon;
    }

    add_action_token(action, instance, cast_level) {
        var location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);
        var ft_per_pixel = location.ft_in_width / location.location_image_size[0];
        var pixels_between_lines = location.grid_size / ft_per_pixel;

        var scale_multiplier = 1;
        if (instance && instance.monster_id) {
            if (instance.size == 'Large') {
                var scale_multiplier = 2;
            }
            else if (instance.size == 'Huge') {
                var scale_multiplier = 3;
            }
            else if (instance.size == 'Gargantuan') {
                var scale_multiplier = 4;
            }
        }


        var icon_save = {
            coords:{
                x:instance.coords.x + pixels_between_lines / 2 * scale_multiplier,
                y:instance.coords.y + pixels_between_lines / 2 * scale_multiplier
            },
            location_mod: pixels_between_lines / 2 * scale_multiplier,
            action: action.id,
            instance: instance.id,
            cast_level: cast_level,
            share_with_players: true,
            player_controlled: true,
            group: this.state.group.id,
            location: this.state.group_location.location_id,
        };
        ajaxWrapper('POST','/api/home/actioninstance/?related=instance&related__instance__only=id,monster_id&', icon_save, this.added_action_token)

        //this.state.chat_socket.send_message({'message':{'add_icon':icon}});
    }

    added_action_token(result) {
        console.log("Added Action Token");
        var icon = result[0]['actioninstance'];

        this.state.chat_socket.send_message({'message':{'add_icon':icon}});
    }

    use_action(action_use) {
        var now = new Date();
        action_use['id'] = now.valueOf();
        action_use['show_damage'] = false;
        this.state.chat_socket.send_message({'message':{'action':action_use}});
    }

    roll_damage(id, crit, undo) {
        this.state.chat_socket.send_message({'message':{'show_damage':{'id':id, 'critical':crit, 'undo': undo}}});
    }

    click_map_handler(position, is_right, e){
        if (is_right){
            console.log('Ping!');

            var selected_monster_instance = window.cmState.selected_monster_instance;
            var colors = null;
            var name = 'Someone';
            if (selected_monster_instance){
                name = selected_monster_instance.name;
                colors = selected_monster_instance.colors;
            }
            else if (this.state.dm){
                name = 'DM';
                colors = ['gold'];
            }

            this.state.chat_socket.send_message({
                message: {
                    ping_map: {
                        position: position,
                        colors: colors,
                        name: name,
                    }
                }
            });
        }
        else if (this.state.selected_monster || this.state.selected_pc || this.state.map_mode == 'add_markers') {
            var vectored_position = {x: position[0], y: position[1]};
            this.add_icon(vectored_position);
        }

        if (window.cmState.selected_monster_instance) {
            if (!('icon_id' in e.target.attrs) || window.cmState.selected_monster_instance.id != e.target.attrs.icon_id) {
                if (this.state.dm) {
                    window.cmState.setGlobalState('selected_monster_instance', null);
                }
            }
        }
    }

    change_location(location_id) {
        var current_location = window.cmState.global_state_lookup(this, 'locations', location_id);

        this.setState({current_location: current_location.id});
        window.cmState.setGlobalState('current_location', current_location);

        //Reload adventure context
        this.get_adventure(current_location);

        ajaxWrapper('GET','/api/home/grouplocation/?group=' + this.props.group_id + '&location=' + current_location.id, {}, this.get_group_location);
    }

    change_group_location() {
        var count = 0;

        for (var key in window.cmState.instances) {
            var instance = window.cmState.instances[key];
            if (instance.player_controlled) {
                count += 1
            }
        }

        this.setState({
            outstanding_count: count
        }, this.update_pc_locations);
    }

    update_pc_locations(){
        var location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);
        var pc_offset = location.location_image_size[0] / (location.ft_in_width/location.grid_size);
        var location_offset = {
            x: window.cmState.map_position[0] * -1 / window.cmState.zoom,
            y: window.cmState.map_position[1] * -1 / window.cmState.zoom
        };

        var player_controlled_instances = []
        for (var key in window.cmState.instances) {
            var instance = window.cmState.instances[key];
            if (instance.player_controlled) {
                player_controlled_instances.push(instance);
            }
        }

        var count = 0;
        for (var pc of player_controlled_instances) {
            var pc_data = {
                coords: {
                    x:location_offset.x + count * pc_offset,
                    y:location_offset.y
                },
                location_id: location.id,
                location: location.id
            }

            var updated_pc = Object.assign(pc, pc_data);
            save_monster_instance(updated_pc, this.change_group_location_callback);

            count += 1;
        }
    }

    change_group_location_callback() {
        if (this.state.outstanding_count <= 1){
            console.log("Icon List", window.cmState.icon_list);
            ajaxWrapper('POST','/api/home/adventuringgroup/' + this.props.group_id + '/', {current_location: this.state.current_location}, console.log);

            var location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);
            var location_data = Object.assign({}, location);
            this.state.chat_socket.send_message({'message':{'current_location': this.state.current_location}});
        }
        else {
            this.setState({outstanding_count: this.state.outstanding_count - 1});
        }
    }


    toggle_fog() {
        if (this.state.fog_of_war) {
            this.state.chat_socket.send_message({'message':{'toggle_fog':false}});
            this.setState({fog_of_war:false});
            ajaxWrapper('POST','/api/home/grouplocation/' + this.state.group_location.id + '/', {'fog_of_war':false}, console.log)
        }
        else {
            this.state.chat_socket.send_message({'message':{'toggle_fog':true}});
            this.setState({fog_of_war:true});
            ajaxWrapper('POST','/api/home/grouplocation/' + this.state.group_location.id + '/', {'fog_of_war':true}, console.log)
        }
    }

    toggle_skill_checks() {
        this.push_modal_content(<SkillChecks ask_for_roll={this.ask_for_roll} />);
    }

    ask_for_roll(name) {
        this.state.chat_socket.send_message({'message':{'ask_for_roll':name}});
        this.setState({ask_for_roll: name});
    }

    clear_roll() {
        this.ask_for_roll('');
    }

    roll_skill(skill_type) {
        if (typeof(skill_type) != 'string') {
            var skill_type = this.state.ask_for_roll;
        }

        var selected_monster_instance = window.cmState.selected_monster_instance;

        //if nothing selected, see if player character has a main instance and roll for that.
        if (!selected_monster_instance) {
            if (this.state.player_character && this.state.player_character.main_instance.id) {
                for (var icon of this.state.icons) {
                    if (icon.id == this.state.player_character.main_instance.id) {
                        selected_monster_instance = icon;
                    }
                }
            }
        }

        var monster = window.cmState.global_state_lookup(this, 'monsters', selected_monster_instance.monster_id);

        console.log("Item", skill_type);
        var equipment_modifiers = ''
        if (selected_monster_instance) {
            for (var item of selected_monster_instance.equipped_items) {
                var equipment_instance = item.instanceequippeditem;
                console.log("Equipment Instance", equipment_instance);
                var equipment = window.cmState.global_state_lookup(this, 'equipment', equipment_instance.equipment_id);

                for (var magic_item of equipment.magical_attributes) {
                    var magical_attribute = magic_item.magicalattribute;
                    if (magical_attribute.saving_throw_type == skill_type) {
                        equipment_modifiers += magical_attribute.saving_throw_bonus;
                    }
                }
            }
        }
        console.log("Equipment Modifiers", equipment_modifiers);

        if (monster) {
            if (['strength','dexterity','constitution','intelligence','wisdom','charisma'].indexOf(skill_type) > -1) {
                var modifier = stat_modifier(monster[skill_type]) + equipment_modifiers;
                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier);
                    var roll_result_two = parse_roll("1d20 - " + modifier);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier);
                    var roll_result_two = parse_roll("1d20 + " + modifier);
                }
            }
            else if (skill_type == 'initiative') {
                var modifier = stat_modifier(monster['dexterity']) + equipment_modifiers;
                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier);
                    var roll_result_two = parse_roll("1d20 - " + modifier);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier);
                    var roll_result_two = parse_roll("1d20 + " + modifier);
                }
            }
            else if (skill_type == 'death_saving_throw') {
                var roll_result_one = parse_roll("1d20");
                var roll_result_two = parse_roll("1d20");
            }
            else {
                var modifier = monster[skill_type] * monster.proficiency_bonus;
                if (['strength_saving_throw','athletics'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.strength);
                }
                else if (['dexterity_saving_throw','acrobatics', 'sleight_of_hand','stealth'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.dexterity);
                }
                else if (['constitution_saving_throw'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.constitution);
                }
                else if (['intelligence_saving_throw','arcana','history','investigation','nature','religion'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.intelligence);
                }
                else if (['wisdom_saving_throw','animal_handling','insight','medicine','perception','survival'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.wisdom);
                }
                else if (['charisma_saving_throw','deception','intimidation','performance','persuasion'].indexOf(skill_type) > -1) {
                    modifier += stat_modifier(monster.charisma);
                }

                if (modifier < 0) {
                    var roll_result_one = parse_roll("1d20 - " + modifier + equipment_modifiers);
                    var roll_result_two = parse_roll("1d20 - " + modifier + equipment_modifiers);
                }
                else {
                    var roll_result_one = parse_roll("1d20 + " + modifier + equipment_modifiers);
                    var roll_result_two = parse_roll("1d20 + " + modifier + equipment_modifiers);
                }
            }

            var check_name = (skill_type + ' check').replaceAll('_', ' ');

            this.use_action({
                instance_id: selected_monster_instance.id,
                hits: [roll_result_one, roll_result_two],
                damages: [],
                action: {'name': check_name}
            });
        }
    }

    take_damage(point_type, damage) {
        console.log("Point Type", point_type, damage);
        var selected_monster_instance = window.cmState.selected_monster_instance;

        //if nothing selected, see if player character has a main instance and roll for that.
        if (!selected_monster_instance) {
            if (this.state.player_character && this.state.player_character.main_instance.id) {
                for (var icon of this.state.icons) {
                    if (icon.id == this.state.player_character.main_instance.id) {
                        selected_monster_instance = icon;
                    }
                }
            }
        }

        if (selected_monster_instance && selected_monster_instance[point_type]) {
            var new_points = selected_monster_instance[point_type] - damage;

            selected_monster_instance[point_type] = new_points;
            var new_data = {'id': selected_monster_instance.id};
            new_data[point_type] = new_points;
            save_monster_instance(new_data);
            //ajaxWrapper('POST','/api/home/monsterinstance/' + selected_monster_instance.id + '/', new_data, console.log)
            this.state.chat_socket.send_message({'message':{'icon': new_data}});
        }
        else if (selected_monster_instance) {
            var monster_instance_data = {
                id: selected_monster_instance.id,
                monster_instance_resources: selected_monster_instance['monster_instance_resources']
            };

            for (var item of monster_instance_data['monster_instance_resources']) {
                var found = false;
                var resource = item['monsterinstanceresource'];

                console.log("Resource", resource.resource_id);
                var resource_name = window.cmState.global_state_lookup(this, 'resources', resource.resource_id).name;
                if (point_type.indexOf("Spell Slot Level") > -1 && resource_name.indexOf(point_type) > -1) {
                    found = true;
                }
                else if (resource.resource_id == point_type) {
                    found = true;
                }

                if (found) {
                    console.log("Found!");
                    var new_points = resource['quantity'] - damage;
                    console.log("New Points", new_points);
                    resource['quantity'] = new_points;
                    ajaxWrapper('POST','/api/home/monsterinstanceresource/' + resource['id'] + '/', {'quantity': new_points}, console.log);

                    item = {
                        'monsterinstanceresource': resource
                    };
                    this.state.chat_socket.send_message({'message':{'icon': monster_instance_data}});
                    break;
                }
            }
            if (!found) {
                console.log("Not Found, Looking through equipment", selected_monster_instance)
                for (var item of selected_monster_instance['equipped_items']) {
                    var equipment_instance = item.instanceequippeditem;

                    console.log("Equipment Instance", equipment_instance);
                    for (var resource_item of equipment_instance['instance_equipment_resources']) {
                        var resource = resource_item.instanceequippeditemresource;
                        var resource_index = equipment_instance['instance_equipment_resources'].indexOf(resource_item);

                        var resource_name = window.cmState.global_state_lookup(this, 'resources', resource.resource_id).name;
                        console.log("Resource, equipment", resource_name)
                        if (resource.resource_id == point_type) {
                            found = true;
                        }

                        if (found) {
                            console.log("Found!")
                            var new_points = resource['quantity'] - damage;
                            resource['quantity'] = new_points;

                            ajaxWrapper('POST','/api/home/instanceequippeditemresource/' + resource['id'] + '/', {'quantity': new_points}, console.log)
                            item.instanceequippeditem['instance_equipment_resources'][resource_index] = {'instanceequippeditemresource': resource};
                            this.state.chat_socket.send_message({'message':{'icon': selected_monster_instance}});
                            break;
                        }
                    }
                }
            }
        }
    }

    set_main_instance() {
        ajaxWrapper('POST','/api/home/playercharacter/' + this.state.player_character.id + '/', {main_instance: window.cmState.selected_monster_instance.id}, this.get_player_character)
    }

    get_player_character(result) {
        var player_character = result[0].playercharacter;
        this.setState({player_character: player_character});
    }

    hide_modal(){
        var state = {modal_content: null};
        if (this.state.second_modal_content){
            state = {
                'second_modal_content': null,
                modal_timestamp: new Date().valueOf()
            };
        }

        this.setState(state);
    }

    change_zoom(direction){
        if (direction >= 0){
            this.setState({zoom_control: this.state.zoom_control + 1});
        }
        else {
            this.setState({zoom_control: this.state.zoom_control - 1});
        }
    }

    render() {
        var max_bounds = [
			[0, 0],
			[100, 250]
		];
        var sidebar_height = window.innerHeight - 20;
        var sidebar_width = 30;

        var map = null;
        if (this.state.current_location && this.state.group_location.location_id == this.state.current_location) {
            var map = <SessionMap map_mode={this.state.map_mode} move_icon={this.move_icon} add_icon={this.add_icon}
                selected_monster={this.state.selected_monster} sidebar_width={sidebar_width}
                dm={this.state.dm} group_id={this.props.group_id} location={this.state.current_location} rotate_icon={this.rotate_icon}
                group_location={this.state.group_location} line_vars={{line_color:this.state.line_color, line_thickness:this.state.line_thickness}}
                fog_of_war={this.state.fog_of_war} click_map={this.click_map_handler}
                center_map={this.center_map} center_map_position={this.state.center_map_position}
                center_map_timestamp={this.state.center_map_timestamp} center_map_ping={this.state.center_map_ping}
                handle_key_press={this.handle_key_press} zoom_control={this.state.zoom_control}
            />;
        }

        var stat_block = null;
        var macro_bar = null;
        var selected_monster_instance = window.cmState.selected_monster_instance;
        if (selected_monster_instance) {
            //if you selected a spell overlay
            if (selected_monster_instance.action_id) {
                stat_block = <AOECard remove_monster_instance={this.remove_monster_instance} action={selected_monster_instance} use_action={this.use_action} select_monster_instance={this.select_monster_instance} />
            }
            else if (selected_monster_instance.location_image_size) {
                stat_block = <LocationDetailsSidebar session={true} change_location={this.change_location} map_mode={this.state.map_mode}
    				key={selected_monster_instance.id} display_location={selected_monster_instance} map_location={{id: this.state.current_location}}
                    setGlobalState={this.setGlobalState} refresh_data={this.get_location_data} dm={this.state.dm} group={this.state.group}
    				details_sidebar_mode={this.state.details_sidebar_mode} set_details_sidebar_mode={this.set_details_sidebar_mode} />;
            }
            else {
                stat_block = <StatBlockMini use_action={this.use_action} push_modal_content={this.push_modal_content}
                    remove_monster_instance={this.remove_monster_instance} chat_socket={this.state.chat_socket} group={this.state.group}
                    add_action_token={this.add_action_token} player_character={this.state.player_character}
                    set_main_instance={this.set_main_instance} dm={this.state.dm} take_damage={this.take_damage} roll_skill={this.roll_skill} />;
            }
        }

        var tabs = [];
        var dm_buttons = null;
        var tab_names = [];

        if (this.state.dm) {
            dm_buttons = [
                <Button type='danger' text='See PC View' onClick={() => this.setState({dm:false})} />,
                <Button type='primary' text='Set Group Location' onClick={this.change_group_location} />,
                <Button type='secondary' text='Skill Checks/Saving Throws' onClick={this.toggle_skill_checks} />,
                <Button type='secondary' text='Short Rest' onClick={() => this.take_rest('short rest')} />,
                <Button type='secondary' text='Long Rest' onClick={() => this.take_rest('long rest')} />,
            ];
            var tab_names = ['Selection','Map Details','Monsters', 'Locations'];//'PCs',
        }
        else if (this.state.group.dm_id == window.cmState.user.id){
            var dm_buttons = [
                <Button type='danger' text='See DM View' onClick={() => this.setState({dm:true})} />,
                <Button type='primary' text='Set Group Location' onClick={this.change_group_location} />,
                <Button type='secondary' text='Skill Checks/Saving Throws' onClick={this.toggle_skill_checks} />,
            ];
        }

        for (var tab_name of tab_names) {
            tabs.push(<Tab name={tab_name} select_tab={this.select_tab} selected_tab={this.state.sidebar_tab} />);
        }

        var sidebar_content = <p>Coming Soon!</p>;
        if (this.state.sidebar_tab == 'Selection') {
            sidebar_content = stat_block;
        }
        else if (this.state.sidebar_tab == 'Map Details') {
            var fow = <Button type='dark' text='Turn On Fog Of War' onClick={this.toggle_fog} />;
            if (this.state.fog_of_war) {
                var fow = <Button type='danger' text='Turn Off Fog Of War' onClick={this.toggle_fog} />;
            }

            var current_location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);
            var defaults = {
                grid_size: current_location.grid_size,
                ft_in_width: current_location.ft_in_width
            };
            sidebar_content = <div>
                <FormWithChildren setGlobalState={this.setGlobalState} globalStateName={'map_details'} autoSetGlobalState={true} defaults={defaults}>
                    <NumberInput name='ft_in_width' label='Total Width Of Map In Ft' />
                    <NumberInput name='grid_size' label='Grid Size In Ft' />
                </FormWithChildren>
                {fow}
            </div>;
        }
        else if (this.state.sidebar_tab == 'Monsters') {
            sidebar_content = <MonsterSearch select_monster={this.select_monster} unselect_monster={this.unselect_monster} selected_monster={this.state.selected_monster} setGlobalState={this.setGlobalState} />
        }
        else if (this.state.sidebar_tab == 'Locations') {
            var current_location = window.cmState.global_state_lookup(this, 'locations', this.state.current_location);
            sidebar_content = <MapLocations key={"maps_ " + this.state.current_location} display_location={current_location} map_location={current_location} change_location={this.change_location} />;
        }
        else if (this.state.sidebar_tab == 'PCs') {
            sidebar_content = <PCList player_characters={this.state.group.player_characters} selected_pc={this.state.selected_pc} select_pc={this.select_pc} />;
        }

        var chat_instance = window.cmState.selected_monster_instance;
        if (!(chat_instance) && this.state.player_character && this.state.player_character.main_instance && 'id' in this.state.player_character.main_instance) {
            chat_instance = this.state.player_character.main_instance;
        }

        var chat_container = <ChatContainer group_id={this.props.group_id} actions_used={this.state.actions_used}
            ask_for_roll={this.state.ask_for_roll} roll_damage={this.roll_damage} push_modal_content={this.push_modal_content}
            dm={this.state.dm} clear_roll={this.clear_roll} roll_skill={this.roll_skill} center_map={this.center_map}
            height={(sidebar_height / 2)} take_damage={this.take_damage} current_instance={chat_instance} />;

        var sidebar_full_content = <div>
            {dm_buttons}
            {sidebar_content}
        </div>;

        if (tabs.length > 0){
            sidebar_full_content = <div>
                {dm_buttons}
                <ul className="nav nav-tabs">
                    {tabs}
                </ul>
                <div style={{marginTop:'10px'}}>
                    {sidebar_content}
                </div>
            </div>;
        }

        var sidebar = <Sidebar loaded={true} start_open={true} no_opener={true} height={'auto'} headerHeight={0} widthPercent={sidebar_width} >
            <div>
            <div style={{padding:'0px 0px 10px', margin:'0px 0px 10px', borderBottom: '2px solid #666', height:(sidebar_height / 2) + 'px', overflowY:'scroll'}}>
                {sidebar_full_content}
            </div>
            {chat_container}
            </div>
        </Sidebar>;

        var edit_monster = false;
        if (this.state.edit_monster) {
            edit_monster = true;
        }

        var modal_content = null;
        if (this.state.modal_content){
            var modal_content_style = {};
            if (this.state.second_modal_content){
                modal_content_style = {display: 'none'};
            }

            modal_content = <EmptyModal show={true} onHide={this.hide_modal}>
                <Container>
                    <div key={this.state.modal_timestamp} style={modal_content_style} >{this.state.modal_content}</div>
                    <div>{this.state.second_modal_content}</div>
                </Container>
            </EmptyModal>;
        }

        var map_modes = ['pan','free_draw','measure','fog_of_war','add_markers'];
        var map_modes_select = [];
        for (var mode of map_modes) {
            if ((mode != 'fog_of_war' && mode != 'add_markers') || this.state.dm) {
                map_modes_select.push(
                    <SelectMode map_mode={mode} current_mode={this.state.map_mode} setGlobalState={this.setGlobalState}
                        line_vars={{line_color:this.state.line_color, line_thickness:this.state.line_thickness}}
                        center_map={this.center_map} push_modal_content={this.push_modal_content} clear_fog_of_war={this.clear_fog_of_war}
                        change_zoom={this.change_zoom} />
                );
            }
        }

        var initiative = null;
        if (this.state.group.id){
            initiative = <InitiativeOrder sidebar_width={sidebar_width} dm={this.state.dm} group={this.state.group}
                use_action={this.use_action} ask_for_roll={this.ask_for_roll} handle_key_press={this.handle_key_press}
                select_monster_instance={this.select_monster_instance} />;
        }

        var content = <div className="container-container" onContextMenu={(e) => e.preventDefault()} >
            <div>
                {map}
                {sidebar}
            </div>

            {initiative}

            {modal_content}
            {macro_bar}

            <div className='map-mode-select-container' style={{position:'absolute', left:'0px', top:'20%', background: 'white', boxShadow: '2px 2px 10px rgba(0,0,0,.2)', border:'#ddd', borderRadius:'3px'}}>
                {map_modes_select}
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.adventure_loaded} />
        )
    }
}
