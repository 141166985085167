import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import SpellCard from 'projectLibrary/spell_card.js';
import save_monster from 'projectLibrary/session_tools/global_data/save_monster.js';

class Spell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
        this.toggle_prepared_spell = this.toggle_prepared_spell.bind(this);
        this.show_spell = this.show_spell.bind(this);
    }

    show_spell(){
        if (this.props.card_inline) {
            this.setState({show: !this.state.show})
        }
        else {
            this.props.push_modal_content(<SpellCard spell={this.props.spell} />);
        }

    }

    toggle_prepared_spell() {
        if (this.props.monster_prepared_spells.indexOf(this.props.spell.id) == -1) {
            this.props.add_spell(this.props.spell);
        }
        else {
            this.props.remove_spell(this.props.spell);
        }
    }

    render() {
        var spell = this.props.spell;

        if (this.props.monster_prepared_spells.indexOf(spell.id) == -1) {
            var prepare_button = <Button type='primary' text='Learn Spell' onClick={this.toggle_prepared_spell} />
        }
        else {
            var prepare_button = <Button type='danger' text='Remove Learned Spell' onClick={this.toggle_prepared_spell} />
        }

        var edit = null;
        if (this.props.edit) {
            var edit = <Button type='secondary' text='Edit' href={'/edit_action/' + spell.id + '/'} target='_blank'/>
        }

        var content = [];
        content.push(
                <tr>
                    <td><a onClick={this.show_spell}>{spell.name}</a></td>
                    <td>{spell.spell_specifics.magic_school}</td>
                    <td>{spell.level}</td>
                    <td>{prepare_button}</td>
                    <td>{edit}</td>
                </tr>
            )

        if (this.state.show) {
            content.push(<SpellCard spell={this.props.spell} />)
        }

        return (content);

    }
}

export default class SpellsForm extends React.Component {
    constructor(props) {
        super(props);

        var prepared_spells = [];
        if (this.props.monster && this.props.monster.action_spells) {
            for (var item of this.props.monster.action_spells) {
                prepared_spells.push(item.actionspell.id);
            }
        }

        this.state = {
            search_name:'',
            search_school:'',
            search_level: '',
            search_class: '',
            spell_list:[],
            monster_learned_spells:[],
            monster_prepared_spells:prepared_spells,
            loaded: false
        };

        this.spells_callback = this.spells_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.add_spell = this.add_spell.bind(this);
        this.remove_spell = this.remove_spell.bind(this);
        this.update_monster_spells = this.update_monster_spells.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/actionspell/?action_type=Spell&related=dnd_classes&order_by=name', {}, this.spells_callback);

        if (this.props.monster.dnd_class){
            this.setState({
                search_class: this.props.monster.dnd_class
            });
        }
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    add_spell(spell) {
        console.log("Add Spell", spell);
        if (this.props.add_spell) {
            this.props.add_spell(spell);
            var prepared_spells = this.state.monster_prepared_spells;
            prepared_spells.push(spell.id)
            this.setState({monster_prepared_spells: prepared_spells})
        }
        else {
            this.update_monster_spells(spell.id);

            var prepared_spells = this.state.monster_prepared_spells;
            prepared_spells.push(spell.id)

            save_monster({'id':this.props.monster.id, 'action_spells[]': prepared_spells});

            //ajaxWrapper('POST','/api/compendium/monster/' + this.props.monster.id + '/', {'action_spells[]': prepared_spells}, console.log)
            this.setState({monster_prepared_spells: prepared_spells})
        }


    }

    remove_spell(spell) {
        console.log("Remove Spell", spell);
        if (this.props.remove_spell) {
            this.props.remove_spell(spell);
            var prepared_spells = this.state.monster_prepared_spells;
            var spells = [];
            for (var item of prepared_spells) {
                if (spell.id != item) {
                    spells.push(item);
                }
            }

            this.setState({monster_prepared_spells: spells});
        }
        else {
            this.update_monster_spells(undefined, spell.id);

            var prepared_spells = this.state.monster_prepared_spells;
            var spells = [];
            for (var item of prepared_spells) {
                if (spell.id != item) {
                    spells.push(item);
                }
            }

            save_monster({'id':this.props.monster.id, 'action_spells[]': spells});
            //ajaxWrapper('POST','/api/compendium/monster/' + this.props.monster.id + '/', {'action_spells[]': spells}, console.log)
            this.setState({monster_prepared_spells: spells});
        }

    }

    update_monster_spells(add_id, remove_id){
        if (add_id){
            var existing_spells = this.props.monster.action_spells;
            for (var spell of this.state.spell_list){
                if (add_id == spell.id){
                    existing_spells.push({actionspell:spell});
                }
            }
            this.props.update_monster('add_spell', {action_spells: existing_spells}, true);
        }
        else if (remove_id){
            var new_spells = [];
            for (var item of this.props.monster.action_spells) {
                var spell = item['actionspell'];
                if (remove_id != spell.id){
                    new_spells.push({actionspell: spell});
                }
            }
            this.props.update_monster('remove_spell', {action_spells: new_spells}, true);
        }
    }

    spells_callback(result) {
        var spell_list = [];
        for (var item of result) {
            var spell = item['actionspell'];
            spell_list.push(spell);
        }

        this.setState({'spell_list':spell_list, loaded:true})
    }

    render() {
        var spells = [];

        for (var spell of this.state.spell_list) {
            if (spell.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) == -1) {
                continue;
            }

            if (this.state.search_school != '' && spell.spell_specifics.magic_school != this.state.search_school) {
                continue;
            }

            if (this.state.search_level != '' && spell.level != this.state.search_level) {
                continue;
            }

            if (this.state.search_class != ''){
                var found = false;
                for (var item of spell.dnd_classes){
                    var class_id = item['dndclass']['id'];
                    if (class_id == this.state.search_class){found = true;}
                }

                if (!(found)){
                    continue;
                }
            }

            spells.push(
                <Spell spell={spell} add_spell={this.add_spell} remove_spell={this.remove_spell} edit={true} card_inline={this.props.card_inline}
                    monster_prepared_spells={this.state.monster_prepared_spells} push_modal_content={this.props.push_modal_content} />
            );
        }

        var magic_schools = [
            {text:'Abjuration', value:'Abjuration'},
            {text:'Conjuration', value:'Conjuration'},
            {text:'Divinination', value:'Divinination'},
            {text:'Enchantment', value:'Enchantment'},
            {text:'Evocation', value:'Evocation'},
            {text:'Illusion', value:'Illusion'},
            {text:'Necromancy', value:'Necromancy'},
            {text:'Transmutation', value:'Transmutation'},
        ];

        var magic_levels = [
            {text:'Cantrip', value:0},
            {text:'1', value:1},
            {text:'2', value:2},
            {text:'3', value:3},
            {text:'4', value:4},
            {text:'5', value:5},
            {text:'6', value:6},
            {text:'7', value:7},
            {text:'8', value:8},
            {text:'9', value:9},
        ];


        var content = <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}} >
            <div>
                <h2>Spells</h2>
                <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true} defaults={this.state} >
                    <TextInput name='search_name' label='Search by Name' className="col-3" />

                    <Select name='search_class' label='Class' className="col-3" optionsUrl={'/api/compendium/DnDClass/'}
                        optionsUrlMap={{'text':'{DnDClass.name}', value:'{DnDClass.id}'}} />

                    <Select name='search_school' label='Magic School' options={magic_schools} className="col-3" />

                    <Select name='search_level' label='Level' options={magic_levels} className="col-3" radio={true} />
                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th>School Of Magic</th>
                        <th>Level</th>
                        <th>Prepare</th>
                        <th>Edit</th>
                    </tr>
                    {spells}
                </table>

            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
