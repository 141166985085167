import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, EmptyModal } from 'library';


class MagicalAttribute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        };
    }

    render() {
        var attribute = this.props.attribute;
        var edit = <Button type='secondary' text='Edit Attribute' href={'/edit_magical_attribute/'+ attribute.id +'/'} target='_blank' />;

        var floating_card = null;
        if (this.state.show) {
            floating_card = <div style={{position: 'absolute', top:'100%', left:'0px', width:'100%', zIndex:'10', cursor: 'pointer'}} onClick={() => this.setState({show:!this.state.show})} >
                <div>{attribute.name}</div>
            </div>;
        }

        var content = [];
        content.push(
            <tr style={{position: 'relative'}} >
                <td><a onClick={() => this.setState({show:!this.state.show})} >{attribute.name}</a></td>
                <td>{edit}</td>
                {floating_card}
            </tr>
        );

        return (content);
    }
}


export default class MagicalAttributeList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            search_name:'',
            magical_attribute_list:[],
            loaded: false,
        };

        this.refresh_data = this.refresh_data.bind(this);
        this.magical_attribute_callback = this.magical_attribute_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    componentDidMount() {
        this.refresh_data();
    }

    refresh_data(){
        ajaxWrapper('GET','/api/compendium/magicalattribute/?order_by=name', {}, this.magical_attribute_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    magical_attribute_callback(result) {
        var magical_attribute_list = [];
        for (var item of result) {
            var magical_attribute = item['magicalattribute'];
            magical_attribute_list.push(magical_attribute);
        }

        this.setState({
            magical_attribute_list:magical_attribute_list,
            loaded:true
        });
    }

    render() {
        var magical_attribute_list = [];
        for (var attribute of this.state.magical_attribute_list) {
            if (this.state.search_name != '' && attribute.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) == -1) {
                continue;
            }

            magical_attribute_list.push(<MagicalAttribute attribute={attribute} />);
        }

        var content = <div>
            <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}} >
                <div>
                    <h2>Magical Attributes</h2>
                    <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                        <TextInput name='search_name' label='Search by Name' className="col-4" />
                    </FormWithChildren>
                    <table className="table">
                        <tr>
                            <th>Name</th>
                            <th>Edit</th>
                        </tr>
                        {magical_attribute_list}
                    </table>
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }
}
