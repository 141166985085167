import React, { Component } from 'react';
import { Circle } from 'react-konva';


var COLOR_POSITIONS_BY_COUNT = [
    [],
    [0],
    [-1/4, 1/4],
    [-2/6, 0, 2/6],
    [-3/8, -1/8, 1/8, 3/8],
    [-4/10, -2/10, 0, 2/10, 4/10],
    [-5/12, -3/12, -1/12, 1/12, 3/12, 5/12],
]

export default class Ping extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            size: 1,
        };

        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.update();
    }

    update(){
        var elapsed = (new Date().valueOf() - this.props.timestamp) / 1000;
        if (elapsed > 2){
            this.setState({size: 0});
            return false;
        }

        var radian = Math.PI * 2 * (elapsed % 1);
        var factor = Math.sin(radian) / 2 + 1;

        this.setState({size: factor});

        setTimeout(() => this.update(), 100);
    }

    render() {
        var colors = this.props.colors || ['gold'];

        var circle = null;
        if (this.state.size > 0){
            var radius = 20 * this.state.size * this.props.zoom;

            circle = [
                <Circle
                    x={this.props.position[0] * this.props.zoom}
                    y={this.props.position[1] * this.props.zoom}
                    fill={'rgb(255,255,255)'}
                    radius={2 * this.props.zoom}
                />,
                <Circle
                    x={this.props.position[0] * this.props.zoom}
                    y={this.props.position[1] * this.props.zoom}
                    stroke={'rgb(255,255,255)'}
                    strokeWidth={2}
                    opacity={.5}
                    radius={radius + (radius / 8)}
                />,
            ];

            var total_colors = colors.length;
            var remaining_colors = colors.length - 1;

            for (var color of colors){
                var step = COLOR_POSITIONS_BY_COUNT[total_colors][remaining_colors] * (radius / 4);
                var width = (radius / 4) / total_colors;

                circle.push(<Circle
                    x={this.props.position[0] * this.props.zoom}
                    y={this.props.position[1] * this.props.zoom}
                    stroke={color}
                    strokeWidth={width}
                    radius={radius + step}
                />,);

                remaining_colors -= 1;
            }
        }

        return (
            circle
        );
    }
}
