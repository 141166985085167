import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {ajaxWrapper, ajaxWrapperFile} from 'functions';

import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import {Image, TextInput, Select, CSSInput, Card, Button} from 'library';

var BOOLEANS = [
    {'text':'True', value:true},
    {'text':'False', value:false},
];

const SortableItem = SortableElement(({value, removeImage}) =>
    <Card css={{'display':'inline-block','width':'20%'}} imageUrl={value['url']}
        id={value['id']} buttons={[<Button text={'Remove Picture'} type={'danger'}
        onClick={() => removeImage({'id':value['id'], 'url':value['url']})} />]} />
);


const SortableList = SortableContainer(({items, removeImage}) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} removeImage={removeImage} />
            ))}
        </ul>
    );
});


class ImageInput extends Component {
    static component_name = 'ImageInput';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'multiple'} name={'multiple'} boolean={true} />,
                <Select label={'preview'} name={'preview'} boolean={true} />,
                <Select label={'required'} name={'required'} boolean={true} />,
                <TextInput label={'Bucket (not required)'} name={'bucket_name'} />,
                <Select label={'Is Public?'} name={'is_public'} boolean={true} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'label css'} name={'label_style'} />,
                <CSSInput label={'css'} name={'style'} />,
            ],
        }

        this.state ={
            files:null,
            uploaded_files:[],
            uploading_from_file_input: false,
        }

        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
        this.removeImage = this.removeImage.bind(this);
    }

    onChange(e) {
        this.setState({
            files:e.target.files,
            uploaded_files:this.props.value
        },
            this.fileUpload(e.target.files)
        );
    }

    fileUpload(files) {
        var url = '/files/imageUpload/';
        const formData = new FormData();
        for (var index in files) {
            if (index != 'length' && index != 'item') {
                formData.append('files[]',files[index]);
            }
        }
        formData.append('name', this.props.name);
        if (typeof(this.props.bucket_name) != 'undefined'){
            formData.append('bucket_name', this.props.bucket_name);
        }
        if (typeof(this.props.is_public) != 'undefined'){
            formData.append('is_public', this.props.is_public);
        }
        else {
            formData.append('is_public', !(window.secretReactVars['secure_uploads']));
        }

        ajaxWrapperFile("POST", url, formData, this.fileUploadCallback);
        this.setState({uploading_from_file_input: true});
        this.props.setFormState({uploading_from_file_input: true});
    }

    removeImage(imageProps) {
        console.log("Remove Image");

        if (this.props.multiple == true) {
            var url = imageProps['url'];
            console.log("Url",url)
            var uploaded_files = this.props.value;
            var order_files = []
            for (var index in uploaded_files) {
                var file = uploaded_files[index];
                if (file.url != url) {
                    order_files.push({
                        'url':file['url'],
                        'order':index,
                        'filename':file['filename']
                    });
                }
            }

            if (this.props.deleteUrl) {
                console.log("Delete Posted", this.props.deleteUrl, imageProps);
                var deleteUrl = resolveVariables({'deleteUrl':this.props.deleteUrl}, imageProps)['deleteUrl'];
                ajaxWrapper("POST",deleteUrl, {}, console.log);
                console.log("Delete Posted");
            }
            var formState = {}
            console.log("Order Files", order_files);
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        }
        else {
            var formState = {}
            formState[this.props.name] = ''
            this.props.setFormState(formState);
        }
    }

    fileUploadCallback(value) {
        console.log("FileUploadCallback", value)
        var uploaded_files = this.props.value;

        if (this.props.multiple == true) {
            if (uploaded_files == undefined) {
                uploaded_files = [];
            }
            for (var index in value['uploaded_files']) {
                uploaded_files.push(value['uploaded_files'][index]);
            }

            uploaded_files.sort(function(a, b) {
                return parseInt(a.order) - parseInt(b.order);
            });
        }
        else {
            console.log("Single File Upload");
            if (uploaded_files == undefined) {
                uploaded_files = '';
            }

            uploaded_files = value['uploaded_files'][0]['url'];
            console.log(uploaded_files);
        }

        var formFiles = {};

        formFiles[this.props.name] = uploaded_files;
        formFiles['uploading_from_file_input'] = false;
        console.log("Form Files",formFiles);

        this.setState({
            uploaded_files: uploaded_files,
            uploading_from_file_input: false,
        }, this.props.setFormState(formFiles));
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        if (this.props.multiple == true) {
            var uploaded_files = arrayMove(this.props.value, oldIndex, newIndex);
            var order_files = []
            for (var index in uploaded_files) {
                var file = uploaded_files[index];
                if (file.order != index) {
                    file.order = index;
                }

                order_files.push({'url':file['url'],'order':index,'filename':file['filename']});
            }

            var formState = {};
            formState[this.props.name] = order_files;

            this.props.setFormState(formState);
        }
    };


    render() {
        if (this.props.multiple == true) {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} multiple />
        } else {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} />
        }

        var previewText = "Preview Your Image Below";
        if (this.props.multiple == true) {
            previewText = "Re-order your images below";
        }

        var image_input = input;
        if (this.props.value && this.props.value.length > 0 && !this.props.preview == false) {
            var image_list = null;
            if (this.props.multiple == true){
                var value = this.props.value;
                image_list = <SortableList axis={'x'} items={value} onSortEnd={this.onSortEnd} removeImage={this.removeImage} />
            }
            else {
                var value = {'filename': '', 'url': this.props.value, 'order': 0};
                if (this.props.preview_height){
                    image_list = <Image style={{'display':'inline-block', maxHeight: this.props.preview_height, width:'auto'}} src={value['url']} />;
                }
                else {
                    image_list = <Image style={{'display':'inline-block'}} src={value['url']} />;
                }
            }

            var pictureViewer = <div className="jumbotron" style={{padding:"10px 15px"}}>
                <div>{previewText}</div>
                {image_list}
            </div>;

            image_input = <div>
                {input}
                {pictureViewer}
            </div>;
        }

        var label = null;
        if (this.props.label) {
          var label = <label style={this.props.label_style || {}} htmlFor="exampleFormControlFile1">{this.props.label}</label>
        }

        return (
            <div className={"form-group " + this.props.class} style={this.props.style}>
                {label}
                {image_input}
            </div>
        )
    }
}

export default ImageInput;
