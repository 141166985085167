import React, { Component } from 'react';
import { Group, Circle, Rect, Arc } from 'react-konva';
import CanvasText from 'projectLibrary/canvas/text.js';
import Health from 'projectLibrary/session_tools/health.js';

const DAMAGE_TYPE_COLORS = {
    "Acid":"#b0bf1a",
    "Cold":"blue",
    "Fire":"red",
    "Force":"purple",
    "Lightning":"white",
    "Necrotic":"black",
    "Poison":"#40fd14",
    "Psychic":"#ebcdcc",
    "Radiant":"#f9d71c",
    "Thunder":"#82a3b6"
}
//https://imgur.com/hmJ6XLl
const DAMAGE_TYPE_IMAGES = {
    "acid":{image:"http://i.imgur.com/SWaX2y5.png", image_size:[1300,1114]},
    "cold":{image:"http://i.imgur.com/yZF9MkX.png", image_size:[780,451]},
    "fire":{image:'http://i.imgur.com/oPafP61.png', image_size:[535,507]},
    "force":{image:"http://i.imgur.com/pHgHKYX.png", image_size:[1080,1920]},
    "lightning":{image:'http://i.imgur.com/utl9T5Z.png', image_size:[626,626]},
    "necrotic":{image:"http://i.imgur.com/yKIJMA7.png", image_size:[1594,1600]},
    "poison":{image:"http://i.imgur.com/NpOjMIk.png", image_size:[626,417]},
    "psychic":{image:"http://i.imgur.com/PlpXTUa.png", image_size:[570,779]},
    "radiant":{image:"http://i.imgur.com/6f2n8GZ.png", image_size:[2400,2400]},
    "thunder":{image:"http://i.imgur.com/2aSGHBR.png", image_size:[278,278]},
}

export default class SpellToken extends Component {
    constructor(props) {
        super(props);

        this.mouse_down = this.mouse_down.bind(this);
        this.mouse_up = this.mouse_up.bind(this);
        this.mouse_move = this.mouse_move.bind(this);

        this.drag_start = this.drag_start.bind(this);
        this.drag_move = this.drag_move.bind(this);
        this.drag_end = this.drag_end.bind(this);

        this.on_click = this.on_click.bind(this);
        this.handle_transform = this.handle_transform.bind(this);

        this.token_ref = React.createRef();

        this.state = {
            fillPatternImage: null,
            scale:4,
            offset:314,
            fillPatternScaleX:10,
            fillPatternScaleY:10
        };

        var icon = this.props.icon;
        var action = window.cmState.global_state_lookup(this, 'actions', icon.action_id);

        if (action.action_damage && action.action_damage.length > 0 && action.action_damage[0].damage_type) {
            var damage_type = action.action_damage[0].damage_type.toLowerCase();
            if (DAMAGE_TYPE_IMAGES[damage_type]) {
                const image = new window.Image();
                image.onload = () => {
                    this.setState({
                      fillPatternImage: image,
                      damage_type: damage_type,
                      image_size: DAMAGE_TYPE_IMAGES[damage_type].image_size,
                      image_url: DAMAGE_TYPE_IMAGES[damage_type].image,
                    });
                }
                image.src = DAMAGE_TYPE_IMAGES[damage_type].image;
            }
        }
    }

    handle_transform(transform_data) {
        var new_rotation = transform_data.target.attrs.rotation;
        var new_coords = {'x':transform_data.target.attrs.x,'y':transform_data.target.attrs.y}
        console.log("Transform Data", transform_data);
        this.props.end_token_transform(this.props.icon, new_rotation, new_coords);
    }

    mouse_down(e) {

    }

    mouse_up(e) {

    }

    mouse_move(e) {

    }

    drag_start(e) {
        this.props.start_token_drag(e);
    }

    drag_move(e) {
        this.props.move_token_drag(e);
    }

    drag_end(e) {
        if (!(e.evt)){
            return false;
        }

        this.props.end_token_drag(e, this);
    }

    on_click() {
        window.cmState.setGlobalState('selected_monster_instance',this.props.icon);
    }

    render() {
        var icon = this.props.icon;
        var action = window.cmState.global_state_lookup(this, 'actions', icon.action_id);;
        var current_location = this.props.current_location;
        var pixels_between_lines = this.props.pixels_between_lines;
        var color = 'white';

        var update_functions = {
            onDragStart: this.drag_start,
            onDragMove: this.drag_move,
            onDragEnd: this.drag_end,
            onMouseDown: this.mouse_down,
            onMouseUp: this.mouse_up,
            onMouseMove: this.mouse_move,
            onClick: this.on_click,
            onTransformEnd: this.handle_transform
        }

        if (action.action_damage && action.action_damage.length > 0 && action.action_damage[0].damage_type) {
            color = DAMAGE_TYPE_COLORS[action.action_damage[0].damage_type];
        }

        var draggable = this.props.draggable;

        var x = current_location[0];
        var y = current_location[1];
        if (action.has_aoe && action.area_of_effect.type == 'aura') {
            draggable = false; //will be false when moves with character.
            window.cmState.subscribe_by_name(this, 'icon_list');

            var icons = window.cmState.icon_list;
            for (var temp_icon of icons) {
                if (temp_icon && temp_icon['id'] == icon.instance_id) {
                    x = (temp_icon['coords']['x'] + icon.location_mod) * this.props.zoom;
                    y = (temp_icon['coords']['y'] + icon.location_mod) * this.props.zoom;
                }
            }
        }

        var pattern_scale_multiplier = 1; // this should have something to do with image size and map image size.
        //lightning below, fireball does need 1.
        if (this.state.damage_type == 'lightning') {
            pattern_scale_multiplier = .5;
        }

        if (action.area_of_effect.type == 'sphere' || action.area_of_effect.type == 'cylinder' || action.area_of_effect.type == 'aura') {
            var radius = pixels_between_lines * this.props.zoom * action.area_of_effect.size / 5;
            if (this.state.fillPatternImage) {
                var diameter = radius * 2;
                var fillPatternScale = [
                    diameter / this.state.image_size[0],
                    diameter / this.state.image_size[1],
                ];

                return (
                    <Circle {...update_functions} name={icon.id} x={x} y={y} draggable={draggable}
                        fillPatternImage={this.state.fillPatternImage} fillPatternRepeat="no-repeat"
                        fillPatternScaleX={fillPatternScale[0]} fillPatternScaleY={fillPatternScale[1]}
                        fillPatternOffsetX={radius} fillPatternOffsetY={radius}
                        ref={node => {this.token_ref = node;}} radius={radius} opacity={0.7}>
                    </Circle>
                );
            }
            else {
                return (
                    <Circle {...update_functions} name={icon.id} x={x} y={y} draggable={draggable} fill={color}
                        ref={node => {this.token_ref = node;}} radius={radius} opacity={0.5}>
                    </Circle>
                );
            }
        }
        else if (action.area_of_effect.type == 'cube' || action.area_of_effect.type == 'line') {
            var width = pixels_between_lines * this.props.zoom * action.area_of_effect.size / 5;
            if (action.area_of_effect.type == 'cube') {
                var height = pixels_between_lines * this.props.zoom * action.area_of_effect.size / 5;
            }
            else {
                var height = pixels_between_lines * this.props.zoom;
            }

            if (this.state.fillPatternImage) {
                var fillPatternScale = [
                    width / this.state.image_size[0],
                    height / this.state.image_size[1],
                ];

                return (
                    <Rect {...update_functions}
                        fillPatternImage={this.state.fillPatternImage} fillPatternRepeat="no-repeat"
                        fillPatternScaleX={fillPatternScale[0]} fillPatternScaleY={fillPatternScale[1]}
                        fillPatternOffsetX={0} fillPatternOffsetY={0}
                        x={x} y={y} draggable={draggable} name={icon.id} rotation={icon.rotation}
                        ref={node => {this.token_ref = node;}} width={width} height={height} opacity={0.7} >
                    </Rect>
                )
            }

            else {
                return (
                        <Rect {...update_functions} x={x} y={y} draggable={draggable} name={icon.id} rotation={icon.rotation}
                            ref={node => {this.token_ref = node;}} width={width} height={height} fill={color} opacity={0.5} >
                        </Rect>
                )
            }

        }
        else { //type == cone
            var radius = pixels_between_lines * this.props.zoom * action.area_of_effect.size / 5;
            if (this.state.fillPatternImage) {
                var diameter = radius * 2;
                var fillPatternScale = [
                    diameter / this.state.image_size[0],
                    diameter / this.state.image_size[1],
                ];

                return (
                    <Arc {...update_functions} fillPatternImage={this.state.fillPatternImage} fillPatternRepeat="no-repeat"
                        fillPatternScaleX={fillPatternScale[0]} fillPatternScaleY={fillPatternScale[1]}
                        fillPatternOffsetX={radius} fillPatternOffsetY={radius}
                        x={x} y={y} draggable={draggable} angle={90} name={icon.id} rotation={icon.rotation}
                        ref={node => {this.token_ref = node;}} radius={radius} innerRadius={0} outerRadius={radius} opacity={0.7} >
                    </Arc>
                );
            }
            else {
                return (
                    <Arc {...update_functions} x={x} y={y} draggable={draggable} angle={90} name={icon.id} rotation={icon.rotation}
                        ref={node => {this.token_ref = node;}} radius={radius} innerRadius={0} outerRadius={radius} fill={color} opacity={0.5} >
                    </Arc>
                );
            }
        }
    }
}
