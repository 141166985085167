import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';

function remove_location(location) {
    console.log("Remove Location", location)
    var id = location.id;
    var location_dict = window.cmState.locations;
    delete location_dict[id]

    var parent_children = [];
    var parent = location_dict[location.parent_id];
    if (parent) {
        for (var child of parent.children) {
            if (child.id == location.id) {
                continue;
            }
            else {
                parent_children.push(child);
            }
        }
        parent.children = parent_children;
        location_dict[location.parent_id] = parent;
    }


    if (window.cmState.selected_monster_instance.id == location.id) {
        window.cmState.setGlobalState('selected_monster_instance', null)
    }

    window.cmState.setGlobalState('locations', location_dict);
}

export default remove_location;
