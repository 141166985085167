import React, { Component } from 'react';
import {FormWithChildren, CheckGroup, Button, Sidebar, TextInput, Alert} from 'library';

class MapControls extends React.Component {
    constructor(props) {
        super(props);
        this.state = {'subscribed':false}

        this.subscribed = this.subscribed.bind(this);
    }

    subscribed(result) {
        this.setState({subscribed: true})
    }

    render () {

        var subscribe = <FormWithChildren submitUrl={'/api/home/subscriber/'} redirect={this.subscribed} submit_text={'Stay Up To Date'} defaults={{email:""}}>
            <TextInput label='Email' name='email' required={true} />
        </FormWithChildren>
        if (this.state.subscribed) {
            subscribe = <Alert type='success' text='Success! We will be in touch.' />
        }

        return (
            <div>
                <h2 style={{marginTop:'20px', marginBottom:'20px'}}>Forgotten Maps is dedicated to making the Forgotten Realms Come Alive! And we plan to do it together, with you!</h2>
                <Button type='success' text='Help Add Content Or Create Your Own World' href='/signup/' />
                <div style={{height:'20px'}}></div>
                <h3>Get Notifications On Our Progress</h3>
                {subscribe}
                <div style={{height:'50px'}}></div>
                <h3>What does it mean for the forgotten realms to come alive?</h3>
                <h3>1. Organizing The Lore</h3>
                <p>First up, it means organizing the content, canon and contributions from the community in an easy to view and navigate world. That first step is made by using maps, tagging locations on maps and being able to zoom all the way to seeing the World of Abeir-Toril and its solar system and zooming in as deep as a Neverwinter Tavern such as the Beached Leviathan. All full of detail for anyone and everyone to use however they want. Whether that be downloading the maps for their own use or just learning about the realms to subvert their DM.</p>
                <div style={{height:'20px'}}></div>
                <h3>2. Letting Everyone Play In The Realms</h3>
                <p>Secondly, it means being able to utilize that lore in your own games easily.</p>
                <ul>
                    <li>Being able to take private or group notes on any location, npc, monster, etc.</li>
                    <li>Make your own parallel universe that your players can break.</li>
                    <li>Be able to play right in the world or seamlessly import it into your current system.</li>
                </ul>
                <div style={{height:'20px'}}></div>
                <h3>3. Add Unlimited Depth To The World</h3>
                <p>Having Maps for specific locations is definitely nice to have. But it’s frustrating when you can’t walk outside the dungeon or set up a catapult a few hundred yards from the castle you plan to attack or hide with an unsuspecting family in Neverwinter while the guards are searching for you.</p>
                <p>So, our plan is to add all that depth. If you want to barrage Cragmaw Castle in Neverwinter Woods, you can set up that catapult a few hundred yards away, because you have a map for every square inch of neverwinter woods already. Think of google maps for the Forgotten Realms, zoom out to earth view or zoom all the way in to see the individual trees in the forest. </p>
                <p>But be careful in your exploration, because not only are the maps populated with detail, but monster’s that live in the region will be wandering around too.</p>
                <div style={{height:'20px'}}></div>
                <h3>4. And Finally, Give The World Life</h3>
                <p>That merchant that you met on the High Road, well she has her own life to live too. While you are off galavanting through forests, mountains and dungeons, she is building a network of smithies through the realm. So, too bad your rogue pick pocketed her and every smith across the land charges you extra until your debts are paid. </p>
                <p>And that time when you thought you might take a week long rest because nobody else was going to go slay that dragon flying around Baldur’s Gate. Well if you choose, other adventurer’s may be taking all the treasure for themselves since they risked life and limb a few days earlier. </p>
                <p>If any of the above interests you, please sign up and give us feedback on what you want to see!</p>
                <Button type='success' text='Buy Us A Coffee' href='https://www.patreon.com/forgottenrealmmaps' target='_blank' />
            </div>
        )
    }
}


export default MapControls;
