import React, { Component } from 'react';
import {FormWithChildren, CheckGroup, Button, Sidebar, TextInput, Alert,NumberInput} from 'library';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';
import { ajaxWrapper } from 'functions';
import parse_roll from 'projectLibrary/monsters/roll.js';

function stat_modifier(number) {
    return Math.floor(number/2) - 5;
}

class InitiativeDisplay extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.initiative) {
            this.state = {'initiative': parseInt(this.props.initiative.initiative_roll.total)}
        }
        else {
            this.state = {'initiative': 0}
        }

        this.select_monster_instance = this.select_monster_instance.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        var new_init = parseInt(state['initiative'])
        this.setState({initiative: new_init})
        this.props.set_new_initiative(this.props.initiative, new_init)
    }

    select_monster_instance() {
        if (this.props.initiative) {
            var instance_id = this.props.initiative.instance_id;
        }
        else if (this.props.icon) {
            var instance_id = this.props.icon.id;
        }
        var data = window.cmState.global_state_lookup(this, 'instances', instance_id);
        window.cmState.setGlobalState('selected_monster_instance', data, true);
    }

    render() {
        var icon_style = {height:'50px', width:'50px'};
        if (this.props.initiative) {
            var initiative = this.props.initiative;
            if (!('monsters' in window.cmState)){
                return (null);
            }
            if (!('instances' in window.cmState) || !(initiative.instance_id in window.cmState.instances)){
                return (null);
            }

            var instance = {};
            if ('instance_id' in initiative){
                instance = window.cmState.global_state_lookup(this, 'instances', initiative.instance_id);
            }

            var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
            var name = monster.name;
            var image = monster.image;

            if (instance.name != '') {
                name = instance.name;
            }

            if (instance.image != '') {
                image = instance.image;
            }

            if (this.props.dm || instance.player_controlled) {
                var edit = null;
                if (this.props.edit) {
                    edit = <FormWithChildren autoSetGlobalState={true} globalStateName={'initiative'} setGlobalState={this.setGlobalState}>
                        <NumberInput name='initiative' style={{width:'60px'}} default={this.state.initiative} />
                    </FormWithChildren>
                }
                return (<div className='initiative' onClick={this.select_monster_instance}>
                    <img src={image} alt={name} style={icon_style} />
                    <div className='initiative-popup' >
                        <span style={{fontSize:'12px', whiteSpace: 'nowrap'}}>{name} ({initiative.initiative_roll.total})</span>
                    </div>
                    {edit}
                </div>);
            }
            else {
                return (<div className='initiative'>
                    <img src={image} style={icon_style} />
                    <div className='initiative-popup' >
                        <span style={{fontSize:'12px', whiteSpace: 'nowrap'}}>({initiative.initiative_roll.total})</span>
                    </div>
                </div>);
            }
        }
        else if (this.props.icon) {
            var icon = this.props.icon;
            return (<div className='initiative' onClick={this.select_monster_instance}>
                <img src={icon.image} alt={icon.name} style={icon_style} />
                <div className='initiative-popup' >
                    <span style={{fontSize:'12px', whiteSpace: 'nowrap'}}>{icon.name}</span>
                </div>
            </div>);
        }
    }
}

export default class InitiativeOrder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            initiative_order:this.props.group.initiative_order,
            current_turn: this.props.group.current_turn,
            edit: false,
            new_initiatives: [],
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.get_initiative_order = this.get_initiative_order.bind(this);
        this.clear_initiative_order = this.clear_initiative_order.bind(this);
        this.roll_for_initiative = this.roll_for_initiative.bind(this);
        this.set_new_initiative = this.set_new_initiative.bind(this);

        this.update_initiative_order = this.update_initiative_order.bind(this);

        this.next_turn = this.next_turn.bind(this);
        this.prev_turn = this.prev_turn.bind(this);

        this.initiative_container = React.createRef();

        this.toggle_edit = this.toggle_edit.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.updateDimensions();

        window.cmState.subscribe_by_name(this, 'current_turn');
        window.cmState.subscribe_by_name(this, 'icon_list');
        window.cmState.subscribe_by_name(this, 'instances');

        var chat_socket = new SocketRouter(this, {
            object_loc: 'object_loc',
            initiativeorder: 'initiativeorder',
            clear_initiative_order:'clear_initiative_order',
            current_turn:'current_turn',
            action:'action',
        }, this.props.group.id);

        this.setState({chat_socket: chat_socket});


        //ajaxWrapper('GET','/api/home/adventuringgroup/' + this.props.group.id +'/', {}, this.get_initiative_order);
    }

    updateDimensions() {
        this.setState({
            width: window.innerWidth
        });
    }

    componentDidUpdate(oldProps) {
        if (oldProps.group != this.props.group) {
            this.setState({
                initiative_order: this.props.group.initiative_order,
                current_turn: this.props.group.current_turn
            });
        }
    }

    update_initiative_order(action){
        var initiative_order = this.state.initiative_order;
        var current_turn = this.state.current_turn;

        action['initiative_roll'] = action.hits[0];

        var found = false;
        for (var initiative of initiative_order) {
            if (initiative.instance_id == action.instance_id) {
                initiative['initiative_roll'] = action.hits[0];
                found = true;
            }
        }

        if (initiative_order.length == 0){
            initiative_order.push(action);
        }
        else if (!found) {
            var current_round = Math.floor(current_turn/initiative_order.length);
            var current_turn_in_round = current_turn % initiative_order.length;

            initiative_order.push(action);
            initiative_order.sort(function(a,b){return b['initiative_roll'].total - a['initiative_roll'].total});
            var new_initiative_index = initiative_order.indexOf(action);
            if (new_initiative_index <= current_turn_in_round){
                current_turn += 1;
            }

            current_turn += current_round;
        }

        if (current_turn < 0){
            current_turn = 0;
        }

        this.setState({
            current_turn: current_turn,
            initiative_order:initiative_order,
        });

        //If the DM, save initiative order to the database
        if(this.props.dm) {
            var data = {
                current_turn: current_turn,
                initiative_order: initiative_order
            };
            ajaxWrapper('POST','/api/home/adventuringgroup/' + this.props.group.id + '/', data, console.log);
        }
    }

    get_initiative_order(result) {
        console.log("INI Result", result);
        if (result.length > 0) {
            this.setState({initiative_order:result[0]['adventuringgroup']['initiative_order']});
        }
    }

    clear_initiative_order() {
        //this.setState({initiative_order:[]})
        ajaxWrapper('POST','/api/home/adventuringgroup/' + this.props.group.id + '/', {initiative_order: [], current_turn:0}, console.log);
        this.state.chat_socket.send_message({'message':{'clear_initiative_order':{}}})
    }

    roll_for_initiative() {
        var monster_ids = [];
        for (var icon of window.cmState.icon_list) {
            var monster = icon.monster;

            if (!icon.player_controlled) {
                if (icon.name != icon.monster.name || monster_ids.indexOf(icon.monster.id) == -1) {
                    console.log("Monster Name", icon.monster.name);
                    var modifier = stat_modifier(monster['dexterity']);
                    if (modifier < 0) {
                        var roll_result_one = parse_roll("1d20 - " + modifier);
                        var roll_result_two = parse_roll("1d20 - " + modifier);
                    }
                    else {
                        var roll_result_one = parse_roll("1d20 + " + modifier);
                        var roll_result_two = parse_roll("1d20 + " + modifier);
                    }

                    if (icon.name == icon.monster.name) {
                        console.log("Monster Name", icon.monster.name);
                        console.log("Monster Id Index", icon.monster.id, monster_ids.indexOf(icon.monster.id));
                        console.log("Monster Ids", monster_ids);
                        monster_ids.push(icon.monster.id);
                    }
                    this.props.use_action({
                        instance_id: icon.id,
                        hits: [roll_result_one, roll_result_two],
                        damages: [],
                        action: {'name': 'initiative'}
                    });
                }
            }

        }
        this.props.ask_for_roll('initiative');
    }

    next_turn() {
        var current_turn = this.state.current_turn + 1;
        ajaxWrapper('POST','/api/home/adventuringgroup/' + this.props.group.id + '/', {current_turn: current_turn}, console.log);
        this.state.chat_socket.send_message({'message':{'current_turn': current_turn}});
        this.setState({current_turn: current_turn});
    }

    prev_turn() {
        var current_turn = this.state.current_turn - 1;
        if (current_turn < 0){
            current_turn = 0;
        }

        ajaxWrapper('POST','/api/home/adventuringgroup/' + this.props.group.id + '/', {current_turn: current_turn}, console.log);
        this.state.chat_socket.send_message({'message':{'current_turn': current_turn}});
        this.setState({current_turn: current_turn});
    }

    toggle_edit() {
        for (var temp_initiative of this.state.new_initiatives) {
            this.props.use_action({
                instance_id: temp_initiative[0].instance_id,
                hits: [{'total':temp_initiative[1]}, {'total':temp_initiative[1]}],
                damages: [],
                action: {'name': 'initiative'}
            });
        }

        this.setState({
            edit: !this.state.edit,
            new_initiatives: []
        });
    }

    set_new_initiative(initiative, new_initiative) {
        var new_initiatives = this.state.new_initiatives;
        var found = false;
        for (var new_initiative of new_initiatives) {
            if (new_initiative[0] == initiative) {
                new_initiative = [initiative, new_initiative];
                found = true;
                break;
            }
        }
        if (!found) {
            new_initiatives.push([initiative, new_initiative]);
        }
        this.setState({new_initiatives: new_initiatives});
    }

    render() {
        var width = this.state.width;
        var icon_style = {height:'50px', width:'50px'};
        var button_style = {paddingTop: '10px'};
        if (width < 1200) {
            icon_style = {height:'30px', width:'30px'};
            button_style = {};
        }

        var container_width = 0;
        if (this.initiative_container.current) {
            container_width = this.initiative_container.current.getBoundingClientRect().width;
        }
        var container_left = (width - container_width) / 2;

        var current_turn = this.state.current_turn;

        var instances = [];
        var extra_instances = [];

        var initiative_length = 0;
        if (this.state.initiative_order) {
            var initiative_length = this.state.initiative_order.length;
        }

        if (initiative_length > 0) {
            for (var item of this.state.initiative_order) {
                var initiative_index = this.state.initiative_order.indexOf(item);
                var initiative = this.state.initiative_order[(current_turn + initiative_index) % initiative_length];

                if (window.cmState.instances) {
                    var instance = window.cmState.global_state_lookup(this, 'instances', initiative.instance_id);
                    if (!(instance)){
                        console.log('ERROR IN INITIATIVE', initiative.instance_id);
                        continue;
                    }

                    if (!(this.props.dm) && !(instance.share_with_players)) {
                        continue;
                    }

                    var instance_jsx = <InitiativeDisplay
                        edit={this.state.edit}
                        initiative={initiative}
                        select_monster_instance={this.props.select_monster_instance}
                        dm={this.props.dm}
                        set_new_initiative={this.set_new_initiative}
                    />;

                    if (instances.length < 5){
                        instances.push(instance_jsx);
                    }
                    else {
                        extra_instances.push(instance_jsx);
                    }
                }

            }
        }
        else {
            var monster_ids = [];
            if (window.cmState.icon_list) {
                for (var icon of window.cmState.icon_list) {
                    var monster = icon.monster;

                    if (icon.player_controlled) {
                        if (icon.name != icon.monster.name || monster_ids.indexOf(icon.monster.id) == -1) {
                            if (icon.name == icon.monster.name) {
                                monster_ids.push(icon.monster.id);
                            }

                            var instance_jsx = <InitiativeDisplay initiative={null} icon={icon}
                                select_monster_instance={this.props.select_monster_instance} />;

                            if (instances.length < 5){
                                instances.push(instance_jsx);
                            }
                            else {
                                extra_instances.push(instance_jsx);
                            }
                        }
                    }
                }
            }
        }

        var buttons = [];
        var left_buttons = [];

        if (this.props.dm) {

            if (this.state.initiative_order && this.state.initiative_order.length > 0) {
                buttons.push(<Button type='secondary' text='<' onClick={this.prev_turn} />);
                buttons.push(<Button type='primary' text='>' onClick={this.next_turn} />);

                if (this.state.edit) {
                    buttons.push(<Button type='success' text={<i class='fas fa-save' />} onClick={this.toggle_edit} />);
                }
                else {
                    buttons.push(<Button type='success' text={<i class='fas fa-edit' />} onClick={this.toggle_edit} />);
                }

                left_buttons.push(<Button type='danger' text='X' onClick={this.clear_initiative_order} />);
            }
            else {
                var roll_text = [
                    <i class="fas fa-bolt"></i>,
                    ' Roll For Initiative'
                ];
                buttons.push(<Button style={{margin:'0px'}} type='outline-danger' text={roll_text} onClick={this.roll_for_initiative} />);
            }
        }

        var round = null;
        if (this.state.initiative_order && this.state.initiative_order.length > 0) {
            var round_num = Math.floor(current_turn/this.state.initiative_order.length) + 1;
            round = <div style={{textAlign:'center'}}>
                <p style={{fontSize:'12px', margin:'auto', backgroundColor:'white', width:'auto', display: 'inline-block', borderRadius:'0px 0px 10px 10px', padding: '0px 8px'}}>Battle: Round {round_num}</p>
            </div>;
        }

        return (
            <div ref={this.initiative_container} style={{position:'absolute', zIndex: 1010, top:'0px', left: container_left + 'px'}}
                onKeyDown={this.props.handle_key_press} tabIndex="0" >
                <div className='initiative_container'>
                    <div style={Object.assign({float:'left'}, button_style)} >
                        {left_buttons}
                    </div>
                    <div style={Object.assign({float:'right'}, button_style)} >
                        {buttons}
                    </div>

                    <div style={{display:'flex'}}>{instances}</div>

                    <div className='initiative-extra-icons'>{extra_instances}</div>

                </div>
                {round}
            </div>
        )
    }
}
