import {ajaxWrapper} from 'functions';
import update_monster from 'projectLibrary/session_tools/global_data/update_monster.js';

function update_monster_from_data(monster, data) {
    for (var key in data) {
        if (key.endsWith('[]')) {
            if (key == 'action_spells[]' || key == 'prepared_spells[]') {
                var temp_list = [];
                for (var item_id of data[key]) {
                    var item = {'actionspell': window.cmState.global_state_lookup(this, 'actions', item_id)};
                    temp_list.push(item);
                }
            }
            var monster_key = key.replace('[]','');
            monster[monster_key] = temp_list;
        }
        else {
            monster[key] = data[key];
        }
    }

    return monster;
}

function save_monster(data) {
    console.log("Save Monster", data)
    var id = null;
    if (data['id']) {
        id = data['id']
        delete data['id'];
    }

    //monster exists
    if (id) {
        var url = '/api/compendium/monster/' + id + '/'
        var monster = window.cmState.global_state_lookup(this, 'monsters', id);
        console.log("Monster Exists", monster);
        var updated_monster = update_monster_from_data(monster, data);
        console.log("Updated Monster", updated_monster);
        update_monster(updated_monster);
        ajaxWrapper('POST', url, data, console.log)
    }
    //monster doesn't exist
    else {
        var url = '/api/compendium/monster/'
        ajaxWrapper('POST', url, data, load_monster)
    }
}

function load_monster(result) {
    var monster = result[0].monster;
    console.log("Save Monster Callback", monster)
    update_monster(monster);
}

export default save_monster;
