import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';

export default class User extends React.Component {
    constructor(props) {
        super(props);

        this.state = {'user':[], loaded: false}

        this.user_callback = this.user_callback.bind(this);
    }

    componentDidMount() {
        if (this.props.user_id) {
            ajaxWrapper('GET','/api/user/user/' + this.props.user_id + '/', {}, this.user_callback)
        }
        else {
            this.setState({loaded:true})
        }
    }

    user_callback(result) {
        var user = result[0]['user']
        this.setState({'user':user, loaded:true});
    }

    render() {

        var submitUrl = '/api/user/user/';
        var deleteUrl = null;
        if (this.props.user_id) {
            submitUrl = '/api/user/user/' + this.props.user_id + '/';
            deleteUrl = '/api/user/user/' + this.props.user_id + '/delete/';
        }

        var content = <div className="container">
            <div>
                <h3>User</h3>
                <FormWithChildren submitUrl={submitUrl} deleteUrl={deleteUrl} redirect={'/users/'} defaults={this.state.user}>
                    <TextInput name='email' label='Email' />
                    <Select name='is_staff' label='Is Staff?' boolean={true} />
                </FormWithChildren>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
