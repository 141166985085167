import React, { Component } from 'react';
import {resolveVariables, ajaxWrapper, resolve_all_children} from 'functions';
import {Wrapper, NumberInput, TextInput, TextArea, CSSInput, Json_Input, Select} from 'library';

class SetGlobalState extends Component {
    static component_name = 'SetGlobalState';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'dataUrl'} name={'dataUrl'} />,
                <TextInput label={'object name'} name={'objectName'} />,
                <TextInput label={'global state name'} name={'globalStateName'} />,
                <Json_Input label={'dataMapping'} name={'dataMapping'} />,
            ],
            can_have_children: true,
        }

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.no_object_found = this.no_object_found.bind(this);
    }

    componentDidMount() {
        var dataUrl = resolveVariables({'dataUrl':this.props.dataUrl}, window.cmState.getGlobalState())['dataUrl'];
        ajaxWrapper("GET", dataUrl, {}, this.ajaxCallback, this.no_object_found);
    }

    ajaxCallback(result) {
        var componentData = result;
        if (result[0] && this.props.objectName && this.props.objectName in result[0]) {
            componentData = result[0][this.props.objectName];
        }

        if (this.props.dataMapping) {
            componentData = resolveVariables({'props':this.props.dataMapping}, componentData)['props'];
        }

        window.cmState.setGlobalState(this.props.globalStateName, componentData, true)
    }

    no_object_found(value) {
        var componentData = {};
        window.cmState.setGlobalState(this.props.globalStateName, componentData, false)
    }

    render() {

        return (
            null
        );
    }
}

export default SetGlobalState;
