import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { NumberInput, FormWithChildren, Button, TextInput, Select } from 'library';

import CreateResource from 'projectLibrary/monsters/fancy_char_sheet/create_resource.js';
import Action from 'projectLibrary/monsters/action.js';


export default class ResourceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_resource: false,
            show_create_new_resource: false
        };

        this.update = this.update.bind(this);
    }

    componentDidMount(){

    }

    update(name, state){
        console.log('Resource Update ', name, state);

        var resource_id = name.replace('resource_', '');
        if ('current' in state){
            var new_instance_resources = [];
            var instance = this.props.instance;
            for (var item of instance.monster_instance_resources) {
                var resource = item.monsterinstanceresource;
                if (resource.resource_id == resource_id){
                    var id = resource.id;
                    var url = '/api/home/MonsterInstanceResource/'+ id +'/';
                    ajaxWrapper('POST', url, {quantity: state['current']}, console.log);

                    resource.quantity = state['current'];
                }

                new_instance_resources.push({monsterinstanceresource: resource});
            }

            this.props.update_instance(name, {monster_instance_resources: new_instance_resources}, true);
        }
        else if ('max' in state){
            var new_monster_resources = [];
            var monster = this.props.monster;
            for (var item of monster.monster_resources) {
                var resource = item.monsterresource;
                if (resource.resource_id == resource_id){
                    var id = resource.id;
                    var url = '/api/compendium/MonsterResource/'+ id +'/';
                    ajaxWrapper('POST', url, {quantity: state['max']}, console.log);

                    resource.quantity = state['max'];
                }

                new_monster_resources.push({monsterresource: resource});
            }

            this.props.update_monster(name, {monster_resources: new_monster_resources}, true);
        }
    }

    render (){
        var instance = this.props.instance;
        var monster = this.props.monster;

        //finding usable resources
        var usable_resources = {}
        for (var item of monster.monster_resources) {
            var monster_resource = item.monsterresource;
            var resource = window.cmState.global_state_lookup(this, 'resources', monster_resource.resource_id);
            if (resource && resource.name.indexOf('Spell Slot Level') == -1) {
                usable_resources[monster_resource.resource_id] = {
                    max: monster_resource.quantity,
                    current:0,
                    name: resource.name,
                    actions:[],
                    resource_details: resource,
                    monster_resource_id: monster_resource.id
                }
            }
        }

        for (var item of instance.monster_instance_resources) {
            var instance_resource = item.monsterinstanceresource;
            var resource_name = window.cmState.global_state_lookup(this, 'resources', instance_resource.resource_id).name;
            if (resource_name && resource_name.indexOf('Spell Slot Level') == -1) {
                usable_resources[instance_resource.resource_id]['current'] = instance_resource.quantity;
                usable_resources[instance_resource.resource_id]['instance_resource_id'] = instance_resource.id;
            }
        }

        var resources = [];
        for (var key in usable_resources){
            var resource = usable_resources[key];
            var resource_jsx = <div className='col-6' ><FormWithChildren className='resource' setGlobalState={this.update} autoSetGlobalState={true} globalStateName={'resource_'+ key} defaults={resource} >
                <div style={{display:'inline-block', paddingLeft: '5%'}}>Max: </div>
                <NumberInput className='resource-max' sneaky_input={true} name={'max'} />
                <NumberInput className='resource-current' sneaky_input={true} name={'current'} />
                <div className='bottom-label'>{resource.name}</div>
            </FormWithChildren></div>;

            resources.push(resource_jsx);
        }

        resources.push(<CreateResource exclude={'Spell Slot Level'}
            monster={this.props.monster} instance={this.props.instance}
            update_monster={this.props.update_monster} update_instance={this.props.update_instance} />);

        return (
            resources
        );
    }
}
