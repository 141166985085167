import React, { Component } from 'react';
import {Wrapper, Navbar, NavLink, If} from 'library';


class GenericNavbar extends Component {

    render() {
        var logo = 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_41_26_icon_monster.jpg';

        var style = {
            boxShadow: '2px 2px 10px rgba(0,0,0,.1)',
            backgroundColor: 'white',
            marginBottom: '10px',
        };

        return (<div>
			<If anti_logic={[['exists', '{user.id}']]} >
				<Navbar style={style} logo={logo} name={"Forgotten Maps"} name_link={"/"} >
					<NavLink href={"/"} name={"HOME"} />
					<NavLink href={"/sword_coast"} name={"MAPS"} />
					<NavLink href={"/monster_list"} name={"MONSTERS"} />
					<NavLink href={"/login/"} name={"LOG IN"} />
				</Navbar>
			</If>

			<If logic={[['exists', '{user.id}'], ['{user.is_staff}', 'false']]} override={false} >
				<Navbar style={style} logo={logo} name={"Forgotten Maps"} name_link={"/"} >
					<NavLink href={"/dashboard/"} name={"HOME"} />
                    <NavLink href={"/my_groups/"} name={"My Groups"} />
                    <NavLink href={"/my_locations/"} name={"My Locations"} />
					<NavLink href={"/logout/"} name={"LOG OUT"} />
				</Navbar>
			</If>

			<If logic={[['exists', '{user.id}'], ['{user.is_staff}', 'true']]} >
				<Navbar style={style} logo={logo} name={"Forgotten Maps"} name_link={"/"} >
					<NavLink href={"/dashboard/"} name={"HOME"} />
                    <NavLink href={"/my_groups/"} name={"My Groups"} />
                    <NavLink href={"/my_locations/"} name={"My Locations"} />
                    <NavLink href={"/dm_tools/"} name={"DM Tools"} />
					<NavLink href={"/logout/"} name={"LOG OUT"} />
				</Navbar>
			</If>
        </div>);
    }
}

export default GenericNavbar;
