import React, { Component } from 'react';
import { NumberInput, FormWithChildren } from 'library';

import Action from 'projectLibrary/monsters/action.js';


export default class ActionList extends React.Component {
    render (){
        var instance = this.props.instance;
        var monster = this.props.monster;

        var costly_actions = [];
        var special_abilities = [];
        var innate_spells = [];
        var actions = [];
        var spell_list = [];

        var props_to_pass = {
            instance: instance,
            use_action: this.props.use_action,
            push_modal_content: this.props.push_modal_content,
            add_action_token: this.props.add_action_token,
        };

        for (var item of monster.action_spells) {
            var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);
            props_to_pass['action'] = action;

            if (!(action.action_damage[0]) || Object.keys(action.action_damage[0]) == 0) {
                continue;
            }

            if (action.action_type == 'Ability') {
                special_abilities.push(
                    <Action {...props_to_pass} />
                );
            }
            else if (action.action_type == 'Attack'){
                if (action.name == "Multiattack") {
                    actions.unshift(
                        <Action {...props_to_pass} />
                    );
                }
                else {
                    actions.push(
                        <Action {...props_to_pass} />
                    );
                }
            }
            else {
                spell_list.push(action);
            }
        }

        for (var item of monster.equipped_items) {
            var equipment = window.cmState.global_state_lookup(this, 'equipment', item.equippeditem.equipment_id);

            if (equipment) {
                for (var item of equipment.actions) {
                    var equipment_action_cost = item['equipmentactioncost'];
                    var action = window.cmState.global_state_lookup(this, 'actions', equipment_action_cost.action_spell_id);
                    props_to_pass['action'] = action;

                    if (action.action_damage[0] && Object.keys(action.action_damage[0]) == 0) {
                        continue;
                    }

                    if (action.action_type == 'Ability') {
                        special_abilities.push(
                            <Action {...props_to_pass} />
                        );
                    }
                    else if (action.action_type == 'Attack'){
                        if (action.name == "Multiattack") {
                            actions.unshift(
                                <Action {...props_to_pass} />
                            );
                        }
                        else {
                            actions.push(
                                <Action {...props_to_pass} />
                            );
                        }
                    }
                    else {
                        spell_list.push(action);
                    }
                }
            }
        }

        return (
            <div class="property-block">
                {actions}
                {special_abilities}
                {costly_actions}
                {innate_spells}
            </div>
        );
    }
}
