import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {FormWithChildren, TextInput, Alert} from 'library';
import settings from 'base/settings.js';

class Subscribe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form_callback: null,
            subscribed:false,
            error: ''
        };

        this.subscribe = this.subscribe.bind(this);
    }

    subscribe(state, callback) {
        if (state['email']){
            this.setState({form_callback: callback});
            ajaxWrapper('POST','/api/home/subscriber/', {'email': state['email']}, this.callback);
        }
        else {
            callback();
        }
    }

    callback(){
        this.form_callback();
        this.setState({subscribed: true});
    }

    render () {
        var subscribed = null;
        if (this.state.subscribed) {
            subscribed = <Alert text="Thank you for subscribing!" type='success' />
        }

        return (
            <div>
                <FormWithChildren submit={this.subscribe} submit_text={'Sign Up For The Beta'} submitClassName='btn-subscribe'>
                    <TextInput className='input-subscribe' placeholder='Email' name='email' />
                </FormWithChildren>
                {subscribed}
            </div>
        )
    }

}


export default Subscribe;
