import React, { Component } from 'react';
import $ from 'jquery';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, CSSInput, Button} from 'library';

class CopyToClipboard extends React.Component {
    static component_name = 'CopyToClipboard';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default={'Default Text'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
        }

        this.myRef = React.createRef();

        this.copy_to_clipboard = this.copy_to_clipboard.bind(this);
    }

    copy_to_clipboard(){
        /* Select the text field */
        this.myRef.current.select();
        /* Copy the text inside the text field */
        document.execCommand("copy");
    }

    render() {
        var input_style = {
            background: '#eee',
            maxWidth: '400px',
            display: 'inline-block',
        }

        return (
            <p style={this.props.style || {}}>
                <input style={input_style} className="form-control" type="text" ref={this.myRef} value={this.props.text} />
                <Button style={{marginLeft:"5px"}} text={'Copy'} onClick={this.copy_to_clipboard} type='default' />
            </p>
        );
    }
}

export default CopyToClipboard;
