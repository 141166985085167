import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {Select} from 'library';

import {
  browserName,
} from "react-device-detect";

var display_options = [
    {'text':'Chrome', value:'Chrome'},
    {'text':'Firefox', value:'Firefox'},
    {'text':'Safari', value:'Safari'},
    {'text':'Opera', value:'Opera'},
    {'text':'IE', value:'IE'},
    {'text':'Edge', value:'Edge'},
    {'text':'Yandex', value:'Yandex'},
    {'text':'Chromium', value:'Chromium'},
    {'text':'Mobile Safari', value:'Mobile Safari'},
]

class BrowserType extends React.Component {
    static component_name = 'BrowserType';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <Select label={'Browser Name'} name={'browser_name'} options={display_options} multiple={true} />,
            ],
            can_have_children: true,
        }
    }


    render() {

        if (browserName == this.props.browser_name) {
            return (this.props.children);
        }
        else {
            return (null);
        }
    }
}


export default BrowserType;
