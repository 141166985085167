import {ajaxWrapper} from 'functions';
import create_icon_list from 'projectLibrary/session_tools/global_data/create_icon_list.js';


const result_map = {
    instances: 'monsterinstance',
    monsters: 'monster',
    equipment: 'equipment',
    actions: 'actionspell',
    action_instances: 'actioninstance',

    locations: 'location',
    location_permissions: 'grouplocationpermission',
    location_notes: 'grouplocationnote',
};

function load_adventure_data(result) {
    for (var key in result_map){
        var object_dict = {};

        var object_name = result_map[key];

        for (var item of result[key]) {
            var item = item[object_name];
            object_dict[item.id] = item;
        }

        window.cmState.setGlobalState(key, object_dict);
    }

    create_icon_list();
}

export default load_adventure_data;
