import React, { Component } from 'react';
import {resolveVariables, ajaxWrapper, resolve_all_children} from 'functions';
import {Wrapper, NumberInput, TextInput, TextArea, CSSInput, Json_Input} from 'library';

class Instance extends Component {
    static component_name = 'Instance';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} />,
                <TextInput label={'dataUrl'} name={'dataUrl'} />,
                <TextInput label={'object name'} name={'objectName'} />,
                <Json_Input label={'dataMapping'} name={'dataMapping'} />,
                <TextInput label={'noDataMessage'} name={'noDataMessage'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.state = {
            componentData: null,
            loaded:false,
        };

        this.ajaxCallback = this.ajaxCallback.bind(this);
        this.refreshData = this.refreshData.bind(this);
        this.no_object_found = this.no_object_found.bind(this);
    }

    componentDidMount() {
        //Subscribe to all variables in dataUrl
        window.cmState.subscribe(this, this.props.dataUrl);

        this.refreshData();
    }

    componentWillUnmount() {
        window.cmState.unsubscribe(this, this.props.dataUrl);
    }

    refreshData() {
        var dataUrl = resolveVariables({'dataUrl':this.props.dataUrl}, window.cmState.getGlobalState())['dataUrl'];
        dataUrl = resolveVariables({'dataUrl':dataUrl}, {props: this.props})['dataUrl'];
        
        ajaxWrapper("GET", dataUrl, {}, this.ajaxCallback, this.no_object_found);
    }

    ajaxCallback(result) {
        var componentData = {};
        if (result[0] && this.props.objectName && this.props.objectName in result[0]) {
            componentData = result[0][this.props.objectName];
        }

        if (this.props.dataMapping) {
            componentData = resolveVariables(this.props.dataMapping, componentData);
        }

        this.setState({componentData:componentData, loaded:true});
    }

    no_object_found(value){
        var componentData = {};
        this.setState({componentData:componentData, loaded:true});
    }

    render() {
        var content = [];

        if (this.state.loaded) {
            var data = this.state.componentData;
            data['refreshData'] = this.refreshData;
            data['setGlobalState'] = this.props.setGlobalState;
            content = resolve_all_children(this, data);
        }

        return (
            <Wrapper className={this.props.className || this.props.class} loaded={this.state.loaded} content={content} />
        );
    }
}

export default Instance;
