import React, { Component } from 'react';
import { ajaxWrapper, sort_objects } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select,
    CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import replace_roll_vars from 'projectLibrary/monsters/replace_roll_vars.js';
import ActionUse from 'projectLibrary/monsters/action_use.js';
import ActionCard from 'projectLibrary/action_card.js';
import SpellCard from 'projectLibrary/spell_card.js';
import save_monster from 'projectLibrary/session_tools/global_data/save_monster.js';
import update_monster from 'projectLibrary/session_tools/global_data/update_monster.js';

export default class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {roll: null, view_action: false}

        this.use = this.use.bind(this);
        this.view_action = this.view_action.bind(this);
        this.add_token = this.add_token.bind(this);
        this.add_prepared_spell = this.add_prepared_spell.bind(this);
        this.remove_prepared_spell = this.remove_prepared_spell.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name('selected_monster_instance');
    }

    view_action() {
        if (this.props.action.action_type != 'Spell') {
            this.props.push_modal_content(<ActionCard action={this.props.action} />);
        }
        else {
            this.props.push_modal_content(<SpellCard spell={this.props.action} />);
        }
    }


    add_prepared_spell() {
        var spell = this.props.action;
        var monster = window.cmState.global_state_lookup(this, 'monsters', window.cmState.selected_monster_instance.monster_id);
        var prepared_spells = [];
        for (var item of monster.prepared_spells) {
            prepared_spells.push(item.actionspell.id);
        }
        prepared_spells.push(spell.id)

        save_monster({'id':monster.id, 'prepared_spells[]': prepared_spells});
    }

    remove_prepared_spell() {
        var spell = this.props.action;
        var monster = window.cmState.global_state_lookup(this, 'monsters', window.cmState.selected_monster_instance.monster_id);
        var prepared_spells = [];
        for (var item of monster.prepared_spells) {
            if (item.actionspell.id != spell.id) {
                prepared_spells.push(item.actionspell.id);
            }
        }

        save_monster({'id':monster.id, 'prepared_spells[]': prepared_spells});
    }

    use() {
        var action = this.props.action;
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
        var equipment = null;
        if (this.props.equipment){
            equipment = this.props.equipment;
        }
        else if (this.props.equipment_instance){
            equipment = window.cmState.global_state_lookup(this, 'equipment', this.props.equipment_instance.equipment_id);
        }

        var hits = null;

        if (action.has_attack_roll) {
            var attack_roll_bonus = replace_roll_vars(action.attack_roll.attack_roll, monster);

            if (equipment) {
                for (var item of equipment.magical_attributes) {
                    var magic_attribute = item.magicalattribute;
                    if (magic_attribute.additional_attack_roll != '') {
                        attack_roll_bonus += ' ' + magic_attribute.additional_attack_roll;
                    }
                }
            }

            console.log("1d20 " + attack_roll_bonus)
            var hit_one = parse_roll("1d20 " + attack_roll_bonus);
            var hit_two = parse_roll("1d20 " + attack_roll_bonus);
            hits = [hit_one, hit_two]
        }

        var spell_save_dc = null;
        if (action.has_dc) {
            console.log("Spell Save", action.action_dc.dc_roll, replace_roll_vars(action.action_dc.dc_roll, monster));
            var spell_save_dc = 8 + parse_roll(replace_roll_vars(action.action_dc.dc_roll, monster))['total'];
        }

        var damages = [];

        var cast_level = this.props.cast_level || action.level;

        for (var key_that_should_be_index in action.action_damage) {
            var damage = action.action_damage[key_that_should_be_index];

            if (damage.damage_scaling) {
                if (damage.damage_scaling == 'cast_level') {

                    var damage_roll = damage.damage_by_level[cast_level];


                    if (damage_roll.damage_roll) {
                        damage_roll = damage_roll.damage_roll;
                    }
                }
                else if (damage.damage_scaling == 'level') {
                    var last_key = -1;
                    var damage_by_level = []
                    for (var key in damage.damage_by_level) {
                        damage_by_level.push({level: parseInt(key), roll: damage.damage_by_level[key]})
                    }
                    damage_by_level = sort_objects(damage_by_level, 'level', true)

                    var damage_roll = null;
                    for (var level of damage_by_level) {
                        var index = damage_by_level.indexOf(level);

                        if (last_key == -1) {
                            last_key = index;
                        }
                        if (level['level'] > monster.level) {
                            var damage_roll = damage_by_level[last_key]['roll']
                            break;
                        }
                        else {
                            last_key = index;
                        }
                    }
                    if (!damage_roll) {
                        damage_roll = damage_by_level[last_key]['roll'];
                    }
                    if (damage_roll.damage_roll) {
                        damage_roll = damage_roll.damage_roll;
                    }
                }
                else {
                    var damage_roll = damage.damage_roll;
                }

                damages.push({
                    damage:parse_roll(replace_roll_vars(damage_roll, monster)),
                    type: damage.damage_type,
                    note:damage.note
                })

                if (equipment) {
                    for (var item of equipment.magical_attributes) {
                        var magic_attribute = item.magicalattribute;

                        if (magic_attribute.additional_attack_damage != '') {
                            damages.push({
                                damage:parse_roll(replace_roll_vars(magic_attribute.additional_attack_damage, monster)),
                                type: magic_attribute.additional_attack_damage_type,
                                note:magic_attribute.name
                            });
                        }
                    }
                }
            }
        }

        var action_use =  {
            hits: hits,
            dc: spell_save_dc,
            damages: damages,
            action_id: action.id,
            instance_id: this.props.instance.id,
            cast_level: cast_level,
            equipment_instance: this.props.equipment_instance,
            equipment: this.props.equipment,
        };

        if (action.area_of_effect.type && this.props.mini) {
            this.add_token();
        }

        this.props.use_action(action_use);
    }

    add_token() {
        if (this.props.add_action_token){
            this.props.add_action_token(this.props.action, this.props.instance, this.props.cast_level);
        }
    }

    render() {
        var equipment_style= {
            fontSize: '.8em',
            display: 'inline-block',
            fontWeight: 'initial',
            background: 'rgba(255,255,255,.5)',
            padding: '2px 6px',
            border: 'thin solid #ccc',
            borderRadius: '10px',
            margin: '0px 0px 0px 10px',
            position: 'relative',
            top: '-1px',
        };

        var action = this.props.action;
        var instance = this.props.instance;
        var monster = null;
        if (instance){
            monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
        }

        var action_name = action.name;

        if (this.props.equipment_instance) {
            var equipment = window.cmState.global_state_lookup(this, 'equipment', this.props.equipment_instance.equipment_id);
            action_name = equipment.name + ': ' + action_name;
            for (var item of equipment.magical_attributes) {
                var magic_attribute = item.magicalattribute;
                action_name += ' ' + magic_attribute.name
            }
        }

        if (action.spell_specifics && action.spell_specifics.ritual_cast) {
            if (action.spell_specifics.concentration) {
                action_name = <b><i>{action_name}</i></b>;
            }
            else {
                action_name = <i>{action_name}</i>;
            }

        }
        else if (action.spell_specifics && action.spell_specifics.concentration) {
            action_name = <b>{action_name}</b>;
        }



        if (this.props.mini) {
            if (action.action_type == 'Spell') {
                return(
                    <span>
                        <a onClick={this.use} style={{paddingRight:'4px'}} className="rollable" onContextMenu={this.view_action}>{action_name},</a>
                    </span>
                )
            }
            else {
                //dms need the little text, characters don't
                var description = null;
                if (this.props.instance && !monster.character) {
                    description = <p>{action.description}</p>;
                }

                var equipment_name = null;
                if (this.props.equipment){
                    equipment_name = <div style={equipment_style} >{this.props.equipment.name}</div>;
                }

                return (
                    <div style={{marginBottom:'5px'}}>
                        <h4><a className="rollable" onContextMenu={this.view_action} onClick={this.use}>{action_name}</a> {equipment_name}</h4> {description}
                    </div>
                )
            }
        }
        else {
            if (action.action_type == 'Spell') {
                if (window.cmState.selected_monster_instance) {
                    var monster = window.cmState.global_state_lookup(this, 'monsters', window.cmState.selected_monster_instance.monster_id);
                    var found = false;
                    for (var item of monster.prepared_spells) {
                        var prepared_spell = item.actionspell;
                        if (prepared_spell.id == action.id) {
                            found = true;
                            break;
                        }
                    }
                }


                if (action.level == 0) {
                    return(
                        <div className='large-action row'>
                            <div className='col-6' ><p><a className="rollable" onClick={this.use}><b>{action_name}</b></a></p></div>
                            <div className='col-3' ><Button className='view' type='info' text='View' onClick={this.view_action} /></div>
                        </div>
                    )
                }
                else if (found) {
                    return(
                        <div className='large-action row'>
                            <div className='col-6' ><p><a className="rollable" onClick={this.use}><b>{action_name}</b></a></p></div>
                            <div className='col-3' ><Button className='view' type='info' text='View' onClick={this.view_action} /></div>
                            <div className='col-3' ><Button className='prepare' type='danger' text='Un-Prepare' onClick={this.remove_prepared_spell} /></div>
                        </div>
                    )
                }
                else {
                    return(
                        <div className='large-action row'>
                            <div className='col-6' ><p><a className="rollable" onClick={this.use}><b>{action_name}</b></a></p></div>
                            <div className='col-3' ><Button className='view' type='info' text='View' onClick={this.view_action} /></div>
                            <div className='col-3' ><Button className='prepare' type='success' text='Prepare' onClick={this.add_prepared_spell} /></div>
                        </div>
                    )
                }

            }
            else {
                return(
                    <div className='large-action row'>
                        <div className='col-6' ><p><a className="rollable" onClick={this.use}><b>{action_name}</b></a></p></div>
                        <div className='col-6' ><Button className='view' type='info' text='View Action' onClick={this.view_action} /></div>
                    </div>
                )
            }


        }

    }
}
