import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {TextInput, FormWithChildren, TextArea, Paragraph, Sidebar,
    Select, ImageInput, NumberInput, Button, Header, Image, Alert} from 'library';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import save_location from 'projectLibrary/session_tools/global_data/save_location.js';
import remove_location from 'projectLibrary/session_tools/global_data/remove_location.js';
import save_permission from 'projectLibrary/session_tools/global_data/save_permission.js';
import save_note from 'projectLibrary/session_tools/global_data/save_note.js';

class Note extends React.Component {
    constructor(props) {
        super(props);

        this.toggle_private_public = this.toggle_private_public.bind(this);
    }

    toggle_private_public() {
        var private_status = false;
        if (this.props.note) {
            private_status = !this.props.note.private;
        }
        var location = this.props.note.location_id || this.props.note.location
        save_note({id:this.props.note.id, private: private_status, location: location})
    }

    render() {
        var note = this.props.note;

        var button = null;
        var private_public = 'Make Private'
        if (note && note.private) {
            private_public = 'Make Public'
        }

        if (this.props.dm || (window.cmState.user && note.player == window.cmState.user.id)) {
            button = <Button type='primary' text={private_public} onClick={this.toggle_private_public} />
        }
        if (this.props.note) {
            return (
                <tr>
                    <td>{note.text}</td>
                    <td>{button}</td>
                </tr>
            )
        }
        else {
            return (null)
        }

    }

}

class LocationDetails extends React.Component {
    constructor(props) {
        super(props);

        var show_notes = false;
        if (this.props.show_notes) {
            show_notes = this.props.show_notes
        }

        this.state = {
            notes: [],
            permission: null,
            saved: false,
            show_notes: show_notes,
            edit: false,
        }

        this.toggle_move_marker = this.toggle_move_marker.bind(this);
        this.new_loc = this.new_loc.bind(this);
        this.location_callback = this.location_callback.bind(this);
        this.notes_callback = this.notes_callback.bind(this);

        this.refresh_data = this.refresh_data.bind(this);
        this.toggle_share = this.toggle_share.bind(this);
        this.permission_callback = this.permission_callback.bind(this);
        this.hide_location_from_group = this.hide_location_from_group.bind(this);
        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
        this.travel = this.travel.bind(this);
        this.toggle_edit = this.toggle_edit.bind(this);
        this.save_note = this.save_note.bind(this);

        this.center_map = this.center_map.bind(this);
    }

    componentDidMount() {
        this.refresh_data();

        window.cmState.subscribe_by_name(this, 'locations');
        window.cmState.subscribe_by_name(this, 'location_notes');
        window.cmState.subscribe_by_name(this, 'location_permissions');
    }

    save_note(state, callback) {
        console.log("Save Note", state);
        var note = {
            group: window.cmState.group.id,
            location: this.props.display_location.id,
            player: window.cmState.user.id,
            private: state['private'],
            text: state['text']
        };
        save_note(note);

        callback();
    }

    toggle_edit() {
        this.setState({edit: !this.state.edit})
    }

    toggle_share() {
        console.log("Toggle Share!");
        var permission = window.cmState.global_state_lookup(this, 'location_permissions', this.props.display_location.id);
        if (permission) {
            console.log("Found Permission", permission);
            save_permission({id:permission.id, show_players:!permission.show_players, location: this.props.display_location.id});
        }
        else {
            console.log("Did Not Find Permission");
            save_permission({
                show_players:true,
                location: this.props.display_location.id,
                group: window.cmState.group.id
            });
        }
    }

    refresh_data() {
        if (this.props.solo && this.props.display_location_id) {
            ajaxWrapper('GET','/api/home/location/' + this.props.display_location_id + '/?related=parent,children,children__tags', {}, this.location_callback)
        }
    }

    permission_callback(result) {
        if (result.length > 0) {
            this.setState({permission: result[0]['grouplocationpermission']})
        }
    }

    notes_callback(result) {
        console.log("Notes Callback", result)
        var notes = [];
        for (var item of result) {
            var note = item['grouplocationnote'];
            if (note.location_id == this.props.display_location.id) {
                notes.push(note)
            }
        }
        this.setState({notes:notes});
    }

    location_callback(result) {
        this.setState({location: result[0]['location']});
    }

    center_map(event){
        var location_details = {};
        for (var child of this.props.map_location.children){
            if (child['location']['id'] == this.props.display_location['id']){
                location_details = child['location'];
            }
        }
        this.props.center_map(event, location_details['coords']);
    }

    travel() {
        if (this.props.change_location) {
            this.props.change_location(this.props.display_location.id);
        }
        else {
            window.location.href = '/?id=' + this.props.display_location.id;
        }
    }

    new_loc(result) {
        console.log("New Loc", result);
        if (result[0]['location']['location_image'] != '') {
            window.location.href = '/?id=' + result[0]['location']['id'];
        }
        else {
            this.setState({saved:true});
        }
    }

    toggle_move_marker() {
        var mode = 'move_marker';
        if (this.props.map_mode == 'move_marker') {
            mode = 'pan';
        }
        if (this.props.set_map_mode) {
            this.props.set_map_mode(mode);
        }
		else if (this.props.setGlobalState) {
            this.props.setGlobalState('location_details', {'map_mode': mode})
        }
	}

    hide_location_from_group() {
        var permission = window.cmState.global_state_lookup(this, 'location_permissions', this.props.display_location.id);
        window.cmState.setGlobalState('selected_monster_instance', null);
        if (permission) {
            save_permission({
                id:permission.id,
                show:false,
                location: this.props.display_location.id
            });
        }
        else {
            save_permission({
                group:window.cmState.group.id,
                show:false,
                location: this.props.display_location.id
            });
        }
        //ajaxWrapper('POST','/api/home/grouplocationpermission/', {group:this.props.group, location: location['id'], show:false}, this.props.refresh_data);
    }

    submit(name, state) {
        var redirect = console.log;
        if (this.props.edit_location) {
            var redirect = this.props.edit_location;
        }

        if (this.props.solo && this.state.image != "") {
            redirect = this.new_loc;
        }

        console.log("Submit!", state);

        var location_id = this.props.location_id;
        if (this.props.display_location) {
            location_id = this.props.display_location.id;
        }

        state['id'] = location_id;
        save_location(state)
        //ajaxWrapper('POST','/api/home/location/' + location_id + '/', state, redirect);
        //callback();
    }

    delete(state) {
        console.log("Delete")
        var redirect = console.log
        if (this.props.solo) {
            redirect = this.new_loc;
        }

        var location = window.cmState.global_state_lookup(this, 'locations', this.props.display_location.id);
        if (location.group_id && location.group_id == window.cmState.group.id) {
            console.log("Remove Location");
            ajaxWrapper('POST', '/api/home/location/' + location.id + '/delete/', {}, console.log);
            delete location['parent'];
            delete location['children'];
            window.cmState.chat_socket.send_message({message:{remove_location: location}});
            //remove_location(this.props.display_location);
        }
        else {
            console.log("Hide Location From Group")
            this.hide_location_from_group();
        }
    }

    render () {
        var location = window.cmState.global_state_lookup(this, 'locations', this.props.display_location.id);
        console.log("Location Details", location);

        var wiki_links = [];
        var wiki_content = null;

        var height = window.innerHeight - 100;
        console.log("Wiki Content", this.props.session, this.props.dm, location.wiki_link);
        if ((!this.props.session || this.props.dm) && location.wiki_link && location.wiki_link != '') {
            wiki_links.push(<Button type='info' href={location.wiki_link} target='_blank' text={[<i class="fa-solid fa-up-right-from-square"></i>,'View Wiki']} />);
            wiki_links.push(<Button type='outline-info' onClick={() => this.setState({show_wiki:!(this.state.show_wiki)})} text={'Toggle Inline Wiki'} />);

            if (this.state.show_wiki){
                wiki_content = <iframe src={location.wiki_link} style={{width:'100%', height: height + 'px', border:'none'}} frameBorder="0"></iframe>;
            }
        }

        var has_permission = false;
        var current_user = window.cmState.user;
        if (current_user && (current_user.is_staff || current_user.id == location.user_who_added_id)){
            has_permission = true;
        }
        if (has_permission || (location.group_id != null && this.props.dm)) {
            if (this.props.map_location['id'] != this.props.display_location['id']){
                var move_markers_button = <Button type='warning' text='Move Marker (Drag It)' onClick={this.toggle_move_marker} />;
        		if (this.props.map_mode == 'move_marker') {
        			move_markers_button = <Button type='danger' text='Stop Moving Marker' onClick={this.toggle_move_marker} />;
        		}

                wiki_links.push(move_markers_button);
            }

            var edit_button = <Button type='warning' href={'/edit_location/'+location['id']+'/'} target='_blank' text={"Edit Details"} />;
            wiki_links.push(edit_button);
        }


        var is_on_map = this.props.map_location.id == location['parent_id'];
        var view_button = null;
        if (is_on_map) {
            view_button = <Button text={<div>
                <i class="far fa-2x fa-compass" style={{verticalAlign:'top'}}></i>
                <div style={{display:'inline-block', fontSize: '18px', paddingLeft: '12px', lineHeight: '30px'}}>View on Map</div>
            </div>} type='info' onClick={this.center_map} />;
        }
        var visit_button = null;
        if (location.location_image && this.props.map_location['id'] != this.props.display_location['id']) {
            var visit_text = <div>
                <Image src='https://mapquest-public.s3.amazonaws.com/22_05_11_19_56_55_wagon_icon.png' style={{verticalAlign: 'top', height: '30px', width:'auto'}} />
                <div style={{display:'inline-block', fontSize: '18px', paddingLeft: '12px', lineHeight: '30px'}}>Travel Here</div>
            </div>;
            var visit_button = <Button type='success' text={visit_text} onClick={this.travel} />;
            //wiki_links.unshift(visit);
        }
        var visit = <div style={{marginBottom:'10px'}}>
            {view_button}
            {visit_button}
        </div>;

        if (location.type == 'interaction') {
            if (location.location_image) {
                var details = <div>
                    <h2>{location.name || 'Untitled Location'}</h2>
                    {wiki_links}
                    {wiki_content}
                    <div className='card container' style={{margin:'5px 0px'}} >
                        <div className='row'>
                            <div class="card-header col-4">
                                <img src={location.location_image} style={{height:'auto', maxWidth: '200px'}} />
                            </div>
                            <div class="col-8">
                                <h4 class="card-title">{location.name}</h4>
                                <p class="card-text">{location.description || 'No Description'}</p>
                            </div>
                        </div>
                    </div>
                </div>;
            }
            else {
                var details = <div>
                    <h2>{location.name || 'Untitled Location'}</h2>
                    {wiki_links}
                    {wiki_content}
                    <div className='card container' style={{margin:'5px 0px'}} >
                        <div className='row'>
                            <div class="col-12">
                                <h4 class="card-title">{location.name}</h4>
                                <p class="card-text">{location.description || 'No Description'}</p>
                            </div>
                        </div>
                    </div>
                </div>;
            }
        }
        else {
            var details = <div>
                <h2>{location.name || 'Untitled Location'}</h2>
                {visit}
                {wiki_links}
                {wiki_content}
                <div className='card' style={{margin:'5px 0px'}} ><p>{location.description || 'No Description'}</p></div>
            </div>;
        }
        


        var notes = [];
        var location_notes = window.cmState.global_state_lookup(this, 'location_notes', this.props.display_location.id);
        if (location_notes) {
            for (var note of location_notes) {
                notes.push(<Note note={note} refresh_data={this.refresh_data} dm={this.props.dm} />)
            }
        }

        var tabs = null;
        if (this.props.group) {
            var details_tab = <li class="nav-item">
              <a class="nav-link active" onClick={() => this.setState({show_notes:false})} href="#">Details</a>
            </li>

            var notes_tab = <li class="nav-item">
              <a class="nav-link" onClick={() => this.setState({show_notes:true})} href="#">Notes</a>
            </li>

            if (this.state.show_notes) {
                var details_tab = <li class="nav-item">
                  <a class="nav-link" onClick={() => this.setState({show_notes:false})} href="#">Details</a>
                </li>

                var notes_tab = <li class="nav-item">
                  <a class="nav-link active" onClick={() => this.setState({show_notes:true})} href="#">Notes</a>
                </li>
            }


            var tabs = <ul class="nav nav-tabs">
                  {details_tab}
                  {notes_tab}
                </ul>;
        }

        make_public = null;
        if (this.props.dm) {
            var hide_button = <Button onClick={this.hide_location_from_group} text='Remove For Campaign' type='danger' deleteType={true} />

            var permission = window.cmState.global_state_lookup(this, 'location_permissions', location.id);
            console.log("Location Details Permission", permission)
            var make_public = [<Button text='Share With Group' type='primary' onClick={this.toggle_share} />, hide_button]
            if (permission && permission.show_players == true) {
                make_public = [<Button text='Hide From Group' type='primary' onClick={this.toggle_share} />, hide_button]
            }
        }

        var notes_section = null;
        if (this.props.group && this.state.show_notes) {
            var defaults = {
                text:'',
                private:true,
                group: this.props.group,
                player: window.cmState.user.id,
                location: this.props.display_location.id,
            };

            notes_section = <div>
            <h3>Notes</h3>
            <table className="table">
                <tr>
                    <th>Note</th>
                    <th>Make Public/Private</th>
                </tr>
                {notes}
            </table>
            <FormWithChildren submit={this.save_note} defaults={defaults}>
                <TextArea name='text' label='Note' />
                <Select name='private' options={[{'text':'Private', value:true}, {text:'Public', value:false}]} defaultoption={false} />
            </FormWithChildren>
            </div>;

            details = null;
        }

        return (
            <div className='container'>
                {make_public}
                {notes_section}
                {details}
            </div>
        )
    }
}

export default LocationDetails;
