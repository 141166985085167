
function get_instance_name(instance){
    var name = instance.name;

    var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

    if (name == ''){
        name = monster.name;
    }

    return name;
}

export default get_instance_name;
