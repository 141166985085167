import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import { Button, FormWithChildren, NumberInput } from 'library';
import EquipmentCard from 'projectLibrary/equipment_card.js';


class Item extends React.Component {
    constructor(props) {
        super(props);

        this.remove_item = this.remove_item.bind(this);
        this.change_item = this.change_item.bind(this);
    }

    change_item(name, state) {
        var item = this.props.item;
        var quantity = state['quantity'];
        this.setState({quantity: quantity});

        var monster_instance = Object.assign({},this.props.instance);
        var items = Object.assign({},);
        var new_items = [];
        for (var item of this.props.instance.equipped_items) {
            var new_item = item['instanceequippeditem'];

            if (item.id == new_item.id) {
                new_item.quantity = quantity;
            }
            new_items.push({'instanceequippeditem':new_item});
        }

        ajaxWrapper('POST','/api/home/InstanceEquippedItem/' + item.id + '/', {quantity: quantity}, console.log);

        this.props.update_instance(name, {'equipped_items': new_items}, true);
    }

    remove_item(item) {
        ajaxWrapper('POST','/api/home/InstanceEquippedItem/' + item.id + '/delete/', {}, console.log);
    }

    render() {
        var item = this.props.item;
        var name = this.props.equipment.name;

        var content = <FormWithChildren className='row' key={'item_' + item.id} autoSetGlobalState={true} setGlobalState={this.change_item} globalStateName={'item'} defaults={{'quantity': item.quantity}}>
            <div className='col-6' ><p><i class="fas fa-coins"></i><b style={{paddingLeft: '5px'}}>{name}</b></p></div>
            <div className='col-6' ><NumberInput name='quantity' /></div>
        </FormWithChildren>;

        return (content);
    }
}


class Equipment extends React.Component {
    constructor(props) {
        super(props);
        this.view = this.view.bind(this);
    }

    view() {
        this.props.push_modal_content(<EquipmentCard equipment={this.props.equipment} />);
    }

    render() {
        var equipment = this.props.equipment;

        return(
            <div className='large-action row'>
                <div className='col-6' ><p><a><b>{equipment.name}</b></a></p></div>
                <div className='col-6' ><Button className='view' type='info' text='View' onClick={this.view} /></div>
            </div>
        )
    }
}


export default class EquipmentList extends React.Component {
    render (){
        var instance = this.props.instance;
        var monster = this.props.monster;

        var equipment_list = [];
        for (var item of monster.equipped_items) {
            var equipment = window.cmState.global_state_lookup(this, 'equipment', item.equippeditem.equipment_id);

            if (equipment) {
                equipment_list.push(
                    <Equipment equipment={equipment} push_modal_content={this.props.push_modal_content} />
                );
            }
        }

        for (var item of instance.equipped_items) {
            var equipped_item = item.instanceequippeditem;
            var equipment = window.cmState.global_state_lookup(this, 'equipment', equipped_item.equipment_id);

            if (equipment && equipment.name == 'Gold') {
                equipment_list.unshift(
                    <Item item={equipped_item} equipment={equipment} instance={instance} update_instance={this.props.update_instance} />
                );
            }
        }

        return (
            equipment_list
        );
    }
}
