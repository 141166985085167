import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select,
    CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import { CRS } from 'leaflet';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';
import stat_modifier from 'projectLibrary/stat_modifier.js';
import Spells from 'projectLibrary/monsters/stat_block/spells.js';
import SavingThrowsAndSkills from 'projectLibrary/monsters/stat_block/saving_throws_and_skills.js';
import SpellsForm from 'projectLibrary/forms/spells.js';
import ActionsForm from 'projectLibrary/forms/actions.js';
import EquipmentForm from 'projectLibrary/forms/equipment.js';
import Inventory from 'projectLibrary/forms/inventory.js';

import update_monster_instance from 'projectLibrary/monsters/update_monster_instance.js';


export default class StatBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_tab:'Basics',
            add: null
        }

        this.roll = this.roll.bind(this);
        this.select_tab = this.select_tab.bind(this);
        this.remove_monster_instance = this.remove_monster_instance.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
    }

    setGlobalState(name, state) {
        console.log("Set Global State", name, state);
        update_monster_instance(this, state);
    }

    roll(item) {
        var monster = this.props.monster.monster;

        if (['strength','dexterity','constitution','intelligence','wisdom','charisma'].indexOf(item) > -1) {
            var modifier = stat_modifier(monster[item])
            if (modifier < 0) {
                var roll_result_one = parse_roll("1d20 - " + modifier);
                var roll_result_two = parse_roll("1d20 - " + modifier);
            }
            else {
                var roll_result_one = parse_roll("1d20 + " + modifier);
                var roll_result_two = parse_roll("1d20 + " + modifier);
            }
        }
        else if (item == 'initiative') {
            var modifier = stat_modifier(monster['dexterity'])
            if (modifier < 0) {
                var roll_result_one = parse_roll("1d20 - " + modifier);
                var roll_result_two = parse_roll("1d20 - " + modifier);
            }
            else {
                var roll_result_one = parse_roll("1d20 + " + modifier);
                var roll_result_two = parse_roll("1d20 + " + modifier);
            }
        }
        else {
            var modifier = monster[item] * monster.proficiency_bonus;
            if (['strength_saving_throw','athletics'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.strength);
            }
            else if (['dexterity_saving_throw','acrobatics', 'sleight_of_hand','stealth'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.dexterity);
            }
            else if (['constitution_saving_throw'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.constitution);
            }
            else if (['intelligence_saving_throw','arcana','history','investigation','nature','religion'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.intelligence);
            }
            else if (['wisdom_saving_throw','animal_handling','insight','medicine','perception','survival'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.wisdom);
            }
            else if (['charisma_saving_throw','deception','intimidation','performance','persuasion'].indexOf(item) > -1) {
                modifier += stat_modifier(monster.charisma);
            }

            if (modifier < 0) {
                var roll_result_one = parse_roll("1d20 - " + modifier);
                var roll_result_two = parse_roll("1d20 - " + modifier);
            }
            else {
                var roll_result_one = parse_roll("1d20 + " + modifier);
                var roll_result_two = parse_roll("1d20 + " + modifier);
            }
        }

        this.props.use_action({
            instance_id: this.props.monster.id,
            hits: [roll_result_one, roll_result_two],
            damages: [],
            action: {'name': item}
        });
    }
    //['strength_saving_throw','athletics','dexterity_saving_throw','acrobatics', 'sleight_of_hand','stealth','constitution_saving_throw','intelligence_saving_throw','arcana','history','investigation','nature','religion','wisdom_saving_throw','animal_handling','insight','medicine','perception','survival','charisma_saving_throw','deception','intimidation','performance','persuasion'].indexOf(item) > -1

    select_tab(name) {
        this.setState({display_tab:name});
    }

    remove_monster_instance() {
        var id = this.props.monster.id;
        ajaxWrapper('POST','/api/home/monsterinstance/' + id + '/delete/', {}, console.log);
        this.props.remove_monster_instance(id);
    }

    render() {
        //cr to exp table https://www.dndbeyond.com/sources/basic-rules/monsters#Challenge
        var instance = this.props.monster;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var special_abilities = [];
        var actions = [];
        var legendary_actions = [];
        var lair_actions = [];

        var macro_spells = [];
        for (var item of monster.macro_spells) {
            macro_spells.push(item.spell.id);
        }

        var macro_actions = [];
        for (var item of monster.macro_actions) {
            macro_actions.push(item.action.id);
        }

        for (var item of monster.action_spells) {
            var action = item.actionspell;
            var macro = macro_actions.indexOf(action.id) > -1
            if (action.action_type == 'Ability') {
                special_abilities.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.props.toggle_macro} macro={macro} />);
            }
            else if (action.action_type == 'Legendary') {
                legendary_actions.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.props.toggle_macro} macro={macro} />);
            }
            else if (action.action_type == 'Attack') {
                if (action.name == "Multiattack") {
                    actions.unshift(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.props.toggle_macro} macro={macro} />);
                }
                else {
                    actions.push(<Action action={action} use_action={this.props.use_action} instance={this.props.monster} toggle_macro={this.props.toggle_macro} macro={macro} />);
                }
            }
        }

        var current_conditions = [];
        if (instance['conditions[]']) {
            for (var item of instance['conditions[]']) {
                current_conditions.push(item);
            }
        }
        else {
            for (var item of instance.conditions) {
                current_conditions.push(item['condition']['id']);
            }
        }

        var tabs = [];
        var tab_names = ['Basics', 'Actions', 'Manage Actions', 'Spells', 'Manage Spells', 'Saving Throws/Skills', 'Inventory', 'Equipment'];
        for (var tab_name of tab_names) {
            tabs.push(<Tab name={tab_name} select_tab={this.select_tab} selected_tab={this.state.display_tab} />);
        }

        var pb = monster.proficiency_bonus;
        var display_content = null;
        if (this.state.display_tab == 'Actions') {
            display_content = <div>
                <h3 style={{marginTop:'20px'}}>Special Actions</h3>
                <PageBreak />
                {special_abilities}
                <h3 style={{marginTop:'20px'}}>Actions</h3>
                <PageBreak />
                {actions}
                <h3 style={{marginTop:'20px'}}>Legendary Actions</h3>
                <PageBreak />
                {legendary_actions}
                <h3 style={{marginTop:'20px'}}>Lair Actions</h3>
                <PageBreak />
            </div>;
        }
        else if (this.state.display_tab == 'Spells') {
            var spells = [];
            for (var item of this.props.monster.monster.action_spells) {
                var action = item.actionspell;
                if (action.action_type == 'Spell') {
                    spells.push(action);
                }
            }

            display_content = <div>
                <Spells group={this.props.group} add_action_token={this.props.add_action_token} spells={spells} instance={this.props.monster} push_modal_content={this.props.push_modal_content}
                    use_action={this.props.use_action} toggle_macro={this.props.toggle_macro} macro_spells={macro_spells} />
            </div>;
        }
        else if (this.state.display_tab == 'Manage Spells') {
            display_content = <SpellsForm monster={this.props.monster.monster} push_modal_content={this.props.push_modal_content} />;
        }
        else if (this.state.display_tab == 'Manage Actions') {
            display_content = <ActionsForm monster={this.props.monster.monster} push_modal_content={this.props.push_modal_content} />;
        }
        else if (this.state.display_tab == 'Equipment') {
            display_content = <EquipmentForm monster={this.props.monster.monster} push_modal_content={this.props.push_modal_content} />;
        }
        else if (this.state.display_tab == 'Inventory') {
            display_content = <Inventory instance={this.props.monster} group={this.props.group} />;
        }
        else if (this.state.display_tab == 'Saving Throws/Skills') {
            display_content = <SavingThrowsAndSkills monster={this.props.monster} use_action={this.props.use_action} />;
        }
        else if (this.state.display_tab == 'Basics') {
            var damage_immunities = '';
            for (var item of monster.damage_immunities) {
                damage_immunities += item.damagetype.name + ',';
            }
            if (damage_immunities == '') {
                damage_immunities = 'None';
            }

            var damage_resistances = '';
            for (var item of monster.damage_resistances) {
                damage_resistances += item.damagetype.name + ',';
            }
            if (damage_resistances == '') {
                damage_resistances = 'None';
            }

            var damage_vulnerabilities = '';
            for (var item of monster.damage_vulnerabilities) {
                damage_vulnerabilities += item.damagetype.name + ',';
            }
            if (damage_vulnerabilities == '') {
                damage_vulnerabilities = 'None';
            }

            var condition_immunities = '';
            for (var item of monster.condition_immunities) {
                condition_immunities += item.condition.name + ',';
            }
            if (condition_immunities == '') {
                condition_immunities = 'None';
            }

            var languages = '';
            for (var item of monster.languages) {
                languages += item.language.name + ',';
            }
            if (languages == '') {
                languages = 'None';
            }


            display_content = <div>
                <p><b>AC: </b> {monster.armor_class}</p>

                <h3>Movement</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Walk</th>
                            <th>Fly</th>
                            <th>Swim</th>
                            <th>Climb</th>
                            <th>Burrow</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{monster.walk_speed} ft.</td>
                            <td>{monster.fly_speed} ft.</td>
                            <td>{monster.swim_speed} ft.</td>
                            <td>{monster.climb_speed} ft.</td>
                            <td>{monster.burrow_speed} ft.</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Sight</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th>Blindsight</th>
                            <th>Darkvision</th>
                            <th>Truesight</th>
                            <th>Tremorsense</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{monster.blindsight} ft.</td>
                            <td>{monster.darkvision} ft.</td>
                            <td>{monster.truesight} ft.</td>
                            <td>{monster.tremorsense} ft.</td>
                        </tr>
                    </tbody>
                </table>

                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Condition Immunities</b></p>
                {condition_immunities}
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Damage Immunities (0x dmg)</b></p>
                {damage_immunities}
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Damage Resistances (1/2x dmg)</b></p>
                {damage_resistances}
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Damage Vulnerabilities (2x dmg)</b></p>
                {damage_vulnerabilities}
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Languages</b></p>
                {languages}

                <p style={{marginBottom:0, marginTop:'1rem'}}><b>passive Perception</b></p>
                <p>{monster.passive_perception}</p>
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Challenge</b></p>
                <p>{monster.challenge_rating} </p>
            </div>;
        }
        else {
            display_content = <p>Coming Soon!</p>;
        }

        var deleteUrl = '/api/home/monsterinstance/' + instance.id + '/delete/';

        var share = <Button text='Show To Players' type='danger' onClick={() => this.setGlobalState('hide',{'share_with_players':true})} />;
        if (instance.share_with_players) {
            var share = <Button text='Hide From Players' type='dark' onClick={() => this.setGlobalState('hide',{'share_with_players':false})} />;
        }

        var player_controlled = <Button text='Give Players Control' type='warning' onClick={() => this.setGlobalState('hide',{'player_controlled':true})} />;
        if (instance.player_controlled) {
            var player_controlled = <Button text='Take Away Players Control' type='dark' onClick={() => this.setGlobalState('hide',{'player_controlled':false})} />;
        }

        var set_main_instance = null;
        if (this.props.player_character && this.props.player_character.main_instance.id != instance.id) {
            set_main_instance = <Button onClick={this.props.set_main_instance} text='Set This Character As My Main' type='primary' />;
        }

        var content = <div style={{backgroundColor:'white', padding:'20px'}}>
            <h2>{monster.name}</h2>
            {set_main_instance}
            <p>{monster.size} {monster.type}, {monster.alignment}</p>

            <div style={{padding:'0px 0px 10px'}}>
                {share}
                {player_controlled}
                <Button onClick={this.remove_monster_instance} type='danger' text='Remove' />
            </div>

            <div>
                <FormWithChildren row={true} key={"current_conditions_" + instance.id} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'health_and_status'} props_will_update={true}>
                    <TextInput name='name' label='Display Name' default={instance.name} />
                    <ImageInput name='image' label='Instance Image' multiple={false} preview={false} bucket_name="mapquest-public" is_public={true} default={instance.image} />
                    <NumberInput name='current_hp' label='Current HP' default={instance.current_hp} className="col-6" />
                    <NumberInput name='max_hp' label='Max HP' default={instance.max_hp} className="col-6" />
                    <Select className="col-12" multiple={true} optionsUrl={'/api/compendium/condition/'} optionsUrlMap={{text:'{condition.name}', value:'{condition.id}'}} name='conditions[]' default={current_conditions} label='Current Conditions' />
                </FormWithChildren>
            </div>

            <ul className="nav nav-tabs">
                {tabs}
            </ul>

            {display_content}

        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }
}
