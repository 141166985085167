import {ajaxWrapper} from 'functions';


function api_lookup(component, dict, id, force) {
    var global_state = window.cmState['getGlobalState']();

    if (!(id)){
        console.error('Invalid ID detected!!', id, dict);

        if (dict in global_state['default_objects']){
            return global_state['default_objects'][dict];
        }

        return null;
    }

    if (!(dict in global_state)){
        console.error('No Dict "'+ dict +'" Located in Global State!!!');
        global_state[dict] = {};
    }

    if (!(id in global_state[dict]) || (typeof(force) != 'undefined' && force)){
        if (!(dict in DICT_TO_URL)){
            return null;
        }

        //Return default, send query, force render on callback
        var url = DICT_TO_URL[dict](id);

        ajaxWrapper('GET', url, {}, (value) => load_data(component, dict, value));

        if (!(dict in global_state['default_objects'])){
            console.error('No Default Object for "'+ dict +'"!!');
            global_state[dict][id] = {'id': id};
        }
        else {
            global_state[dict][id] = Object.assign({}, global_state['default_objects'][dict], {'id': id});
        }
    }

    return global_state[dict][id];
}


function load_data(component, dict, value) {
    var global_state = window.cmState['getGlobalState']();
    var data_dict = global_state[dict];

    var object_name = Object.keys(value[0])[0];
    var object_id = value[0][object_name]['id'];

    data_dict[object_id] = value[0][object_name];

    component.forceUpdate();
}


function location_url(id){
    console.log("Refresh Locations");
    var url = '/api/home/location/' + id + '/';
    url += '?related=children,tags,link,group_locations,location_permission,location_note';
    url += '&related__children__only=id';
    if (window.cmState.group){
        url += '&related__group_locations__group=' + window.cmState.group.id;
        url += '&related__location_permission__group=' + window.cmState.group.id;
        url += '&related__location_note__group=' + window.cmState.group.id;
    }

    return url;
}

function instance_url(id) {
    var url = '/api/home/monsterinstance/' + id + '/';
    url += '?related=monster_instance_resources,equipped_items,equipped_items__instance_equipment_resources,conditions';

    return url;
}

function action_instance_url(id){
    var url = '/api/home/actioninstance/' + id + '/';
    return url;
}

function class_url(id){
    var url = '/api/compendium/dndclass/' + id + '/';
    return url;
}

function language_url(id){
    var url = '/api/compendium/language/' + id + '/';
    return url;
}

function damage_type_url(id){
    var url = '/api/compendium/damagetype/' + id + '/';
    return url;
}

function condition_url(id){
    var url = '/api/compendium/condition/' + id + '/';
    return url;
}

function resource_url(id){
    var url = '/api/compendium/resource/' + id + '/';
    return url;
}

function action_url(id){
    var url = '/api/compendium/actionspell/' + id + '/?related=resource_costs';
    return url
}

function magical_attribute_url(id){
    var url = '/api/compendium/magicalattribute/' + id + '/';
    return url
}

function equipment_url(id){
    var url = '/api/compendium/equipment/' + id + '/?related=actions,resources,magical_attributes';
    return url;
}

function monster_url(id){
    var url = '/api/compendium/monster/' + id + '/';

    var actions = 'action_spells,prepared_spells,';
    var resources_and_items = 'monster_resources,equipped_items,';
    var resistance_and_immunities = 'damage_immunities,damage_vulnerabilities,damage_resistances,condition_immunities,languages';
    url += '?related=' + actions + resources_and_items + resistance_and_immunities;
    url += '&related__action_spells__only=id&related__prepared_spells__only=id&related__monster_resources__only=resource_id,quantity&related__equipped_items__only=equipment_id,quantity';

    return url;
}


const DICT_TO_URL = {
    'locations': location_url,
    'instances': instance_url,
    'action_instances': action_instance_url,

    'classes': class_url,
    'languages': language_url,
    'damage_types': damage_type_url,
    'conditions': condition_url,
    'resources': resource_url,

    'actions': action_url,
    'magicalattributes': magical_attribute_url,
    'equipment': equipment_url,
    'monsters': monster_url,
};


export default api_lookup;
