import React, { Component } from 'react';
import {ajaxWrapper} from 'functions';
import {Wrapper} from 'library';

import {FormWithChildren, TextInput, Select, PasswordInput, Navbar, Alert, If, NumberInput, CSSInput} from 'library';

class SignUpForm extends Component {
    static component_name = 'SignUpForm';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'redirectUrl'} name={'redirectUrl'} default={''} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        };

        this.state = {'error':'', 'first_name':'','last_name':'', 'email':'', 'password':'','type':'User'};

        this.setGlobalState = this.setGlobalState.bind(this);
        this.login = this.login.bind(this);
        this.signup_failure = this.signup_failure.bind(this);
    }

    componentDidMount(){
        this.setState(this.props.defaults);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    login(value) {
        window.location = '/thankyou/';
    }

    signup_failure(value) {
        this.setState({error: value});
    }

    render() {
        var content = <div>
            <If logic={[["exists", this.state.error]]}>
                <Alert type={'danger'} text={this.state.error} />
            </If>
            <FormWithChildren submitUrl="/users/signup/" submit_text="Submit" autoSetGlobalState={true} setGlobalState={this.setGlobalState}
                defaults={this.state} redirect={this.login} submit_failure={this.signup_failure}>
                {this.props.children}
            </FormWithChildren>
        </div>;

        return (
            <Wrapper loaded={true} content={content} />
        );
    }
}
export default SignUpForm;
