import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';

function update_location(location) {
    var id = location.id;

    var location_dict = window.cmState.locations;
    location_dict[id] = location;

    var parent = window.cmState.global_state_lookup(this, 'locations', location.parent_id);
    var parents_children = [];

    var found = false;
    var child_dict = {
        location: {id: location.id}
    };

    for (var child of parent.children) {
        if (child.location.id == location.id) {
            found = true;
            parents_children.push(child_dict);
        }
        else {
            parents_children.push(child);
        }
    }
    if (!found) {
        parents_children.push(child_dict);
    }
    parent['children'] = parents_children;

    var children = [];
    for (var key in location_dict) {
        var loc = location_dict[key];

        var child_dict = {
            location: {id: loc.id}
        };

        if (loc && loc.parent_id == location.id) {
            children.push(child_dict);
        }
    }
    location.children = children;

    window.cmState.setGlobalState('locations', location_dict);

}

export default update_location;
