import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';

function update_permisson(permission) {
    var location_id = permission.location_id;

    var permission_dict = window.cmState.location_permissions;
    permission_dict[location_id] = permission;

    window.cmState.setGlobalState('location_permissions', permission_dict);
}

export default update_permisson;
