import React, { Component } from 'react';
import { Checkbox, FormWithChildren } from 'library';
import stat_modifier from 'projectLibrary/stat_modifier.js';


class SkillProficiency extends React.Component {
    render() {
        var className = '';
        var value = this.props.value;
        var checked_value = value;

        if (value == 2){
            checked_value = 1;
            className = 'expertise';
        }
        else if (!(value)){ value = 0;}

        var label = parseInt(value) * this.props.pb + stat_modifier(parseInt(this.props.stat));

        return (
            <div className='row'>
                <div className='col-4' ><Checkbox className={className} setFormState={this.props.setFormState} name={this.props.name}
                    label={label} value={checked_value} secret_value={1} /></div>
                <div className="rollable saving-throw col-8" onClick={() => this.props.roll_skill(this.props.name)} >
                    {this.props.display_name}</div>
            </div>
        );
    }
}


export default class SkillProficiencyBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0};
        this.update = this.update.bind(this);
    }

    componentDidMount(){
    }

    update(name, data){
        var monster = this.props.monster;
        for (var key in data){
            if (typeof(data[key]) == 'undefined'){
                if (monster[key] == 1){
                    data[key] = 2;
                }
                else {
                    data[key] = 0;
                }
            }
        }

        this.props.update_monster(name, data);
    }


    render (){
        var monster = this.props.monster;
        var pb = monster.proficiency_bonus;

        return (
            <FormWithChildren className='skills' props_will_update={true} defaults={monster}
                setGlobalState={this.update} autoSetGlobalState={true} globalStateName={'skills'} >
                <div className='bottom-label bordered'>SKILLS</div>

                <SkillProficiency roll_skill={this.props.roll_skill} name='acrobatics' pb={pb} stat={monster.dexterity} display_name='Acrobatics (DEX)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='animal_handling' pb={pb} stat={monster.wisdom} display_name='Animal Handling (WIS)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='arcana' pb={pb} stat={monster.intelligence} display_name='Arcana (INT)' />

                <SkillProficiency roll_skill={this.props.roll_skill}name='athletics' pb={pb} stat={monster.strength} display_name='Athletics (STR)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='deception' pb={pb} stat={monster.charisma} display_name='Deception (CHA)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='history' pb={pb} stat={monster.intelligence} display_name='History (INT)' />

                <SkillProficiency roll_skill={this.props.roll_skill}name='insight' pb={pb} stat={monster.wisdom} display_name='Insight (WIS)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='intimidation' pb={pb} stat={monster.charisma} display_name='Intimidation (CHA)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='investigation' pb={pb} stat={monster.intelligence} display_name='Investigation (INT)' />

                <SkillProficiency roll_skill={this.props.roll_skill}name='medicine' pb={pb} stat={monster.wisdom} display_name='Medicine (WIS)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='nature' pb={pb} stat={monster.intelligence} display_name='Nature (INT)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='perception' pb={pb} stat={monster.wisdom} display_name='Perception (WIS)' />

                <SkillProficiency roll_skill={this.props.roll_skill}name='performance' pb={pb} stat={monster.charisma} display_name='Performance (CHA)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='persuasion' pb={pb} stat={monster.charisma} display_name='Persuasion (CHA)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='religion' pb={pb} stat={monster.intelligence} display_name='Religion (INT)' />

                <SkillProficiency roll_skill={this.props.roll_skill}name='sleight_of_hand' pb={pb} stat={monster.dexterity} display_name='Sleight Of Hand (DEX)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='stealth' pb={pb} stat={monster.dexterity} display_name='Stealth (DEX)' />
                <SkillProficiency roll_skill={this.props.roll_skill}name='survival' pb={pb} stat={monster.wisdom} display_name='Survival (WIS)' />
            </FormWithChildren>
        );
    }
}
