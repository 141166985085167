import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { PageBreak, Container } from 'library';


export default class EquipmentCard extends React.Component {
    render() {
        var equipment = this.props.equipment;

        return (
            <div className='shadowed-border' style={{padding:'10px', background:'white'}}>
                <h2>{equipment.name}</h2>
                <p><b>Description: </b> {equipment.description}</p>
            </div>
        )
    }
}
