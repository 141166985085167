import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';


export default class UserList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {'users':[], loaded: false}

        this.user_callback = this.user_callback.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/user/user/', {}, this.user_callback)
    }


    user_callback(result) {
        var user_list = [];
        for (var item of result) {
            var user = item['user']
            user_list.push(user);
        }

        this.setState({'users':user_list, loaded:true})
    }

    render() {

        var users = [];
        for (var user of this.state.users) {
            users.push(<tr>
                <td>{user.email}</td>
                <td>{user.is_staff}</td>
                <td><Button type='primary' text='Edit' href={'/user/' + user.id + '/'} /></td>
            </tr>)
        }

        var content = <div className="container">
            <div>
                <h3>Users</h3>
                <Button type='primary' text='Add User' href='/user/' />
                <table className="table">
                    <tr>
                        <th>Email</th>
                        <th>Is Staff</th>
                        <th>Edit</th>
                    </tr>
                    {users}
                </table>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
