import React, { Component } from 'react';
import { resolveVariables } from 'functions';
import {Wrapper, Navbar, NavLink, Div, Image, Span, Link, Paragraph, Container,
    Header, FormWithChildren, PasswordInput} from 'library';


class PasswordReset extends Component {
    render() {
        return (<div>
				<Container order={"1"} style={{'padding-top': '20px'}} min_height={true} >
					<Header text={"Reset Your Password"}  />
					<FormWithChildren submitUrl={resolveVariables({"text":"/api/user/user/{params.1}/"}, window.cmState.getGlobalState(this))["text"]} redirectUrl={"/login/"} >
						<PasswordInput name={"password"} confirm_password={true}  />
					</FormWithChildren>
				</Container>
        </div>);
    }
}

export default PasswordReset;
