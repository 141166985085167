import 'babel-polyfill';
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11';

import React, { Component } from 'react';
import './App.css';
import {ajaxWrapper, setGlobalState, clear_tokens} from "functions";
import { Wrapper, ErrorBoundary, BrokenComponent, PageNotFound } from 'library';
import * as Sentry from '@sentry/browser';
//Component Madness

//Admin
import AppList from './pages/admin/appList.js';
import ModelList from './pages/admin/modelsList.js';
import InstanceList from './pages/admin/modelInstances.js';
import Instance from './pages/admin/instance.js';
import InstanceTable from './pages/admin/modelInstancesTable.js';

import Nav from 'projectLibrary/generic_components/navbar.js';
//import Footer from 'projectLibrary/footer.js';
import ModelMaker from './pages/model_builder/djangoModelMaker.js';

//API Querying
import APIDocs from './pages/admin/apiDocs.js';

import Viewfilegroups from './pages/file_manager/viewfilegroups.js';
import FileManager from './pages/file_manager/filemanager.js';

import Login from 'projectLibrary/users/login.js';
import PasswordReset from 'projectLibrary/users/passwordreset.js';
import PasswordResetRequest from 'projectLibrary/users/passwordresetrequest.js';
import SignUpForm from 'projectLibrary/users/signupform.js';

import LandingPage from 'projectLibrary/public/landing_page.js';
import AboutUs from 'projectLibrary/public/aboutus.js';
import ContactUs from 'projectLibrary/public/contactus.js';
import ThankYou from 'projectLibrary/public/thankyou.js';
import LocationPage from 'projectLibrary/location_page.js';

import ProcGen from 'projectLibrary/proc_gen/proc_gen_map.js';
import TownExplorer from 'projectLibrary/proc_gen/town_explorer.js';

import User from 'projectLibrary/users/user.js';
import UserList from 'projectLibrary/users/user_list.js';

import MyLocations from 'projectLibrary/nav/my_locations.js';
import LocationDetailsSidebar from 'projectLibrary/display_components/location_details.js';

import Group from 'projectLibrary/nav/group.js';
import MyGroups from 'projectLibrary/nav/my_groups.js';
import Dashboard from 'projectLibrary/nav/dashboard.js';
import SessionDashboard from 'projectLibrary/session_dashboard.js';

import DiceRoller from 'projectLibrary/display_components/dice_roller.js';
import FreeDraw from 'projectLibrary/session_tools/free_draw.js';

import PublicMonsterList from 'projectLibrary/public/monster_list.js';
import MonsterLookUp from 'projectLibrary/public/monster_look_up.js';
import MonsterLink from 'projectLibrary/public/monster_link.js';

import FullPageFancyCharSheet from 'projectLibrary/monsters/fancy_char_sheet/full_page.js';
import EditAction from 'projectLibrary/forms/edit_action.js';
import EditEquipment from 'projectLibrary/forms/edit_equipment.js';
import EditMagicalAttribute from 'projectLibrary/forms/edit_magical_attribute.js';
import EditMonster from 'projectLibrary/forms/edit_monster.js';
import EditLocation from 'projectLibrary/forms/edit_location.js';

import DMTools from 'projectLibrary/nav/dm_tools.js';
import ActionList from 'projectLibrary/dm_tools/action_list.js';
import EquipmentList from 'projectLibrary/dm_tools/equipment_list.js';
import MagicalAttributeList from 'projectLibrary/dm_tools/magical_attribute_list.js';
import MonsterList from 'projectLibrary/dm_tools/monster_list.js';


class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            csrfmiddlewaretoken: undefined,
            user:{}
        };

        this.load_global_context = this.load_global_context.bind(this);
        this.load_user = this.load_user.bind(this);
    }

    componentDidMount() {
        this.load_global_context(window.secretReactVars.global_context);

        var path = this.getURL()[0].toLowerCase();

        var token = localStorage.getItem('token');

        var loginNoRedirects = [
            'login','signup','passwordresetrequest', 'passwordreset', 'admin',
            'about','contact', 'contribute', 'thankyou', 'neverwinter', 'luskan', 'triboar',
            'sword_coast', 'yartar','silverymoon', 'town_explorer', '', 'monster_list',
            'dnd_5e_monster_stat_block'
        ];

        if (token) {
            //ajaxWrapper("GET", "/users/user/", {}, this.load_user.bind(this), clear_tokens);
            this.load_user(window.secretReactVars.logged_in_user);

            this.setState({token: token});
            if (path.indexOf('login') > -1) {
                window.location.href = '/';
            }
        } else if (loginNoRedirects.indexOf(path) == -1 && window.location.pathname != "/") {
            localStorage.setItem('redirected_from', window.location.pathname);
            window.location.href = '/login/';
        }
        else {
          this.setState({'loaded':true})
        }
    }

    load_user(result){
        if (!(result)){
            clear_tokens();
        }
        else {
            console.log("Load User Result", result)
            window.cmState.setGlobalState('user',result);
            this.setState({
                user: result,
                loaded: true,
            });
        }
    }

    logOut() {
        console.log("Log Out");
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('token_time');
        window.location.href = '/users/logout/';
    }

    load_global_context(value) {
        window.secretReactVars["csrfmiddlewaretoken"] = value['csrfmiddlewaretoken'];
        window.secretReactVars["twofactor"] = value['twofactor'];
        window.secretReactVars["secure_uploads"] = value['secure_uploads'];

        if ('glitchtip_dsn' in value){
            Sentry.init({
                dsn: value['glitchtip_dsn'],
                debug: true,
            });
        }

        this.setState({
            csrfmiddlewaretoken: value.csrfmiddlewaretoken,
            twofactor: value['twofactor'],
            secure_uploads: value['secure_uploads']
        });
    }


    getURL() {
        var url = window.location.pathname;
        if (url[0] == '/'){ url = url.substring(1);}
        if (url[url.length - 1] == '/'){ url = url.substring(0,url.length-1);}
        var url_split = url.split('/');

        var params = {};
        for (var index in url_split) {
            params[index] = url_split[index]
        }

        var href = window.location.href;
        if (href.endsWith("#")) {
            href = href.substring(0, href.length - 1)
        }
        if (href.indexOf('?') > -1) {
            console.log("Here")
            var post_params = href.split('?')[1];
            var split_params = post_params.split('&');
            console.log("Post Params", post_params, split_params);

            for (var index in split_params) {
                var temp = split_params[index]
                var temp_split = temp.split('=')
                params[temp_split[0]] = temp_split[1]
            }
        }

        return params;
    }

    render() {
        var params = this.getURL();
        var param_dict = {};
        for (var index in params) {
            param_dict[index.toString()] = params[index]
        }
        window.cmState.setGlobalState('params',param_dict)
        window.cmState.setGlobalState('location', window.location)

        var adminPages = [
            'applist','models','modelinstances',
            'modelinstancestable','instance',
        ];

        var loggedInPages = [];
        var route = params[0].toLowerCase();

        var loading = <h1>Loading . . . </h1>;
        var content = null;
        var no_navbar = false;

        console.log("Route!", route);
        if (this.state.loaded) {

            if (adminPages.indexOf(route) > -1 && this.state.loaded && !(this.state.user.is_staff)){
                //window.location = '/';
                console.log("Not an admin", this.state.loaded, this.state.user)
            } else if (loggedInPages.indexOf(route) > -1 && this.state.loaded && typeof(this.state.user.id) != 'undefined'){
                //window.location = '/login/';
                console.log("Need to be logged in");
            }
            else if (route == 'logout') {
                this.logOut();
            }
            else if (route == 'add_monster_link') {
                content = <MonsterLink monster_id={params[1]} />
            }
            else if (route == 'dnd_5e_monster_stat_block') {
                content = <MonsterLookUp name={params[1]} />
            }
            else if (route == 'monster_list') {
                content = <PublicMonsterList />
            }
            else if (route == 'my_groups') {
                content = <MyGroups />
            }
            else if (route == 'group') {
                content = <Group group_id={params[1]} />
            }
            else if (route == 'edit_action') {
                content = <EditAction action_id={params[1]} />
            }
            else if (route == 'edit_equipment') {
                content = <EditEquipment equipment_id={params[1]} />
            }
            else if (route == 'edit_magical_attribute') {
                content = <EditMagicalAttribute magical_attribute_id={params[1]} />
            }
            else if (route == 'edit_monster') {
                content = <EditMonster monster_id={params[1]} />
            }
            else if (route == 'my_locations') {
                content = <MyLocations />
            }
            else if (route == 'edit_location') {
                content = <EditLocation location_id={params[1]} />
            }
            else if (route == 'dashboard') {
                content = <Dashboard />
            }
            else if (route == 'session') {
                content = <SessionDashboard group_id={params[1]} />;
                no_navbar = true;
            }

            else if (route == 'dm_tools'){
                if (params[1] == 'monster_list'){
                    content = <MonsterList />;
                }
                else if (params[1] == 'action_list'){
                    content = <ActionList all={true} />;
                }
                else if (params[1] == 'equipment_list'){
                    content = <EquipmentList />;
                }
                else if (params[1] == 'magical_attribute_list'){
                    content = <MagicalAttributeList />;
                }
                else {
                    content = <DMTools />;
                }
            }

            else if (route == "files") {
                content = <FileManager />;
            }
            else if (route == "filegroup") {
                content = <Viewfilegroups />;
            }

            else if (route == "applist") {
                content = <AppList user_id={this.state.token}/>;
            }
            else if (route == "models") {
                content = <ModelList app={params[1]} user_id={this.state.token}/>;
            }
            else if (route == "modelinstances") {
                content = <InstanceList app={params[1]} model={params[2]} user_id={this.state.token}/>;
            }
            else if (route == "modelinstancestable") {
                content = <InstanceTable app={params[1]} model={params[2]}/>;
            }
            else if (route == "modelmaker") {
                content = <ModelMaker user_id={this.state.token}/>;
            }
            else if (route == "instance") {
                content = <Instance app={params[1]} model={params[2]} id={params[3]} user_id={this.state.token}/>;
            }
            else if (route == 'apidocs') {
                content = <APIDocs app={params[1]} />
            }
            else if (route == 'neverwinter') {
                content = <LocationPage id='4a82f848-1665-4a6c-8608-0da9636b3e74' group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'luskan') {
                content = <LocationPage id="bfb2ab7c-042f-4b08-ab13-9e94a01dc1d9" group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'sword_coast') {
                content = <LocationPage id="6527f3c7-fe59-4820-838b-ff1354181285" group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'triboar') {
                content = <LocationPage id="43ff441e-005e-4887-8661-4acec6bc9e62" group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'yartar') {
                content = <LocationPage id="db71b09f-ef7f-40a2-902b-2a34b74e386c" group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'silverymoon') {
                content = <LocationPage id="ad9742b9-baca-421f-8886-99d1d9b74e50" group={params.group_id}/>;
                no_navbar = true;
            }
            else if (route == 'proc_gen') {
                content = <ProcGen />
            }
            else if (route == 'town_explorer') {
                content = <TownExplorer />
            }
            else if (route == 'dice_roller') {
                content = <DiceRoller />
            }
            else if (route == 'free_draw') {
                content = <FreeDraw />
            }
            else if (route == 'fancy_char_sheet') {
                content = <FullPageFancyCharSheet id={params[1]} />
            }
            else if (route == 'users') {
                content = <UserList />
            }
            else if (route == 'user') {
                content = <User user_id={params[1]} />
            }

            else if (route == 'login'){
                content = <Login />;
            }
            else if (route == 'passwordreset'){
                content = <PasswordReset />
            }
            else if (route == 'passwordresetrequest'){
                content = <PasswordResetRequest />
            }
            else if (route == 'signup'){
                content = <SignUpForm />
            }

            else if (route == 'about'){
                content = <AboutUs />
            }
            else if (route == 'contact' || route == 'contribute'){
                content = <ContactUs />
            }
            else if (route == 'thankyou'){
                content = <ThankYou />
            }

            else if (route == 'broken_page'){
                content = <BrokenComponent />;
            }
            else if (route == '') {
                var host = window.location.host;
                if (params.id) {
                    content = <LocationPage id={params.id} group={params.group_id}/>
                    no_navbar = true;
                }
                else if (window.cmState.user) {
                    window.location.href = '/dashboard/'
                }
                else {
                    content = <LandingPage id={params.id} group={params.group_id}/>
                }
            }
            else {
                content = <PageNotFound />;
            }
        }

        var navbar = <Nav />;
        if (no_navbar){
            navbar = null;
        }

        return (
            <div className="App">
                <ErrorBoundary>
                    {navbar}
                    <Wrapper style={{paddingTop: '60px'}} content={content} loaded={this.state.loaded} />
                </ErrorBoundary>
            </div>
        );
    }
}

export default App;
