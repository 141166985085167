import {ajaxWrapper} from 'functions';
import update_note from 'projectLibrary/session_tools/global_data/update_note.js';

function update_note_from_data(note, data) {
    for (var key in data) {
        note[key] = data[key];
    }

    return note;
}

function save_note(data) {
    console.log("Save Note", data)
    var id = null;
    if (data['id']) {
        id = data['id']
        delete data['id'];
    }

    //location exists
    if (id) {
        var url = '/api/home/grouplocationnote/' + id + '/'
        var notes = window.cmState.global_state_lookup(this, 'location_notes', data.location);
        for (var note of notes) {
            if (note.id == id) {
                break;
            }
        }
        console.log("Note", note, notes, id)
        var updated_note = update_note_from_data(note, data);
        window.cmState.chat_socket.send_message({message: {'update_note': updated_note}})
        //update_permission(updated_permission);

        ajaxWrapper('POST', url, data, console.log)
    }
    //location doesn't exist
    else {
        var url = '/api/home/grouplocationnote/'
        ajaxWrapper('POST', url, data, load_note)
    }
}

function load_note(result) {
    var updated_note = result[0].grouplocationnote;
    for (var key in updated_note) {
        if (key.endsWith('_id')) {
            var new_key = key.replace('_id','')
            updated_note[new_key] = updated_note[key];
        }
    }

    window.cmState.chat_socket.send_message({message: {'update_note': updated_note}});
}

export default save_note;
