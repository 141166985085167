import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';

export default class Link extends React.Component {
    constructor(props) {
        super(props);

        this.select_item = this.select_item.bind(this);
    }

    select_item() {
        this.props.select_item(this.props.id, this.props.type);
    }

    render() {

        return (
            <a onClick={this.select_item} style={{color:'blue', cursor:'pointer', fontSize:'20px'}}>{this.props.text}</a>
        )
    }
}
