import $ from 'jquery';
import clear_tokens from './ajax_redirects/clear_tokens.js';
import handle_error from './ajax_redirects/handle_error.js';


function ajaxWrapper(type, url, data, return_func, fail_func, return_func_params){
    var auth_token = '';
    var beforeSend = function(request) {
        if (type === "POST" || type === "PUT") {
            request.setRequestHeader('X-CSRFToken', window.secretReactVars["csrfmiddlewaretoken"]);
        }
    }

    if (type === "POST" || type === "PUT"){
        data = JSON.stringify(data);
    }

    if (!url || url.indexOf('{') > -1 || url.indexOf('}') > -1){
        console.log("Request to ", url, " cancelled. Not a valid path");
        return false;
    }

    $.ajax({
        type: type,
        url: url,
        contentType: 'application/json',
        beforeSend: beforeSend,
        data: data,
        statusCode: {
            200: function(value) {
                if (typeof(value) === 'string'){
                    console.log('Ajax got invalid string response while requesting ', url);
                    console.log(value);
                    fail_func();
                }
                else if (typeof(value) === "object" && "redirect" in value) {
                    window.location = value['redirect'] + "?redirect=" + window.secretReactVars["BASE_URL"];
                }
                else {
                    if (return_func_params){
                        return_func(...return_func_params);
                    }
                    else {
                        return_func(value);
                    }
                }
            },
            400: function(xhr) {
                handle_error(xhr, type, url, data, return_func, fail_func);
            },
            401: function(xhr) {
                handle_error(xhr, type, url, data, return_func, fail_func);
            },
            404: function(xhr){
                handle_error(xhr, type, url, data, return_func, fail_func);
            },
            500: function(xhr){
                handle_error(xhr, type, url, data, return_func, fail_func);
            }
        },
    });
}



export default ajaxWrapper;
