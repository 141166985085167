import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Body, Container, Button, Wrapper, TextInput, NumberInput, FormWithChildren, Span,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, Header, Image, Paragraph, Link } from 'library';

import Footer from 'projectLibrary/generic_components/footer.js';
import Subscribe from 'projectLibrary/public/subscribe.js';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class LandingPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
            counter: 0,
            fading: false
		};

        this.cycle_problems = this.cycle_problems.bind(this);
        this.end_fade = this.end_fade.bind(this);
	}

	componentDidMount() {
        setTimeout(this.cycle_problems, 10000);
	}

    cycle_problems() {
        this.setState({fading: true});
        setTimeout(this.end_fade, 600);
    }

    end_fade() {
        this.setState({fading:false, counter: this.state.counter+1});
        setTimeout(this.cycle_problems, 10000);
    }


	render() {
        var senarios = [
            {
                problem: "When your characters decide that they randomly want to head to Neverwinter",
                solution: "Forgotten Maps has your back with an interactive map complete with wikis on all the places they might want to check out.",
                image: 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_35_22_image5.png'
            },
            {
                problem: 'When your sorcerer decides they want to cast a major illusion for their suggested victim to see',
                solution: 'Forgotten Maps has your back. We gently remind them that it will break concentration, but we let you decide if you want to enforce it',
                image: 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_35_35_image6.png'
            },
            {
                problem: 'When your bard has hypnotized a group of ogres, and then got speared in the back by a rogue orc',
                solution: 'Forgotten Maps has your back. We let them know they need to roll a concentration save to see if they are about to be surrounded by angry ogres instead',
                image: 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_35_03_image1.png'
            },
            {
                problem: 'When all of a sudden you realize your party picked a fight with a lich',
                solution: "Forgotten Maps has your back. We've already prepared an easy to use stat block for you to use actions and track the Lich’s spells"   ,
                image: 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_35_17_image4.png'
            },
            {
                problem: 'When your wizard found a way to target 4 types of monsters with one fireball',
                solution: "Forgotten Maps has your back. you no longer have to look up everyone's Dexterity modifier and if they have proficiency, instead its a button click away",
                image: 'https://mapquest-public.s3.amazonaws.com/21_01_01_17_35_09_image3.png'
            }
        ];

        var sneaky_images = [];
        for (var item of senarios){
            sneaky_images.push(<Image style={{opacity:0,height:'1px',width:'1px'}} src={item.image} />);
        }

        var index = this.state.counter % senarios.length;
        var senario = senarios[index];

        var main_text_style = {
            opacity: '1',
            transition: 'opacity 0.3s ease-in-out'
        }
        var main_image_style = {
            opacity: '1',
            borderRadius: '3px',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            transition: 'opacity 0.3s ease-in-out'
        };
        if (this.state.fading){
            main_image_style['opacity'] = '0';
            main_text_style['opacity'] = '0';
        }

        var mid_section_image_style = {
            borderRadius: '3px',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
        };
        var campaign_image_style = {
            borderRadius: '3px',
            boxShadow: '2px 2px 10px rgba(0,0,0,.2)',
            margin: '10px'
        };

        var content = <div className='larger-text'>
            <Body>
                <div>
                    <Container min_height={true} style={{position:'relative'}} >
                        <BrowserView>
                        <div className='row' style={{padding: '10% 0px'}} >
                            <div className='col-5'>
                                <Header size={1} text={'DnD 5e'} style={{fontSize:'3.5rem'}} />
                                <Header size={1} text={'Made Easy'} style={{fontSize:'3.5rem'}} />
                                <br/>
                                <Header size={4} text={senario.problem} style={main_text_style} />
                                <br/>
                                <Header size={4} text={senario.solution} style={main_text_style} />
                            </div>

                            <div className='col-7'>
                                <Image style={main_image_style} src={senario.image} />
                                {sneaky_images}
                            </div>
                        </div>
                        <div style={{position: 'absolute', bottom: '25px', textAlign:'center', width: '100%'}}>
                            <Header size={1} text={'Get Access To The Beta'} /><br/><br/>
                            <Link href='https://discord.gg/cYvcWSUxTn' className='btn discord-btn' >
                                <Image style={{width: '60px'}} src='https://mapquest-public.s3.amazonaws.com/21_01_05_20_04_13_Discord-logo.png' />
                                <Span style={{fontSize: '1.5rem', padding: '0px 10px 0px 20px', position: 'relative', top: '3px'}} text={'Join Our Discord Server'} />
                            </Link>
                        </div>
                        </BrowserView>
                        <MobileView>
                            <br/><br/>
                            <Header size={1} text={'DnD 5e'} style={{fontSize:'3.5rem'}} />
                            <Header size={1} text={'Made Easy'} style={{fontSize:'3.5rem'}} />
                            <br/><br/>
                            <Image style={main_image_style} src={senario.image} />
                            <br/><br/><br/>
                            <Header size={4} text={senario.problem} style={main_text_style} />
                            <br/>
                            <Header size={4} text={senario.solution} style={main_text_style} />
                            <div style={{textAlign:'center', width: '100%', marginTop:'75px', marginBottom:'20px'}}>
                                <Header size={1} text={'Get Access To The Beta'} /><br/><br/>
                                <Link href='https://discord.gg/cYvcWSUxTn' className='btn discord-btn' >
                                    <Image style={{width: '60px'}} src='https://mapquest-public.s3.amazonaws.com/21_01_05_20_04_13_Discord-logo.png' />
                                    <Span style={{fontSize: '1.5rem', padding: '0px 10px 0px 20px', position: 'relative', top: '3px'}} text={'Join Our Discord Server'} />
                                </Link>
                            </div>
                        </MobileView>
                    </Container>
                </div>

                <div style={{background:'#eee'}} >
                    <Container>
                    <div className='row' style={{padding:'10% 0px'}} >
                        <div className='col-12' style={{textAlign: 'center'}}>
                            <Header size={1} text={"We've already set up your table."} />
                            <Paragraph text={'Forgotten Maps has everything you need to bring your campaigns to life.'} />
                            <br/><br/>
                        </div>

                        <div className='col-md-7 col-12'>
                            <Header size={2} text={'Pre-prepared maps'} />
                            <Paragraph text={'We’ve got maps covering the sword coast and we are expanding to cover all of Faerun, Toril and the other planes. And anytime you need a location we don’t have or a specific map for your campaign it takes just a couple of clicks to upload it.'} />
                        </div>
                        <div className='col-md-5 col-12'>
                            <Image style={mid_section_image_style} src={'https://mapquest-public.s3.amazonaws.com/21_01_05_01_53_38_pinned-map.JPG'} />
                        </div>

                        <div className='col-12' style={{textAlign:'center'}}><br/><br/><br/><br/></div>

                        <div className='row col-12'>
                            <div className='col-md-7 col-12 order-1 order-md-2'>
                                <Header size={2} text={'Monsters Ready To Go'} />
                                <Paragraph text={'We’ve got every publicly available monster from goblins to dragons ready to jump into battle. And it’s easy to add in a new one or your own homebrewed monster to face your PCs in battle of strength or wits.'} />
                            </div>
                            <div className='col-md-5 col-12 order-2 order-md-1'>
                                <Image style={mid_section_image_style} src={'https://mapquest-public.s3.amazonaws.com/21_01_05_01_34_19_monsters.JPG'} />
                            </div>
                        </div>

                        <div className='col-12' style={{textAlign:'center'}}><br/><br/><br/><br/></div>

                        <div className='col-md-7 col-12'>
                            <Header size={2} text={'Abilities and Items Already In'} />
                            <Paragraph text={'For your PCs, we’ve got the classic DnD abilities, spells and items already prepared so your players can quickly build their characters.'} />
                        </div>
                        <div className='col-md-5 col-12'>
                            <Image style={mid_section_image_style} src={'https://mapquest-public.s3.amazonaws.com/21_01_05_01_38_57_abailities.JPG'} />
                        </div>

                        <div className='col-12' style={{textAlign:'center'}}><br/><br/><br/><br/></div>

                    </div>
                    </Container>
                </div>

                <div style={{background:'#fff'}}>
                    <div className='col-12' style={{textAlign: 'center'}}>
                        <br/><br/><br/><br/>
                        <Header size={2} text={'Just Create A Campaign, Invite your Friends and Hit Play'} />
                        <Paragraph text={'Forgotten Maps makes it easy to start a new campaign. Just give it a name and choose your starting location. Then invite your friends. And your ready to start building your new campaign and playing your first session.'} />
                        <br/><br/><br/><br/>
                    </div>
                </div>

                <div style={{background:'#eee'}} >
                    <Container>
                    <div className='row' style={{padding:'10% 0px', textAlign:'center'}}>
                        <div className='col-12'>
                            <Header size={1} text={'Join The Community And Stay Up To Date'} /><br/><br/>
                            <Link href='https://discord.gg/cYvcWSUxTn' className='btn discord-btn' >
                                <Image style={{width: '60px'}} src='https://mapquest-public.s3.amazonaws.com/21_01_05_20_04_13_Discord-logo.png' />
                                <Span style={{fontSize: '1.5rem', padding: '0px 10px 0px 20px', position: 'relative', top: '3px'}} text={'Join Our Discord Server'} />
                            </Link>
                        </div>
                    </div>
                    </Container>
                </div>
            </Body>

            <Footer />
        </div>;

		return (
			<Wrapper content={content} loaded={true} />
		);
	}
}

export default LandingPage;
