import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, Container } from 'library';
import ActionCard from 'projectLibrary/action_card.js';
import ResourceList from 'projectLibrary/monsters/fancy_char_sheet/resources_list.js';
import ActionsForm from 'projectLibrary/forms/actions.js';
import FancySpellSheet from 'projectLibrary/monsters/fancy_char_sheet/spell_main.js';
import parse_roll from 'projectLibrary/monsters/roll.js';

export default class LevelUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {class: '', dnd_classes:[], dnd_class_dict: {}, hp: false, abilities: false, loaded:false, health_roll: null}

        this.dnd_class_callback = this.dnd_class_callback.bind(this);
        this.submit_class = this.submit_class.bind(this);
        this.complete = this.complete.bind(this);
        this.roll = this.roll.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/dndclass/', {}, this.dnd_class_callback)
    }

    dnd_class_callback(result) {
        var dnd_classes = [];
        var dnd_class_dict = {}
        for (var item of result) {
            var dnd_class = item.dndclass;
            dnd_classes.push(dnd_class);
            dnd_class_dict[dnd_class.id] = dnd_class
        }

        this.setState({dnd_classes:dnd_classes, dnd_class_dict: dnd_class_dict, loaded:true})
    }

    submit_class(state, callback) {
        console.log("Submit Class", state, callback)
        this.setState({class: state['class']})
    }

    roll() {
        var monster = window.cmState.global_state_lookup(this, 'monsters', window.cmState.selected_monster_instance.monster_id);

        var custom_roll = {
            'action': {
                'name': monster.name + "'s Roll for Health",
            },
            'instance_id': window.cmState.selected_monster_instance.id,

        };
        var con_mod = Math.floor(monster.constitution/2) - 5
        console.log("Hit Roll")
        console.log("1d" + this.state.dnd_class_dict[this.state.class].hit_die + " + " + con_mod)
        var result = parse_roll("1d" + this.state.dnd_class_dict[this.state.class].hit_die + " + " + con_mod);
        console.log("Result", result)
        custom_roll['custom_roll'] = result;
        this.setState({roll: result})
        this.props.use_action(custom_roll)
    }

    complete() {
        this.props.level_up();
    }

    render() {
        var instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var props_to_pass = {
            instance: instance,
            monster: monster,
            resources: this.props.resources,
            equipment: this.props.equipment,

            push_modal_content: this.props.push_modal_content,
            use_action: this.props.use_action,
            add_action_token: this.props.add_action_token
        };


        if (this.state.class == '') {
            var dnd_class_options = [];
            for (var dnd_class of this.state.dnd_classes) {
                dnd_class_options.push({text: dnd_class.name, value:dnd_class.id});
            }

            var content = <FormWithChildren submit={this.submit_class}>
                    <Select label='Take a level in which class?' options={dnd_class_options} name='class'/>
                </FormWithChildren>

        }
        else if (!this.state.hp) {

            var roll = <Button text='Roll' type='primary' onClick={this.roll} />;
            if (this.state.roll) {
                roll = <p>You rolled a {this.state.roll.rolls[0][0].roll} + CON_MOD ({this.state.roll.modifiers[0]}) = {this.state.roll.total}</p>
            }

            var max_hp = <div className='hp' >
                <div style={{padding:'1% 10%'}} >
                    <div>Hit Point Maximum </div>
                    <FormWithChildren className='hp-max' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'health'} defaults={monster} >
                        <NumberInput sneaky_input={true} name={'hit_points'} style={{textAlign:'center'}} />
                    </FormWithChildren>
                    </div>
                </div>;

            var resources = <div className="fancy-char-sheet"><div className='resources row' ><ResourceList {...props_to_pass} update_instance={this.props.update_instance} update_monster={this.props.update_monster} /></div></div>;
            var dnd_class_dict = this.state.dnd_class_dict;
            console.log("Class", this.state.class, dnd_class_dict)
            var con_mod = Math.floor(monster.constitution/2) - 5
            var average_roll = Math.ceil((parseInt(dnd_class_dict[this.state.class].hit_die) + 1 )/ 2);

            var content = <div>
                <p>You chose to level up for the class, {dnd_class_dict[this.state.class].name}, and thus get an extra 1d{dnd_class_dict[this.state.class].hit_die} plus your constitution modifier ({con_mod}) more health or you can take the average roll of {average_roll} plus your constitution modifier ({con_mod}) more health. Once you roll you can't go back though! In addition you get an extra hit dice of d{dnd_class_dict[this.state.class].hit_die} for use during your short rests.</p>



                {roll}
                {max_hp}
                {resources}

                <Button text='Previous' type='danger' onClick={() => this.setState({class: ''})}/>
                <Button text='Next' type='success' onClick={() => this.setState({hp: true})}/>
            </div>
        }
        else if (!this.state.abilities) {
            var abilities =<ActionsForm monster={this.props.monster} update_monster={this.props.update_monster} push_modal_content={this.props.push_modal_content} card_inline={true} />;
            var resources = <div className="fancy-char-sheet"><div className='resources row' ><ResourceList {...props_to_pass} update_instance={this.props.update_instance} update_monster={this.props.update_monster} /></div></div>;
            var content = <div>
                <p>Choose your class and level and learn new abilities and add any new resources.</p>
                <Button text='Previous' type='danger' onClick={() => this.setState({hp: false})}/>
                <Button text='Next' type='success' onClick={() => this.setState({abilities: true})}/>
                {resources}
                {abilities}
            </div>
        }
        else {
            var spell_slots_by_level = {
                0: [0,0,0,0,0,0,0,0,0],
                1: [2,0,0,0,0,0,0,0,0],
                2: [3,0,0,0,0,0,0,0,0],
                3: [4,2,0,0,0,0,0,0,0],
                4: [4,3,0,0,0,0,0,0,0],
                5: [4,3,2,0,0,0,0,0,0],
                6: [4,3,3,0,0,0,0,0,0],
                7: [4,3,3,1,0,0,0,0,0],
                8: [4,3,3,2,0,0,0,0,0],
                9: [4,3,3,3,1,0,0,0,0],
                10: [4,3,3,3,2,0,0,0,0],
                11: [4,3,3,3,2,1,0,0,0],
                12: [4,3,3,3,2,1,0,0,0],
                13: [4,3,3,3,2,1,1,0,0],
                14: [4,3,3,3,2,1,1,0,0],
                15: [4,3,3,3,2,1,1,1,0],
                16: [4,3,3,3,2,1,1,1,0],
                17: [4,3,3,3,2,1,1,1,1],
                18: [4,3,3,3,2,1,1,1,1],
                19: [4,3,3,3,2,1,1,1,1],
                20: [4,3,3,3,2,1,1,1,1],
            }

            var spells = <FancySpellSheet {...this.props} />;
            var next_level = monster.level + 1;

            var spell_slots = <table className="table">
                <tr>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                </tr>
                <tr>
                    <td>{spell_slots_by_level[next_level][0]}</td>
                    <td>{spell_slots_by_level[next_level][1]}</td>
                    <td>{spell_slots_by_level[next_level][2]}</td>
                    <td>{spell_slots_by_level[next_level][3]}</td>
                    <td>{spell_slots_by_level[next_level][4]}</td>
                    <td>{spell_slots_by_level[next_level][5]}</td>
                    <td>{spell_slots_by_level[next_level][6]}</td>
                    <td>{spell_slots_by_level[next_level][7]}</td>
                    <td>{spell_slots_by_level[next_level][8]}</td>
                </tr>
            </table>;


            var content = <Container>
                <Button text='Previous' type='danger' onClick={() => this.setState({abilities: false})}/>
                <Button text='Complete' type='success' onClick={this.complete}/>
                <p>Make sure you have the right amount of spell slots as show below.</p>
                <h5>Spell Slots</h5>
                {spell_slots}

                {spells}
            </Container>
        }

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )

    }
}
