import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import {Wrapper, PageBreak} from 'library';

import parse_roll from 'projectLibrary/monsters/roll.js';
import stat_modifier from 'projectLibrary/stat_modifier.js';

export default class SavingThrowsAndSkills extends React.Component {
    constructor(props) {
        super(props);

        this.roll = this.roll.bind(this);
    }

    roll(name) {
        this.props.roll_skill(name);
    }

    render() {
        //cr to exp table https://www.dndbeyond.com/sources/basic-rules/monsters#Challenge

        var instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);
        var pb = monster.proficiency_bonus;

        var content = <div>
            <h3><a className="rollable" style={{fontSize:'inherit'}} onClick={() => this.roll('initiative')}>Initiative: {Math.floor(monster.dexterity/2) - 5}</a></h3>
            <h3><a className="rollable" style={{fontSize:'inherit'}} onClick={() => this.roll('death_saving_throw')}>Death Saving Throw</a></h3>
            <div style={{height:'10px'}}></div>
            <PageBreak />
            <div style={{height:'10px'}}></div>
            <h3>Saving Throws</h3>
            <table className="table">
                <tr>
                    <th><a className="rollable" onClick={() => this.roll('strength_saving_throw')}>STR</a></th>
                    <th><a className="rollable" onClick={() => this.roll('dexterity_saving_throw')}>DEX</a></th>
                    <th><a className="rollable" onClick={() => this.roll('constitution_saving_throw')}>CON</a></th>
                    <th><a className="rollable" onClick={() => this.roll('intelligence_saving_throw')}>INT</a></th>
                    <th><a className="rollable" onClick={() => this.roll('wisdom_saving_throw')}>WIS</a></th>
                    <th><a className="rollable" onClick={() => this.roll('charisma_saving_throw')}>CHA</a></th>
                </tr>
                <tr>
                    <td>{monster.strength_saving_throw * pb + stat_modifier(monster.strength)}</td>
                    <td>{monster.dexterity_saving_throw * pb + stat_modifier(monster.dexterity)}</td>
                    <td>{monster.constitution_saving_throw * pb + stat_modifier(monster.constitution)}</td>
                    <td>{monster.intelligence_saving_throw * pb + stat_modifier(monster.intelligence)}</td>
                    <td>{monster.wisdom_saving_throw * pb + stat_modifier(monster.wisdom)}</td>
                    <td>{monster.charisma_saving_throw * pb + stat_modifier(monster.charisma)}</td>
                </tr>
            </table>
            <div style={{height:'10px'}}></div>
            <PageBreak />
            <div style={{height:'10px'}}></div>
            <h3>Skills</h3>
            <div className="row">
                <div className="col-4">
                    <p><a className="rollable" onClick={() => this.roll('acrobatics')}><b>Acrobatics (DEX): </b>{monster.acrobatics * pb + stat_modifier(monster.dexterity)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('animal_handling')}><b>Animal Handling (WIS): </b>{monster.animal_handling * pb + stat_modifier(monster.wisdom)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('arcana')}><b>Arcana (INT): </b>{monster.arcana * pb + stat_modifier(monster.intelligence)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('athletics')}><b>Athletics (STR): </b>{monster.athletics * pb + stat_modifier(monster.strength)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('deception')}><b>Deception (CHA): </b>{monster.deception * pb + stat_modifier(monster.charisma)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('history')}><b>History (INT): </b>{monster.history * pb + stat_modifier(monster.intelligence)}</a></p>
                </div>
                <div className="col-4">
                    <p><a className="rollable" onClick={() => this.roll('insight')}><b>Insight (WIS): </b>{monster.insight * pb + stat_modifier(monster.wisdom)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('intimidation')}><b>Intimidation (CHA): </b>{monster.intimidation * pb + stat_modifier(monster.charisma)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('investigation')}><b>Investigation (INT): </b>{monster.investigation * pb + stat_modifier(monster.intelligence)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('medicine')}><b>Medicine (WIS): </b>{monster.medicine * pb + stat_modifier(monster.wisdom)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('nature')}><b>Nature (INT): </b>{monster.nature * pb + stat_modifier(monster.intelligence)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('perception')}><b>Perception (WIS): </b>{monster.perception * pb + stat_modifier(monster.wisdom)}</a></p>
                </div>
                <div className="col-4">
                    <p><a className="rollable" onClick={() => this.roll('performance')}><b>Performance (CHA): </b>{monster.performance * pb + stat_modifier(monster.charisma)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('persuasion')}><b>Persuasion (CHA): </b>{monster.persuasion * pb + stat_modifier(monster.charisma)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('religion')}><b>Religion (INT): </b>{monster.religion * pb + stat_modifier(monster.intelligence)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('sleight_of_hand')}><b>Sleight Of Hand (DEX): </b>{monster.sleight_of_hand * pb + stat_modifier(monster.dexterity)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('stealth')}><b>Stealth (DEX): </b>{monster.stealth * pb + stat_modifier(monster.dexterity)}</a></p>
                    <p><a className="rollable" onClick={() => this.roll('survival')}><b>Survival (WIS): </b>{monster.survival * pb + stat_modifier(monster.wisdom)}</a></p>
                </div>

            </div>

        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }


}
