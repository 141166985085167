
var ICON_COLORS = [
    [0, 168, 243],//Blue
    [255, 242, 0],//Yellow
    [184, 61, 186],//Purple
    [255, 174, 200],//Pink
    [255, 127, 39],//Orange
    [236, 28, 36],//Red
    [14, 209, 69],//Green
    [185, 122, 86],//Brown
];


function create_icon_colors(index){
    var color_index = index % ICON_COLORS.length;
    var loops = Math.floor(index / ICON_COLORS.length);

    var token_colors = [];

    if (loops > ICON_COLORS.length){
        console.error('TOO MANY TOKENS!');
    }
    else if (loops > 0){
        token_colors.push(
            'rgb('+ICON_COLORS[loops-1][0]+','+ICON_COLORS[loops-1][1]+','+ICON_COLORS[loops-1][2]+')'
        );
    }

    token_colors.push(
        'rgb('+ICON_COLORS[color_index][0]+','+ICON_COLORS[color_index][1]+','+ICON_COLORS[color_index][2]+')'
    );

    return token_colors;
}

export default create_icon_colors;
