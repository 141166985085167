import React, { Component } from 'react';
import { Checkbox, FormWithChildren, TextInput, NumberInput, Select } from 'library';


export default class PlayerMetaData extends React.Component {
    render (){
        var monster = this.props.monster;

        var monster_data = {
            name: monster.name,
            dnd_class: monster.dnd_class_id,
            dnd_subclass: monster.dnd_subclass_id,
            dnd_race: monster.dnd_race_id,
            level: monster.level,
        };

        return (
            <FormWithChildren className='player-metadata header col-12' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'player_metadata'} defaults={monster_data} >
                <TextInput className='player-name' sneaky_input={true} name={'name'} />
                <div className='top-block' ><div className='top-block-text' >
                    <div className='player-class inline-block'>
                        {'Class: '}
                        <Select sneaky_input={true} name="dnd_class" optionsUrl={'/api/compendium/DnDClass/'} optionsUrlMap={{'text':'{DnDClass.name}', value:'{DnDClass.id}'}} />
                    </div>
                    <div className='player-subclass inline-block'>
                        {'Subclass: '}
                        <Select sneaky_input={true} name="dnd_subclass" optionsUrl={'/api/compendium/DnDSubClass/'} optionsUrlMap={{'text':'{DnDSubClass.name}', value:'{DnDSubClass.id}'}} />
                    </div>
                    <div className='player-level inline-block'>
                        {'Level: '}
                        <NumberInput sneaky_input={true} name={'level'} />
                    </div>

                    <div className='player-race'>
                        {'Race: '}
                        <Select sneaky_input={true} name="dnd_race" optionsUrl={'/api/compendium/DnDRace/'} optionsUrlMap={{'text':'{DnDRace.name}', value:'{DnDRace.id}'}} />
                    </div>
                </div></div>
            </FormWithChildren>
        );
    }
}
