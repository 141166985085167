import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, TextInput, CSSInput} from 'library';

class Header extends React.Component {
    static component_name = 'Header';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'text'} name={'text'} default='' />,
                <TextInput label={'class'} name={'className'} default={''} />,
                <NumberInput label={'size'} name={'size'} default={3} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
            can_have_children: true,
        }
    }


    render() {
        var style = this.props.style || {};
        var className = this.props.className || '';

        var content = this.props.text;
        if (this.props.children && this.props.children.length > 0){
            content = this.props.children;
        }

        var header = <h1 className={className} style={style}>{content}</h1>;
        if (this.props.size) {
            if (this.props.size == 2) {
                header = <h2 className={className} style={style}>{content}</h2>;
            }
            else if (this.props.size == 3) {
                header = <h3 className={className} style={style}>{content}</h3>;
            }
            else if (this.props.size == 4) {
                header = <h4 className={className} style={style}>{content}</h4>;
            }
            else if (this.props.size == 5) {
                header = <h5 className={className} style={style}>{content}</h5>;
            }
            else if (this.props.size == 6) {
                header = <h6 className={className} style={style}>{content}</h6>;
            }
        }
        return (header);
    }
}

export default Header;
