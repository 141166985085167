import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import ActionCard from 'projectLibrary/action_card.js';

class Action extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
        this.toggle_prepared_action = this.toggle_prepared_action.bind(this);
        this.show_action = this.show_action.bind(this);
    }

    show_action() {
        if (this.props.card_inline) {
            this.setState({show: !this.state.show})
        }
        else {
            this.props.push_modal_content(<ActionCard action={this.props.action} />);
        }
    }

    toggle_prepared_action() {
        if (this.props.monster_learned_actions.indexOf(this.props.action.id) == -1) {
            this.props.add_action(this.props.action);
        }
        else {
            this.props.remove_action(this.props.action);
        }
    }

    render() {
        var action = this.props.action;

        if (this.props.monster_learned_actions.indexOf(action.id) == -1) {
            var prepare_button = <Button type='primary' text='Learn Action' onClick={this.toggle_prepared_action} />
        }
        else {
            var prepare_button = <Button type='danger' text='Remove Learned Action' onClick={this.toggle_prepared_action} />
        }

        var edit = <Button type='secondary' text='Edit Action' href={'/edit_action/' + action.id + '/'} target='_blank' />

        var content = [];
        content.push(
                <tr>
                    <td><a onClick={this.show_action}>{action.name}</a></td>
                    <td>{action.level}</td>
                    <td>{prepare_button}</td>
                    <td>{edit}</td>
                </tr>
            )

        if (this.state.show) {
            content.push(<ActionCard action={this.props.action} />)
        }

        return (content);

    }
}

export default class ActionsForm extends React.Component {
    constructor(props) {
        super(props);

        var learned_actions = [];
        if (this.props.monster && this.props.monster.action_spells) {
            for (var learned_action of this.props.monster.action_spells) {
                learned_actions.push(learned_action.actionspell.id);
            }
        }

        this.state = {
            search_name:'',
            search_class:'',
            search_level: '',
            action_list:[],
            monster_learned_actions:learned_actions,
            loaded: false
        };

        this.actions_callback = this.actions_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.add_action = this.add_action.bind(this);
        this.remove_action = this.remove_action.bind(this);
        this.update_monster_actions = this.update_monster_actions.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/actionspell/?exclude__action_type=Spell&related=dnd_classes&available_to_pc=True', {}, this.actions_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    add_action(action) {
        if (this.props.add_action) {
            this.props.add_action(action);
            var learned_actions = this.state.monster_learned_actions;
            learned_actions.push(action.id)
            this.setState({monster_learned_actions: learned_actions})
        }
        else {
            this.update_monster_actions(action.id);

            var learned_actions = this.state.monster_learned_actions;
            learned_actions.push(action.id)
            ajaxWrapper('POST','/api/compendium/monster/' + this.props.monster.id + '/', {'action_spells[]': learned_actions}, console.log)
            this.setState({monster_learned_actions: learned_actions})
        }
    }

    remove_action(action) {
        if (this.props.remove_action) {
            this.props.remove_action(action);
            var learned_actions = this.state.monster_learned_actions;
            var actions = [];
            for (var learned_action of learned_actions) {
                if (action.id != learned_action) {
                    actions.push(learned_action);
                }
            }
            this.setState({monster_learned_actions: actions});
        }
        else {
            this.update_monster_actions(undefined, action.id);

            var learned_actions = this.state.monster_learned_actions;
            var actions = [];
            for (var learned_action of learned_actions) {
                if (action.id != learned_action) {
                    actions.push(learned_action);
                }
            }

            ajaxWrapper('POST','/api/compendium/monster/' + this.props.monster.id + '/', {'action_spells[]': actions}, console.log)
            this.setState({monster_learned_actions: actions});
        }
    }

    update_monster_actions(add_id, remove_id){
        if (add_id){
            var existing_actions = this.props.monster.action_spells;
            for (var action of this.state.action_list){
                if (add_id == action.id){
                    existing_actions.push({actionspell:action});
                }
            }
            this.props.update_monster('add_action', {action_spells: existing_actions}, true);
        }
        else if (remove_id){
            var new_actions = [];
            for (var item of this.props.monster.action_spells) {
                var action = item['actionspell'];
                if (remove_id != action.id){
                    new_actions.push({actionspell: action});
                }
            }
            this.props.update_monster('remove_action', {action_spells: new_actions}, true);
        }
    }

    actions_callback(result) {
        var action_list = [];
        for (var item of result) {
            var action = item['actionspell'];
            action_list.push(action);
        }

        this.setState({'action_list':action_list, loaded:true})
    }

    render() {

        var actions = [];
        for (var action of this.state.action_list) {
            if (this.props.only_attacks && (!(action.action_damage[0]) || Object.keys(action.action_damage[0]) == 0)) {
                continue;
            }
            else if (this.props.only_abilities && (action.action_damage[0] && Object.keys(action.action_damage[0]) != 0)) {
                continue;
            }

            var found = true;
            if (this.state.search_class) {
                found = false;
                for (var item of action.dnd_classes) {
                    var dnd_class = item.dndclass;
                    if (dnd_class.id == this.state.search_class) {
                        found = true;
                    }
                }
            }

            if (found) {
                if (this.state.search_name == '' || action.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) > -1) {
                    if (this.state.search_school == '' || action.magic_school == this.state.search_school) {
                        if (this.state.search_level == '' || action.level == this.state.search_level) {
                            actions.push(<Action action={action} add_action={this.add_action} remove_action={this.remove_action}
                                card_inline={this.props.card_inline} monster_learned_actions={this.state.monster_learned_actions}
                                push_modal_content={this.props.push_modal_content} />);
                        }
                    }
                }
            }

        }

        var magic_schools = [
            {text:'Abjuration', value:'Abjuration'},
            {text:'Conjuration', value:'Conjuration'},
            {text:'Divinination', value:'Divinination'},
            {text:'Enchantment', value:'Enchantment'},
            {text:'Evocation', value:'Evocation'},
            {text:'Illusion', value:'Illusion'},
            {text:'Necromancy', value:'Necromancy'},
            {text:'Transmutation', value:'Transmutation'},
        ];

        var magic_levels = [
            {text:'Cantrip', value:0},
            {text:'1', value:1},
            {text:'2', value:2},
            {text:'3', value:3},
            {text:'4', value:4},
            {text:'5', value:5},
            {text:'6', value:6},
            {text:'7', value:7},
            {text:'8', value:8},
            {text:'9', value:9},
        ];


        var content = <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}} >
            <div>
                <h2>Actions</h2>
                <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                    <TextInput name='search_name' label='Search by Name' className="col-4" />
                    <Select name='search_level' label='Level' options={magic_levels} className="col-4" />
                    <Select name='search_class' label='Class' className="col-4" optionsUrl={'/api/compendium/DnDClass/'}
                        optionsUrlMap={{'text':'{DnDClass.name}', value:'{DnDClass.id}'}} />
                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th>School Of Magic</th>
                        <th>Level</th>
                        <th>Prepare</th>
                    </tr>
                    {actions}
                </table>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
