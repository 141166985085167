import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {CSSInput, TextInput} from 'library';

class Navbar extends React.Component {
    static component_name = 'Navbar';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput name='name' label='Name' />,
                <TextInput name='logo' label='Logo Url' />,
                <TextInput name='name_link' label='Url' />,
                <TextInput name='className' label='Class' />,
                <CSSInput label={'Css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var custom_style = {
            background: 'white'
        };
        custom_style = Object.assign(custom_style, this.props.style);

        var classes = 'navbar navbar-expand-lg navbar-light navbar-fixed-top ' + this.props.className;

        var links = [];

        for (var index in this.props.children) {
            links.push(<li key={index} className="nav-item">{this.props.children[index]}</li>);
        }

        var logo = null;
        var name = null;
        if (this.props.logo) {
            logo = <img src={this.props.logo} style={{height:'60px'}} />;
        }
        if (this.props.name) {
            name = this.props.name;
        }


        return (
            <nav className={classes} style={custom_style}>
                <a className="navbar-brand" href={this.props.name_link}>{logo} {name}</a>
                {/*<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>*/}
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav ml-auto">
                      {links}
                    </ul>
                </div>
            </nav>
        );
    }
}



export default Navbar;
