import React, { Component } from 'react';
import { Button } from 'library';


export default class SkillCheckPrompt extends React.Component {
    render() {
        var check_button = null;
        var dm_clear_check = null;

        if (this.props.ask_for_roll != '') {
            check_button = <Button onClick={this.props.roll_skill} text={'Roll ' + this.props.ask_for_roll} type='primary' />;
            if (this.props.dm) {
                dm_clear_check = <Button onClick={this.props.clear_roll} text='Clear Roll' type='danger' />;
            }
        }

        return (
            <div style={{position:'absolute', zIndex:10, borderRadius:'4px', top:'40px', left:'0px', padding:'4px', background:'white', boxShadow:'2px 2px 10px rgba(0,0,0,.2)'}} >
                {check_button}
                {dm_clear_check}
            </div>
        );
    }
}
