import React, { Component } from 'react';
import $ from 'jquery';
import {resolveVariables} from 'functions';
import Carousel from 'react-bootstrap/Carousel';
import {NumberInput, TextInput, CSSInput, Button} from 'library';

class CMCarousel extends React.Component {
    static component_name = 'Carousel';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />
            ],
            can_have_children: true,
        }

        this.state = {
            index: 0,
            direction: null,
        }

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedIndex, e) {
        this.setState({index: selectedIndex, direction: e.direction});
    };

    render() {
        var items = [];
        var children = [];
        for (var i in this.props.children){
            var child = this.props.children[i];
            children.push(child);

            if (children.length == 2){
                items.push(<Carousel.Item>
                    <div className='row' style={{margin:"0px 15px"}} >{children}</div>
                </Carousel.Item>);

                children = [];
            }
        }

        return (
            <Carousel activeIndex={this.state.index} direction={this.state.direction} onSelect={this.handleSelect}>
                {items}
            </Carousel>
        )
    }
}

export default CMCarousel;
