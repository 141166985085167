import { ajaxWrapper } from 'functions';


function update_monster_instance(context, state) {
    var instance = context.props.monster;
    for (var key in state) {
        if (instance[key] != undefined || key == 'conditions[]') {
            instance[key] = state[key];
        }
    }
    console.log("Instance After Set Global", instance);
    var submitUrl = '/api/home/monsterinstance/' + instance.id + '/';

    ajaxWrapper('POST', submitUrl, state, reload_instance_image_size.bind(context));


    var conditions = window.cmState.conditions;
    console.log("Conditions", conditions)
    console.log("Instance Conditions", instance['conditions[]'])

    if ('conditions[]' in instance){
        instance['conditions'] = [];
        for (var condition_id of state['conditions[]']){
            if (conditions[condition_id]){
                instance['conditions'].push({
                    'condition':conditions[condition_id]
                });
            }
        }
        delete instance['conditions[]'];
    }

    context.props.chat_socket.send_message({'message':{'icon': instance}});
}

function reload_instance_image_size(value){
    var instance = this.props.monster;
    var old_image_size = instance.image_size;
    if (typeof(old_image_size) == 'string'){
        old_image_size = JSON.parse(old_image_size);
    }

    var incoming_instance = value[0]['monsterinstance'];
    var new_image_size = incoming_instance.image_size;
    if (typeof(new_image_size) == 'string'){
        new_image_size = JSON.parse(new_image_size);
    }

    var image_exists = (new_image_size[0] > 0 && new_image_size[1] > 0);

    if (image_exists && (new_image_size[0] != old_image_size[0] || new_image_size[1] != old_image_size[1])){
        instance.image_size = incoming_instance.image_size;
        this.props.chat_socket.send_message({'message':{'icon': instance}});
    }
}


export default update_monster_instance;
