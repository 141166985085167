import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select,
    CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab } from 'library';
import stat_modifier from 'projectLibrary/stat_modifier.js';


export default class SkillChecks extends React.Component {
    constructor(props) {
        super(props);
    }

    roll(roll_type) {
        this.props.ask_for_roll(roll_type);
    }

    render() {
        return (
            <div>
                <h3><a className="rollable" style={{fontSize:'inherit'}} onClick={() => this.roll('initiative')}>Initiative</a></h3>
                <h3><a className="rollable" style={{fontSize:'inherit'}} onClick={() => this.roll('death_saving_throw')}>Death Saving Throw</a></h3>
                <h3>Saving Throws</h3>
                <table className="table">
                    <thead>
                        <tr>
                            <th><a className="rollable" onClick={() => this.roll('strength_saving_throw')}>STR</a></th>
                            <th><a className="rollable" onClick={() => this.roll('dexterity_saving_throw')}>DEX</a></th>
                            <th><a className="rollable" onClick={() => this.roll('constitution_saving_throw')}>CON</a></th>
                            <th><a className="rollable" onClick={() => this.roll('intelligence_saving_throw')}>INT</a></th>
                            <th><a className="rollable" onClick={() => this.roll('wisdom_saving_throw')}>WIS</a></th>
                            <th><a className="rollable" onClick={() => this.roll('charisma_saving_throw')}>CHA</a></th>
                        </tr>
                    </thead>
                </table>

                <h3>Skills</h3>
                <div className="row">
                    <div className="col-4">
                        <p><a className="rollable" onClick={() => this.roll('acrobatics')}>Acrobatics (DEX)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('animal_handling')}>Animal Handling (WIS)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('arcana')}>Arcana (INT)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('athletics')}>Athletics (STR)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('deception')}>Deception (CHA)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('history')}>History (INT)</a></p>
                    </div>
                    <div className="col-4">
                        <p><a className="rollable" onClick={() => this.roll('insight')}>Insight (WIS)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('intimidation')}>Intimidation (CHA)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('investigation')}>Investigation (INT)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('medicine')}>Medicine (WIS)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('nature')}>Nature (INT)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('perception')}>Perception (WIS)</a></p>
                    </div>
                    <div className="col-4">
                        <p><a className="rollable" onClick={() => this.roll('performance')}>Performance (CHA)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('persuasion')}>Persuasion (CHA)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('religion')}>Religion (INT)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('sleight_of_hand')}>Sleight Of Hand (DEX)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('stealth')}>Stealth (DEX)</a></p>
                        <p><a className="rollable" onClick={() => this.roll('survival')}>Survival (WIS)</a></p>
                    </div>

                </div>

            </div>
        )
    }
}
