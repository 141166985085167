import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';
import {TextInput, FormWithChildren, TextArea, Paragraph, Sidebar, Wrapper,
    Select, ImageInput, NumberInput, Button, Header, Image, Alert, PageBreak} from 'library';

var type_options = [
    {'text':'Location','value':'location'},
    {'text':'Shop','value':'shop'},
    {'text':'Region','value':'region'},
    {'text':'Interaction','value':'interaction'},
];


class Note extends React.Component {
    constructor(props) {
        super(props);

        this.toggle_private_public = this.toggle_private_public.bind(this);
    }

    toggle_private_public() {
        var private_status = false;
        if (this.props.note) {
            private_status = !this.props.note.private;
        }
        var location = this.props.note.location_id || this.props.note.location

        //save_note({id:this.props.note.id, private: private_status, location: location})
    }

    render() {
        var note = this.props.note;

        var button = null;
        var private_public = 'Make Private'
        if (note && note.private) {
            private_public = 'Make Public'
        }

        if (this.props.dm || (window.cmState.user && note.player == window.cmState.user.id)) {
            button = <Button type='primary' text={private_public} onClick={this.toggle_private_public} />
        }
        if (this.props.note) {
            return (
                <tr>
                    <td>{note.text}</td>
                    <td>{button}</td>
                </tr>
            )
        }
        else {
            return (null)
        }
    }
}


class EditLocation extends React.Component {
    constructor(props) {
        super(props);

        var show_notes = false;
        if (this.props.show_notes) {
            show_notes = this.props.show_notes
        }

        this.state = {
            location: {},
            tag_options: [],
            location_options: [],
            location_lookup: {},
            loaded: false,
            saved: false,
        }

        this.refresh_data = this.refresh_data.bind(this);
        this.location_callback = this.location_callback.bind(this);
        this.locations_callback = this.locations_callback.bind(this);
        this.tags_callback = this.tags_callback.bind(this);

        this.submit = this.submit.bind(this);
        this.delete = this.delete.bind(this);
        this.delete_callback = this.delete_callback.bind(this);
    }

    componentDidMount() {
        this.refresh_data();

        ajaxWrapper('GET','/api/home/tag/?order_by=name', {}, this.tags_callback);
        ajaxWrapper('GET','/api/home/location/?only=name&exclude__name=&order_by=name', {}, this.locations_callback);
    }

    refresh_data() {
        if (this.props.location_id){
            ajaxWrapper('GET','/api/home/location/' + this.props.location_id + '/?related=tags,user_who_added,parent', {}, this.location_callback);
        }
        else {
            this.setState({
                loaded: true,
            });
        }
    }

    location_callback(result) {
        var location = result[0]['location'];
        var tags = [];
        for (var tag of location['tags']){
            tags.push(tag['tag']['id']);
        }
        location['tags[]'] = tags;
        location['parent_has_map'] = location.parent['location_image'];
        location['parent'] = location.parent['id'];
        delete location.parent_id;

        this.setState({
            location: location,
            loaded: true,
        });
    }

    locations_callback(result){
        var locations = [];
        var location_lookup = {};

        for (var location of result){
            locations.push({
                'text': location['location']['name'],
                'value': location['location']['id'],
            });

            location_lookup[location['location']['id']] = location['location']['name'];
        }

        this.setState({
            location_options: locations,
            location_lookup: location_lookup,
        });
    }

    tags_callback(result){
        var tags = [];
        for (var tag of result){
            tags.push({
                'text': <div>
                    <Image src={tag['tag']['image']} style={{height:'65px', width:'auto'}} />
                    <span style={{fontSize:'20px', marginLeft:'10px'}} >{tag['tag']['name']}</span>
                </div>,
                'value': tag['tag']['id'],
            });
        }

        this.setState({
            tag_options: tags,
        });
    }

    submit(state, callback) {
        console.log("Submit!", state);

        if (this.props.location_id){
            this.setState({
                saved: true,
            }, this.refresh_data());
        }
        else {
            window.location = '/edit_location/'+ state[0]['location']['id'] +'/';
        }
    }

    delete(){
        if (this.props.location_id){
            var delete_url = '/api/home/location/' + this.props.location_id + '/delete/';
            ajaxWrapper("POST", delete_url, {}, this.delete_callback);
        }
    }

    delete_callback(result){
        window.location = '/my_locations/';
    }

    render () {
        var location = this.state.location;

        var map_size = null;
        if (location && location.location_image) {
            map_size = <NumberInput name='ft_in_width' label='Total image width in feet' />;
        }

        var form_key = 'location_details_form_' + this.props.date_key;
        if (this.state.location) {
            var form_key = 'location_details_form_' + location.id;
        }

        form_key += '_' + this.state.type;

        var submit_url = '/api/home/location/';
        var delete_url = null;
        if (this.props.location_id){
            submit_url += this.props.location_id + '/';
        }

        var owner = null;
        var user_who_added = window.cmState.user.id;
        if (location.user_who_added){
            var username = location.user_who_added['username'] || 'Someone missing a username!';
            owner = <Alert type='info' >
                {'Created By: ' + username}
            </Alert>;
            user_who_added = location.user_who_added['id'];
        }

        var children = [];
        var children_container = null;
        if (Object.keys(this.state.location_lookup).length > 0 && location.children){
            for (var child of location.children){
                var location_name = this.state.location_lookup[child['location']['id']];
                if (location_name){
                    children.push(<div className='col-3'><div className='card'>
                        <h5>{location_name}</h5>
                        <Button type='info' href={'/edit_location/'+ child['location']['id'] +'/'} text='Edit' target='_blank' />
                    </div></div>);
                }
            }

            if (children.length > 0){
                children_container = <div>
                    <h3>Children:</h3>
                    <div className='row'>
                        {children}
                    </div>
                </div>;
            }
        }

        var saved_message = null;
        if (this.state.saved) {
            saved_message = <Alert type='success' text='Saved' />;
        }

        var content = <div>
            {owner}
            <FormWithChildren key={form_key} defaults={location} submitUrl={submit_url} delete={this.delete} redirect={this.submit} >
                <TextInput name='name' label='Name' />
                <ImageInput name='location_image' label='Upload Image of Map' multiple={false} preview={true} preview_height={'500px'} bucket_name="mapquest-public" is_public={true} />

                <NumberInput name='ft_in_width' label='Total Image Width (ft)' />
                <NumberInput name='grid_size' label='How Large Should Icons Appear? (ft)' />

                <TextInput name='wiki_link' label='Website Link' />
                <TextArea name='description' label='Description' />
                <Select name='type' options={type_options} label='Type' />
                <Select multiple={true} options={this.state.tag_options} name='tags[]' label='Tags' radio={true} />
                <Select options={this.state.location_options} name='parent' label='Parent Location' />
                <TextInput name='user_who_added' default={user_who_added} style={{display:'none'}} />
            </FormWithChildren>
            {saved_message}
            <br/><br/>
            <PageBreak />
            <br/><br/>
            {children_container}
            <br/><br/>
        </div>;

        var notes = [];
        if (this.props.location_id){
            var location_notes = window.cmState.global_state_lookup(this, 'location_notes', this.props.location_id);
            if (location_notes) {
                for (var note of location_notes) {
                    notes.push(<Note note={note} refresh_data={this.refresh_data} dm={this.props.dm} />);
                }
            }
        }

        var notes_section = null;
        if (this.props.group) {
            var defaults = {
                text:'',
                private:true,
                group: this.props.group,
                player: window.cmState.user.id,
                location: this.props.location_id,
            };

            notes_section = <div>
            <h3>Notes</h3>
            <table className="table">
                <tr>
                    <th>Note</th>
                    <th>Make Public/Private</th>
                </tr>
                {notes}
            </table>
            <FormWithChildren submit={this.save_note} defaults={defaults}>
                <TextArea name='text' label='Note' />
                <Select name='private' options={[{'text':'Private', value:true}, {text:'Public', value:false}]} defaultoption={false} />
            </FormWithChildren>
            </div>;
        }

        var visit = null;
        if (!(location['id'])){

        }
        else if (location.location_image){
            var visit_text = <div>
                <Image src='https://mapquest-public.s3.amazonaws.com/22_05_11_19_56_55_wagon_icon.png' style={{verticalAlign: 'top', height: '25px', width:'auto'}} />
                <div style={{display:'inline-block', fontSize: '22px', paddingLeft: '6px', lineHeight: '25px'}}>{'Travel Here'}</div>
            </div>;
            visit = <div style={{marginBottom:'10px'}}>
                <Button type='success' text={visit_text} href={'/?id='+ location['id']} target='_blank' />
            </div>;
        }
        else if (location.parent_has_map){
            var visit_text = <div>
                <Image src='https://mapquest-public.s3.amazonaws.com/22_05_11_19_56_55_wagon_icon.png' style={{verticalAlign: 'top', height: '25px', width:'auto'}} />
                <div style={{display:'inline-block', fontSize: '16px', paddingLeft: '6px', lineHeight: '25px'}}>{'Travel to Parent Map'}</div>
            </div>;
            visit = <div style={{marginBottom:'10px'}}>
                <Button type='success' text={visit_text} href={'/?id='+ location['parent']} target='_blank' />
            </div>;
        }

        return (
            <Wrapper loaded={this.state.loaded} >
                <div className="container">
                    <h2>Edit Location: {location['name']}</h2>
                    {visit}
                    {content}
                    {notes_section}
                    <br/><br/>
                </div>
            </Wrapper>
        )
    }
}


export default EditLocation;
