import React, { Component } from 'react';
import {Button} from 'library';

export default class RollSave extends React.Component {
    constructor(props) {
        super(props);

        this.roll_skill = this.roll_skill.bind(this);
    }

    roll_skill() {
        const dc_check_lookups = {
            'STR': 'strength_saving_throw',
            'DEX': 'dexterity_saving_throw',
            'CON': 'constitution_saving_throw',
            'INT': 'intelligence_saving_throw',
            'WIS': 'wisdom_saving_throw',
            'CHA': 'charisma_saving_throw',
        }

        this.props.roll_skill(dc_check_lookups[this.props.dc_type])
    }

    render() {
        return (<Button type='primary' text={'Roll ' + this.props.dc_type + ' Save'} onClick={this.roll_skill} />)
    }
}
