import React, { Component } from 'react';
import { Checkbox, FormWithChildren } from 'library';
import stat_modifier from 'projectLibrary/stat_modifier.js';


class SavingThrow extends React.Component {
    render() {
        var value = this.props.value;
        if (!(value)){ value = 0;}
        var label = parseInt(value) * this.props.pb + stat_modifier(parseInt(this.props.stat));

        return (
            <div className='row'>
                <div className='col-4' ><Checkbox setFormState={this.props.setFormState} name={this.props.name} label={label} value={this.props.value} secret_value={1} /></div>
                <div className="rollable saving-throw col-8" onClick={() => this.props.roll_skill(this.props.name)} >{this.props.display_name}</div>
            </div>
        );
    }
}

export default class SavingThrowBlock extends React.Component {
    render (){
        var monster = this.props.monster;
        var pb = monster.proficiency_bonus;

        return (
            <FormWithChildren className='saving-throws' setGlobalState={this.props.update_monster} autoSetGlobalState={true} globalStateName={'saving_throw_block'} defaults={monster} >
                <div className='bottom-label bordered'>SAVING THROWS</div>
                <SavingThrow name='strength_saving_throw' pb={pb} stat={monster.strength} display_name='STR' roll_skill={this.props.roll_skill} />
                <SavingThrow name='dexterity_saving_throw' pb={pb} stat={monster.dexterity} display_name='DEX' roll_skill={this.props.roll_skill} />
                <SavingThrow name='constitution_saving_throw' pb={pb} stat={monster.constitution} display_name='CON' roll_skill={this.props.roll_skill} />

                <SavingThrow name='intelligence_saving_throw' pb={pb} stat={monster.intelligence} display_name='INT' roll_skill={this.props.roll_skill} />
                <SavingThrow name='wisdom_saving_throw' pb={pb} stat={monster.wisdom} display_name='WIS' roll_skill={this.props.roll_skill} />
                <SavingThrow name='charisma_saving_throw' pb={pb} stat={monster.charisma} display_name='CHA' roll_skill={this.props.roll_skill} />
            </FormWithChildren>
        );
    }
}
