import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';


var user_count = 0;

class Player extends React.Component {
    constructor(props) {
        super(props);

        this.make_dm = this.make_dm.bind(this);
        this.remove_player = this.remove_player.bind(this);
    }

    make_dm() {
        var current_dm = this.props.dm;
        var player = this.props.player;
        var group = this.props.group;

        ajaxWrapper('POST','/api/home/adventuringgroup/' + group.id + '/', {dm: player.user.id}, console.log);
        ajaxWrapper('POST','/api/home/playercharacter/' + player.id + '/delete/',{}, console.log);
        ajaxWrapper('POST','/api/home/playercharacter/',{user:current_dm.id, group: group.id}, this.props.refresh_data);
    }

    remove_player() {
        var player = this.props.player;
        var group = this.props.group;

        ajaxWrapper('POST','/api/home/playercharacter/' + player.id + '/delete/',{}, this.props.refresh_data);
    }

    render() {
        return (
            <tr>
                <td>{this.props.player.user.email}</td>
                <td><Button text='Make DM' type='danger' onClick={this.make_dm} deleteType={true} /></td>
                <td><Button text='Remove Player' type='danger' onClick={this.remove_player} deleteType={true} /></td>
            </tr>
        )
    }
}

export default class Group extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            dm: window.cmState.user.id,
            dm_id: window.cmState.user.id,
            loaded: false,
            player_characters: [],
        };

        this.group_callback = this.group_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.submit_redirect = this.submit_redirect.bind(this);
        this.add_player = this.add_player.bind(this);
        this.check_user = this.check_user.bind(this);
        this.refresh_data = this.refresh_data.bind(this);
    }

    componentDidMount() {
        if (this.props.group_id) {
            this.refresh_data();
        }
        else {
            this.setState({loaded:true})
        }
    }

    refresh_data() {
        ajaxWrapper('GET','/api/home/adventuringgroup/' + this.props.group_id + '/?related=dm,player_characters,player_characters__user', {}, this.group_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    submit_redirect(result) {
        console.log("Result", result);
        window.location.href = '/group/' + result[0]['adventuringgroup']['id'];
    }

    group_callback(result) {
        var group = result[0]['adventuringgroup'];
        group['loaded'] = true;

        //group['dm_email'] = group['dm']['email']
        console.log("Group", group);
        this.setState(group);
    }

    add_player(result, callback) {
        var email = result['email'];
        ajaxWrapper('GET','/api/user/user/?email=' + email, {}, this.check_user);
        callback();
    }

    check_user(result) {
        if (result.length == 0) {
            ajaxWrapper('POST','/api/user/user/', {email:this.state.email, password:'default'}, this.check_user);
        }
        else {
            ajaxWrapper('POST', '/api/home/playercharacter/', {'user':result[0]['user']['id'], group: this.props.group_id}, this.refresh_data);
            ajaxWrapper('POST','/api/email/', {'to_email':this.state.email, 'subject': 'Join the ' + this.state.name + ' on Forgotten Maps', text:'Join your friends on forgotten maps. You were invited by ' + window.cmState.user.email + '.'}, console.log)
        }
    }

    render() {
        var submitUrl = '/api/home/adventuringgroup/';
        var dm = null;
        var invite_players = null;
        if (this.props.group_id) {
            submitUrl = '/api/home/adventuringgroup/' + this.props.group_id + '/';
            dm = <p>This group's dm is: {this.state.dm.email}</p>
            invite_players = <FormWithChildren defaults={{email:''}} submit={this.add_player} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'invite_player'} submit_text='Invite'>
                <TextInput label='Email' name='email' />
            </FormWithChildren>
        }

        var current_players = [];
        for (var item of this.state.player_characters) {
            var player = item.playercharacter;
            current_players.push(<Player player={player} group={this.state} dm={this.state.dm} refresh_data={this.refresh_data} />)
        }

        var content = <div className="container">
            <div>
                <br/>
                <h2>Add/Edit Group</h2>
                <br/>

                <div className='card-container' >
                    <div className='card'>
                        {dm}
                        <FormWithChildren defaults={this.state} submitUrl={submitUrl} redirect={this.submit_redirect}>
                            <TextInput name='name' label='Name' />
                        </FormWithChildren>
                    </div>

                    <div className='card'>
                        <h3>Current Players</h3>
                        <table className="table">
                            <tr>

                            </tr>
                            {current_players}
                        </table>
                        {invite_players}
                    </div>
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
