import React, { Component } from 'react';
import RollSave from 'projectLibrary/monsters/roll_save.js';


export default class ConcentrationRoll extends React.Component {
    render() {
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var roll_save = <RollSave dc_type={'CON'} roll_skill={this.props.roll_skill} />
        if (this.props.public) {
            roll_save = null;
        }

        var dc_check = <div className='dc-check col-12' >
            {'DC ' + this.props.dc + ' CON'}
            <br />
            {roll_save}
        </div>;

        var concentration = window.cmState.global_state_lookup(this, 'actions', this.props.concentration_id);
        var name = 'CON Save to keep concentrating on ' + concentration.name;

        if (this.props.full_display) {
            return(
                <div className='chat-entry' >
                    <div className='chat-header' >
                        <span>{monster.name}</span>
                        <span>{' : '}</span>
                        {name}
                     </div>
                     <div className='hit-section row'>
                         {dc_check}
                     </div>
                </div>
            )
        }
        else {
            return (null);
        }

    }
}
