import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {CSSInput} from 'library';

class TableRow extends React.Component {
    static component_name = 'TableRow';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {

        return (
            <tr style={this.props.style || {}}>
                {this.props.children}
            </tr>
        );
    }
}

export default TableRow;
