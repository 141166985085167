import React, { Component } from 'react'
import * as THREE from 'three';
import * as CANNON from 'cannon';
import * as DiceManagerClass from 'threejs-dice';
import {Button} from 'library';

export default class Scene extends Component {
  constructor(props) {
    super(props)

    this.start = this.start.bind(this)
    this.stop = this.stop.bind(this)
    this.animate = this.animate.bind(this)
  }

  componentDidMount() {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight
    var world = new CANNON.World();

    var DiceManager = window.Dice.DiceManager;
    DiceManager.setWorld(world);

    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(
      75,
      width / height,
      0.1,
      1000
    )
    const renderer = new THREE.WebGLRenderer({ antialias: true, alpha:true })
    var DiceD6 = window.Dice.DiceD6;
    const dice = new DiceD6({backColor: '#ff0000'});

    camera.position.z = 4
    scene.add(dice.getObject())
    renderer.setClearColor('#000000', .5)
    renderer.setSize(width, height)

    this.world = world;
    this.scene = scene;
    this.camera = camera;
    this.renderer = renderer;
    this.dice = dice;

    dice.getObject().position.x = 150;
    dice.getObject().position.y = 100;
    dice.getObject().rotation.x = 20 * Math.PI / 180;
    dice.updateBodyFromMesh();

    DiceManager.prepareValues([{dice: dice, value: 6}]);


    this.mount.appendChild(this.renderer.domElement)
  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  start() {
    if (!this.frameId) {
        console.log("Starting!")
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {
      console.log("Animate!")
      this.world.step(1.0 / 60.0);

      this.dice.updateMeshFromBody(); // Call this after updating the physics world for rearranging the mesh according to the body

      this.renderer.render(this.scene, this.camera);

      this.frameId = window.requestAnimationFrame(this.animate)
  }

  renderScene() {
    this.renderer.render(this.scene, this.camera)
  }

  render() {
    return (
      [<Button type='primary' text='Roll' onClick={this.start} />,
      <p style={{position:'absolute', top:'400px', left:'400px' }}>Hello there beautiful</p>,
      <div
        onClick={this.stop}
        style={{ width: '800px', height: '800px'}}
        ref={(mount) => { this.mount = mount }}
      />,
      ]
    )
  }
}
