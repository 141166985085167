import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput, Select} from 'library';

class Video extends React.Component {
    static component_name = 'Video';
    constructor(props) {
        super(props);

        this.state = {
            width: 640,
            playing: false
        }

        this.config = {
            form_components: [
                <TextInput label={'Video URL'} name={'video_url'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <CSSInput label={'child css'} name={'child_style'} default={{}} />,
                <Select label={'autoplay'} name={'autoplay'} boolean={false} />,
            ],
            can_have_children: true,
        }

        this.playVideo = this.playVideo.bind(this);
        this.pauseVideo = this.pauseVideo.bind(this);

        this.container = React.createRef();
    }

    componentDidMount(){
        var newWidth = this.container.current.offsetWidth;
        this.setState({width: newWidth});
    }

    playVideo() {
        // You can use the play method as normal on your video ref
        this.refs.vidRef.play();
        this.setState({playing: true})
    }

    pauseVideo() {
        // Pause as well
        if (this.state.playing) {
            this.refs.vidRef.pause();
            this.setState({playing: false})
        }
    }

    // You can pass your function references to your child components as props (here passing down to the Buttons component)
    render() {

        var child_style = this.props.child_style || {position: 'absolute', top:'40%', left:'45%'}

        var play = null;
        if (!this.state.playing) {
        play = <div id='playButton' onClick={this.playVideo} style={child_style}>
        {this.props.children}
        </div>;
        }

        var video_type = 'mp4';
        if (this.props.video_url) {
            var video_type = this.props.video_url.substring(this.props.video_url.length - 3, this.props.video_url.length);
        }



        var video = null;
        if (this.props.video_url && this.props.video_url.indexOf('player.vimeo.com') > -1) {
            var width = this.state.width;
            var height = (width/640) * 360;
            video = <iframe src={this.props.video_url} width={width} height={height} allow="autoplay; fullscreen" allowfullscreen></iframe>;
        }
        else if (this.props.video_url && this.props.video_url.indexOf('youtube.com') > -1) {
            video = <div style={{width:'560px', margin:'auto'}}><iframe width="560" height="315" src={this.props.video_url} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
        }
        else {
            if (this.props.autoplay) {
                video = <video autoPlay muted ref="vidRef" src={this.props.video_url} type={"video/" + video_type} style={{width:'100%'}}></video>;
            } else {
                video = <div onClick={this.pauseVideo} style={{position:'relative'}}>
                    {play}
                    <video ref="vidRef" src={this.props.video_url} type={"video/" + video_type} style={{width:'100%'}}></video>
                </div>;
            }
        }

        return(
            <div style={this.props.style || {}} ref={this.container}>
                {video}
            </div>
        );
    }
}


export default Video;
