import React, { Component } from 'react';
import { Button } from 'library';
import Token from 'projectLibrary/session_tools/token.js';


export default class LocationToken extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'zoom');
    }

    render() {
        var location = this.props.location;
        var parent_location = this.props.parent_location;

        var map_link = <p>{location.name}</p>;
        if (location.image) {
            var link_href = '/?id=' + location.id;
            if (this.props.group) {
                link_href = '/?id=' + location.id + '&group_id=' + this.props.group;
            }
            map_link = <a href={link_href} target='_blank'>{location.name}</a>;
        }

        var wiki_link = null;
        if (location.wiki_link) {
            wiki_link = <a href={location.wiki_link} target='_blank'>Forgotten Realms Wiki</a>;
        }

        var icon_image = {
            'image': 'https://mapquest-public.s3.amazonaws.com/22_05_09_13_50_13_construction_marker.png',
            'shadowUrl': 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            'image_size': [390, 517],
            'shadowSize': [41, 41]
        }; // Default Construction Icon

        if (location['tags']) {
            for (var item of location['tags']) {
                var tag = item['tag'];
                if (tag['image']) {
                    icon_image = tag['details'];
                    icon_image['image'] = tag['image'];
                    break;
                }
            }
        }

        var more_dots = null;
        if (location.description) {
            var more_dots = location.description.length > 100 ? '...' : '';
        }

        var current_location = [
            location.coords.x * window.cmState.zoom,
            location.coords.y * window.cmState.zoom,
        ];

        location.share_with_players = false;
        if (!window.cmState.group) {
            location.share_with_players = true;
        }

        var permission = window.cmState.global_state_lookup(this, 'location_permissions', location.id);
        if (permission && permission.show_players) {
            location.share_with_players = true;
        }

        location.image = icon_image.image;
        location.image_size = icon_image.image_size;
        location.location = true;

        var proportional_zoom = window.cmState.zoom;
        if (proportional_zoom < 2){
            proportional_zoom = 2;
            if (proportional_zoom > window.cmState.zoom * 5){
                proportional_zoom = window.cmState.zoom * 5;
            }
        }

        var highlighted = false;
        if (window.cmState.selected_monster_instance && location['id'] == window.cmState.selected_monster_instance['id']){
            highlighted = true;
        }

        return (
            <Token icon={location} type={'pin'} zoom={proportional_zoom} anchor={'bottom'} highlighted={highlighted} dm={true} draggable={this.props.draggable}
                start_token_drag={this.props.start_token_drag} move_token_drag={this.props.move_token_drag} end_token_drag={this.props.end_token_drag}
                current_location={current_location} index={this.props.index} pixels_between_lines={this.props.pixels_between_lines} />
        );


    }
}
