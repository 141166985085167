// CheckoutForm.js
import React from 'react';
import {injectStripe} from 'react-stripe-elements';
import {ajaxWrapper, run_functions} from 'functions';
import {Alert} from 'library';

import StripeCard from './stripecard.js';
class StripeForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {saving: false, saved: false};

        this.stripeCallback = this.stripeCallback.bind(this);
        this.stripeFailback = this.stripeFailback.bind(this);
    }

    handleSubmit = (ev) => {
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();

        // Within the context of `Elements`, this call to createToken knows which Element to
        // tokenize, since there's only one in this group.
        this.props.stripe.createToken({name: this.props.email}).then(({token}) => {
            console.log("Stripe Ajax Request", {'token':token.id, 'description':this.props.description, price: this.props.price});
            ajaxWrapper("POST", this.props.url, {'token':token.id, 'description':this.props.description, price: this.props.price}, this.stripeCallback, this.stripeFailback);
        });

        // However, this line of code will do the same thing:
        //
        // this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});

        // You can also use createSource to create Sources. See our Sources
        // documentation for more: https://stripe.com/docs/stripe-js/reference#stripe-create-source
        //
        // this.props.stripe.createSource({type: 'card', name: 'Jenny Rosen'});

        this.setState({saving: true});
    };

    stripeCallback(value) {
        console.log("Value", value);

        if ('error' in value){
            this.stripeFailback(value['error']);
        }
        else {
            run_functions(this.props.functions);
        }

        this.setState({saving: false, saved: true});
    }

    stripeFailback(value){
        this.setState({
            error: value,
            saving: false
        });
    }

    render() {
        var message = null;
        if (this.state.error){
            message = <Alert type={'danger'} text={this.state.error} />;
        }

        var btn_style = {marginTop: "20px"};
        var button = <button className="btn btn-primary" style={btn_style} >{this.props.submit || "Pay"}</button>;
        if (this.state.saving) {
            button = <button className="btn btn-primary disabled" disabled="disabled" style={btn_style} >{this.props.submit || "Pay"}</button>;
        }
        else if (this.state.saved) {
            button = null;
        }

        return (
            <form onSubmit={this.handleSubmit}>
            {message}
            <StripeCard />
            <img src="https://componentmadness-images.s3.amazonaws.com/19_11_21_20_51_00_secure-stripe-payment-logo.png"
                style={{width: "400px", float: "right", maxWidth: '100%'}} />
            {button}
            </form>
        );
    }
}

export default injectStripe(StripeForm);
