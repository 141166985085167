import React, { Component } from 'react';
import { ajaxWrapper } from "functions";

import * as Sentry from "@sentry/browser";


class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            has_error: false
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { has_error: true };
    }

    componentDidCatch(error, info) {
        var pathname = window.location.pathname + window.location.search;
        error.name = 'React ' + error.name + ' at ' + pathname;
        var global_context = JSON.parse(JSON.stringify(window.cmState.getGlobalState()));

        Sentry.withScope(scope => {
            Object.keys(info).forEach(key => {
                scope.setExtra(key, info[key]);
            });
            Object.keys(global_context).forEach(key => {
                scope.setExtra('global_context_' + key, global_context[key]);
            });
            Sentry.captureException(error);
        });
    }


    render() {
        if (this.state.has_error) {
            // You can render any custom fallback UI
            return <div>
                <div style={{textAlign:'center', boxShadow:'2px 2px 10px rgba(0,0,0,.1)', padding:'10px'}} >
                    <img src="https://mapquest-public.s3.amazonaws.com/21_01_01_17_41_26_icon_monster.jpg" style={{height: '75px'}} />
                    <strong style={{fontSize:'30px', padding: '2px 15px', display: 'inline-block'}} >{'Forgotten Maps'}</strong>
                </div>

                <br/>

                <div style={{textAlign:'center'}} >
                    <h1>Something went wrong.  Our team has been notified.</h1>
                </div>
            </div>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
