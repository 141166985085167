import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup,
    ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab, Container, Link, Checkbox } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import { CRS } from 'leaflet';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import replace_roll_vars from 'projectLibrary/monsters/replace_roll_vars.js';
import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';
import stat_modifier from 'projectLibrary/stat_modifier.js';
import StatBlock from 'projectLibrary/monsters/stat_block/stat_block.js';
import Spells from 'projectLibrary/monsters/stat_block/spells.js';
import SavingThrowsAndSkills from 'projectLibrary/monsters/stat_block/saving_throws_and_skills.js';
import ActionUse from 'projectLibrary/monsters/action_use.js';
import ModalFancyCharSheet from 'projectLibrary/monsters/fancy_char_sheet/modal_version.js';
import LevelUp from 'projectLibrary/forms/level_up.js';

import save_monster_instance from 'projectLibrary/session_tools/global_data/save_monster_instance.js';


class RecoveryRoll extends React.Component {
    constructor(props) {
        super(props);

        this.roll_to_recover = this.roll_to_recover.bind(this);
    }

    roll_to_recover() {
        var roll = parse_roll("1d6");

        var action_use =  {
            custom_roll: roll,
            hits:[null, null],
            dc: null,
            damages:[],
            action: {'name': this.props.resource.name + ' Recovery Roll'},
            instance_id: this.props.instance.id,
            cast_level: null,
        };

        this.setState({
            roll: <ActionUse {...action_use} push_modal_content={this.props.push_modal_content} />
        });

        this.props.use_action(action_use);

        if (roll.total >= 5) {
            this.props.take_damage(this.props.resource.resource_details.id, -1)
        }
    }

    render() {
        return (
            <Button type='primary' text='Roll To Recover' onClick={this.roll_to_recover} />
        )
    }
}

class RecoveryTurn extends React.Component {
    constructor(props) {
        super(props);

        this.recover = this.recover.bind(this);
    }

    recover() {
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var resource_id = this.props.resource;

        var current = 0;
        for (var item of this.props.instance.monster_instance_resources) {
            var instance_resource = item.monsterinstanceresource;
            if (instance_resource.resource_id == this.props.resource.resource_details.id) {
                current = instance_resource.quantity;
                break;
            }
        }

        var max = 0;
        for (var item of monster.monster_resources) {
            var monster_resource = item.monsterresource;
            if (monster_resource.resource_id == this.props.resource.resource_details.id) {
                max = monster_resource.quantity;
                break;
            }
        }

        var diff = current - max;
        this.props.take_damage(this.props.resource.resource_details.id, diff);
    }

    render() {
        return (
            <Button type='primary' text='New Turn' onClick={this.recover} />
        )
    }
}

class TimeFilter extends React.Component {
    constructor(props) {
        super(props);

        this.set_time_filter = this.set_time_filter.bind(this);
    }

    set_time_filter() {
        this.props.set_time_filter(this.props.option[1]);
    }

    render() {
        var active = '';
        if (this.props.option[1] == this.props.action_time_to_use) {
            active = 'active';
        }

        return (
            <li class="nav-item">
                <a class={"nav-link " + active} onClick={this.set_time_filter}>{this.props.option[0]}</a>
            </li>
        )
    }
}

export default class StatBlockMini extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display_tab:'Actions',
            add: null,
            action_time_to_use: 'All',
        }

        this.select_tab = this.select_tab.bind(this);
        this.remove_monster_instance = this.remove_monster_instance.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.toggle_full_block = this.toggle_full_block.bind(this);
        this.toggle_saving_throws_and_skills = this.toggle_saving_throws_and_skills.bind(this);
        this.set_time_filter = this.set_time_filter.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'equipment');
        window.cmState.subscribe_by_name(this, 'conditions_select');
        window.cmState.subscribe_by_name(this, 'resources');
        window.cmState.subscribe_by_name(this, 'instances');
        window.cmState.subscribe_by_name(this, 'selected_monster_instance')
    }

    stop_concentration() {
        var data = {'id': window.cmState.selected_monster_instance.id, 'concentrating_on':null};
        save_monster_instance(data);
    }

    toggle_full_block() {
        this.props.push_modal_content(
            <ModalFancyCharSheet roll_skill={this.props.roll_skill}
                push_modal_content={this.props.push_modal_content} use_action={this.props.use_action} add_action_token={this.props.add_action_token} />
        );
    }

    toggle_saving_throws_and_skills() {
        this.props.push_modal_content(<SavingThrowsAndSkills {...this.props} />);
    }

    setGlobalState(name, state) {
        state['id'] = window.cmState.selected_monster_instance.id;
        save_monster_instance(state);
    }

    set_time_filter(filter) {
        this.setState({action_time_to_use: filter});
    }

    select_tab(name) {
        this.setState({display_tab:name});
    }

    remove_monster_instance() {
        var selected_monster_instance = window.cmState.selected_monster_instance;
        var id = selected_monster_instance.id;

        this.props.remove_monster_instance(id);
    }

    render() {
        //cr to exp table https://www.dndbeyond.com/sources/basic-rules/monsters#Challenge
        var full_block_button_style = {
            position: 'absolute',
            top: '10px',
            right: '0px',
        };

        var selected_monster_instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', selected_monster_instance.monster_id);

        var special_abilities = [];
        var spells = [];
        var innate_spells = [];
        var actions = [];
        var legendary_actions = [];
        var lair_actions = [];

        //finding usable resources
        var usable_resources = {};
        for (var item of monster.monster_resources) {
            var monster_resource = item.monsterresource;
            var resource = window.cmState.global_state_lookup(this, 'resources', monster_resource.resource_id);
            if (resource && resource.name.indexOf('Spell Slot Level') == -1) {
                usable_resources[monster_resource.resource_id] = {
                    max: monster_resource.quantity,
                    current:0, name: resource.name,
                    actions:[],
                    resource_details: resource
                }
            }
        }

        for (var item of selected_monster_instance.monster_instance_resources) {
            var instance_resource = item.monsterinstanceresource;
            var resource_name = window.cmState.global_state_lookup(this, 'resources', instance_resource.resource_id).name;
            if (resource_name && resource_name.indexOf('Spell Slot Level') == -1) {
                usable_resources[instance_resource.resource_id]['current'] = instance_resource.quantity
            }
        }

        console.log("Instance Equipment", selected_monster_instance.equipped_items);
        for (var item of selected_monster_instance.equipped_items) {
            var equipment_instance = item.instanceequippeditem;
            var equipment = window.cmState.global_state_lookup(this, 'equipment', equipment_instance.equipment_id);
            console.log("Equipment Instance Equipment", equipment.name);

            for (var item of equipment_instance.instance_equipment_resources) {
                var equipment_instance_resource = item.instanceequippeditemresource;

                var equipment_resource = null;
                for (var item of equipment.resources){
                    var resource = item.equipmentresource;
                    if (resource.resource_id == equipment_instance_resource.resource_id){
                        equipment_resource = resource;
                    }
                }

                var resource = window.cmState.global_state_lookup(this, 'resources', equipment_instance_resource.resource_id);
                if (resource && resource.name.indexOf('Spell Slot Level') == -1) {
                    usable_resources[equipment_instance_resource.resource_id] = {
                        max: equipment_resource.quantity,
                        current:equipment_instance_resource.quantity,
                        name: resource.name,
                        actions:[],
                        resource_details: resource
                    };
                }
            }
        }



        var spell_list = []
        for (var item of monster.action_spells) {
            var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);
            if (this.state.action_time_to_use != 'All' && this.state.action_time_to_use.toLowerCase() != action.time_to_use.toLowerCase()) {
                continue;
            }

            var found = false;
            for (var item of action.resource_costs) {
                var cost = item.resourcecost;
                if (usable_resources[cost.resource_id]) {
                    usable_resources[cost.resource_id]['actions'].push(
                        <Action action={action} use_action={this.props.use_action} instance={selected_monster_instance}
                        mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                    );
                    found = true;
                    usable_resources[cost.resource_id]['action_type'] = action['action_type']
                }
            }

            if (found) {
                //this was added to handle someone who had both an innate spell and learned spell
                if (action.action_type == 'Spell') {
                    spell_list.push(action);
                }
                continue;
            }

            if (action.action_type == 'Ability') {
                special_abilities.push(
                    <Action action={action} use_action={this.props.use_action} instance={selected_monster_instance} mini={true}
                        push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />)
            }
            else if (action.action_type == 'Attack') {
                if (action.name == "Multiattack") {
                    actions.unshift(<Action action={action} use_action={this.props.use_action} instance={selected_monster_instance}
                        mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />);
                }
                else {
                    actions.push(<Action action={action} use_action={this.props.use_action} instance={selected_monster_instance}
                        mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />);
                }
            }
            else {
                console.log("Spell gets here!");
                spell_list.push(action);
            }
        }


        for (var item of monster.equipped_items) {
            var equipped_item = item.equippeditem;
            var equipment = window.cmState.global_state_lookup(this, 'equipment', equipped_item.equipment_id);

            for (var item of equipment.actions) {
                var equipment_action_cost = item['equipmentactioncost'];
                var action = window.cmState.global_state_lookup(this, 'actions', equipment_action_cost.action_spell_id);

                if (this.state.action_time_to_use != 'All' && this.state.action_time_to_use.toLowerCase() != action.time_to_use.toLowerCase()) {
                    continue;
                }

                var sizes = ['Huge ', 'Large ', 'Gargantuan '];
                for (var size of sizes) {
                    if (action['name'].indexOf(size) > -1) {
                        action['name'] = action['name'].replace(size,'')
                    }
                }

                var description = '';
                if (equipment.category_range.indexOf('Ranged') > -1) {
                    description += 'Ranged Attack: '
                }
                else {
                    description += 'Melee Attack: '
                }

                if (action.attack_roll && action.attack_roll.attack_roll) {
                    var attack_mod = parse_roll(replace_roll_vars(action.attack_roll.attack_roll, monster)).total;
                    var range = action.range;
                    if (action.range_long){ range += '/' + action.range_long;}

                    description += '+' + attack_mod + ' to hit, range ' + range + 'ft., one target.';
                }

                if (action.action_damage) {
                    for (var key_that_should_be_index in action.action_damage) {
                        var damage = action.action_damage[key_that_should_be_index];

                        if (damage.damage_roll) {
                            var damage_roll = replace_roll_vars(damage.damage_roll, monster)
                            if (damage_roll.startsWith('+ ')) {
                                damage_roll = damage_roll.replace('+ ','')
                            }
                            var damage_roll_split = damage_roll.split(' ');
                            var multiplier = 1;
                            var average = 0;

                            for (var split of damage_roll_split) {
                                if (split == '+') {
                                    multiplier = 1;
                                }
                                else if (split == '-') {
                                    multiplier = -1;
                                }
                                else if (split.indexOf('d') > -1) {
                                    var d_split = split.split('d')
                                    average += parseInt(d_split[0]) * (parseInt(d_split[1]) + 1)/2 * multiplier;
                                }
                                else {
                                    average += parseInt(split) * multiplier;
                                }
                            }

                            average = Math.floor(average);
                            description += 'Hit ' + average + ' (' + damage_roll + ') ' + damage.damage_type + ' damage.';
                        }
                    }
                }

                action['description'] = description;

                var found = false;
                for (var item of action.resource_costs) {
                    var cost = item.resourcecost;
                    if (usable_resources[cost.resource_id]) {
                        usable_resources[cost.resource_id]['actions'].push(
                            <Action action={action} use_action={this.props.use_action} instance={selected_monster_instance}
                            mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                        );
                        found = true;
                        usable_resources[cost.resource_id]['action_type'] = action['action_type']
                    }
                }

                if (found) {
                    //this was added to handle someone who had both an innate spell and learned spell
                    if (action.action_type == 'Spell') {
                        spell_list.push(action);
                    }
                    continue;
                }

                if (action.action_type == 'Ability') {
                    special_abilities.push(<Action action={action} equipment={equipment} use_action={this.props.use_action} instance={selected_monster_instance}
                        mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />);
                }
                else if (action.action_type == 'Attack'){
                    if (action.name == "Multiattack") {
                        actions.unshift(<Action action={action} equipment={equipment} use_action={this.props.use_action} instance={selected_monster_instance}
                            mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />);
                    }
                    else {
                        actions.push(<Action action={action} equipment={equipment} use_action={this.props.use_action} instance={selected_monster_instance}
                            mini={true} push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />);
                    }
                }
                else {
                    spell_list.push(action);
                }
            }
        }

        var costly_actions = [];
        for (var key in usable_resources) {
            var resource = usable_resources[key];

            if (resource.resource_details['recovery_type'] == 'at will') {
                var usage = "(At Will)";
            }
            else if (resource.resource_details['recovery_type'] == 'roll') {
                var usage = <span>({resource.current}/{resource.max})
                <RecoveryRoll take_damage={this.props.take_damage} resource={resource} use_action={this.props.use_action}
                    instance={selected_monster_instance} /></span>;
            }
            else if (resource.resource_details['recovery_type'] == 'per_turn') {
                var usage = <span>({resource.current}/{resource.max})
                <RecoveryTurn take_damage={this.props.take_damage} resource={resource} use_action={this.props.use_action}
                    instance={selected_monster_instance} /></span>;
            }
            else {
                var usage = "(" + resource.current + "/" + resource.max + ")";
            }

            var display_cost = <div><h3 style={{fontSize:'14px'}}>{resource.name} {usage}</h3>
                <div class="property-block">
                    {resource.actions}
                </div>
            </div>;

            if (resource.action_type == 'Ability') {
                special_abilities.push(display_cost);
            }
            else if (resource.action_type == 'Attack') {
                actions.push(display_cost);
            }
            else if (resource.action_type == 'Spell') {
                innate_spells.push(display_cost);
            }
            else {
                costly_actions.push(display_cost);
            }
        }


        if (monster.prepared_spells && monster.prepared_spells.length > 0) {
            var spell_list = [];
            for (var item of monster.prepared_spells) {
                var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);
                if (this.state.action_time_to_use != 'All' && this.state.action_time_to_use.toLowerCase() != action.time_to_use.toLowerCase()) {
                    continue;
                }
                spell_list.push(action);
            }

            for (var item of monster.action_spells) {
                var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);
                if (this.state.action_time_to_use != 'All' && this.state.action_time_to_use.toLowerCase() != action.time_to_use.toLowerCase()) {
                    continue;
                }
                if (action.level == 0 && action.action_type == 'Spell') {
                    spell_list.push(action);
                }
            }
        }

        var current_conditions = [];
        if (selected_monster_instance['conditions[]']) {
            for (var condition of selected_monster_instance['conditions[]']) {
                current_conditions.push(condition);
            }
        }
        else {
            for (var item of selected_monster_instance.conditions) {
                current_conditions.push(item['condition']['id']);
            }
        }

        var pb = monster.proficiency_bonus;
        var display_content = null;

        var display_abilities = null;
        if (special_abilities.length > 0) {
            var display_abilities = <div><h3>Abilities</h3>
                <div class="property-block">
                    <p>Right Click on Action For Detail Card</p>
                    {special_abilities}
                </div>
            </div>;
        }

        var display_actions = null;
        if (actions.length > 0) {
            var display_actions = <div><h3>Actions</h3>
                <div class="property-block">
                    <p>Right Click on Action For Detail Card</p>
                    {actions}
                </div>
            </div>;
        }

        var display_legendary_actions = null;
        if (legendary_actions.length > 0) {
            var display_legendary_actions = <div><h3>Legendary Actions</h3>
                <div class="property-block">
                    <p>Right Click on Action For Detail Card</p>
                    {legendary_actions}
                </div>
            </div>;
        }

        if (spell_list.length > 0) {
            var spell_display = <div>
                <h3>Spells</h3>
                <div class="property-block">
                    <p>Right Click on Spell For Detail Card</p><br />
                    <p><b>Concentration</b> spells are bolded. <i>Ritual</i> are italicized.</p>
                    <Spells add_action_token={this.props.add_action_token} spells={spell_list} group={this.props.group}
                        instance={selected_monster_instance} push_modal_content={this.props.push_modal_content} use_action={this.props.use_action}
                        mini={true} />
                    {innate_spells}
                </div>
            </div>;
            spells.push(spell_display)
        }
        else if (innate_spells.length > 0) {
            var spell_display = <div>
                <h3>Spells</h3>
                <div class="property-block">
                    <p>Right Click on Spell For Detail Card</p>
                    {innate_spells}
                </div>
            </div>;
            spells.push(spell_display)
        }

        var immunities = [];
        var condition_immunities = '';
        for (var item of monster.condition_immunities) {
            condition_immunities += item.condition.name + ','
        }
        if (condition_immunities == '') {
            condition_immunities = 'None';
        }
        else {
            immunities.push(
                <div class="property-line">
                    <div>
                        <h4><b>Condition Immunities</b></h4>
                        <p style={{wordBreak:'break-all'}}>{condition_immunities}</p>
                    </div>
                </div>
            );
        }

        var damage_immunities = '';
        for (var item of monster.damage_immunities) {
            damage_immunities += item.damagetype.name + ','
        }
        if (damage_immunities == '') {
            damage_immunities = 'None';
        }
        else {
            immunities.push(
                <div class="property-line">
                    <div>
                        <h4><b>Damage Immunities (0x dmg)</b></h4>
                        <p> {damage_immunities}</p>
                    </div>
                </div>
            );
        }

        var damage_resistances = '';
        for (var item of monster.damage_resistances) {
            damage_resistances += item.damagetype.name + ','
        }
        if (damage_resistances == '') {
            damage_resistances = 'None';
        }
        else {
            immunities.push(
                <div class="property-line">
                    <div>
                        <h4><b>Damage Resistances (1/2x dmg)</b></h4>
                        <p> {damage_resistances}</p>
                    </div>
                </div>
            );
        }

        var damage_vulnerabilities = '';
        for (var item of monster.damage_vulnerabilities) {
            damage_vulnerabilities += item.damagetype.name + ','
        }
        if (damage_vulnerabilities == '') {
            damage_vulnerabilities = 'None';
        }
        else {
            immunities.push(
                <div class="property-line">
                    <div>
                        <h4><b>Damage Vulnerabilities (2x dmg)</b></h4>
                        <p> {damage_vulnerabilities}</p>
                    </div>
                </div>
            );
        }


        var languages = '';
        for (var item of monster.languages) {
            languages += item.language.name + ','
        }
        if (languages == '') {
            languages = 'None';
        }

        var challenge_rating = null;
        if (!monster.character) {
            var challenge_rating = <div>
                <p style={{marginBottom:0, marginTop:'1rem'}}><b>Challenge</b></p>
                <p>{monster.challenge_rating} </p>
            </div>;
        }

        var movements = ['walk_speed','fly_speed','swim_speed','climb_speed','burrow_speed']
        var speed = '';
        for (var movement_type of movements) {
            if (monster[movement_type] != 0) {
                speed += monster[movement_type] + '  ft. ' + movement_type.substring(0, movement_type.length - 6) + ', '
            }
        }

        var sights = ['blindsight','darkvision','truesight','tremorsense']
        var sight = '';
        for (var sight_type of sights) {
            if (monster[sight_type] != 0) {
                sight += monster[sight_type] + ' ft. ' + sight_type + ', '
            }
        }
        var display_sight = null;
        if (sight != '') {
            display_sight = <div class="property-line last">
                <h4><b>Sight</b></h4>
                <p id="speed"> {sight}</p>
            </div>;
        }


        var languages = '';
        for (var item of monster.languages) {
            languages += item.language.name + ','
        }
        if (languages == '') {
            languages = 'None';
        }

        var deleteUrl = '/api/home/monsterinstance/' + selected_monster_instance.id + '/delete/'

        var share = null;
        var player_controlled = null;
        var remove_button = null;
        var set_main_instance = null;
        if (this.props.player_character && this.props.player_character.main_instance.id != selected_monster_instance.id) {
            set_main_instance = <Button onClick={this.props.set_main_instance} text='Set This Character As My Main' type='primary' />
        }

        var manage_buttons = <div>{set_main_instance}</div>;

        if (this.props.dm) {
            var share = <Button text='Show To Players' type='danger' onClick={() => this.setGlobalState('hide',{'share_with_players':true})} />;
            if (selected_monster_instance.share_with_players) {
                share = <Button text='Hide From Players' type='dark' onClick={() => this.setGlobalState('hide',{'share_with_players':false})} />;
            }

            var player_controlled = <Button text='Give Players Control' type='warning' onClick={() => this.setGlobalState('hide',{'player_controlled':true})} />;
            if (selected_monster_instance.player_controlled) {
                player_controlled = <Button text='Take Away Players Control' type='dark' onClick={() => this.setGlobalState('hide',{'player_controlled':false})} />;
            }

            if (selected_monster_instance.player_controlled) {
                if (this.props.dm) {
                    remove_button = <Button type='danger' text='Remove (Remove PC Control First)' disabled />;
                }
            }
            else {
                remove_button = <Button onClick={this.remove_monster_instance} type='danger' text='Remove' />;
            }


            manage_buttons = <div style={{padding:'0px 0px 10px'}}>
                {set_main_instance}
                {share}
                {player_controlled}
                {remove_button}
            </div>;
        }

        var sizes = [
            {'text':'Tiny','value':'Tiny'},
            {'text':'Small','value':'Small'},
            {'text':'Medium','value':'Medium'},
            {'text':'Large','value':'Large'},
            {'text':'Huge','value':'Huge'},
            {'text':'Gargantuan','value':'Gargantuan'},
        ]

        var edit_instance_form = <FormWithChildren key={selected_monster_instance.id + '_mini_form'} row={true} autoSetGlobalState={true} setGlobalState={this.setGlobalState} globalStateName={'health_and_status'} props_will_update={true}>
            <TextInput name='name' label='Display Name' default={selected_monster_instance.name} className="col-4" />
            <ImageInput name='image' label='Instance Image' multiple={false} preview={false} bucket_name="mapquest-public" is_public={true} default={selected_monster_instance.image} className="col-4" />
            <Select className="col-4" name='size' label='Size' default={selected_monster_instance.size} options={sizes} />
            <NumberInput name='current_hp' label='Current HP' default={selected_monster_instance.current_hp} className="col-6" />
            <NumberInput name='max_hp' label='Max HP' default={selected_monster_instance.max_hp} className="col-6" />
            <Select className="col-12" multiple={true} options={window.cmState.conditions_select} name='conditions[]' default={current_conditions} no_blank_option={true} label='Current Conditions' />
        </FormWithChildren>;

        if (this.props.out_of_session) {
            manage_buttons = null;
            edit_instance_form = null;
        }

        var right_abilities = <div>
            {display_abilities}
            {costly_actions}
            {display_legendary_actions}
        </div>;
        var left_abilities = null;

        if (spells) {
            var right_abilities = null;
            var left_abilities = <div>
                {display_abilities}
                {costly_actions}
                {display_legendary_actions}
            </div>;
        }

        var hit_dice = null;
        if (monster.hit_dice) {
            var num_die = monster.hit_dice.split('d')[0]
            var con_mod = stat_modifier(monster.constitution)
            var added_health = num_die * con_mod;

            hit_dice = "(" + monster.hit_dice + " + " + added_health + ")"
        }

        var death_saves = null;
        if (selected_monster_instance.current_hp <= 0) {
            death_saves = <div>
                <Button onClick={() => this.props.roll_skill('death_saving_throw')} text='Roll Death Save' type='dark' />
                <FormWithChildren className='col-12 row' setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'health'} defaults={selected_monster_instance} >
                <div className='death-saves' >
                    <div className='death-save-success'>
                        SUCCESSES
                        <div>
                            <Checkbox name={'death_success_1'} style={{display:'inline-block'}} secret_value={1} />
                            <Checkbox name={'death_success_2'} style={{display:'inline-block'}} secret_value={1} />
                            <Checkbox name={'death_success_3'} style={{display:'inline-block'}} secret_value={1} />
                        </div>
                    </div>
                    <div className='death-save-failure'>
                        FAILURES
                        <div>
                            <Checkbox name={'death_failure_1'} style={{display:'inline-block'}} secret_value={1} />
                            <Checkbox name={'death_failure_2'} style={{display:'inline-block'}} secret_value={1} />
                            <Checkbox name={'death_failure_3'} style={{display:'inline-block'}} secret_value={1} />
                        </div>
                    </div>
                </div>
                </FormWithChildren>
            </div>
        }

        var time_filters = [];
        var time_to_use_options = [['All','All'], ['Actions', '1 action'], ['Bonus Actions','1 bonus action'], ['Reactions', '1 reaction']];
        for (var option of time_to_use_options) {
            time_filters.push(<TimeFilter option={option} action_time_to_use={this.state.action_time_to_use} set_time_filter={this.set_time_filter} />)
        }

        var time_to_use_filters = null;
        if (monster.character) {
            var time_to_use_filters = <ul class="nav nav-pills nav-fill">
                {time_filters}
            </ul>
        }

        var concentration = null;
        if (selected_monster_instance.concentrating_on_id) {
            var concentrating_on = window.cmState.global_state_lookup(this, 'actions', selected_monster_instance.concentrating_on_id);

            concentration = [
                <div>
                    <span>Concentrating On Level {selected_monster_instance.concentration_level} </span>
                    <Action add_action_token={null} action={concentrating_on} instance={selected_monster_instance} mini={true}
                        use_action={this.props.use_action} cast_level={selected_monster_instance.concentration_level} push_modal_content={this.props.push_modal_content} />
                </div>
            ];

            concentration.push(<Button type='danger' text='Stop Concentration' onClick={this.stop_concentration} />)
        }


        var content = <div>
            {manage_buttons}
            {death_saves}
            {concentration}
            <div id="stat-block-wrapper">
                <div id="stat-block" class="stat-block wide">
                    <Button style={full_block_button_style} text={<i class="fas fa-expand-arrows-alt"></i>} onClick={this.toggle_full_block} type='secondary' />
                    <hr class="orange-border"></hr>
                    <div class="section-left">
                    {time_to_use_filters}
                        <div class="creature-heading">
                            <h1 id="monster-name">{selected_monster_instance.name}</h1>
                            <h2 id="monster-type">{monster.size} {monster.type}, {monster.alignment}</h2>
                        </div>
                        <svg height="5" width="100%" class="tapered-rule">
                            <polyline points="0,0 400,2.5 0,5"></polyline>
                        </svg>
                        <div class="top-stats">
                            <div class="property-line first">
                                <h4><b>Armor Class</b></h4>
                                <p id="armor-class"> {monster.armor_class}</p>
                            </div>
                            <div class="property-line">
                                <h4><b>Hit Points</b></h4>
                                <p id="hit-points"> {selected_monster_instance.current_hp}/{selected_monster_instance.max_hp} {hit_dice}</p>
                            </div>
                            <div class="property-line">
                                <h4><b>Speed</b></h4>
                                <p id="speed"> {speed}</p>
                            </div>
                            {display_sight}
                            <svg height="5" width="100%" class="tapered-rule">
                                <polyline points="0,0 400,2.5 0,5"></polyline>
                            </svg>
                            <div class="scores">
                                <div class="scores-strength">
                                    <h4>STR</h4>
                                    <p id="strpts">{monster.strength}<span>{' (' + stat_modifier(monster.strength) + ')'}</span></p>
                                </div>
                                <div class="scores-dexterity">
                                    <h4>DEX</h4>
                                    <p id="dexpts">{monster.dexterity}<span>{' (' + stat_modifier(monster.dexterity) + ')'}</span></p>
                                </div>
                                <div class="scores-constitution">
                                    <h4>CON</h4>
                                    <p id="conpts">{monster.constitution}<span>{' (' + stat_modifier(monster.constitution) + ')'}</span></p>
                                </div>
                                <br />
                                <div class="scores-intelligence">
                                    <h4>INT</h4>
                                    <p id="intpts">{monster.intelligence}<span>{' (' + stat_modifier(monster.intelligence) + ')'}</span></p>
                                </div>
                                <div class="scores-wisdom">
                                    <h4>WIS</h4>
                                    <p id="wispts">{monster.wisdom}<span>{' (' + stat_modifier(monster.wisdom) + ')'}</span></p>
                                </div>
                                <div class="scores-charisma">
                                    <h4>CHA</h4>
                                    <p id="chapts">{monster.charisma}<span>{' (' + stat_modifier(monster.charisma) + ')'}</span></p>
                                </div>
                            </div>
                            <svg height="5" width="100%" class="tapered-rule">
                                <polyline points="0,0 400,2.5 0,5"></polyline>
                            </svg>
                            <div id="properties-list">
                            <div class="property-line first">
                                <div>
                                    <h4><Button onClick={this.toggle_saving_throws_and_skills} text='Saving Throws/Skills' type='dark' /></h4>
                                </div>
                            </div>
                            {immunities}
                            <div class="property-line">
                                <div>
                                    <h4><b>Senses</b></h4>
                                    <p> passive Perception {monster.passive_perception}</p>
                                </div>
                            </div>
                            <div class="property-line">
                                <div>
                                    <h4><b>Languages</b></h4>
                                    <p> {languages}</p>
                                </div>
                            </div>
                        </div>
                        <div id="challenge-rating-line" class="property-line last">
                            <h4><b>Challenge</b></h4>
                            <p id="challenge-rating"> {monster.challenge_rating}</p>
                        </div>
                        </div>
                        <svg height="5" width="100%" class="tapered-rule">
                            <polyline points="0,0 400,2.5 0,5"></polyline>
                        </svg>
                        <div class="actions">
                            <div id="traits-list-left">
                                {left_abilities}
                            </div>
                        </div>

                    </div>
                    <div class="section-right">

                        <div class="actions">
                            <div id="traits-list-right">
                                {right_abilities}
                                {display_actions}
                                {spells}
                            </div>
                        </div>
                    </div>
                    <hr class="orange-border bottom"></hr>
                </div>
            </div>
            <div>
                {edit_instance_form}
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }


}
