import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import Link from 'projectLibrary/proc_gen/link.js';

export default class BuildingContent extends React.Component {

    render() {
        var person = this.props.person;

        var retired = '';
        if (person.retired) {
            retired = 'Retired '
        }
        if (!person.alive) {
            retired += 'Dead '
        }

        var works_at = null;
        if (person.business_id) {
            var business = this.props.business_dict[person.business_id];
            works_at = <p>Works At <Link text={business.name} type='business' select_item={this.props.select_item} id={business.id} /></p>
        }

        var friends = [];
        var enemies = [];
        var exes = [];
        var romance = null;

        for (var item of person.relationships_alpha) {
            var relationship = item.relationship;

            var other_person = this.props.person_dict[relationship.person_two_id];
            if (other_person) {
                if (relationship.type == 'Friends') {
                    friends.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else if (relationship.type == 'Enemies') {
                    enemies.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else if (relationship.type == 'Exes') {
                    exes.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else {
                    romance = <div>
                        <h4>{relationship.type} with <Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>;
                }
            }

        }

        for (var item of person.relationships_beta) {
            var relationship = item.relationship;
            var other_person = this.props.person_dict[relationship.person_one_id];
            if (other_person) {
                if (relationship.type == 'Friends') {
                    friends.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else if (relationship.type == 'Enemies') {
                    enemies.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else if (relationship.type == 'Exes') {
                    exes.push(<div>
                        <h4><Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>)
                }
                else {
                    romance = <div>
                        <h4>{relationship.type} with <Link text={other_person.name} type='person' select_item={this.props.select_item} id={other_person.id} /></h4>
                        <p>Known For: {relationship.age} years Strength: {relationship.strength}</p>
                    </div>;
                }
            }

        }

        var friends_text = null;
        if (friends.length > 0) {
            friends_text = <div>
                <h3>Friends</h3>
                {friends}
            </div>
        }

        var enemies_text = null;
        if (enemies.length > 0) {
            enemies_text = <div>
                <h3>Enemies</h3>
                {enemies}
            </div>
        }

        var exes_text = null;
        if (exes.length > 0) {
            exes_text = <div>
                <h3>Exes</h3>
                {exes}
            </div>
        }

        return (
            <div>
                <h2>{person.name}</h2>
                <p>Age: {person.age}</p>
                <p>Profession: {retired}{person.profession}</p>
                {works_at}

                {romance}
                {exes_text}
                {friends_text}
                {enemies_text}

            </div>
        )
    }
}
