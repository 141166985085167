import api_lookup from './api_lookup.js';

window.cmState = {
    'completed':false,
    'full_loads': []
}

function setGlobalState(name, state, hard) {
    //console.log("Setting Global State", name, state);

    if (!(hard) && state && typeof state == 'object') {
        //checking for dict over list
        if (state.length == undefined) {
            var new_state = window.cmState[name] || {};
            for (var index in state) {
                new_state[index] = state[index];
            }
            window.cmState[name] = new_state;
        }
        else {
            window.cmState[name] = state;
        }
    }
    else {
        window.cmState[name] = state;
    }

    check_subscribers_to_update(name, state);
}


function check_subscribers_to_update(name, state){
    //console.log("Checking Subscribers to update", name, state)

    if (window.cmState.kwarg_subscribers){
        if (name == 'refreshAll') {
            for (var index in window.cmState.kwarg_subscribers) {
                var subscriber_list = window.cmState.kwarg_subscribers[index];
                update_subscribers(subscriber_list);
            }
        }
        else {
            if (window.cmState.kwarg_subscribers[name]){
                var subscriber_list = window.cmState.kwarg_subscribers[name];
                update_subscribers(subscriber_list);
            }
        }


        //Check if state update is more complex than a string value
        if (state && typeof state == 'object') {
            for (var key in state){
                //Use the variable format to compose a accurate name
                var deeper_name = name + '.' + key;

                if (window.cmState.kwarg_subscribers[deeper_name]){
                    var subscriber_list = window.cmState.kwarg_subscribers[deeper_name];
                    update_subscribers(subscriber_list);
                }
            }
        }
    }

    return true;
}


function update_subscribers(context, state){
    for (var index in context) {
        var component = context[index];
        if (component.refreshData) {
            component.refreshData();
        }
        else if (component.set_form_state){
            component.set_form_state(state, true);
        }
        else if (component.forceUpdate) {
            component.forceUpdate();
        }
    }
}

function subscribe(component, unresolved_string) {
    //Components must subscribe themselves to global state updates
    //This is accomplished by providing their own context, and the string
    //containing whichever variables they want to subcribe to
    if (!unresolved_string) {
        return false;
    }

    //Instantiate the subscription dictionary
    if (!window.cmState['kwarg_subscribers']) {
        window.cmState['kwarg_subscribers'] = {};
    }

    var matches = get_variables_from_string(unresolved_string);

    for (var i in matches) {
        var match = matches[i][1];

        //Reduce varaible down to two values because subscription only checks that deep
        if(match.indexOf('.') > -1) {
            var match_peices = match.split('.');
            match = match_peices[0] + '.' + match_peices[1];
        }
        subscribe_by_name(component, match);
    }
}

function subscribe_by_name(component, name){
    //Instantiate the subscription dictionary
    if (!window.cmState['kwarg_subscribers']) {
        window.cmState['kwarg_subscribers'] = {};
    }

    if (!window.cmState['kwarg_subscribers'][name]) {
        window.cmState['kwarg_subscribers'][name] = [];
    }

    if (window.cmState.kwarg_subscribers[name].indexOf(component) == -1) {
        window.cmState.kwarg_subscribers[name].push(component);
    }
}



function unsubscribe(component, unresolved_string){
    var matches = get_variables_from_string(unresolved_string);

    for (var i in matches) {
        var match = matches[i][1];

        //Reduce varaible down to two values because subscription only checks that deep
        if(match.indexOf('.') > -1) {
            var match_peices = match.split('.');
            match = match_peices[0] + '.' + match_peices[1];
        }
        unsubscribe_by_name(component, match);
    }
}

function unsubscribe_by_name(component, name){
    var component_index = window.cmState.kwarg_subscribers[name].indexOf(component);
    if (component_index > -1){
        window.cmState.kwarg_subscribers[name].splice(component_index, 1);
    }
}

function get_variables_from_string(unresolved_string){
    //Pull all varaibles out of submitted string
    var anything_inside_curly_brackets_regex = /\{([^{}]+)\}/g;
    var matches = [];
    if (typeof unresolved_string == 'string') {
        var matches = [...unresolved_string.matchAll(anything_inside_curly_brackets_regex)];
    }

    return matches;
}

function is_valid_react_child(child) {
    if (child != null && typeof(child) == 'object' && String(child.$$typeof) == 'Symbol(react.element)'){
        return true;
    }

    return false;
}

function getGlobalState(component) {
    return window.cmState;
}

function global_state_lookup(component, name, key){
    return api_lookup(component, name, key);
}

window.cmState['setGlobalState'] = setGlobalState;
window.cmState['getGlobalState'] = getGlobalState;
window.cmState['is_valid_react_child'] = is_valid_react_child;
window.cmState['global_state_lookup'] = global_state_lookup;

window.cmState['subscribe'] = subscribe;
window.cmState['subscribe_by_name'] = subscribe_by_name;
window.cmState['unsubscribe'] = unsubscribe;

export default setGlobalState;
