import React, { Component } from 'react';
import {ajaxWrapper, resolveVariables} from 'functions';

import {Wrapper, Div, Image, Span, Link, Paragraph, FormWithChildren} from 'library';

class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
			<Div className={'footer-wrapper'} style={{padding:'2% 0px'}} >
				<Div style={{'': ''}} className={"row"} >

                    <Div className={"col-12"} >
                        <div style={{textAlign: 'right', marginTop: '40px'}} >
                            <a href='https://discord.gg/WGPpSr5d'>Join Our Discord Community</a>
                        </div>
                    </Div>

                    <Div className={"col-12"} style={{textAlign:'center'}} >
                        <Image src='https://mapquest-public.s3.amazonaws.com/21_01_01_17_41_26_icon_monster.jpg' style={{width:'100px'}} />
                    </Div>
                    
					<Div className={"col-12"} >
                        <div style={{textAlign: 'center', marginTop: '40px'}} >
                            <p>COPYRIGHT 2020  |  ALL RIGHTS RESERVED</p>
                        </div>
                    </Div>
				</Div>
			</Div>
        </div>);
    }
}

export default Footer;
