import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import ActionCard from 'projectLibrary/action_card.js';

class MagicalAttribute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: false}
        this.toggle_prepared_action = this.toggle_prepared_action.bind(this);
        this.show_action = this.show_action.bind(this);
    }

    show_action() {
        if (this.props.card_inline) {
            this.setState({show: !this.state.show})
        }
        else {
            this.props.push_modal_content(<ActionCard action={this.props.action} />);
        }
    }

    toggle_prepared_action() {
        if (this.props.magical_attributes.indexOf(this.props.attribute.id) == -1) {
            this.props.add_magical_attribute(this.props.attribute);
        }
        else {
            this.props.remove_magical_attribute(this.props.attribute);
        }
    }

    render() {
        var attribute = this.props.attribute;

        if (this.props.magical_attributes.indexOf(attribute.id) == -1) {
            var prepare_button = <Button type='primary' text='Add Magic' onClick={this.toggle_prepared_action} />
        }
        else {
            var prepare_button = <Button type='danger' text='Remove Magic' onClick={this.toggle_prepared_action} />
        }

        var edit = <Button type='secondary' text='Edit Magical Attribute' href={'/edit_magic_attribute/' + attribute.id + '/'} target='_blank' />

        var content = [];
        content.push(
                <tr>
                    <td><a onClick={this.show_action}>{attribute.name}</a></td>
                    <td>{prepare_button}</td>
                    <td>{edit}</td>
                </tr>
            )

        if (this.state.show) {
            content.push(<ActionCard action={this.props.attribute} />)
        }

        return (content);

    }
}

export default class MagicalAttributesForm extends React.Component {
    constructor(props) {
        super(props);

        var magical_attributes = [];
        if (this.props.magical_attributes) {
            for (var item of this.props.magical_attributes) {
                magical_attributes.push(item.magicalattribute.id);
            }
        }

        this.state = {
            search_name: '',
            magical_attributes_list:[],
            magical_attributes:magical_attributes,
            loaded: false
        };

        this.abilities_callback = this.abilities_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.add_magical_attribute = this.add_magical_attribute.bind(this);
        this.remove_magical_attribute = this.remove_magical_attribute.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/magicalattribute/', {}, this.abilities_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    add_magical_attribute(attribute) {
        if (this.props.add_magical_attribute) {
            this.props.add_magical_attribute(attribute);
            var magical_attributes = this.state.magical_attributes;
            magical_attributes.push(attribute.id)
            this.setState({magical_attributes: magical_attributes})
        }

    }

    remove_magical_attribute(attribute) {
        if (this.props.remove_magical_attribute) {
            this.props.remove_magical_attribute(attribute);
            var magical_attributes_current = this.state.magical_attributes;
            var magical_attributes = [];
            for (var item of magical_attributes_current) {
                if (attribute.id != item) {
                    magical_attributes.push(item);
                }
            }
            this.setState({magical_attributes: magical_attributes});
        }

    }


    abilities_callback(result) {
        var attribute_list = [];
        for (var item of result) {
            var attribute = item['magicalattribute']
            attribute_list.push(attribute);
        }

        this.setState({'magical_attributes_list':attribute_list, loaded:true})
    }

    render() {

        var attributes = [];
        for (var attribute of this.state.magical_attributes_list) {
            if (this.state.search_name == '' || attribute.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) > -1) {
                attributes.push(<MagicalAttribute attribute={attribute} add_magical_attribute={this.add_magical_attribute} remove_magical_attribute={this.remove_magical_attribute} card_inline={this.props.card_inline} magical_attributes={this.state.magical_attributes} push_modal_content={this.props.push_modal_content} />);
            }
        }

        var content = <div className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
            <div>
                <h2>Magical Attributes</h2>
                <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                    <TextInput name='search_name' label='Search by Name' className="col-6" />
                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th>Toggle</th>
                        <th>Edit</th>
                    </tr>
                    {attributes}
                </table>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
