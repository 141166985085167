import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class Link extends React.Component {
    static component_name = 'Link';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Link'} name={'href'} />,
                <TextInput label={'Target'} name={'target'} />,
                <TextInput label={'Text'} name={'text'} />,
                <TextInput label={'Class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }
    }

    render() {
        var target = '_self';
        if (this.props.target) {
            target= this.props.target;
        }

        return (
            <a href={this.props.href} target={target} className={this.props.className}
                style={this.props.style} onClick={this.props.onClick}>
                {this.props.text}
                {this.props.children}
            </a>
        );
    }
}


export default Link;
