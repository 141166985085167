import React, { Component } from 'react';
import {Button, Alert} from 'library';
import ActionCard from 'projectLibrary/action_card.js';
import SpellCard from 'projectLibrary/spell_card.js';
import RollSave from 'projectLibrary/monsters/roll_save.js';
import TakeDamage from 'projectLibrary/monsters/take_damage.js';

export default class ActionUse extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roll: null,
            show_action: false
        };

        this.roll_damage = this.roll_damage.bind(this);
        this.roll_crit = this.roll_crit.bind(this);
        this.hide_damage = this.hide_damage.bind(this);
        this.show_action = this.show_action.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'resources');
        window.cmState.subscribe_by_name(this, 'selected_monster_instance');
        window.cmState.subscribe_by_name(this, 'instances');
    }

    roll_damage() {
        this.props.roll_damage(this.props.id, false);
    }

    roll_crit() {
        this.props.roll_damage(this.props.id, true);
    }

    hide_damage() {
        this.props.roll_damage(this.props.id, false, true);
    }

    show_action() {
        if (this.props.action.action_type == 'Spell') {
            this.props.push_modal_content(<SpellCard spell={this.props.action} />);
        }
        else {
            this.props.push_modal_content(<ActionCard action={this.props.action} />);
        }
    }

    render() {
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var dc_check = null;
        if (this.props.action.action_dc && this.props.action.action_dc.dc_type) {
            var roll_save = <RollSave dc_type={this.props.action.action_dc.dc_type} roll_skill={this.props.roll_skill} />
            if (this.props.public) {
                roll_save = null;
            }

            dc_check = <div className='dc-check col-12' >
                {'DC ' + this.props.dc + ' ' + this.props.action.action_dc.dc_type}
                <br />
                {roll_save}
            </div>;
        }

        var hits = this.props.hits || [];
        var parsed_hits = [];
        for (var hit of hits){
            var resolved_roll_strings = [];
            var roll_function = ['1d20'];
            var parsed_color = 'black';

            if (hit.rolls){
                for (var dice of hit.rolls) {
                    for (var die of dice) {
                        resolved_roll_strings.push(die.roll);

                        if (die.die == die.roll) {
                            parsed_color = 'green';
                        }
                        else if (die.roll == 1) {
                            parsed_color = 'red';
                        }
                    }

                    resolved_roll_strings.push(...hit.modifiers);
                    roll_function.push(...hit.modifiers);
                }
            }

            if (this.props.full_display) {
                parsed_hits.push(<div className={'col-6 action-hit ' + parsed_color}>
                    <div className='action-hit-total' >{String(hit.total)}</div>
                    <div className='action-hit-breakdown' >
                        <div>{roll_function.join(' + ')}</div>
                        <div>{resolved_roll_strings.join(' + ')}</div>
                    </div>
                </div>);
            }
        }

        var hit_section = <div className='row'>
            {dc_check}
            {parsed_hits}
        </div>;

        var damages = [];
        var total_damage = 0
        for (var damage of this.props.damages) {
            var damage_index = this.props.damages.indexOf(damage);

            var total_damage_for_type = 0;
            var total_damage_display = 0;

            if (damage.type == 'Healing') {
                total_damage -= damage.damage.total;
                total_damage_for_type -= damage.damage.total;
            }
            else {
                total_damage += damage.damage.total;
                total_damage_for_type += damage.damage.total;
            }

            total_damage_display += damage.damage.total
            if (this.props.show_damage == 'critical') {
                total_damage += damage.damage.crit.total;
                total_damage_for_type += damage.damage.crit.total;

                total_damage_display += damage.damage.crit.total;
            }

            var resolved_damage_strings = [];
            var damage_function = [];

            for (var rolls of damage.damage.rolls) {
                for (var die of rolls) {
                    resolved_damage_strings.push(die.roll);
                }

                resolved_damage_strings.push(...damage.damage.modifiers);
            }

            var action_damage = this.props.action.action_damage[damage_index];
            if (action_damage && action_damage.damage_scaling) {
                if (action_damage.damage_scaling == 'cast_level') {
                    var damage_function = action_damage.damage_by_level[this.props.cast_level]
                    if (damage_function.damage_roll) {
                        damage_function = damage_function.damage_roll;
                    }
                }
                else if (action_damage.damage_scaling == 'level') {
                    var last_key = 1;
                    for (var key in action_damage.damage_by_level) {
                        if (key > monster.level) {
                            var damage_function = action_damage.damage_by_level[last_key]
                            break;
                        }
                        else {
                            last_key = key;
                        }
                    }
                    if (damage_function.damage_roll) {
                        damage_function = damage_function.damage_roll;
                    }
                }
                else {
                    var damage_function = action_damage.damage_roll;
                }
            }

            var crit = null;
            if (this.props.hits && this.props.hits[0] && this.props.show_damage == 'critical') {
                crit = <span style={{fontSize:'12px'}} >(Hit: {damage.damage.total} + Crit: {damage.damage.crit.total})</span>;
            }

            var damage_breakdown = null;

            if (this.props.full_display) {
                var damage_breakdown = <div className='action-damage-breakdown' >
                    <div>{damage_function}</div>
                    <div>{resolved_damage_strings.join(' + ')}</div>
                </div>
            }

            var take_damage = null;
            if (total_damage_for_type >= 0 && !this.props.public) {
                take_damage = <span style={{textAlign:'center', paddingLeft:'5px'}}>
                    <TakeDamage take_damage={this.props.take_damage} point_type='current_hp' text='Take Damage' damage={total_damage_for_type} current_instance={this.props.current_instance} send_concentration_roll={this.props.send_concentration_roll} />
                    <TakeDamage take_damage={this.props.take_damage} point_type='current_hp' text='Take Damage' damage={Math.floor(total_damage_for_type/2)} half={true} current_instance={this.props.current_instance} send_concentration_roll={this.props.send_concentration_roll} />
                </span>;
            }
            if (total_damage <= 0 && !this.props.public) {
                take_damage = <span style={{textAlign:'center', paddingLeft:'5px'}}>
                    <TakeDamage take_damage={this.props.take_damage} point_type='current_hp' text='Take Damage' damage={total_damage_for_type} current_instance={this.props.current_instance} send_concentration_roll={this.props.send_concentration_roll} />
                </span>;
            }

            damages.push(<div className='col-12 action-damage'>
                <div className='action-damage-total' >
                    <span style={{fontSize:'16px', fontWeight:'bold'}} >{total_damage_display}</span>
                    <span style={{fontSize:'12px'}} > {damage.type}</span>
                    <span style={{fontSize:'12px'}} >{' damage.'} {damage.note}</span>
                    {crit}
                    {take_damage}
                </div>
                {damage_breakdown}
            </div>);
        }

        var can_roll_damage = this.props.full_display && this.props.damages.length > 0 && ((this.props.current_instance && this.props.instance.id == this.props.current_instance.id) || this.props.public);

        var damage_section = null;
        if (can_roll_damage) {
            var roll_crit = null;
            if (parsed_hits.length > 0) {
                roll_crit = <Button type='danger' onClick={this.roll_crit} text='Crit'/>
            }

            var damage_section = <div className='damage-section' >
                <div style={{textAlign:'center', paddingTop:'3px'}}>
                    <Button type='danger' onClick={this.roll_damage} text='Roll Damage'/>
                    {roll_crit}
                </div>
            </div>;
        }

        var hide_damage = null;
        if (this.props.show_damage || (!this.props.action.has_dc && !this.props.action.has_attack_roll)) {
            if (can_roll_damage) {
                hide_damage = <Button type='info' onClick={this.hide_damage} text={<i class="fas fa-undo"></i>}
                    style={{position:'absolute', top:'5px', right:'15px'}} />;
            }

            var damage_section = <div className='damage-section' >
                <div className='row' style={{position: 'relative'}}>
                    {damages}
                    {hide_damage}
                </div>
            </div>;
        }

        var name = null;
        var monster_name = '???'
        if (this.props.full_display) {
            name = <span style={{fontWeight:'bold'}} ><a onClick={this.show_action} style={{color:'blue', cursor:'pointer'}}>{this.props.action.name}</a></span>
            monster_name = monster.name;
        }

        var usable_resources = {}
        var instance = this.props.instance;
        for (var item of instance.monster_instance_resources) {
            var resource = item.monsterinstanceresource;
            usable_resources[resource.resource_id] = resource.quantity;
        }

        if (this.props.equipment_instance) {
            var equipment_instance = this.props.equipment_instance;
            for (var item of equipment_instance.instance_equipment_resources) {
                var resource = item.instanceequippeditemresource;
                usable_resources[resource.resource_id] = resource.quantity;
            }
        }


        var usage = [];
        if ((this.props.current_instance && this.props.instance.id == this.props.current_instance.id) || this.props.public) {
            var resource_costs = this.props.action.resource_costs || [];
            for (var item of resource_costs) {
                var cost = item.resourcecost;
                var point_type = null;

                //for spells since their resource is all Spell Slot Level 1 even though they can use different resources at diff cast levels
                if (cost.resource_id == '9913733a-9472-47b6-8f29-318605fc123e' && usable_resources[cost.resource_id] != undefined) {
                    if (this.props.cast_level > 0) {
                        var point_type = 'Spell Slot Level ' + this.props.cast_level;
                        var point_type_descriptor = 'Use Level ' + this.props.cast_level + ' Spell Slot'
                        //console.log("Point Type", point_type);
                    }
                }
                else if (usable_resources[cost.resource_id]) {
                    var point_type = cost.resource_id;
                    var point_type_descriptor = 'Use ' + window.cmState.global_state_lookup(this, 'resources', cost.resource_id).name
                }

                if (point_type) {
                    usage.push(<div style={{textAlign:'center', paddingTop:'3px'}}>
                        <TakeDamage take_damage={this.props.take_damage} point_type={point_type} text={point_type_descriptor}
                            damage={cost.cost} current_instance={this.props.current_instance} action={this.props.action}
                            cast_level={this.props.cast_level}/>
                    </div>);
                }
            }
        }

        var cast_level = '';
        if (this.props.cast_level) {
            cast_level = "(cast at level " + this.props.cast_level + ")"
        }
        var equipment = '';
        if (this.props.equipment){
            equipment = <span style={{textTransform:'none'}}>{"(using " + this.props.equipment.name + ")"}</span>
        }

        var break_concentration = null;
        if (this.props.instance.id) {
            var instance = window.cmState.global_state_lookup(this, 'instances', this.props.instance.id);
            //instance can stop existing if you remove the instance you are clicking on.
            if (instance && instance.concentrating_on_id) {
                var concentrating_on = window.cmState.global_state_lookup(this, 'actions', instance.concentrating_on_id);
                var action = this.props.action;
                //console.log(instance);
                if (concentrating_on && concentrating_on.id && action && action.spell_specifics && action.spell_specifics.concentration && action.id != concentrating_on.id) {
                    break_concentration = <Alert type='danger' text={'Casting this will break ' + monster.name + "'s concentration on " + concentrating_on.name} />
                }
            }
        }

        return(
            <div className='chat-entry' >
                <div className='chat-header' >
                    <span>{monster_name}</span>
                    <span>{' : '}</span>
                    {name} {cast_level} {equipment}
                 </div>
                <div className='hit-section' >{hit_section}</div>
                {damage_section}
                {break_concentration}
                {usage}
            </div>
        )
    }
}
