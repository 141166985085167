import {ajaxWrapper} from 'functions';
import load_icon_from_monster from 'projectLibrary/session_tools/global_data/load_icon_from_monster.js';
import create_icon_list from 'projectLibrary/session_tools/global_data/create_icon_list.js';


function remove_monster_instance(id) {
    console.log("Remove Monster Instance", id)
    var instance_dict = window.cmState.instances;
    var action_instance_dict = window.cmState.action_instances;
    console.log("Remove Monster Instance", id, instance_dict[id], action_instance_dict[id]);

    if (window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.id == id) {
        window.cmState.setGlobalState('selected_monster_instance', null);
    }

    if (instance_dict[id]) {
        delete instance_dict[id];
        window.cmState.setGlobalState('instances', instance_dict);

        create_icon_list();
    }

    if (action_instance_dict[id]) {
        delete action_instance_dict[id];
        window.cmState.setGlobalState('action_instances', action_instance_dict);
    }
}


export default remove_monster_instance;
