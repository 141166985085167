import React, { Component } from 'react';
import {ajaxWrapper, ajaxWrapperFile} from "functions";
import {TextInput, Select, CSSInput} from 'library';


class FileInput extends Component {
    static component_name = 'FileInput';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'label'} name={'label'} />,
                <Select label={'required'} name={'required'} boolean={true} />,
                <TextInput label={'Bucket (not required)'} name={'bucket_name'} />,
                <Select label={'Is Public?'} name={'is_public'} boolean={true} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'label css'} name={'label_style'} />,
                <CSSInput label={'css'} name={'style'} />,
            ],
        }

        this.state = {
          files:null,
          uploaded_files:[],
        }

        this.onChange = this.onChange.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
        this.fileUploadCallback = this.fileUploadCallback.bind(this);
      }

      onChange(e) {
        this.setState({files:e.target.files}, this.fileUpload(e.target.files))
      }

      fileUpload(files) {
        var url = '/api/fileUpload/';
        if (this.props.uploadUrl){
            url = this.props.uploadUrl;
        }

        const formData = new FormData();
        for (var index in files) {
            if (index != 'length' && index != 'item') {
                formData.append('files[]',files[index]);
            }
        }
        formData.append('name', this.props.name);
        if (typeof(this.props.bucket_name) != 'undefined'){
            formData.append('bucket_name', this.props.bucket_name);
        }
        if (typeof(this.props.is_public) != 'undefined'){
            formData.append('is_public', this.props.is_public);
        }
        else {
            formData.append('is_public', !(window.secretReactVars['secure_uploads']));
        }

        ajaxWrapperFile("POST", url, formData, this.fileUploadCallback);
      }

      fileUploadCallback(value) {
        console.log("Value", value)
        var newState = {}
        newState[this.props.name] = value['uploaded_files'][0]['url'];

        console.log("New State", newState)
        this.props.setFormState(newState)
      }

    render() {

        if (this.props.multiple == true) {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} multiple />
        } else {
            var input = <input onChange={this.onChange} type="file" className="form-control-file" id="exampleFormControlFile1" name={this.props.name} />
        }

        var current = null;
        if (this.props.value != '') {
          current = <a href={this.props.value} target='_blank'>Currently Uploaded File</a>
        }

        return (


           <div className="form-group">
            <label htmlFor="exampleFormControlFile1">{this.props.label}</label>
            <br />
            {current}
            {input}
          </div>

        )
    }
}

export default FileInput;
