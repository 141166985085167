import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {FormWithChildren, TextInput, Button, Json_Input, Select} from 'library';

class Function_Input extends Component {
    static component_name = 'Function_Input';
    constructor(props) {
        super(props);

        this.state = {functions:[]}

        this.setGlobalState = this.setGlobalState.bind(this);
        this.addFunction = this.addFunction.bind(this);
        this.clear_functions = this.clear_functions.bind(this);
    }

    setGlobalState(name, state) {
      var newState = {};
      var value = this.props.value;
      for (var index in state) {
        if (index.indexOf('function_') > -1) {
          var i = index.split('_')[1]
          value[i][0] = state[index];
        }
        else if (index.indexOf('value_') > -1) {
          var i = index.split('_')[1]
          value[i][1] = state[index];
        }
      }

      var formState = {};
      formState[this.props.name] = value;
      this.props.setFormState(formState);
    }

    clear_functions() {
        var state = {};
        state[this.props.name] = [];
        this.props.setFormState(state);
    }

    addFunction() {
      var value = this.props.value;
      if (!value) {
        value = [];
      }
      value.push(['','']);

      console.log("Here", this.props.value, value)
      var formState = {};
      formState[this.props.name] = value;
      this.props.setFormState(formState)
    }


    render() {
        var components = [];
        var componentProps = [];
        var defaults = {}
        var i = 0;
        var functions = [
            {'text':'ajaxWrapper',value:'ajaxWrapper'},
            {'text':'setState',value:'setState'},
            {'text':'setGlobalState',value:'setGlobalState'},
            {'text':'redirect',value:'redirect'},
            {'text':'ga_event',value:'ga_event'}
        ];

        console.log("Render Value", this.props.value);

        for (var index in this.props.value) {
          components.push(<FormWithChildren key={i} defaults={defaults} autoSetGlobalState={true}
              setGlobalState={this.setGlobalState} globalStateName={'form'} dont_resolve_anything={this.props.dont_resolve_anything}>
            <Select name={'function_' + i} options={functions} default={this.props.value[index][0]} defaultoption="Select A Function" />
            <Json_Input name={'value_' + i} default={this.props.value[index][1]} />
          </FormWithChildren>);

          i += 1;
        }

        components.push(
            <Button type={'danger'} text={'Clear'} onClick={this.clear_functions} />
        );

        return (
              <div className={"form-group"}>
                {this.props.label}
                <br />
                <Button type={'primary'} text={'Add Function'} onClick={this.addFunction} />
                <br />
                {components}
              </div>
        )


    }
}

export default Function_Input;
