import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Icon } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import { CRS } from 'leaflet';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import parse_roll from 'projectLibrary/monsters/roll.js';
import SpellUse from 'projectLibrary/monsters/spell_use.js';
import SpellCard from 'projectLibrary/spell_card.js';


function stat_modifier(number) {
    return Math.floor(number/2) - 5;
}

export default class Spell extends React.Component {
    constructor(props) {
        super(props);
        this.state = {roll: null, view_spell: false}

        this.use = this.use.bind(this);
        this.add_token = this.add_token.bind(this);
        this.view_spell = this.view_spell.bind(this);
        this.toggle_macro = this.toggle_macro.bind(this);
    }

    toggle_macro() {
        this.props.toggle_macro('Spell', this.props.spell);
    }

    view_spell() {
        this.props.push_modal_content(<SpellCard spell={this.props.spell} />);
    }

    use() {
        var spell = this.props.spell;
        var instance = this.props.instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var hits = null;
        var spell_save_dc = null;

        var monster = window.cmState.global_state_lookup(this, 'monsters', this.props.instance.monster_id);

        //8 + proficiency_bonus + spell modifier
        var spell_types = ['intelligence','wisdom','charisma'];
        var spell_modifier = 0;

        for (var item of spell_types) {
            console.log(item, monster[item]);
            var value = monster[item];
            if (value > spell_modifier) {
                spell_modifier = value;
            }
        }

        var proficiency_bonus
        var attack_roll_bonus = monster.proficiency_bonus + Math.floor(spell_modifier/2) - 5;

        if (!spell.action_dc.dc_type && (spell.action_damage.damage_at_slot_level || spell.action_damage.damage_at_character_level || spell.action_damage.damage)) {
            var hit_one = parse_roll("1d20 + " + attack_roll_bonus);
            var hit_two = parse_roll("1d20 + " + attack_roll_bonus);
            hits = [hit_one, hit_two]
        }
        else {
            var spell_save_dc = 8 + attack_roll_bonus;
        }

        console.log("Hits", hit_one, hit_two)

        var damages = [];
        if (spell.action_damage.damage_at_slot_level) {
            var slot_level = this.props.cast_level;
            var damage_dice = spell.action_damage.damage_at_slot_level[slot_level];
            damages.push({
                damage:parse_roll(damage_dice),
                type: spell.action_damage.damage_type.name
            });
        }
        else if (spell.action_damage.damage_at_character_level) {
            var last_key = 1;
            for (var key in spell.action_damage.damage_at_character_level) {
                if (key > monster.level) {
                    damages.push({
                        damage:parse_roll(spell.action_damage.damage_at_character_level[last_key]),
                        type: spell.action_damage.damage_type.name
                    });
                    break;
                }
                else {
                    last_key = key;
                }
            }
        }
        else if (spell.action_damage.damage_dice) {
            damages.push({
                damage:parse_roll(spell.action_damage.damage_dice),
                type: spell.action_damage.damage_type.name
            });
        }

        var spell_use =  {
            hits: hits,
            dc: spell_save_dc,
            damages: damages,
            spell_id: spell.id,
            instance_id: this.props.instance.id,
            cast_level: this.props.cast_level
        }
        this.setState({roll:<SpellUse {...spell_use} push_modal_content={this.props.push_modal_content} />});
        this.props.use_action(spell_use);

        if (this.props.spell.area_of_effect.type && this.props.mini) {
            this.add_token();
        }

    }

    add_token() {
        console.log("Add Spell", this.props.spell);
        this.props.add_action_token(this.props.spell, this.props.instance);
    }

    render() {
        var spell = this.props.spell;
        if (spell.higher_level_description != "") {
            var use = <p><b><a onClick={this.use}>Level {this.props.cast_level} {spell.name}</a></b></p>;
        }
        else {
            var use = <p><b><a onClick={this.use}>{spell.name}</a></b></p>;
        }

        var get_token = null;
        if (this.props.spell.area_of_effect.type) {
            var get_token = <Button type='secondary' text='See On Map' onClick={this.add_token} />
        }

        var macro = <Button type='secondary' text='Add To Macro Bar' onClick={this.toggle_macro} />;
        if (this.props.macro) {
            macro = <Button type='danger' text='Remove From Macro Bar' onClick={this.toggle_macro} />
        }

        if (this.props.mini) {
            return(
                <span>
                    <a onClick={this.use} style={{paddingRight:'4px'}} onContextMenu={this.view_spell}>{spell.name},</a>
                </span>
            )
        }
        else {
            return(
                <div>
                    {use}
                    <Button type='primary' text='Cast Spell' onClick={this.use} />
                    <Button type='info' text='View Spell' onClick={this.view_spell} />
                    {macro}
                    {get_token}
                    {this.state.roll}
                </div>
            )
        }

    }
}
