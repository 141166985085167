import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab,
    Link } from 'library';

import ActionsForm from 'projectLibrary/forms/actions.js';
import SpellsForm from 'projectLibrary/forms/spells.js';

import Action from 'projectLibrary/monsters/action.js';
import Spell from 'projectLibrary/monsters/spell.js';

export default class EditMagicalAttribute extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name:'',
            additional_attack_roll:'',
            additional_attack_damage:'',
            additional_attack_damage_type:'',
            stat_bonus:'',
            stat_type:'',
            saving_throw_bonus:'',
            saving_throw_type:'',
            saved: false,
            loaded: false
        };

        this.saved = this.saved.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);
        this.submit = this.submit.bind(this);
        this.attribute_callback = this.attribute_callback.bind(this);

    }

    componentDidMount() {
        console.log("Component Did Mount", this.props.magical_attribute_id)
        if (this.props.magical_attribute_id) {
            console.log("Making Request")
            ajaxWrapper('GET','/api/compendium/magicalattribute/' + this.props.magical_attribute_id + '/', {}, this.attribute_callback)
        }
        else {
            this.setState({loaded: true})
        }
    }

    attribute_callback(result) {
        console.log("Attribute Callback", result)
        var attribute = result[0].magicalattribute;
        attribute['loaded'] = true;
        this.setState(attribute)
    }

    setGlobalState(name, state) {
        this.setState(state)
    }

    submit() {
        var submitUrl = '/api/compendium/magicalattribute/';
        if (this.props.magical_attribute_id) {
            submitUrl = '/api/compendium/magicalattribute/' + this.props.magical_attribute_id + '/';
        }

        console.log("State", this.state)
        ajaxWrapper('POST',submitUrl, this.state, this.saved)
    }

    saved() {
        this.setState({saved:true})
    }

    render() {
        var defaults = this.state;
        var delete_button = null;
        if (this.props.magicalattribute_id) {
            delete_button = <Button type='danger' text='Delete Attribute' dangerType={true} href={'/api/compendium/magicalattribute/' + this.props.magical_attribute_id + '/delete/'} />;
        }

        var stat_types = [
            {text: 'All', value:'all'},
            {text: 'Strength', value:'strength'},
            {text: 'Dexterity', value:'dexterity'},
            {text: 'Constitution', value:'constitution'},
            {text: 'Intelligence', value:'intelligence'},
            {text: 'Wisdom', value:'wisdom'},
            {text: 'Charisma', value:'charisma'},
        ];

        var saving_throw_types = [
            {text: 'All', value:'all'},
            {text: 'Strength Saving Throw', value:'strength_saving_throw'},
            {text: 'Dexterity Saving Throw', value:'dexterity_saving_throw'},
            {text: 'Constitution Saving Throw', value:'constitution_saving_throw'},
            {text: 'Intelligence Saving Throw', value:'intelligence_saving_throw'},
            {text: 'Wisdom Saving Throw', value:'wisdom_saving_throw'},
            {text: 'Charisma Saving Throw', value:'charisma_saving_throw'},
            {text: 'Acrobatics', value:'acrobatics'},
            {text: 'Animal Handling', value:'animal_handling'},
            {text: 'Arcana', value:'arcana'},
            {text: 'Athletics', value:'athletics'},
            {text: 'Deception', value:'deception'},
            {text: 'History', value:'history'},
            {text: 'Insight', value:'insight'},
            {text: 'Intimidation', value:'intimidation'},
            {text: 'Investigation', value:'investigation'},
            {text: 'Medicine', value:'medicine'},
            {text: 'Nature', value:'nature'},
            {text: 'Perception', value:'perception'},
            {text: 'Performance', value:'performance'},
            {text: 'Persuasion', value:'persuasion'},
            {text: 'Religion', value:'religion'},
            {text: 'Sleight of Hand', value:'sleight_of_hand'},
            {text: 'Stealth', value:'stealth'},
            {text: 'Survival', value:'survival'},
        ]

        var saved = null;
        if (this.state.saved) {
            saved = <Alert text='Saved' type='success' />
        }

        if (this.state.loaded) {
            return (
                <div className="container">
                    <div className='card-container row'>
                        <div className='card col-12' >
                            <Link href='/dm_tools/magical_attribute_list/' text='<- Magical Attribute List' />
                        </div>

                        <div className="col-12 card">
                            <FormWithChildren defaults={defaults} setGlobalState={this.setGlobalState} autoSetGlobalState={true} globalStateName={'form'} >
                                <h1>{'Edit Magical Attribute: ' + defaults['name']}</h1>
                                <TextInput name='name' label='Name' />
                                <TextInput name='additional_attack_roll' label='Additional Attack Roll' />
                                <TextInput name='additional_attack_damage' label='Additional Damage' />
                                <Select name='additional_attack_damage_type' label='Additional Attack Damage Type'  optionsUrl={'/api/compendium/damagetype/'}
                                    optionsUrlMap={{text: '{damagetype.name}', value: '{damagetype.name}'}} />
                                <TextInput name='stat_bonus' label='Stat Bonus (+/- for change, number only for absolute)'/>
                                <Select name='stat_type' label='Stat Type' options={stat_types} />
                                <TextInput name='saving_throw_bonus' label='Stat Bonus (+/- for change, number only for absolute)'/>
                                <Select name='saving_throw_type' label='Saving Throw Type' options={saving_throw_types} />
                            </FormWithChildren>
                        </div>

                        <div className='card col-12'>
                            {saved}
                            <div><Button style={{padding: '15px 30px', fontSize:'1.2em'}} type='success' text='Save Attribute' onClick={this.submit} /></div>
                            <br/>
                            <div>{delete_button}</div>
                        </div>
                    </div>
                </div>
            )
        }
        else {
            return (null);
        }



    }
}
