import remove_monster_instance from 'projectLibrary/session_tools/global_data/remove_monster_instance.js';
import update_monster_instance from 'projectLibrary/session_tools/global_data/update_monster_instance.js';
import update_permission from 'projectLibrary/session_tools/global_data/update_permission.js';
import update_location from 'projectLibrary/session_tools/global_data/update_location.js';
import remove_location from 'projectLibrary/session_tools/global_data/remove_location.js';
import update_note from 'projectLibrary/session_tools/global_data/update_note.js';
window.cmState['socket_subscribers'] = []


function SocketRouter(component, types, group) {
    if (typeof(group) == "undefined" || group == "undefined"){
        console.error("ERROR WITH WEBSOCKETS!!!! ", component, types, group);
    }

    this.chat_socket = create_and_bind_socket_connection(this, group);
    this.send_message = send_message.bind(this);

    this.component = component;
    this.types = types;
    this.group = group;

    var ip_address = window.secretReactVars.global_context.ip;
    var user_id = '';
    if (window.secretReactVars.logged_in_user){
        user_id = window.secretReactVars.logged_in_user.id;
    }
    var timestamp = window.secretReactVars.loaded_timestamp;

    this.sender_id = ip_address +'_'+ user_id +'_'+ timestamp;

    window.cmState['socket_subscribers'].push({types: types, chat_socket: this.chat_socket});
}

function create_and_bind_socket_connection(context, group){
    var chat_socket = new WebSocket("wss://" + window.location.host + "/ws/chat/"+ group +"/");
    //var chat_socket = new WebSocket("wss://forgottenmaps.com/ws/chat/"+ group +"/");
    chat_socket.onmessage = receive_message.bind(context);
    chat_socket.onclose = chat_closed.bind(context);
    chat_socket.onopen = chat_opened.bind(context);

    return chat_socket;
}

function receive_message(incoming) {
    //console.log("Message Received", incoming);
    var incoming_data = JSON.parse(incoming.data);
    var message_data = incoming_data['message'];

    //Filter out messages created from this client and not intended for local publishing
    if (!(incoming_data['local']) && incoming_data['sender'] == this.sender_id){
        return false;
    }

    //Filter out messages that dont match the current subscriber
    var found = false;
    for (var key in message_data) {
        if (this.types[key]) {
            found = true;
        }
    }
    if (!found) {
        return false;
    }

    //Handle message updates
    if (message_data['icon']) {
        update_monster_instance(message_data['icon']);
    }
    else if (message_data['icons']) {
        window.cmState.setGlobalState('icon_list', message_data['icons']);
    }
    else if (message_data['remove_icon']) {
        remove_monster_instance(message_data['remove_icon']);
    }
    else if (message_data['instance']) {
        update_monster_instance(message_data['instance']);
    }
    else if (message_data['update_location']) {
        update_location(message_data['update_location']);
    }
    else if (message_data['update_permission']) {
        update_permission(message_data['update_permission']);
    }
    else if (message_data['remove_location']) {
        remove_location(message_data['remove_location']);
    }
    else if (message_data['update_note']) {
        update_note(message_data['update_note']);
    }
    else if (message_data['add_icon']) {
        update_monster_instance(message_data['add_icon']);
    }
    else if (message_data['object_loc']) {
        this.component.setState({'object_loc': message_data['object_loc']});
    }
    else if (message_data['ask_for_roll'] != null) {
        this.component.setState({'ask_for_roll': message_data['ask_for_roll']});
    }
    else if (message_data['action']) {
        console.log("Action Received", message_data['action']);
        if (this.component.add_to_chat_history) {
            this.component.add_to_chat_history(message_data['action']);
        }

        if (message_data['action']['action'] && message_data['action']['action']['name'].indexOf('initiative') > -1) {
            //console.log("Initiative Socket!!")
            if (this.component.state.initiative_order) {
                //console.log("Initiative Component Found!")
                var action = message_data['action'];
                this.component.update_initiative_order(action);
            }
        }
    }
    else if ('clear_initiative_order' in message_data) {
        console.log("In the Initiative Clearing Section");
        if (this.component.state.initiative_order) {
            this.component.setState({initiative_order:[], current_turn:0});
        }
    }
    else if ('current_turn' in message_data) {
        console.log("In the Initiative Turn Section", message_data)
        if ('current_turn' in this.component.state) {
            console.log("Found Component");
            this.component.setState({current_turn:message_data['current_turn']});
        }
    }
    else if (message_data['current_location']) {
        console.log("New Location");
        if (this.component.change_location) {
            this.component.change_location(message_data['current_location']);
        }
    }
    else if (message_data['fog']) {
        if (this.component.state.removed_rectangles){
            var removed_rectangles = this.component.state.removed_rectangles;
            removed_rectangles.push(message_data['fog']);
            this.component.setState({removed_rectangles: removed_rectangles});
        }
    }
    else if (message_data['clear_fog']) {
        if (this.component.state.removed_rectangles){
            this.component.setState({removed_rectangles: []});
        }
    }
    else if (typeof(message_data['toggle_fog']) != 'undefined') {
        if (this.types['toggle_fog'] in this.component.state) {
            var new_state = {};
            new_state[this.types['toggle_fog']] = message_data['toggle_fog'];
            this.component.setState(new_state);
        }
    }
    else if (message_data['free_draw_lines']) {
        if (this.component.state.free_draw_lines){
            var user = window.cmState.user;
            var new_lines = Object.assign({}, this.component.state.free_draw_lines);

            for (var key in message_data['free_draw_lines']){
                if (key != user.id){
                    new_lines[key] = message_data['free_draw_lines'][key];
                }
            }

            this.component.setState({free_draw_lines:new_lines});
        }
    }
    else if (message_data['show_damage']) {
        if (this.component.show_action_damage) {
            this.component.show_action_damage(message_data['show_damage']);
        }
    }
    else if (message_data['ping_map']){
        if (this.component.add_to_chat_history) {
            this.component.add_to_chat_history(message_data);
        }
        else if (this.component.handle_ping_map) {
            this.component.handle_ping_map(message_data);
        }
    }

    else {
        console.log("Did Not Find Data", message_data);
        var messages = this.component.state.messages;
        messages.push(message_data);
        this.component.setState({messages:messages});
    }
}

function chat_closed(e) {
    console.log("Socket Closed Unexpectedly. Reconnect will be attempted in 1 second.", e.reason);
    if (this.component.state.actions_used) {
        var actions_used = this.component.state.actions_used;
        if (actions_used.length == 0 || !('event_type' in actions_used[actions_used.length - 1]) || actions_used[actions_used.length - 1].event_type != 'disconnect'){
            actions_used.push({text: "Socket Closed Unexpectedly. Reconnect will be attempted in 1 second.", event_type: 'disconnect'});
            this.component.setState({actions_used:actions_used});
        }
    }

    setTimeout(function() {
        this.chat_socket = create_and_bind_socket_connection(this, this.group);
    }.bind(this), 1000);
}

function chat_opened(e){
    if (this.component.state.actions_used) {
        var actions_used = this.component.state.actions_used;
        actions_used.push({'text': 'Connected Successfully!', event_type: 'connect'});
        this.component.setState({actions_used:actions_used});
    }
}

function send_message(outgoing_data) {
    publish_message_locally(outgoing_data);

    if (this.chat_socket.readyState == 1){
        console.log("Sending Socket Message", this.sender_id);
        outgoing_data['sender'] = this.sender_id;
        var outgoing_string = JSON.stringify(outgoing_data);

        this.chat_socket.send(outgoing_string);
        console.log("Sent Message", outgoing_string);
    }
    else {
        var text = 'Message Failed to Send! Socket is not connected to server!';
        var failed_string = JSON.stringify({message:{action: {text: text, event_type: 'disconnect'}}});
        publish_message_locally(failed_string);
    }

    return true;
}

function publish_message_locally(outgoing_data){
    outgoing_data['local'] = true;
    var outgoing_string = JSON.stringify(outgoing_data);

    for (var component of window.cmState['socket_subscribers']){
        component.chat_socket.onmessage({
            data: outgoing_string
        });
    }
}


export default SocketRouter;
