

//in xdy, num is x and die_sides is y
function roll_dice(number, die_sides) {
    var dice_rolls = {'rolls':[], total:0};

    for (var index = 0; index < number; index++) {
        var roll = Math.floor(Math.random() * die_sides) + 1;
        dice_rolls['rolls'].push({roll:roll, die:die_sides});
        dice_rolls['total'] += roll;
    }

    return dice_rolls
}

//die rolls in the form of ["xdy", "xdy"], modifiers is [number, number]
function calculate_roll(die_rolls, modifiers) {
    var rolls = [];
    var total = 0;

    for (var die_roll of die_rolls) {
        var positive = true;
        if (die_roll[0] == '+') {
            positive = true;
        }
        else {
            positive = false;
        }

        var split_roll = die_roll.substring(1).split('d');

        var roll = roll_dice(parseInt(split_roll[0]), parseInt(split_roll[1]));
        rolls.push(roll['rolls'])

        if (positive) {
            total += roll['total'];
        }
        else {
            total -= roll['total'];
        }

    }

    for (var modifier of modifiers) {
        total += modifier;
    }

    return {rolls:rolls, total:total, modifiers: modifiers}
}

//roll string in the format "xdy + idj - k"
function parse_roll(roll_string) {
    var roll_split = [];
    var die_rolls = [];
    var modifiers = [];

    var die_string = '+';
    for (let i = 0; i < roll_string.length; i++) {
      var char = roll_string[i];
      if (char == ' ') {
          if (die_string.length > 1) {
              roll_split.push(die_string);
              die_string = '';
          }
      }
      else if (char == '+') {
          if (die_string.length > 1) {
              roll_split.push(die_string);
              die_string = '';
          }
          die_string = '+'
      }
      else if (char == '-') {
          if (die_string.length > 1) {
              roll_split.push(die_string);
              die_string = '';
          }
          die_string = '-'
      }
      else {
          die_string += char;
      }
    }

    //catch the last die_string
    if (die_string.length > 1) {
        roll_split.push(die_string);
    }

    for (var item of roll_split) {
        var die_string = item.trim();
        if (die_string.indexOf('d') > -1) {
            die_rolls.push(die_string);
        }
        else {
            modifiers.push(parseInt(die_string));
        }
    }


    var first_roll = calculate_roll(die_rolls, modifiers)
    var crit_roll = calculate_roll(die_rolls, [0])
    first_roll['crit'] = crit_roll;
    return first_roll;
}

export default parse_roll;
