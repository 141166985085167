import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { PageBreak, Container, Alert } from 'library';


export default class SpellCard extends React.Component {
    render() {
        var spell = this.props.spell;

        var ritual = '';
        if (spell.spell_specifics.ritual_cast) {
            ritual = ', Ritual'
        }

        var concentration = '';
        if (spell.spell_specifics.concentration) {
            concentration = ', Concentration';
        }

        var cast_level = null;
        if (this.props.action_use && this.props.action_use.cast_level != spell.level){
            cast_level = <Alert type='info' text={'Cast at level ' + this.props.action_use.cast_level} />;
        }

        //goal: http://www.highprogrammer.com/alan/design/5e-dnd-cards/1original.png
        var spell_content = <Container>
            <div style={{padding:'10px 0px'}}>
                <div className="row">
                    <div className="col-12"><br/></div>
                    <div className="col-12"><h2>{spell.name}</h2></div>
                    <div className="col-12">{spell.level} level {spell.spell_specifics.magic_school} spell.</div>
                    <div className="col-12">{cast_level}</div>
                    <div className="col-12"><br/></div>

                    <div className="col-6">
                        <p><b>Casting Time: </b> {spell.time_to_use}{ritual}</p>
                    </div>
                    <div className="col-6">
                        <p><b>Range: </b> {spell.range}</p>
                    </div>
                    <div className="col-6">
                        <p><b>Components: </b> {spell.spell_specifics.components} ({spell.spell_specifics.material})</p>
                    </div>
                    <div className="col-6">
                        <p><b>Duration: </b> {spell.duration}{concentration}</p>
                    </div>

                    <div className="col-12"><b>Materials: </b> {spell.spell_specifics.material}</div>
                    <div className="col-12"><br/></div>

                    <div className="col-12" style={{whiteSpace:'break-spaces'}} ><b>Description: </b> {spell.description}</div>
                    <div className="col-12"><br/></div>

                    <div className="col-12" style={{whiteSpace:'break-spaces'}} ><b>At Higher Levels: </b> {spell.spell_specifics.higher_level_description}</div>
                    <div className="col-12"><br/></div>
                </div>
            </div>
        </Container>

        return (
            spell_content
        );
    }
}
