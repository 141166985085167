import React, { Component } from 'react';
import { Line } from 'react-konva';


export default class FogOfWar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rectangles_to_draw: [],
        }

        this.check_rectangle_for_overlap = this.check_rectangle_for_overlap.bind(this);
        this.break_two_rectangles_into_three = this.break_two_rectangles_into_three.bind(this);

        this.is_overlapping_1D = this.is_overlapping_1D.bind(this);
        this.is_overlapping = this.is_overlapping.bind(this);
        this.is_containing_1D = this.is_containing_1D.bind(this);
        this.is_containing = this.is_containing.bind(this);
    }

    componentDidMount(){
        var rectangles = this.state.rectangles_to_draw;
        for (var newest_rectangle of this.props.removed_rectangles) {
            rectangles = this.check_rectangle_for_overlap(rectangles, newest_rectangle);
        }
        this.setState({rectangles_to_draw: rectangles});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.removed_rectangles_length != this.props.removed_rectangles_length){
            console.log("Fog of war did update!", prevProps.removed_rectangles_length, this.props.removed_rectangles_length)
            if (prevProps.removed_rectangles_length > this.props.removed_rectangles_length){
                console.log("There are less removed Rectangles!!")
                if (this.props.removed_rectangles_length == 0) {
                    console.log("Clear Fog!")
                    this.setState({rectangles_to_draw: []});
                }
            }
            else {
                var rectangles = this.state.rectangles_to_draw;
                if (rectangles.length == 0) {
                    for (var newest_rectangle of this.props.removed_rectangles) {
                        rectangles = this.check_rectangle_for_overlap(rectangles, newest_rectangle);
                    }
                    this.setState({rectangles_to_draw: rectangles});
                }
                else {
                    var newest_rectangle = this.props.removed_rectangles.slice().pop();
                    var new_rectangles = this.check_rectangle_for_overlap(rectangles, newest_rectangle);
                    this.setState({rectangles_to_draw: new_rectangles});
                }

            }
        }
    }

    check_rectangle_for_overlap(rectangles, rectangle) {
        var new_rectangles = [];
        var added = false;

        for (var existing_rectangle of rectangles){
            var existing_is_containing = this.is_containing(existing_rectangle, rectangle);
            var new_is_containing = this.is_containing(rectangle, existing_rectangle);

            var is_overlapping = this.is_overlapping(existing_rectangle, rectangle);

            if (existing_is_containing){
                new_rectangles = rectangles;
                added = true;
                break;
            }
            else if (new_is_containing){
                continue;
            }
            else if (is_overlapping){
                var broken_rectangles = this.break_two_rectangles_into_three(existing_rectangle, rectangle);
                if (broken_rectangles.length == 0){
                    new_rectangles.push(existing_rectangle);
                }
                else {
                    for (var broken_rectangle of broken_rectangles) {
                        new_rectangles = this.check_rectangle_for_overlap(new_rectangles, broken_rectangle);
                    }
                    added = true;
                }
            }
            else {
                new_rectangles.push(existing_rectangle);
            }
        }

        if (!(added)){
            new_rectangles.push(rectangle);
        }

        return (new_rectangles);
    }

    break_two_rectangles_into_three(box_1, box_2){
        var rectangles = [];

        var box_1_is_upper_left = box_1.start_point[0] <= box_2.start_point[0] && box_1.start_point[1] <= box_2.start_point[1];
        var box_2_is_upper_left = box_2.start_point[0] < box_1.start_point[0] && box_2.start_point[1] < box_1.start_point[1];
        var box_1_is_upper_right = box_1.start_point[0] >= box_2.start_point[0] && box_1.start_point[1] <= box_2.start_point[1];
        var box_2_is_upper_right = box_2.start_point[0] > box_1.start_point[0] && box_2.start_point[1] < box_1.start_point[1];

        if (box_1_is_upper_left || box_2_is_upper_left) {
            if (box_2_is_upper_left) {
                var temp_box = box_1;
                box_1 = box_2;
                box_2 = temp_box;
            }

            var box_passes_right_side = box_2.end_point[0] > box_1.end_point[0];
            var x_max = box_1.end_point[0];
            if (!(box_passes_right_side)) {
                x_max = box_2.end_point[0];
            }

            var box_passes_bottom_side = box_2.end_point[1] > box_1.end_point[1];
            var y_max = box_1.end_point[1];
            if (!(box_passes_bottom_side)) {
                y_max = box_2.end_point[1];
            }

            rectangles.push(box_1);
            if (box_passes_right_side) {
                rectangles.push({
                    start_point: [x_max, box_2.start_point[1]],
                    end_point: [box_2.end_point[0], y_max],
                });
            }
            if (box_passes_bottom_side){
                rectangles.push({
                    start_point: [box_2.start_point[0], y_max],
                    end_point: [box_2.end_point[0], box_2.end_point[1]],
                });
            }
        }
        else if (box_1_is_upper_right || box_2_is_upper_right) {
            if (box_2_is_upper_right) {
                var temp_box = box_1;
                box_1 = box_2;
                box_2 = temp_box;
            }

            var box_is_wider_than = box_2.end_point[0] > box_1.end_point[0];
            var box_passes_bottom_side = box_2.end_point[1] > box_1.end_point[1];
            if (box_passes_bottom_side && !(box_is_wider_than)) {
                rectangles.push(box_1);
                rectangles.push({
                    start_point: [box_2.start_point[0], box_2.start_point[1]],
                    end_point: [box_1.start_point[0], box_2.end_point[1]],
                });
                rectangles.push({
                    start_point: [box_1.start_point[0], box_1.end_point[1]],
                    end_point: [box_2.end_point[0], box_2.end_point[1]],
                });
            }
            else if (box_passes_bottom_side && box_is_wider_than) {
                rectangles.push(box_2);
                rectangles.push({
                    start_point: [box_1.start_point[0], box_1.start_point[1]],
                    end_point: [box_1.end_point[0], box_2.start_point[1]],
                });
            }
            else if (!(box_passes_bottom_side) && !(box_is_wider_than)) {
                rectangles.push(box_1);
                rectangles.push({
                    start_point: [box_2.start_point[0], box_2.start_point[1]],
                    end_point: [box_1.start_point[0], box_2.end_point[1]],
                });
            }
            else if (!(box_passes_bottom_side) && box_is_wider_than) {
                rectangles.push(box_1);
                rectangles.push({
                    start_point: [box_2.start_point[0], box_2.start_point[1]],
                    end_point: [box_1.start_point[0], box_2.end_point[1]],
                });
                rectangles.push({
                    start_point: [box_1.end_point[0], box_2.start_point[1]],
                    end_point: [box_2.end_point[0], box_2.end_point[1]],
                });
            }

        }

        return (rectangles);
    }

    is_containing(box_1, box_2){
        var x_containing = this.is_containing_1D(box_1.start_point[0], box_1.end_point[0], box_2.start_point[0], box_2.end_point[0]);
        var y_containing = this.is_containing_1D(box_1.start_point[1], box_1.end_point[1], box_2.start_point[1], box_2.end_point[1]);

        return (x_containing && y_containing);
    }

    is_overlapping(box_1, box_2) {
        var x_overlap = this.is_overlapping_1D(box_1.start_point[0], box_1.end_point[0], box_2.start_point[0], box_2.end_point[0]);
        var y_overlap = this.is_overlapping_1D(box_1.start_point[1], box_1.end_point[1], box_2.start_point[1], box_2.end_point[1]);

        return (x_overlap && y_overlap);
    }

    is_containing_1D(x_1_min, x_1_max, x_2_min, x_2_max){
        return (x_2_min >= x_1_min && x_1_max >= x_2_max);
    }

    is_overlapping_1D(x_1_min, x_1_max, x_2_min, x_2_max){
        return (x_1_max > x_2_min && x_2_max > x_1_min);
    }

    render() {
        var zoom = this.props.zoom;
        var points = [];

        var map_points = [
            [0, 0],
            [this.props.map_size[0], 0],
            [this.props.map_size[0], this.props.map_size[1]],
            [0, this.props.map_size[1]]
        ];

        for (var point of map_points){
            points.push(point[0] * zoom);
            points.push(point[1] * zoom);
        }

        for (var rectangle of this.state.rectangles_to_draw) {
            points.push(rectangle.start_point[0] * zoom);
            points.push(rectangle.start_point[1] * zoom);

            points.push(rectangle.start_point[0] * zoom);
            points.push(rectangle.end_point[1] * zoom);

            points.push(rectangle.end_point[0] * zoom);
            points.push(rectangle.end_point[1] * zoom);

            points.push(rectangle.end_point[0] * zoom);
            points.push(rectangle.start_point[1] * zoom);

            points.push(rectangle.start_point[0] * zoom);
            points.push(rectangle.start_point[1] * zoom);

            points.push(map_points[3][0] * zoom);
            points.push(map_points[3][1] * zoom);
        }

        var opacity = 1;
        if (this.props.dm) {
            opacity = .5;
        }

        return (
            <Line ref={this.props.fog_ref} points={points} /*stroke="red" strokeWidth={3}*/ fill="#333333" opacity={opacity} closed={true} />
        );
    }
}
