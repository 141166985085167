import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput } from 'library';
import { Map, Marker, Popup, TileLayer, LeafletMap, ImageOverlay, Polygon } from 'react-leaflet'
import { CRS } from 'leaflet';
import Location from 'projectLibrary/map_components/location_token.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class FantasyMap extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			viewport: {
				center: [86.5, 125],
				zoom: 6,
			},
			full_map_zoom: 3,
			window: { width: 10, height: 10 }
		}

		//'https://media.wizards.com/2015/images/dnd/resources/Sword-Coast-Map_MedRes.jpg' 93, 250

        this.calculate_bounds = this.calculate_bounds.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.on_viewport_changed = this.on_viewport_changed.bind(this);
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

    componentDidUpdate(prevProps) {
        var viewport = this.props.viewport;
        if (prevProps.viewport != viewport) {
            this.calculate_bounds();
        }
    }

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({
            window: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        });
	}

    calculate_bounds() {
        var location = this.props.location;

        var aspect_ratio = location.max_lat / location.max_lng;
        var height_ratio = aspect_ratio / this.state.window.height;
        var width_ratio = 1 / this.state.window.width;

        if (height_ratio > width_ratio) {
            var miles_to_compare = location.total_width_in_miles * this.state.window.width * height_ratio;
        } else {
            var miles_to_compare = location.total_width_in_miles * this.state.window.width * width_ratio;
        }

		var full_map_zoom = Math.log2(25000 / miles_to_compare);
        if (full_map_zoom % .4 != 0){
            var multiplier = parseInt(full_map_zoom / .4);
            full_map_zoom = .4 * multiplier;
        }

		if (isMobile) {
			full_map_zoom -= 1;
		}

		var viewport = {
			center: [location.max_lat / 2, location.max_lng / 2],
			zoom: full_map_zoom
		};

        var new_state = {};
		new_state.viewport = viewport;
		new_state.full_map_zoom = full_map_zoom;

		this.setState(new_state);
    }

	handleClick(e) {
		this.props.handleClick(e);
	}

	on_viewport_changed(viewport) {
		// The viewport got changed by the user, keep track in state
		/*
		if (viewport.zoom < this.state.full_map_zoom) {
			viewport.zoom = this.state.full_map_zoom;
		} else if (viewport.zoom > this.state.full_map_zoom + 30) {
			viewport.zoom = this.state.full_map_zoom + 30;
		}

		if (viewport.zoom == this.state.viewport.zoom && viewport.center[0] == this.state.viewport.center[0] && viewport.center[1] == this.state.viewport.center[1]) {
			return false;
		}

		var lat_delta = (viewport.center[0] - this.state.viewport.center[0]) / this.props.location.max_lat;
		var lng_delta = (viewport.center[1] - this.state.viewport.center[1]) / this.props.location.max_lng;

		if (Math.abs(lat_delta) > .01 || Math.abs(lng_delta) > .01 || viewport.zoom != this.state.viewport.zoom) {
			console.log("Updating Viewport", viewport.zoom, viewport.center[0], viewport.center[1]);
			this.setState({ viewport: viewport });
		} else {
			console.log(lat_delta, lng_delta, "Delta too small to update!");
		}
		*/
	}

	render() {


		var map_config = {
			duration: .1,
			maxBounds: 1000,
			zoomSnap: .4,
			zoomDelta: .4,
			wheelPxPerZoomLevel: 150,
			viewport: this.state.viewport,
			onClick: this.handleClick,
			onViewportChanged: this.on_viewport_changed,
			crs: CRS.Simple
		};

        var map_images = [];
        for (var y = 0; y < 3; y++) {
            for (var x = 0; x < 3; x++) {
                map_images.push(
                    <ImageOverlay
                    key={"image_" + y + "_" + x}
                    url={"https://forgotten-maps-proc-gen.s3-us-west-2.amazonaws.com/full_forest_" + y + "_" + x + ".png"}
                    bounds={[[0 - 10 * y,0 + 10 * x],[10 - 10 * y,10 + 10 * x]]}
                    />)
            }
        }

		//var map_image = <ImageOverlay key={"image_0"} url={"https://forgotten-maps-proc-gen.s3-us-west-2.amazonaws.com/full_forest_0_1.png"} bounds={[[0,0],[100, 180]]} />;
        //var map_image_2 = <ImageOverlay key={"image_1"} url={"https://forgotten-maps-proc-gen.s3-us-west-2.amazonaws.com/full_forest_0_2.png"} bounds={[[0,180],[100, 360]]} />;
        //var map_image_3 = <ImageOverlay key={"image_2"} url={"https://forgotten-maps-proc-gen.s3-us-west-2.amazonaws.com/full_forest_1_0.png"} bounds={[[-100,0],[0, 180]]} />;

		return (
			<Map bounds={[[0,0],[100,180]]} crs={CRS.Simple}>
                {map_images}
            </Map>
		);
	}
}

export default FantasyMap;
