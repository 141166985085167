import {ajaxWrapper} from 'functions';
import update_permission from 'projectLibrary/session_tools/global_data/update_permission.js';

function update_permission_from_data(permission, data) {
    for (var key in data) {
        if (key == 'location') {
            permission['location_id'] = data[key];
        }
        permission[key] = data[key];
    }

    return permission;
}

function save_permission(data) {
    console.log("Save Permission", data)
    var id = null;
    if (data['id']) {
        id = data['id']
        delete data['id'];
    }

    //location exists
    if (id) {
        var url = '/api/home/grouplocationpermission/' + id + '/'
        var permission = window.cmState.global_state_lookup(this, 'location_permissions', data.location);
        var updated_permission = update_permission_from_data(permission, data);
        window.cmState.chat_socket.send_message({message: {'update_permission': updated_permission}})
        //update_permission(updated_permission);

        ajaxWrapper('POST', url, data, console.log)
    }
    //location doesn't exist
    else {
        var url = '/api/home/grouplocationpermission/'
        ajaxWrapper('POST', url, data, load_permission)
    }
}

function load_permission(result) {
    var permission = result[0].grouplocationpermission;
    window.cmState.chat_socket.send_message({message: {'update_permission': permission}})
    //update_permission(permission);
}

export default save_permission;
