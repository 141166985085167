import React, { Component } from 'react';
import {Wrapper, Container, Header, Div} from 'library';


class ThankYou extends Component {
    render() {
        return (<div>
				<Container>
					<Div style={{'margin-top': '50px', 'text-align': 'center'}} >
						<Header text={"Thank you!"}  />
						<Header size={"4"} text={"Thank you for signing up to contribute. We'll be getting in touch soon to let you start changing things on the map. Look out for an email shortly!"}  />
					</Div>
				</Container>
        </div>);
    }
}

export default ThankYou;
