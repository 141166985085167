import React, { Component } from 'react';
import {ajaxWrapper} from 'functions';
import settings from 'base/settings.js';
import {Button, FormWithChildren, ColorPicker, NumberInput, Container} from 'library';

var MAP_MODE_ICONS = {
    'pan': <i class="fas fa-expand"></i>,
    'free_draw': <i class="fas fa-pencil-alt"></i>,
    'measure': <i class="fas fa-ruler"></i>,
    'fog_of_war': <i class="far fa-eye"></i>,
    'add_markers': <i class="fas fa-map-marker-alt"></i>,
}

export default class SelectMode extends React.Component {
    constructor(props) {
        super(props);

        this.select_map_mode = this.select_map_mode.bind(this);
        this.toggle_modal = this.toggle_modal.bind(this);
    }

    select_map_mode() {
        this.props.setGlobalState('map_mode', {'map_mode': this.props.map_mode});
    }

    toggle_modal() {
        this.props.push_modal_content(
            <FormWithChildren globalStateName={'free_draw'} autoSetGlobalState={true} setGlobalState={this.props.setGlobalState} defaults={this.props.line_vars}>
                <ColorPicker name='line_color' />
                <NumberInput name='line_thickness' />
            </FormWithChildren>
        );
    }

    render() {
        if (this.props.map_mode == this.props.current_mode) {
            if (this.props.map_mode == 'pan') {
                return (<div className='map-mode-select btn btn-secondary'>
                    {MAP_MODE_ICONS[this.props.map_mode]}
                    <div className='floating-map-options' style={{textAlign: 'left'}} >
                        <div><Button type='primary' text='Center' onClick={this.props.center_map} /></div>
                        <div style={{paddingTop:'10px'}}>
                            <Button type='primary' text={<i class="fas fa-search-plus"></i>} onClick={() => this.props.change_zoom(1)} />
                            <Button type='danger' text={<i class="fas fa-search-minus"></i>} onClick={() => this.props.change_zoom(-1)} />
                        </div>
                    </div>
                </div>);
            }
            else if (this.props.map_mode == 'free_draw') {
                return (<div className='map-mode-select btn btn-secondary'>
                    {MAP_MODE_ICONS[this.props.map_mode]}
                    <div className='floating-map-options' >
                        <Button type='primary' text='Edit' onClick={this.toggle_modal} />
                    </div>
                </div>);
            }
            else if (this.props.map_mode == 'fog_of_war') {
                return (<div className='map-mode-select btn btn-secondary'>
                    {MAP_MODE_ICONS[this.props.map_mode]}
                    <div className='floating-map-options' >
                        <Button type='danger' text='Clear' onClick={this.props.clear_fog_of_war} />
                    </div>
                </div>);
            }
            else {
                return (<div className='map-mode-select btn btn-secondary' title={this.props.map_mode} >
                    {MAP_MODE_ICONS[this.props.map_mode]}
                </div>);
            }
        }
        else {
            return (<div className='map-mode-select btn btn-outline-secondary' title={this.props.map_mode} onClick={this.select_map_mode} >
                {MAP_MODE_ICONS[this.props.map_mode]}
            </div>);
        }
    }
}
