import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren,
    TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import Link from 'projectLibrary/proc_gen/link.js';


export default class BusinessContent extends React.Component {

    render() {
        var business = this.props.business;

        var extra_content = null;

        var employees = [];

        var founder = this.props.person_dict[business.founder_id]
        var owner = this.props.person_dict[business.owner_id]
        var owner_text = null;
        if (owner) {
            owner_text = <p>Owner: <Link type='person' text={owner.name} select_item={this.props.select_item} id={owner.id} /></p>
        }

        var salesman = this.props.person_dict[business.top_salesman_id]
        var artisan = this.props.person_dict[business.top_artisan_id]

        if (business.employee.length > 1) {
            extra_content = [<h3>All Employees</h3>]

            for (var item of business.employee) {
                var employee = item.person;
                extra_content.push(<div><Link type='person' text={employee.name} select_item={this.props.select_item} id={employee.id} /><br/></div>)
            }
        }

        var hiring = 'No';
        if (business.hiring) {
            hiring = 'Yes!'
        }

        return (
            <div>
                <h2>{business.name}</h2>
                <p>{business.profession} Store in operation for {business.age} years.</p>
                <p>Yearly Customers: {business.fame}</p>
                <p>Hiring? {hiring}</p>
                <p>Product Rating: {business.top_skill}/20</p>
                <p>Salesmanship: {business.top_charisma}/20</p>
                <div style={{height:'20px'}}></div>
                <p>Founder: <Link type='person' text={founder.name} select_item={this.props.select_item} id={founder.id} /></p>
                {owner_text}
                <p>Main Salesman: <Link type='person' text={salesman.name} select_item={this.props.select_item} id={salesman.id} /></p>
                <p>Main Artisan: <Link type='person' text={artisan.name} select_item={this.props.select_item} id={artisan.id} /></p>

                {extra_content}
            </div>
        )
    }
}
