import {ajaxWrapper} from 'functions';


function update_note(note) {
    var location_id = note.location;

    var saved_notes = window.cmState.global_state_lookup(this, 'location_notes', location_id);

    var notes = [];
    var found = false;
    for (var temp_note of saved_notes) {
        if (temp_note.id == note.id) {
            found = true;
            notes.push(note);
        }
        else {
            notes.push(temp_note);
        }
    }
    if (!found) {
        notes.push(note);
    }

    window.cmState.location_notes[location_id] = notes;

    window.cmState.setGlobalState('location_notes', window.cmState.location_notes);

}

export default update_note;
