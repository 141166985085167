import React, { Component } from 'react';
import { resolveVariables } from 'functions';
import {Wrapper, Container, FormWithChildren, TextInput, TextArea, Header, If, Alert} from 'library';


class ContactUs extends Component {
    render() {
        return (<div>
				<Container order={"1"} style={{'margin': '20px auto'}} min_height={true} >
					<Header text={"Help Contribute To Forgotten Maps"}  />
					<FormWithChildren functions={resolveVariables({"text":[['ajaxWrapper', {'url': '/api/email/', 'data': {'text': '{text} <br/> from {from_email}', 'subject': 'Message From {location.origin}: {subject}'}, 'type': 'POST'}], ['setGlobalState', {'sent': true}]]}, window.cmState.getGlobalState(this))["text"]} submitUrl={"/api/email/"} submit_text={"Send"} >
						<TextInput name={"from_email"} label={"Your Email Address"}  />
						<TextInput name={"subject"} label={"Subject"} default={"Contributing to Forgotten Maps"}  />
						<TextArea name={"text"} label={"Message"}  />
					</FormWithChildren>
					<If logic={[['{sent}', 'true']]} override={false} >
						<Alert text={"Message Sent"} type={"success"} style={{'margin-top': '20px'}}  />
					</If>
				</Container>
        </div>);
    }
}

export default ContactUs;
