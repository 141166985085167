import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { FormWithChildren, TextInput, Button, PageBreak } from 'library';

export default class MonsterSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            url: '',
            monster: this.props.monster_id,
        }

    }


    render() {

        return (
            <div>
                <div className='container'>
                    <h2>Add Link</h2>

                    <PageBreak />
                    <FormWithChildren submitUrl={'/api/compendium/monsterlink/'} globalStateName={'monster_search'} defaults={this.state}>
                        <TextInput name='name' label='Name' />
                        <TextInput name='url' label='URL' />
                    </FormWithChildren>
                </div>
            </div>
        )
    }
}
