import {ajaxWrapper} from 'functions';
import update_location from 'projectLibrary/session_tools/global_data/update_location.js';

function update_location_from_data(location, data) {
    for (var key in data) {
        location[key] = data[key];
    }

    delete location['parent']
    delete location['children']

    return location;
}

function save_location(data) {
    console.log("Save Location Data", data);
    var id = null;
    if (data['id']) {
        id = data['id']
        delete data['id'];
    }

    //location exists
    if (id) {
        var url = '/api/home/location/' + id + '/'
        var location = window.cmState.global_state_lookup(this, 'locations', id);
        var updated_location = update_location_from_data(location, data);
        ajaxWrapper('POST', url + '?related=tags,links', data, load_location);
    }
    //location doesn't exist
    else {
        var url = '/api/home/location/?related=tags,link'
        ajaxWrapper('POST', url, data, load_location);
    }
}

function load_location(result) {
    var location = result[0].location;
    window.cmState.chat_socket.send_message({message: {'update_location': location}});
    //update_location(location);
}

export default save_location;
