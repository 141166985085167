import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren, PageBreak, Tab } from 'library';

export default class EditCondition extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var submitUrl = '/api/compendium/condition/';
        var defaults = {}
        if (this.props.condition) {
            defaults = this.props.condition;
            submitUrl = '/api/compendium/condition/' + this.props.condition.id + '/';
        }

        return (
            <FormWithChildren submitUrl={submitUrl} defaults={defaults}>
                <TextInput name='name' label='Name' />
                <TextArea name='description' label='Description' />
            </FormWithChildren>
        )
    }
}
