import {ajaxWrapper} from 'functions';
import update_monster_instance from 'projectLibrary/session_tools/global_data/update_monster_instance.js';


function save_monster_instance(data, callback) {
    console.log("Save Monster Instance", data);
    if (!(callback)){ callback = console.log;}

    var id = null;
    if (data['id']) {
        id = data['id'];
    }

    var player_dict = window.cmState.player_characters;

    //monsterinstance already exists
    if (id in window.cmState.instances || player_dict[id]) {
        console.log("Monster Instance Already Exists");
        var instance_data = Object.assign({}, data);
        var url = '/api/home/monsterinstance/' + id + '/';
        if (id in window.cmState.instances) {
            var instance = window.cmState.instances[id];
        }
        else {
            console.log("Player Dict");
            var instance = player_dict[id];
        }

        update_monster_instance(data);
        window.cmState.chat_socket.send_message({'message':{'instance':data}});

        delete instance_data['monster'];
        delete instance_data['equipped_items'];
        delete instance_data['monster_instance_resources'];
        if (instance_data['concentrating_on_id']){
            instance_data['concentrating_on'] = instance_data['concentrating_on_id'];
            delete instance_data['concentrating_on_id'];
        }

        ajaxWrapper('POST', url, instance_data, (result) => load_monster_instance(result, callback));
    }
    //action instance
    else if (id in window.cmState.action_instances) {
        var action_data = Object.assign({}, data);
        var url = '/api/home/actioninstance/' + id + '/';

        update_monster_instance(data);
        window.cmState.chat_socket.send_message({'message':{'icon':data}});

        delete action_data['action'];
        delete action_data['instance'];
        ajaxWrapper('POST', url, action_data, callback);
    }
    //monsterinstance doesn't exist
    else {
        console.log('Creating new Monster Instance');
        var url = '/api/home/monsterinstance/?related=monster_instance_resources';
        ajaxWrapper('POST', url, data, load_monster_instance);
    }
}

function load_monster_instance(result, callback) {
    var monster_instance = result[0].monsterinstance;
    console.log("Update Monster Instance", monster_instance);

    update_monster_instance(monster_instance);
    window.cmState.chat_socket.send_message({'message':{'instance':monster_instance}});

    if (typeof(callback) != 'undefined'){
        callback();
    }
}


export default save_monster_instance;
