
import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Container, Button } from 'library';

import FancySpellSheet from 'projectLibrary/monsters/fancy_char_sheet/spell_main.js';
import FancyBioSheet from 'projectLibrary/monsters/fancy_char_sheet/bio_main.js';
import FancyCharSheet from 'projectLibrary/monsters/fancy_char_sheet/main.js';
import LevelUp from 'projectLibrary/forms/level_up.js';

import save_monster from 'projectLibrary/session_tools/global_data/save_monster.js';
import save_monster_instance from 'projectLibrary/session_tools/global_data/save_monster_instance.js';

export default class ModalFancyCharSheet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            instance: null,
            monster: null,
            width: 0,
            updated_timestamp: new Date().valueOf()
        };

        this.update_monster_relay = this.update_monster_relay.bind(this);
        this.update_instance_relay = this.update_instance_relay.bind(this);
        this.level_up = this.level_up.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'selected_monster_instance');
        window.cmState.subscribe_by_name(this, 'resources');
        window.cmState.subscribe_by_name(this, 'equipment');
    }

    level_up() {
        var instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var data = {
            instance: instance,
            monster: monster,
            resources: window.cmState.resources,
            equipment: window.cmState.equipment,
            update_monster: this.update_monster_relay,
            update_instance: this.update_instance_relay,
            push_modal_content: this.props.push_modal_content,
            use_action: this.props.use_action,
            add_action_token: this.props.add_action_token,
            roll_skill: this.props.roll_skill
        };

        this.props.push_modal_content(<LevelUp {...data} />)
    }

    update_monster_relay(name, data, only_state) {

        var monster = window.cmState.global_state_lookup(this, 'monsters', window.cmState.selected_monster_instance.monster_id);
        data['id'] = monster.id
        var proficiency_bonus = [
            {level:1, bonus:2},
            {level:5, bonus:3},
            {level:9, bonus:4},
            {level:13, bonus:5},
            {level:17, bonus:6},
        ]

        var level = data['level'] || monster.level;
        var bonus = 0;
        for (var prof_bonus of proficiency_bonus) {
            if (prof_bonus.level <= level) {
                bonus = prof_bonus.bonus;
            }
        }

        data['proficiency_bonus'] = bonus;

        var spellcasting_modifier = bonus;

        //this is only mostly going to be accurate until we build in classes
        var max_bonus = 0;
        if (monster.intelligence >= monster.wisdom && monster.intelligence >= monster.charisma) {
            console.log("INT")
            max_bonus = Math.floor(monster.intelligence/2) - 5;
        }
        else if (monster.wisdom >= monster.intelligence && monster.wisdom >= monster.charisma) {
            console.log("WIS")
            max_bonus = Math.floor(monster.wisdom/2) - 5;
        }
        else if (monster.charisma >= monster.wisdom && monster.charisma >= monster.intelligence) {
            console.log("CHA")
            max_bonus = Math.floor(monster.charisma/2) - 5;
        }
        spellcasting_modifier += max_bonus;

        if (monster.spellcasting_modifier != spellcasting_modifier){
            data['spellcasting_modifier'] = spellcasting_modifier;
        }

        console.log("Update Monster Relay", data)
        save_monster(data);

        this.setState({
            updated_timestamp: new Date().valueOf()
        });
    }

    update_instance_relay(name, data, only_state) {

        var instance = window.cmState.selected_monster_instance;
        data['id'] = instance.id;
        console.log("Update Monster Instance Relay", data)
        save_monster_instance(data);

        this.setState({
            updated_timestamp: new Date().valueOf()
        });
    }

    render() {
        var instance = window.cmState.selected_monster_instance;
        var monster = window.cmState.global_state_lookup(this, 'monsters', instance.monster_id);

        var data = {
            instance: instance,
            monster: monster,
            update_monster: this.update_monster_relay,
            update_instance: this.update_instance_relay,
            push_modal_content: this.props.push_modal_content,
            use_action: this.props.use_action,
            add_action_token: this.props.add_action_token,
            roll_skill: this.props.roll_skill
        };

        var content = <Container>
            <Button type='primary' text='Level Up' onClick={this.level_up} />
            <FancyCharSheet {...data} />

            <div ><br/><br/><br/></div>
            <div style={{borderTop: '2px solid black'}} ><br/><br/><br/></div>

            <FancySpellSheet {...data} />

            <div ><br/><br/><br/></div>
            <div style={{borderTop: '2px solid black'}} ><br/><br/><br/></div>

            <FancyBioSheet {...data} />
        </Container>;

        return (
            content
        )
    }
}
