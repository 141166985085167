import React, { Component } from 'react';
import { Container, Select, Button, FormWithChildren } from 'library';
import stat_modifier from 'projectLibrary/stat_modifier.js';
import get_spells_by_level from 'projectLibrary/monsters/get_spells_by_level.js';
import SpellLevel from 'projectLibrary/monsters/fancy_char_sheet/spell_level.js';
import SpellsForm from 'projectLibrary/forms/spells.js';
import CreateResource from 'projectLibrary/monsters/fancy_char_sheet/create_resource.js';


export default class FancySpellSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {width: 0};

        this.updateDimensions = this.updateDimensions.bind(this);
        this.show_spells = this.show_spells.bind(this);
    }

    componentDidMount(){
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    updateDimensions() {
      var width = document.getElementById('fancy-spell-sheet').getBoundingClientRect().width;
      this.setState({'width':width});
    }

    show_spells(){
        this.props.push_modal_content(
            <SpellsForm monster={this.props.monster} update_monster={this.props.update_monster} card_inline={true} />
        );
    }


    render() {
        var font_size = (this.state.width * .013) + 'px';
        var instance = this.props.instance;
        var monster = this.props.monster;

        var update_functions = {
            update_monster: this.props.update_monster,
            update_instance: this.props.update_instance,
            push_modal_content: this.props.push_modal_content,
            add_action_token: this.props.add_action_token,
            use_action: this.props.use_action
        }

        var ability_options = [
            {text: 'STRENGTH', value: 'strength'},
            {text: 'DEXTERITY', value: 'dexterity'},
            {text: 'CONSTITUTION', value: 'constitution'},
            {text: 'INTELLIGENCE', value: 'intelligence'},
            {text: 'WISDOM', value: 'wisdom'},
            {text: 'CHARISMA', value: 'charisma'}
        ];

        var top_section = null;
        var body_section = null;

        if (monster){
            var spell_bonus = 0;
            var spell_dc = 0;
            var spell_stat = '';
            if (monster.spellcasting_ability){
                spell_stat = monster[monster.spellcasting_ability];
                spell_bonus = stat_modifier(spell_stat);
                spell_dc = 8 + spell_bonus + monster.proficiency_bonus;
            }

            var spell_list = [];
            for (var item of monster.action_spells) {
                var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);
                if (action.action_type != 'Ability' && action.action_type != 'Attack'){
                    spell_list.push(action);
                }
            }
            var spells_by_level = get_spells_by_level(this, spell_list, monster, instance);

            var create_resource = <CreateResource only={'Spell Slot Level'} small_size={'col-12'}
                text={'Add Spell Slot'} monster={this.props.monster} instance={this.props.instance}
                update_monster={this.props.update_monster} update_instance={this.props.update_instance} />;

            var top_section = <div className='header col-12' >
                <div className='player-name'>{monster.name}</div>
                <div className='top-block' >
                    <div className='row top-level-block' >
                        <div className='col-4' >
                            <div className='top-level-spell-block'>
                                <Select sneaky_input={true} name='spellcasting-ability' className="top-level-spell-block-text" options={ability_options} default={monster.spellcasting_ability} />

                            </div>
                            <div className='under-title'>SPELLCASTING ABILITY</div>
                        </div>
                        <div className='col-4' >
                            <div className='top-level-spell-block'>
                                <div className='top-level-spell-block-value'>{spell_dc}</div>
                            </div>
                            <div className='under-title'>SPELL SAVE DC</div>
                        </div>
                        <div className='col-4' >
                            <div className='top-level-spell-block'>
                                <div className='top-level-spell-block-value'>{spell_bonus}</div>
                            </div>
                            <div className='under-title'>SPELL ATTACK BONUS</div>
                        </div>
                    </div>
                </div>
            </div>;

            var body_section = <div className='body col-12 row' >
                <div className='col-4'>
                    <SpellLevel monster={monster} instance={instance} level={0} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={1} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={2} spells={spells_by_level} {...update_functions} />
                </div>
                <div className='col-4'>
                    <SpellLevel monster={monster} instance={instance} level={3} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={4} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={5} spells={spells_by_level} {...update_functions} />
                </div>
                <div className='col-4'>
                    <SpellLevel monster={monster} instance={instance} level={6} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={7} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={8} spells={spells_by_level} {...update_functions} />
                    <SpellLevel monster={monster} instance={instance} level={9} spells={spells_by_level} {...update_functions} />

                    <div className='resources' >
                        <div className='col-12'><div className='resource' style={{textAlign: 'center', padding: '5% 2%'}} >
                            <Button type={'default'} text={'Edit Spells'} onClick={this.show_spells} />
                        </div></div>
                        {create_resource}
                    </div>
                </div>
            </div>
        }

        return (
            <div id='fancy-spell-sheet' className='fancy-sheet fancy-spell-sheet row' style={{fontSize: font_size}} >
                {top_section}
                {body_section}
            </div>
        )
    }
}
