
function load_icon_from_monster(instance, monster) {
    var name = instance.name;
    if (name == "") {
        name = monster.name;
    }

    var image = monster['image'];
    var image_size = monster['image_size'];
    var override_image = false;
    if (instance.image != '') {
        image = instance.image;
        image_size = instance.image_size;
        override_image = true;
    }

    var is_player_instance = false;
    var players = window.cmState.group.player_characters;
    for (var item of players){
        var player = item['playercharacter'];
        if (player.main_instance && player.main_instance.id == instance.id){
            is_player_instance = true;
        }
    }

    var icon = Object.assign({}, instance);
    icon['image'] = image;
    icon['image_size'] = image_size;
    icon['override_image'] = override_image;
    icon['type'] = 'icon';
    icon['name'] = name;
    icon['monster'] = monster;
    icon['is_player_instance'] = is_player_instance;
    
    return icon;
}

export default load_icon_from_monster;
