import React, { Component } from 'react';
import $ from 'jquery';

import { ajaxWrapper } from 'functions';
import { Button, PageBreak, FormWithChildren, TextInput } from 'library';
import ActionUse from 'projectLibrary/monsters/action_use.js';
import SpellUse from 'projectLibrary/monsters/spell_use.js';
import CustomRoll from 'projectLibrary/monsters/custom_roll.js';
import ConcentrationRoll from 'projectLibrary/monsters/concentration_roll.js';
import SkillCheckPrompt from 'projectLibrary/session_tools/skill_check_prompt.js';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';

import parse_roll from 'projectLibrary/monsters/roll.js';


class PingMap extends React.Component {
    constructor(props) {
        super(props);

        this.use = this.use.bind(this);
    }

    use(event) {
        var coords = {
            x: this.props.action['ping_map']['position'][0],
            y: this.props.action['ping_map']['position'][1],
        };

        this.props.center_map(event, coords);
    }

    render() {
        var details = this.props.action['ping_map'];

        return (
            <div className='chat-entry' >
                <div className='chat-header' >
                    <span>{details['name']}</span>
                    <span>{' : '}</span>
                 </div>
                <div style={{"padding":"10px"}}>
                    <div style={{display:'inline-block', fontSize: '20px'}} >The map has been pinged!</div>
                    <Button type='success' text='Go There' style={{marginLeft: '10px', display: 'inline-block'}} onClick={this.use} />
                </div>
            </div>
        )
    }
}


export default class ChatContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            actions_used:[],
            previous_rolls:[],
            num_back:-1,
            text: ''
        };

        this.handle_key_down = this.handle_key_down.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.get_chat_history = this.get_chat_history.bind(this);
        this.load_chat_history = this.load_chat_history.bind(this);
        this.add_to_chat_history = this.add_to_chat_history.bind(this);
        this.show_action_damage = this.show_action_damage.bind(this);

        this.send_custom_roll = this.send_custom_roll.bind(this);
        this.send_concentration_roll = this.send_concentration_roll.bind(this);
    }

    componentDidMount() {
        var socket_mapping = {
            action: 'action',
            show_damage: 'show_damage',
            ping_map: 'ping_map',
        };

        var chat_socket = new SocketRouter(this, socket_mapping, this.props.group_id);
        this.setState({chat_socket: chat_socket});

        this.get_chat_history();
    }

    setGlobalState(name, state) {
        this.setState(state)
    }

    componentDidUpdate(){
        $(".chat-scroll-container").scrollTop($(".chat-scroll-container")[0].scrollHeight);
    }

    get_chat_history(){
        var url = '/api/home/GroupChatMessage/?order_by=-created_at&limit=50&group_id=' + this.props.group_id;
        ajaxWrapper('GET', url, {}, this.load_chat_history);
    }

    load_chat_history(value){
        var actions_used = this.state.actions_used;
        value.reverse();
        for (var item of value){
            var action_used = item['GroupChatMessage']['data']['action'];
            if (action_used){
                actions_used.push(action_used);
            }
        }

        actions_used.push({'donation': true});

        this.setState({actions_used: actions_used});
    }

    add_to_chat_history(action){
        var actions_used = this.state.actions_used;
        actions_used.push(action);
        this.setState({actions_used:actions_used});
    }

    handle_key_down(key_code) {
        // arrow up/down button should select next/previous list element
        if (key_code === "ArrowDown" && this.state.num_back > 0) {
            this.setState({
                num_back: this.state.num_back - 1,
                text: this.state.previous_rolls[this.state.num_back - 1]
            });
        } else if (key_code === "ArrowUp" && this.state.num_back < this.state.previous_rolls.length - 1) {
            this.setState({
                num_back: this.state.num_back + 1,
                text: this.state.previous_rolls[this.state.num_back + 1]
            });
        }
    }

    show_action_damage(message){
        var actions_used = this.state.actions_used;
        for (var action of actions_used) {
            if (action && action['id'] == message['id']) {
                if (message['undo']) {
                    delete action['show_damage'];
                }
                else if (message['critical']) {
                    action['show_damage'] = 'critical';
                }
                else {
                    action['show_damage'] = 'normal';
                }
            }
        }

        this.setState({actions_used: actions_used});
    }

    send_concentration_roll(dc, action_id) {
        console.log("Concentration", dc);
        var chat_instance = this.props.current_instance;

        if (chat_instance) {
            var concentration_roll = {
                'action': {
                    'concentration_id': action_id,
                    'instance': chat_instance,
                    'dc': dc,
                }
            };

            this.state.chat_socket.send_message({'message':concentration_roll});
        }
    }

    send_custom_roll(state, submit_finished){
        state = this.state;
        console.log("Send Message", state['text']);
        var chat_instance = this.props.current_instance;
        var previous_rolls = this.state.previous_rolls;
        previous_rolls.unshift(state['text'])
        this.setState({
            previous_rolls:previous_rolls,
            num_back:-1,
            text:''
        });

        if (chat_instance) {
            var custom_roll = {
                'action': {
                    'text': state['text'],
                    'instance_id': chat_instance.id
                }
            };

            var result = parse_roll(state['text'].replace('/r ', ''));
            custom_roll['action']['custom_roll'] = result;
            this.state.chat_socket.send_message({'message':custom_roll});
        }

        submit_finished();
    }


    render() {
        //console.log("Actions Used", this.props.actions_used);
        var action_data = [];
        if (window.cmState.instances && window.cmState.actions){
            action_data = this.state.actions_used;
        }

        var actions_used = [];
        for (var action of action_data) {
            if (!action) {
                console.log("Broken Action", action_data, action);
                continue;
            }

            if ('instance_id' in action){
                action.instance = window.cmState.global_state_lookup(this, 'instances', action.instance_id);
            }
            if ('action_id' in action){
                action.action = window.cmState.global_state_lookup(this, 'actions', action.action_id);
            }
            if ('spell_id' in action){
                action.spell = window.cmState.global_state_lookup(this, 'actions', action.spell_id);
            }

            var full_display = this.props.dm || (action.instance && action.instance.player_controlled);

            if (action['ping_map']){
                actions_used.push(<PingMap action={action} center_map={this.props.center_map} />);
            }
            else if (action.custom_roll || (action.action && action.action.custom_roll)) {
                actions_used.push(<CustomRoll key={action.id} {...action} roll_skill={this.props.roll_skill} take_damage={this.props.take_damage} full_display={full_display} />);
            }
            else if (action.concentration_id) {
                actions_used.push(<ConcentrationRoll {...action} key={action.id} roll_skill={this.props.roll_skill} full_display={full_display} />);
            }
            else if (action.action) {
                //console.log("Action", action.action)
                actions_used.push(<ActionUse key={action.id} {...action} send_concentration_roll={this.send_concentration_roll}
                    roll_skill={this.props.roll_skill} take_damage={this.props.take_damage} full_display={full_display}
                    roll_damage={this.props.roll_damage} current_instance={this.props.current_instance} push_modal_content={this.props.push_modal_content} />);
            }
            else if (action.donation){
                actions_used.push(<div className='chat-entry' >
                    <div className='chat-header' >
                        <span>Your Humble Developers</span>
                        <span>{' : '}</span>
                     </div>
                    <div style={{"padding":"10px"}}>
                        <h3>If you've enjoyed using Rogue Tech for your DnD sessions, consider donating.</h3>
                        <Button type='success' text='Donate Now' href='https://www.patreon.com/forgottenrealmmaps' style={{fontSize:"18px"}} target={"_blank"} />
                    </div>
                </div>);
            }
            else {
                var message_class = 'alert alert-info';
                if ('event_type' in action && action.event_type == 'disconnect'){
                    message_class = 'alert alert-danger';
                }
                actions_used.push(<div className={message_class} >{action.text}</div>);
            }
        }

        actions_used.push(<br/>);
        actions_used.push(<br/>);

        var skill_check_prompt = <SkillCheckPrompt ask_for_roll={this.props.ask_for_roll} dm={this.props.dm}
            roll_skill={this.props.roll_skill} clear_roll={this.props.clear_roll} />;

        var default_text = '';
        if (this.state.num_back != -1) {
            var default_text = this.state.previous_rolls[this.state.num_back];
        }

        return (
            <div className='chat-container' style={{position:'relative'}} >
                <h2>Recent Activity</h2>
                <PageBreak />
                {skill_check_prompt}
                <div className='chat-scroll-container' style={{height:(this.props.height - 33) + 'px', overflowY:'scroll'}} >
                    {actions_used}
                    <FormWithChildren submit_on_enter={true} no_submit_button={true} submit={this.send_custom_roll}
                        reset_state_on_submit={true} redirect={console.log} globalStateName={'chatroom'} key_listener={this.handle_key_down}
                        autoSetGlobalState={true} setGlobalState={this.setGlobalState} >
                        <TextInput name='text' default={default_text} placeholder="1d20 + 2"/>
                    </FormWithChildren>
                </div>
            </div>
        );
    }
}
