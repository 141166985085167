import ReactGA from 'react-ga';
const trackingId = 'UA-36358352-2';

ReactGA.initialize(trackingId);


function ga_event(category, action){
    ReactGA.event({
      category: category,
      action: action,
    });
}


export default ga_event;
