import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {CSSInput, TextInput, Icon} from 'library';

class TableHeader extends React.Component {
    static component_name = 'TableHeader';
    constructor(props) {
        super(props);

        this.config = {
            form_components: [
                <TextInput label={'data'} name={'data'} default='' />,
                <TextInput label={'Sort By'} name={'sort_by'} default='' />,
                <TextInput label={'Sort Name'} name={'sort_name'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
            ],
            can_have_children: true,
        }

        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        if (this.props.sort_by != '' && this.props.sort_name != '') {
            //Subscribe to sort_name
            window.cmState['subscribe_by_name'](this, this.props.sort_name);

            var current_sort = window.cmState.getGlobalState()[this.props.sort_name];
            var reverse_sort = '-' + this.props.sort_by;
            if (this.props.sort_by == current_sort) {
                window.cmState.setGlobalState(this.props.sort_name, reverse_sort);
            }
            else {
                window.cmState.setGlobalState(this.props.sort_name, this.props.sort_by);
            }
        }
    }

    render() {
        var style = this.props.style || {};
        var sorting = null;
        var container_style = {
            display: 'inline-block',
            verticalAlign: 'top',
            height: '28px'
        };
        var container_style_with_padding = Object.assign({}, container_style);
        container_style_with_padding['padding'] = '4px 10px 4px 0px';

        if (this.props.sort_name != '' && this.props.sort_name != undefined) {
            var current_sort = window.cmState.getGlobalState()[this.props.sort_name];
            var reverse_sort = '-' + this.props.sort_by;
            style['cursor'] = 'pointer';

            var up_style = {'color':'gray'};
            var down_style = {'color':'gray'};
            if (current_sort == this.props.sort_by){
                up_style['color'] = 'black';
            }
            else if (current_sort == '-' + this.props.sort_by){
                down_style['color'] = 'black';
            }

            sorting = <div style={{display:'flex', flexDirection:'column', marginLeft:'3px'}}>
                <Icon icon={'chevron-up'} size={1} style={up_style} />
                <Icon icon={'chevron-down'} size={1} style={down_style}/>
            </div>
        }

        return (
            <th onClick={this.onClick} style={style}>
                <div style={container_style_with_padding} >{this.props.data || this.props.children}</div>
                <div style={container_style} >{sorting}</div>
            </th>
        );
    }
}

export default TableHeader;
