
import {ajaxWrapper} from 'functions';


function load_dnd_constants(callback) {
    ajaxWrapper('GET', '/api/get_constants/', {}, (value) => load_dnd_constants_callback(value, callback));
}

function load_dnd_constants_callback(result, callback){
    var result_map = result['result_map'];

    for (var key in result_map){
        var object_dict = {};

        var object_name = result_map[key];

        for (var item of result[key]) {
            var constant_item = item[object_name];
            object_dict[constant_item.id] = constant_item;
        }

        window.cmState.setGlobalState(key, object_dict);

        if (key == 'conditions'){
            var conditions_select = [];
            for (var key in object_dict){
                var item = object_dict[key];
                conditions_select.push({
                    text: item.name,
                    value: item.id
                });
            }

            window.cmState.setGlobalState('conditions_select', conditions_select);
        }
    }

    if ('default_objects' in result){
        window.cmState.setGlobalState('default_objects', result['default_objects']);
    }

    callback();
}

export default load_dnd_constants;
