import React, { Component } from 'react';
import {ajaxWrapper} from 'functions';
import CanvasText from 'projectLibrary/canvas/text.js';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import LocationToken from 'projectLibrary/map_components/location_token.js';
import get_scaled_position from 'projectLibrary/canvas/get_scaled_position.js';


export default class LocationMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            map_position: [0,0],
            zoom: 0.5,
            lines:[],
            lat_multiplier:.65,
            lng_multiplier: 1.54,
        };

        this.start_token_drag = this.start_token_drag.bind(this);
        this.move_token_drag = this.move_token_drag.bind(this);
        this.end_token_drag = this.end_token_drag.bind(this);

        this.end_map_drag = this.end_map_drag.bind(this);
        this.on_zoom = this.on_zoom.bind(this);
        this.click_map = this.click_map.bind(this);

        this.screen_size = this.screen_size.bind(this);
    }

    start_token_drag(e) {
        console.log('Start Token Drag');
        var event_position = [e.evt.clientX, e.evt.clientY];
        if (e.evt.type == 'touchmove') {
            event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
        }

        var position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );

        this.setState({start_token_drag_position: position});
    }

    move_token_drag(e) {
        var event_position = [e.evt.clientX, e.evt.clientY];
        if (e.evt.type == 'touchmove') {
            event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
        }

        var position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );

        this.setState({end_token_drag_position: position});
    }

    end_token_drag(e, token) {
        console.log("E", e)
        console.log("Token", token);

        var ft_per_pixel = this.props.location.ft_in_width / this.props.location.location_image_size[0];
        var pixels_between_lines = this.props.location.grid_size / ft_per_pixel;

        var event_position = [e.evt.clientX, e.evt.clientY];

        var token_postion = e.target.position();
        var position = get_scaled_position(
            [token_postion.x, token_postion.y],
            [0, 0],
            this.state.zoom
        );

        //Actual mouse position
        var mouse_position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );
        if (isNaN(mouse_position[0]) || isNaN(mouse_position[0])) {
            mouse_position = this.state.end_token_drag_position;
        }

        var zoom_proportion = token.props.zoom / this.state.zoom;
        var x_offset = ((pixels_between_lines * (token.props.icon.image_size[0] / token.props.icon.image_size[1])) / 2) * zoom_proportion;
        var y_offset = pixels_between_lines * zoom_proportion;
        var x = position[0] + x_offset;
        var y = position[1] + y_offset;

        var grid_position = {x: x, y: y};

        token.token_ref.move({
            x: (x - position[0]) * this.state.zoom,
            y: (y - position[1]) * this.state.zoom,
        });
        token.token_ref.getLayer().draw();

        this.props.move_icon(token.props.icon.id, grid_position, true);

        this.setState({
            start_token_drag_position: null,
            end_token_drag_position: null,
        });
    }

    end_map_drag(position) {
        this.setState({map_position: position});
    }

    click_map(position, e) {
        if (this.props.click_map) {
            this.props.click_map(position, e);
        }
    }

    on_zoom(zoom) {
        this.setState({zoom: zoom});
        window.cmState.setGlobalState('zoom', zoom)
    }

    screen_size(zoom, offset) {
        this.setState({zoom:zoom, map_position:[offset,offset]})
        window.cmState.setGlobalState('zoom', zoom)
    }

    render() {
        var zoom = this.state.zoom;
        var map_size = this.props.location.location_image_size;
        var ft_per_pixel = this.props.location.ft_in_width / map_size[0];
        var pixels_between_lines = this.props.location.grid_size / ft_per_pixel;

        var is_map_draggable = true;

        var markers = [];
		var colors = ['red','blue','green','white','black','grey','purple'];
		var color_index = 0;

        for (var marker of this.props.markers) {
			var color = null;
			var marker_index = this.props.markers.indexOf(marker);

            var draggable = false;
            if (this.props.map_mode == 'move_marker' && window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.id == marker.id) {
                draggable = true;
            }
            if (marker.type != 'region') {
                markers.push(<LocationToken admin_access={this.props.admin_access} location={marker} index={marker_index} start_token_drag={this.start_token_drag} move_token_drag={this.move_token_drag}
                    end_token_drag={this.end_token_drag} set_details_sidebar_mode={this.props.set_details_sidebar_mode} color={color}
                    group={this.props.group} pixels_between_lines={pixels_between_lines} parent_location={this.props.location} lat_multiplier={this.state.lat_multiplier}
                    lng_multiplier={this.state.lng_multiplier} draggable={draggable} />);
            }
        }

        console.log("Markers", markers.length);

        return (
            <div className="container-container">
                <div>
                    <CanvasMap screen_size={this.screen_size} location={this.props.location} is_map_draggable={is_map_draggable} on_drag_end={this.end_map_drag}
                        on_zoom={this.on_zoom} click_map={this.click_map} center_sidebar_width={this.props.center_sidebar_width}
                        center_map_position={this.props.center_map_position} center_map_timestamp={this.props.center_map_timestamp}
                        map_mode={this.props.map_mode} map_position={this.state.map_position} line_vars={this.props.line_vars}>
                        {markers}
                    </CanvasMap>
                </div>
            </div>
        );
    }
}
