import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { PageBreak, Container } from 'library';


export default class ActionCard extends React.Component {
    render() {
        var action = this.props.action;

        return (
            <div className='card' style={{whiteSpace: 'pre-wrap'}}>
                <h2 style={{marginBottom:'15px', borderBottom: 'thin solid #cfcfcf'}} >{action.name}</h2>
                <p><b>Description: </b> {action.description}</p>
            </div>
        )
    }
}
