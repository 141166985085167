import React, { Component } from 'react';
import { ajaxWrapper, is_right_click } from 'functions';
import { Stage, Layer, Line, Rect, Group, Shape, Text } from 'react-konva';
import FogOfWar from 'projectLibrary/session_tools/fog_of_war.js';
import CanvasText from 'projectLibrary/canvas/text.js';
import Health from 'projectLibrary/session_tools/health.js';
import CanvasMap from 'projectLibrary/canvas/canvas_map.js';
import Token from 'projectLibrary/session_tools/token.js';
import get_scaled_position from 'projectLibrary/canvas/get_scaled_position.js';
import SpellToken from 'projectLibrary/session_tools/spell_token.js';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';
import Transformer from 'projectLibrary/canvas/transformer.js';
import LocationToken from 'projectLibrary/map_components/location_token.js';


class FreeDrawLine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hover: false,
        };

        this.click_line = this.click_line.bind(this);
        this.hover = this.hover.bind(this);
    }

    click_line(event) {
        if (is_right_click(event.evt)){
            this.props.remove_line(this.props.index);
        }

        event.cancelBubble = true;
    }

    hover() {
        var new_hover = !this.state.hover;

        if (new_hover){
            this.props.change_map_cursor('crosshair');
        }
        else {
            this.props.change_map_cursor('default');
        }

        this.setState({
            hover: new_hover
        });
    }

    render() {
        return (
            <Line key={'Line_' + this.props.key} points={this.props.points}
                stroke={this.props.stroke} strokeWidth={this.props.strokeWidth}
                onMouseUp={this.click_line} onTouchEnd={this.click_line}
                onMouseEnter={this.hover} onMouseLeave={this.hover} />
        )
    }
}

export default class SessionMap extends Component {
    constructor(props) {
        super(props);

        var lines = this.props.group_location.free_draw_lines;
        if (Array.isArray(lines)){
            lines = {
                '0': lines
            };
        }

        this.state = {
            start_draw_position: null,
            end_draw_position: null,
            map_position: [0,0],
            zoom: 0.5,
            removed_rectangles: this.props.group_location.fog_of_war_rectangles,
            fog_socket: null,
            lines:[],
            free_draw_lines:lines,
            map_cursor: 'default',
        };

        this.start_token_drag = this.start_token_drag.bind(this);
        this.move_token_drag = this.move_token_drag.bind(this);
        this.end_token_drag = this.end_token_drag.bind(this);

        this.start_draw = this.start_draw.bind(this);
        this.move_draw = this.move_draw.bind(this);
        this.end_draw = this.end_draw.bind(this);

        this.end_map_drag = this.end_map_drag.bind(this);
        this.on_zoom = this.on_zoom.bind(this);
        this.click_map = this.click_map.bind(this);

        this.remove_line = this.remove_line.bind(this);
        this.screen_size = this.screen_size.bind(this);

        this.end_token_transform = this.end_token_transform.bind(this);
        this.change_map_cursor = this.change_map_cursor.bind(this);
    }

    componentDidMount() {
        var socket_mapping = {
            fog: 'removed_rectangles',
            free_draw_lines:'free_draw_lines',
            clear_fog: 'clear_fog'
        }
        var fog_socket = new SocketRouter(this, socket_mapping, this.props.group_location.group_id);

        this.setState({
            fog_socket: fog_socket,
        });

        window.cmState.subscribe_by_name(this, 'icon_list');
        window.cmState.subscribe_by_name(this, 'action_instances');
        window.cmState.subscribe_by_name(this, 'locations');
        window.cmState.subscribe_by_name(this, 'location_permissions');
    }

    start_token_drag(e) {
        console.log('Start Token Drag');
        var event_position = [e.evt.clientX, e.evt.clientY];
        if (e.evt.type == 'touchmove') {
            event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
        }

        var position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );

        this.setState({start_token_drag_position: position});
    }

    move_token_drag(e) {
        var event_position = [e.evt.clientX, e.evt.clientY];
        if (e.evt.type == 'touchmove') {
            event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
        }

        var position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );

        this.setState({end_token_drag_position: position});
    }

    end_token_drag(e, token) {
        var location = window.cmState.global_state_lookup(this, 'locations', this.props.location);

        var ft_per_pixel = location.ft_in_width / location.location_image_size[0];
        var pixels_between_lines = location.grid_size / ft_per_pixel;
        var event_position = [e.evt.clientX, e.evt.clientY];

        var token_postion = e.target.position();
        var position = get_scaled_position(
            [token_postion.x, token_postion.y],
            [0, 0],
            this.state.zoom
        );

        //Actual mouse position
        var mouse_position = get_scaled_position(
            event_position,
            this.state.map_position,
            this.state.zoom
        );
        if (isNaN(mouse_position[0]) || isNaN(mouse_position[0])) {
            mouse_position = this.state.end_token_drag_position;
        }

        var mouse_grid_offset = [
            parseInt((mouse_position[0] - position[0]) / pixels_between_lines) * pixels_between_lines,
            parseInt((mouse_position[1] - position[1]) / pixels_between_lines) * pixels_between_lines,
        ];

        if (token.props.icon.location == true){
            var zoom_proportion = token.props.zoom / this.state.zoom;
            var x_offset = ((pixels_between_lines * (token.props.icon.image_size[0] / token.props.icon.image_size[1])) / 2) * zoom_proportion;
            var y_offset = pixels_between_lines * zoom_proportion;
            var x = position[0] + x_offset;
            var y = position[1] + y_offset;
        }
        else if (!token.props.icon.action_id) {
            var x = Math.floor(mouse_position[0] / pixels_between_lines) * pixels_between_lines - mouse_grid_offset[0];
            var y = Math.floor(mouse_position[1] / pixels_between_lines) * pixels_between_lines - mouse_grid_offset[1];
        }
        else {
            var x = position[0];
            var y = position[1];
        }

        var grid_position = {x: x, y: y};

        token.token_ref.move({
            x: (x - position[0]) * this.state.zoom,
            y: (y - position[1]) * this.state.zoom
        });
        token.token_ref.getLayer().draw();

        var location = false;
        if (token.props.icon.location == true) {
            console.log("Token is location", token);
            location = true;
        }
        this.props.move_icon(token.props.icon.id, grid_position, location);

        this.setState({
            start_token_drag_position: null,
            end_token_drag_position: null,
        });
    }

    end_token_transform(icon, rotation, coords) {
        var position = get_scaled_position(
            [coords.x, coords.y],
            [0, 0],
            this.state.zoom
        );
        var new_coords = {
            x: position[0],
            y: position[1]
        };
        this.props.rotate_icon(icon.id, rotation, new_coords);
    }

    start_draw(position) {
        if (this.props.map_mode == 'fog_of_war') {
            this.setState({start_reveal_position: position});
        }
        else if (this.props.map_mode == 'free_draw') {
            this._drawing = true;

            var user = window.cmState.user;
            var free_draw_lines = Object.assign({}, this.state.free_draw_lines);
            var existing_lines = free_draw_lines[user.id] || [];
            free_draw_lines[user.id] = [
                ...existing_lines,
                {
                    points:[parseInt(position[0]), parseInt(position[1])],
                    color:this.props.line_vars.line_color,
                    thickness:this.props.line_vars.line_thickness
                }
            ];

            this.setState({
              free_draw_lines: free_draw_lines
            });
        }
        else if (this.props.map_mode == 'measure') {
            this.setState({start_token_drag_position: position});
        }
    }

    move_draw(position) {
        var location = window.cmState.global_state_lookup(this, 'locations', this.props.location);

        if (this.props.map_mode == 'fog_of_war') {
            this.setState({end_reveal_position: position});
        }
        else if (this.props.map_mode == 'free_draw') {
            if (!this._drawing) {
              return false;
            }
            var user = window.cmState.user;
            const lines = this.state.free_draw_lines[user.id];

            var last_line = lines[lines.length - 1];
            var last_position = [last_line.points[last_line.points.length - 2], last_line.points[last_line.points.length - 1]];

            var map_size = location.location_image_size;
            var ft_per_pixel = location.ft_in_width / map_size[0];
            var pixels_between_grid_lines = location.grid_size / ft_per_pixel;

            var distance = Math.sqrt(Math.pow(last_position[0] - position[0], 2) + Math.pow(last_position[1] - position[1], 2));
            //Line segments should be no less than 10% of a grid's size
            if (distance < (pixels_between_grid_lines * .2)){
                return false;
            }

            last_line.points.push(parseInt(position[0]));
            last_line.points.push(parseInt(position[1]));
            lines.splice(lines.length - 1, 1, last_line);

            var updated_lines = lines.concat();
            var free_draw_lines = Object.assign({}, this.state.free_draw_lines);
            free_draw_lines[user.id] = updated_lines;

            this.setState({
              free_draw_lines: free_draw_lines,
            });

            this.state.fog_socket.send_message({'message':{'free_draw_lines': free_draw_lines}})
        }
        else if (this.props.map_mode == 'measure') {
            this.setState({end_token_drag_position: position});
        }
    }

    end_draw(position) {
        console.log("End Draw")
        if (this.props.map_mode == 'fog_of_war') {
            var start_position = this.state.start_reveal_position;
            var rectangles = this.state.removed_rectangles;

            var min_x = position[0] < start_position[0] ? position[0] : start_position[0];
            var min_y = position[1] < start_position[1] ? position[1] : start_position[1];
            var max_x = position[0] > start_position[0] ? position[0] : start_position[0];
            var max_y = position[1] > start_position[1] ? position[1] : start_position[1];

            var new_rectangle = {
                start_point: [min_x, min_y],
                end_point: [max_x, max_y]
            };
            rectangles.push(new_rectangle);

            this.setState({
                start_reveal_position: null,
                removed_rectangles: rectangles
            });
            ajaxWrapper('POST','/api/home/grouplocation/' + this.props.group_location.id + '/', {fog_of_war_rectangles: rectangles}, console.log)

            this.state.fog_socket.send_message({'message': {'fog':new_rectangle}});
        }
        else if (this.props.map_mode == 'free_draw') {
            this._drawing = false;
            var free_draw_lines = this.state.free_draw_lines;
            console.log("Free Draw Lines Length", free_draw_lines.length);
            this.state.fog_socket.send_message({'message':{'free_draw_lines': free_draw_lines}});
            ajaxWrapper('POST','/api/home/grouplocation/' + this.props.group_location.id + '/', {free_draw_lines: free_draw_lines}, console.log)
        }
        else if (this.props.map_mode == 'measure') {
            this.setState({start_token_drag_position: null, end_token_drag_position:null});
        }
    }

    end_map_drag(position) {
        this.setState({map_position: position});
    }

    click_map(position, is_right, e) {
        if (this.props.click_map) {
            this.props.click_map(position, is_right, e);
        }
    }

    on_zoom(zoom) {
        this.setState({zoom: zoom});
        window.cmState.setGlobalState('zoom', zoom);
    }

    screen_size(zoom, offset) {
        this.setState({zoom:zoom, map_position:[offset,offset]});
        window.cmState.setGlobalState('zoom', zoom);
    }

    remove_line(key) {
        var id = key.split('_')[0];
        var index = key.split('_')[1];

        var free_draw_lines = this.state.free_draw_lines;
        free_draw_lines[id].splice(index,1);

        this.setState({
            free_draw_lines: free_draw_lines,
            map_cursor: 'default',
        });
        this.state.fog_socket.send_message({'message':{'free_draw_lines':free_draw_lines}});
        ajaxWrapper('POST','/api/home/grouplocation/' + this.props.group_location.id + '/', {free_draw_lines: this.state.free_draw_lines}, console.log);
    }

    change_map_cursor(style){
        this.setState({
            map_cursor: style,
        });
    }

    render() {
        var zoom = this.state.zoom;
        var location = window.cmState.global_state_lookup(this, 'locations', this.props.location);
        var map_size = location.location_image_size;

        var ft_per_pixel = location.ft_in_width / map_size[0];
        var pixels_between_lines = location.grid_size / ft_per_pixel;

        var is_map_draggable = false;
        var are_tokens_draggable = false;
        if (this.props.map_mode == 'pan') {
            is_map_draggable = true;
            are_tokens_draggable = true;
        }

        var grid_lines = [];
        for (var i = 0; i < map_size[0]/pixels_between_lines + 1; i ++) {
            grid_lines.push(<Line closed stroke="rgba(0,0,0,.2)" strokeWidth={1}
                points={[
                    pixels_between_lines * this.state.zoom * i,
                    0 * this.state.zoom,
                    pixels_between_lines * this.state.zoom * i,
                    map_size[1] * this.state.zoom
                ]} />
            );
        }

        for (var i = 0; i < map_size[1]/pixels_between_lines + 1; i ++) {
            grid_lines.push(<Line closed stroke="rgba(0,0,0,.2)" strokeWidth={1}
                points={[
                    0,
                    pixels_between_lines * this.state.zoom * i,
                    map_size[0] * this.state.zoom,
                    pixels_between_lines * this.state.zoom * i
                ]} />
          );
        }

        var fog_of_war = null;
        if (this.props.fog_of_war){
            fog_of_war = <FogOfWar zoom={this.state.zoom} map_size={location.location_image_size} fog_ref={this.fog_reference}
                removed_rectangles={this.state.removed_rectangles} removed_rectangles_length={this.state.removed_rectangles.length} dm={this.props.dm}/>;
        }

        var drawn_rect = null;
        if (this.props.map_mode == 'fog_of_war' && this.state.start_reveal_position && this.state.end_reveal_position) {
            var x = this.state.start_reveal_position[0] * zoom;
            var y = this.state.start_reveal_position[1] * zoom;
            var width = (this.state.end_reveal_position[0] * zoom - x);
            var height = (this.state.end_reveal_position[1] * zoom - y);

            drawn_rect = <Rect x={x} y={y} width={width} height={height} fill="red" />;
        }

        var measurement_line = null;
        var measurement_text = null;
        if (this.props.map_mode != 'fog_of_war' && this.state.start_token_drag_position && this.state.end_token_drag_position) {
            var x_1 = this.state.start_token_drag_position[0] * zoom;
            var y_1 = this.state.start_token_drag_position[1] * zoom;
            var x_2 = this.state.end_token_drag_position[0] * zoom;
            var y_2 = this.state.end_token_drag_position[1] * zoom;

            //console.log("Measurement Coords", x_1, y_1, x_2, y_2)
            //console.log("Hypotenuse", Math.sqrt(Math.pow(x_1 - x_2, 2) + Math.pow(y_1 - y_2, 2)))
            //console.log("Ft Per Pixel", ft_per_pixel)

            var measurement_points = [x_1, y_1, x_2, y_2];
            var distance = (Math.sqrt(Math.pow(x_1 - x_2, 2) + Math.pow(y_1 - y_2, 2)) * ft_per_pixel / this.state.zoom);
            var measurement_scale = ' ft.'
            if (distance > 5280) {
                distance = distance / 5280;
                measurement_scale = ' miles.'
            }

            distance = distance.toFixed(1);


            var middle_ground = [(x_1 + x_2) / 2, (y_1 + y_2) / 2];

            measurement_line = <Line stroke="red" points={measurement_points} />;
            measurement_text = <CanvasText zoom={this.state.zoom} pixels_between_lines={pixels_between_lines} image_size={[1000,100]}
                x={middle_ground[0]} y={middle_ground[1]} text={distance + measurement_scale} />;
        }

        var image_instances = {};
        var icons = [];
        if (window.cmState.icon_list) {
            for (var icon of window.cmState.icon_list) {
                var icon_index = window.cmState.icon_list.indexOf(icon);

                var current_location = [
                    icon.coords.x * this.state.zoom,
                    icon.coords.y * this.state.zoom
                ];

                if (isNaN(current_location[0])){
                    console.log("NaN Found!!!", icon, this.state);
                }
                var highlighted = window.cmState.selected_monster_instance && icon.id == window.cmState.selected_monster_instance.id;

                icons.push(<Token key={icon.id} icon={icon} zoom={this.state.zoom} highlighted={highlighted} dm={this.props.dm} draggable={are_tokens_draggable}
                    start_token_drag={this.start_token_drag} end_token_drag={this.end_token_drag} move_token_drag={this.move_token_drag}
                    current_location={current_location} index={icon_index} pixels_between_lines={pixels_between_lines} />);
            }
        }

        var actions = [];
        var token_index = 0;
        for (var key in window.cmState.action_instances) {
            var icon = window.cmState.global_state_lookup(this, 'action_instances', key);
            var action = window.cmState.global_state_lookup(this, 'actions', icon.action_id);

            var current_location = [
                icon.coords.x * this.state.zoom,
                icon.coords.y * this.state.zoom
            ];

            if (isNaN(current_location[0])) {
                console.log("NaN Found!!!", icon, this.state);
            }

            actions.push(<SpellToken key={icon.id} icon={icon} zoom={this.state.zoom} dm={this.props.dm} draggable={are_tokens_draggable}
                start_token_drag={this.start_token_drag} end_token_drag={this.end_token_drag}
                move_token_drag={this.move_token_drag} end_token_transform={this.end_token_transform}
                current_location={current_location} index={token_index} pixels_between_lines={pixels_between_lines} />);

            token_index += 1;

            if (window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.id == icon.id) {
                if (['cube','line','cone'].indexOf(action.area_of_effect.type) > -1) {
                    actions.push(<Transformer icon={icon} />);
                }
            }

        }

        var free_draw_lines = [];
        for (var key in this.state.free_draw_lines){
            for (var line of this.state.free_draw_lines[key]) {
                var line_index = this.state.free_draw_lines[key].indexOf(line);

                var points = line.points;
                var zoom = this.state.zoom;
                var modified_line = [];
                for (var index = 0; index < points.length; index++) {
                    if (index % 2 == 0) {
                        modified_line.push(points[index] * zoom);
                    }
                    else {
                        modified_line.push(points[index] * zoom);
                    }
                }

                free_draw_lines.push(<FreeDrawLine key={key + '_' + line_index} index={key + '_' + line_index} points={modified_line}
                    stroke={line['color']} strokeWidth={line['thickness']} remove_line={this.remove_line} change_map_cursor={this.change_map_cursor} />);
            }
        }

        var markers = [];
		var colors = ['red','blue','green','white','black','grey','purple']
		var color_index = 0;
        var children = [];
        var full_location = window.cmState.global_state_lookup(this, 'locations', location.id);

        //console.log('Session Map: Location Children', full_location);

        for (var child of full_location.children) {
			var color = null;
            var child_index = children.indexOf(child);

			var marker = window.cmState.global_state_lookup(this, 'locations', child['location'].id);

            //Limit markers shown to existing group
            if (marker.group_id == null || marker.group_id == window.cmState.group.id) {
                if (marker.lat_lng_list && marker.lat_lng_list != "[]" && marker.lat_lng_list.length > 0) {
    				color = colors[color_index % colors.length];
    				color_index += 1;
    			}

                var draggable = false;
                if (this.props.map_mode == 'move_marker' && window.cmState.selected_monster_instance && window.cmState.selected_monster_instance.id == marker.id) {
                    draggable = true;
                }
                if (marker.type != 'region') {
                    var permission = window.cmState.global_state_lookup(this, 'location_permissions', marker.id);
                    var dm_permissions = !permission || permission.show;
                    var player_permissions = permission && permission.show && permission.show_players
                    if ((this.props.dm && dm_permissions) || player_permissions) {
                        markers.push(<LocationToken draggable={draggable} location={marker} index={child_index} map_mode={this.props.map_mode} color={color}
                            start_token_drag={this.start_token_drag} move_token_drag={this.move_token_drag} end_token_drag={this.end_token_drag}
                            group={this.props.group} pixels_between_lines={pixels_between_lines} parent_location={location} />);
                    }
                }
            }
        }

        var fog_and_icons = null;
        if (this.props.dm){
            fog_and_icons = [
                fog_of_war,
                markers,
                free_draw_lines,
                actions,
                icons,
            ];
        }
        else {
            fog_and_icons = [
                markers,
                free_draw_lines,
                actions,
                icons,
                fog_of_war,
            ];
        }

        return (
            <CanvasMap group_id={this.props.group_id} location={location} is_map_draggable={is_map_draggable} on_drag_end={this.end_map_drag}
                on_zoom={this.on_zoom} start_draw={this.start_draw} move_draw={this.move_draw} end_draw={this.end_draw}
                click_map={this.click_map} sidebar_width={this.props.sidebar_width} screen_size={this.screen_size}
                map_mode={this.props.map_mode} map_position={this.state.map_position} line_vars={this.props.line_vars}
                center_map={this.props.center_map} center_map_position={this.props.center_map_position}
                center_map_timestamp={this.props.center_map_timestamp} center_map_ping={this.props.center_map_ping}
                handle_key_press={this.props.handle_key_press} cursor_style={this.state.map_cursor}
                zoom_control={this.props.zoom_control}
            >
                {grid_lines}

                {fog_and_icons}

                {drawn_rect}

                {measurement_line}
                {measurement_text}
            </CanvasMap>
        );

    }
}
