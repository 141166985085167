import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea,
    Select, CheckGroup, ImageInput, Sidebar, Alert, CardWithChildren } from 'library';


export default class Dashboard extends React.Component {
    render() {
        var content = <div className="container">
            <div>
                <br/>
                <h2>Welcome To Forgotten Maps!</h2>
                <br/>
                <div className="row">
                    <div className="col-12" onClick={() => window.location ='/sword_coast/'}  style={{cursor:'pointer'}}>
                        <CardWithChildren name="Explore The Forgotten Realms" imageUrl="https://mapquest-public.s3.us-east-2.amazonaws.com/navigation/worldmap.jpg">

                        </CardWithChildren>
                    </div>
                    <div className="col-md-6 col-sm-12" onClick={() => window.location ='/my_locations/'}  style={{cursor:'pointer'}}>
                        <CardWithChildren name="Create Your Own Worlds" imageUrl="https://mapquest-public.s3.us-east-2.amazonaws.com/navigation/my_worlds.jpg">

                        </CardWithChildren>
                    </div>
                    <div className="col-md-6 col-sm-12" onClick={() => window.location ='/my_groups/'}  style={{cursor:'pointer'}}>
                        <CardWithChildren name="Play With Your Group" imageUrl="https://mapquest-public.s3.us-east-2.amazonaws.com/navigation/my_groups.jpg">

                        </CardWithChildren>
                    </div>

                    <div className="col-12" >
                        <CardWithChildren>
                            <h4>Profile Details:</h4>
                            <FormWithChildren submitUrl={'/api/user/user/'+window.cmState.user.id+'/'} defaults={{'username': window.cmState.user.username}}>
                                <TextInput name='username' label='Username' />
                            </FormWithChildren>
                        </CardWithChildren>
                    </div>
                </div>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }
}
