import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {NumberInput, Select, CSSInput, TextInput} from 'library';
import { evaluate } from 'mathjs';

var BOOLEANS = [
  {'text':'True', value:true},
  {'text':'False', value:false},
];

class MathInput extends Component {
    static component_name = 'MathInput';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'name'} name={'name'} default={'Default Text'} />,
                <TextInput label={'default'} name={'default'} />,
                <TextInput label={'label'} name={'label'} />,
                <TextInput label={'output'} name={'output'} />,
                <TextInput label={'class'} name={'className'} />,
                <CSSInput label={'css'} name={'style'} default={{}} />,
                <CSSInput label={'label css'} name={'label_style'} default={{}} />,
            ],
        }

        this.state = {};
    }

    componentDidMount() {}

    componentDidUpdate(prevProps) {
        if (this.props.output != prevProps.output){
            var new_state = {};

            var output = this.props.output;
            if (output && output.indexOf('{') == -1){
                try {
                    output = evaluate(output);
                }
                catch (e) {
                    console.log(e);
                }
            }

            new_state[this.props.name] = output;
            this.props.setFormState(new_state);
        }
    }

    render() {
        var layout = this.props.layout || '';
        var style = this.props.style || {};

        var value = this.props.default;
        if (this.props.value){
            value = this.props.value;
        }

        var input = <input type="text" className="form-control" name={this.props.name} onChange={this.handleChange} value={value} />;

        var label = null;
        if (this.props.label && this.props.label != '') {
            label = <label style={this.props.label_style}>{this.props.label}</label>;
        }

        return (
              <div className={"form-group " + this.props.className + " " + layout} style={style} >
                {label}
                {input}
              </div>
        )
    }
}

export default MathInput;
