import React, { Component } from 'react';
import { NumberInput, FormWithChildren } from 'library';

import Action from 'projectLibrary/monsters/action.js';


export default class FeatureList extends React.Component {
    render (){
        var instance = this.props.instance;
        var monster = this.props.monster;

        var costly_actions = [];
        var special_abilities = [];
        var innate_spells = [];
        var actions = [];
        var spell_list = [];

        for (var item of monster.action_spells) {
            var action = window.cmState.global_state_lookup(this, 'actions', item.actionspell.id);

            if (action.action_damage[0] && Object.keys(action.action_damage[0]) != 0) {
                continue;
            }

            if (action.action_type == 'Ability') {
                special_abilities.push(
                    <Action action={action} use_action={this.props.use_action} instance={this.props.instance}
                    push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                );
            }
            else if (action.action_type == 'Attack'){
                if (action.name == "Multiattack") {
                    actions.unshift(
                        <Action action={action} use_action={this.props.use_action} instance={this.props.instance}
                        push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                    );
                }
                else {
                    actions.push(
                        <Action action={action} use_action={this.props.use_action} instance={this.props.instance}
                        push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                    );
                }
            }
            else {
                spell_list.push(action);
            }
        }

        for (var item of monster.equipped_items) {
            var equipment = window.cmState.global_state_lookup(this, 'equipment', item.equippeditem.equipment_id);

            if (equipment) {
                for (var equipment_action_cost of equipment.actions) {
                    var action = window.cmState.global_state_lookup(this, 'actions', equipment_action_cost.action_spell_id);

                    if (action.action_damage[0] && Object.keys(action.action_damage[0]) != 0) {
                        continue;
                    }

                    if (action.action_type == 'Ability') {
                        special_abilities.push(
                            <Action action={action} use_action={this.props.use_action} instance={this.props.monster}
                            push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                        );
                    }
                    else if (action.action_type == 'Attack'){
                        if (action.name == "Multiattack") {
                            actions.unshift(
                                <Action action={action} use_action={this.props.use_action} instance={this.props.monster}
                                push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                            );
                        }
                        else {
                            actions.push(
                                <Action action={action} use_action={this.props.use_action} instance={this.props.monster}
                                push_modal_content={this.props.push_modal_content} add_action_token={this.props.add_action_token} />
                            );
                        }
                    }
                    else {
                        spell_list.push(action);
                    }
                }
            }
        }

        return (
            <div class="property-block">
                {actions}
                {special_abilities}
                {costly_actions}
                {innate_spells}
            </div>
        );
    }
}
