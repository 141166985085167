import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';

class Item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {'quantity': props.item.quantity};

        this.remove_item = this.remove_item.bind(this);
        this.change_item = this.change_item.bind(this);
    }

    componentDidMount() {
        window.cmState.subscribe_by_name(this, 'equipment');
    }

    remove_item() {
        this.props.remove_item(this.props.item)
    }

    change_item(name, state) {
        this.props.change_item(this.props.item, state['quantity']);
        this.setState({quantity: state['quantity']});
    }

    render() {
        var item = this.props.item;
        var equipment = window.cmState.global_state_lookup(this, 'equipment', item.equipment_id);
        var name = equipment.name;

        var content = <div>
            <p>{name}</p>
            <FormWithChildren key={'item_' + item.id} autoSetGlobalState={true} setGlobalState={this.change_item} globalStateName={'item'} defaults={this.state}>
                <NumberInput name='quantity' />
            </FormWithChildren>
        </div>;

        return (content);
    }
}

export default class Inventory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chat_socket: null,
            message: null
        }

        this.change_item = this.change_item.bind(this);
        this.remove_item = this.remove_item.bind(this);
        this.saved = this.saved.bind(this);
    }

    componentDidMount() {
        var socket_mapping = {
        };

        var chat_socket = new SocketRouter(this, socket_mapping, this.props.group.id);
        this.setState({chat_socket: chat_socket});
    }

    change_item(item, quantity) {
        var monster_instance = Object.assign({},this.props.instance);
        var items = Object.assign({},monster_instance.equipped_items);
        var new_items = [];
        for (var item of items) {
            var new_item = Object.assign({},item['instanceequippeditem']);
            if (item.id == new_item.id) {
                new_item.quantity = quantity;
            }
            new_items.push({'item':new_item});
        }

        monster_instance['equipped_items'] = new_items;

        ajaxWrapper('POST','/api/home/InstanceEquippedItem/' + item.id + '/', {quantity: quantity}, console.log);

        var data = {'icon':monster_instance};
        this.state.chat_socket.send_message({'message':data});
    }

    remove_item(item) {
        ajaxWrapper('POST','/api/home/InstanceEquippedItem/' + item.id + '/delete/', {}, console.log);
    }

    saved(value) {
        this.setState({message: 'Saved Successfully!'});
    }

    render() {
        var instance_items = this.props.instance.equipped_items;
        console.log("Instance Resources", instance_items);
        var items = [];
        for (var instance_item of instance_items) {
            var item = instance_item['instanceequippeditem'];
            console.log("Item", item);
            items.push(<Item item={item} change_item={this.change_item} remove_item={this.remove_item} />);
        }

        var notes_defaults = {
            inventory_text: this.props.instance.inventory_text,
            notes: this.props.instance.notes
        };

        var message = null;
        if (this.state.message){
            message = <Alert type='success' text={this.state.message} />;
        }

        var content = <div className="container">
            <div>
                <h2>Inventory</h2>
                {items}

                <FormWithChildren submitUrl={'/api/home/MonsterInstance/'+ this.props.instance.id +'/'} key={'inventory'} defaults={notes_defaults} redirect={this.saved}>
                    <TextArea name='inventory_text' label='General Inventory' rows={'10'} />
                    <TextArea name='notes' label='Additional Notes' rows={'10'} />
                    {message}
                </FormWithChildren>
            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={true} />
        )
    }


}
