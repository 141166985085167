import React, { Component } from 'react';
import { ajaxWrapper } from 'functions';
import settings from 'base/settings.js';
import { Navbar, Button, Wrapper, TextInput, NumberInput, FormWithChildren, TextArea, Select, CheckGroup, ImageInput, Sidebar, Alert } from 'library';
import ActionCard from 'projectLibrary/action_card.js';


class Equipment extends React.Component {
    constructor(props) {
        super(props);

        this.add_equipment = this.add_equipment.bind(this);
        this.show_equipment = this.show_equipment.bind(this);
        this.remove_equipment = this.remove_equipment.bind(this);
    }

    show_equipment() {
        this.props.push_modal_content(<ActionCard action={this.props.equipment} />);
    }

    add_equipment() {
        this.props.add_equipment(this.props.equipment);
    }

    remove_equipment() {
        this.props.remove_equipment(this.props.equipment);
    }

    render() {
        var equipment = this.props.equipment;

        if (this.props.owned_equipment.indexOf(equipment.id) == -1) {
            var prepare_button = <Button type='primary' text='Add Equipment' onClick={this.add_equipment} />
        }
        else {
            var prepare_button = <Button type='danger' text='You Own This!' onClick={this.remove_equipment} />
        }

        var content = [];
        content.push(
                <tr>
                    <td><a onClick={this.show_equipment}>{equipment.name}</a></td>
                    <td>{prepare_button}</td>
                </tr>
            )

        return (content);

    }
}

export default class EquipmentForm extends React.Component {
    constructor(props) {
        super(props);

        var owned_equipment = [];
        var equipped_dict = {};
        if (this.props.monster && this.props.monster.equipped_items) {
            for (var item of this.props.monster.equipped_items) {
                var entry = item.equippeditem;
                var equipment_id = entry.equipment_id;
                owned_equipment.push(equipment_id);
                equipped_dict[equipment_id] = entry;
            }
        }

        this.state = {
            search_name:'',
            equipment_list:[],
            owned_equipment:owned_equipment,
            equipped_dict: equipped_dict,
            loaded: false
        };

        this.equipment_callback = this.equipment_callback.bind(this);
        this.setGlobalState = this.setGlobalState.bind(this);

        this.add_equipment = this.add_equipment.bind(this);
        this.add_equipment_callback = this.add_equipment_callback.bind(this);
        this.remove_equipment = this.remove_equipment.bind(this);
    }

    componentDidMount() {
        ajaxWrapper('GET','/api/compendium/equipment/', {}, this.equipment_callback);
    }

    setGlobalState(name, state) {
        this.setState(state);
    }

    add_equipment(equipment) {
        this.update_monster_equipment(equipment.id);

        var owned_equipment = this.state.owned_equipment;
        owned_equipment.push(equipment.id);

        ajaxWrapper('POST','/api/compendium/equippeditem/', {
            equipment: equipment.id,
            monster: this.props.monster.id,
            quantity:1
        }, this.add_equipment_callback);
        this.setState({owned_equipment: owned_equipment});
    }

    add_equipment_callback(value){
        var new_equipment = value[0]['equippeditem'];
        var equipped_dict = this.state.equipped_dict;
        equipped_dict[new_equipment.equipment_id] = new_equipment;

        this.setState({
            equipped_dict: equipped_dict,
        });
    }

    remove_equipment(equipment) {
        this.update_monster_equipment(undefined, equipment.id);

        var owned_equipment = this.state.owned_equipment;

        var new_owned_equipment = [];
        for (var item of owned_equipment) {
            if (equipment.id != item) {
                new_owned_equipment.push(item);
            }
        }

        var delete_id = this.state.equipped_dict[equipment.id].id;
        ajaxWrapper('POST','/api/compendium/equippeditem/'+ delete_id +'/delete/', {}, console.log);

        this.setState({owned_equipment: new_owned_equipment});
    }

    update_monster_equipment(add_id, remove_id){
        if (add_id){
            var existing_equipments = this.props.monster.equipped_items;
            for (var equipment of this.state.equipment_list){
                if (add_id == equipment.id){
                    var data = {
                        equipment_id: equipment.id,
                        monster_id: this.props.monster.id,
                        quantity: 1
                    };
                    existing_equipments.push({equippeditem:data});
                }
            }
            this.props.update_monster('add_equipment', {equipped_items: existing_equipments}, true);
        }
        else if (remove_id){
            var new_equipments = [];
            for (var item of this.props.monster.equipped_items) {
                var equipment = item['equippeditem'];
                if (remove_id != equipment.equipment_id){
                    new_equipments.push({equippeditem: equipment});
                }
            }
            this.props.update_monster('remove_equipment', {equipped_items: new_equipments}, true);
        }
    }

    equipment_callback(result) {
        var equipment_list = [];
        for (var item of result) {
            var equipment = item['equipment'];
            equipment_list.push(equipment);
        }

        console.log("Equipment_list", equipment_list)

        this.setState({'equipment_list':equipment_list, loaded:true})
    }

    render() {
        var equipment = [];

        for (var item of this.state.equipment_list) {
            if (this.state.search_name == '' || item.name.toLowerCase().indexOf(this.state.search_name.toLowerCase()) > -1) {
                equipment.push(<Equipment equipment={item} add_equipment={this.add_equipment} remove_equipment={this.remove_equipment}
                    owned_equipment={this.state.owned_equipment} push_modal_content={this.props.push_modal_content} />)
            }
        }

        var content = <div className="container">
            <div>
                <h2>Equipment</h2>
                <FormWithChildren row={true} setGlobalState={this.setGlobalState} autoSetGlobalState={true}>
                    <TextInput name='search_name' label='Search by Name' className="col-4" />

                </FormWithChildren>
                <table className="table">
                    <tr>
                        <th>Name</th>
                        <th>Own It</th>
                    </tr>
                    {equipment}
                </table>

            </div>
        </div>;

        return (
            <Wrapper content={content} loaded={this.state.loaded} />
        )
    }


}
