import React, { Component } from 'react';
import {resolveVariables, ajaxWrapper} from 'functions';
import {Wrapper, Paragraph, Container, Break, Button, Header, Alert} from 'library';


class DropboxEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.onClick = this.onClick.bind(this);
        this.view = this.view.bind(this);
        this.download = this.download.bind(this);
        this.open_link = this.open_link.bind(this);
    }

    componentDidMount() {

    }

    onClick() {
        this.props.redirect(this.props.data);
    }

    view() {
        ajaxWrapper("GET", '/dropbox/view_link/?folder=' + this.props.data['id'], {}, this.open_link);
    }
    download() {
        ajaxWrapper("GET", '/dropbox/download_link/?folder=' + this.props.data['id'], {}, this.open_link);
    }

    open_link(value){
        window.open(value['url'], "_blank");
    }

    render() {
        var type = 'outline-info';
        var className = '';
        var click_handler = this.onClick;
        var text = [
            <i class="fas fa-1x fa-folder-open" style={{marginRight: '15px'}}></i>,
            this.props.data['name']
        ];

        if (this.props.data.type == 'file'){
            type = 'link';
            click_handler = this.view;
            var text = [
                <i class="fas fa-1x fa-file-alt" style={{marginRight: '15px'}}></i>,
                this.props.data['name']
            ];
        }
        else if (this.props.data.type == 'back'){
            type = 'outline-warning';
            var text = [
                <i class="fas fa-1x fa-long-arrow-alt-left" style={{marginRight: '15px'}}></i>,
                this.props.data['name']
            ];
        }

        var content = <Button onClick={click_handler} className={className} type={type} text={text} style={{fontSize: '20px', margin: '5px'}} />;

        return (
            <div>
                {content}
            </div>
        );
    }
}

class DropboxExplorer extends React.Component {
    static component_name = 'DropboxExplorer';

    constructor(props) {
        super(props);
        this.config = {
            form_components: [],
            can_have_children: true,
        };

        this.state = {
            has_access: false,
            folder: {name:'', id:''},
            starting_folder: {name:'', id:''},
            parent_folders: [],
            entries: [],
            pending_navigation: false
        };

        this.redirect_to_dropbox = this.redirect_to_dropbox.bind(this);
        this.request_oauth_token = this.request_oauth_token.bind(this);
        this.check_user_access = this.check_user_access.bind(this);
        this.update_dropbox_list = this.update_dropbox_list.bind(this);
        this.get_dropbox_list = this.get_dropbox_list.bind(this);
        this.save_list = this.save_list.bind(this);
    }

    componentDidMount(){
        var global_state = window.cmState.getGlobalState();
        var user = global_state.user;

        //ajaxWrapper("GET", '/api/dropbox/DropboxAccess/?user=' + user.id, {}, this.check_user_access);
        ajaxWrapper("GET", '/dropbox/check_access/', {}, this.check_user_access);

        if ('folder' in global_state['params']){
            var folder = {id: global_state['params']['folder'], name: global_state['params']['folder']};
            this.setState({starting_folder: folder, folder: folder});
        }
    }

    check_user_access(value){
        if (value.access){
            this.update_dropbox_list(this.state.folder);
        }
    }

    request_oauth_token(){
        ajaxWrapper("GET", '/dropbox/dropbox_oauth/', {}, this.redirect_to_dropbox);
    }

    redirect_to_dropbox(value){
        console.log(value);
        window.location = value['url'];
    }

    update_dropbox_list(folder){
        if (folder.id == null){
            var parent_folders = this.state.parent_folders;
            var folder = parent_folders.pop();
            this.setState({
                has_access: true,
                folder: folder,
                parent_folders: parent_folders,
                pending_navigation: true
            }, this.get_dropbox_list);
        }
        else {
            // Going down folder tree
            var parent_folders = this.state.parent_folders;
            if (this.state.folder.id != '' && folder.name != this.state.starting_folder.name){
                parent_folders.push(this.state.folder);
            }

            this.setState({
                has_access: true,
                folder: folder,
                parent_folders: parent_folders,
                pending_navigation: true
            }, this.get_dropbox_list);
        }
    }

    get_dropbox_list(){
        ajaxWrapper("GET", '/dropbox/search/?folder=' + this.state.folder['id'], {}, this.save_list);
    }

    save_list(value){
        console.log(value);
        this.setState({
            entries: value['entries'],
            folder: value['folder'],
            pending_navigation: false
        });
    }

    render() {
        var content = [
            <Paragraph text={'No Dropbox Access Detected!'} />,
            <Button onClick={this.request_oauth_token} type={'primary'} text={"Request Access"} />
        ];
        var header = null;

        if (this.state.has_access && this.state.entries){
            header = <Header size={3} text={this.state.folder.name} />
            if (this.state.folder.name == 'Home Directory'){
                header = <Alert type='info'>
                    <Header size={3} text={'You have succesfully authenticated with Dropbox.'} />
                    <p>Navigate to a specific folder to view files and folders.</p>
                </Alert>
            }

            var entry_list = [];

            if (this.state.parent_folders.length > 0){
                var entry = <DropboxEntry redirect={this.update_dropbox_list} data={{name:'Back', id: null, type: 'back'}} text={'Back'} />;
                entry_list.push(entry);
            }

            for (var i in this.state.entries){
                var entry = <DropboxEntry redirect={this.update_dropbox_list} data={this.state.entries[i]} />;
                entry_list.push(entry);
            }

            content = entry_list;
        }

        return (
            <div className={''} style={{}}><Container>
                <Break/><Break/>
                {header}
                <Wrapper min_height={true} content={content} loaded={!(this.state.pending_navigation)} />
                <Break/><Break/>
            </Container></div>
        );
    }
}


export default DropboxExplorer;
