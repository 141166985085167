import React, { Component } from 'react';
import { Text, Rect, Line } from 'react-konva';

var COLORS = {
    'green': [0,177,81],
    'yellow': [255,193,2],
    'red': [255,1,0],
};


export default class Health extends React.Component {
    constructor(props) {
        super(props);
    }


    render() {
        var scaleX = this.props.zoom;
        var scaleY = this.props.zoom;
        var on_click = null;

        var icon = this.props.icon;
        var scale_multiplier = 1;
        if (icon && icon.monster) {
            if (icon.size == 'Large') {
                var scale_multiplier = 2;
            }
            else if (icon.size == 'Huge') {
                var scale_multiplier = 3;
            }
            else if (icon.size == 'Gargantuan') {
                var scale_multiplier = 4;
            }
        }

        var image_size = this.props.image_size;

        scaleX = (this.props.pixels_between_lines * this.props.zoom)/image_size[0] * scale_multiplier
        scaleY = (this.props.pixels_between_lines * this.props.zoom)/image_size[1] * scale_multiplier
        on_click = this.toggle_popup;

        var pixel_grid_size = this.props.zoom * this.props.pixels_between_lines;
        var icon_size = pixel_grid_size * scale_multiplier;

        var corner_size = pixel_grid_size / 6;
        var bar_height = pixel_grid_size / 8;

        var health_width = 0;
        //if has hp value
        if (this.props.icon.max_hp > 0) {
            health_width = this.props.icon.current_hp/this.props.icon.max_hp * icon_size;
        }

        var health_color = COLORS['green'];
        var health_percentage = parseInt((this.props.icon.current_hp/this.props.icon.max_hp) * 100);
        if (health_percentage >= 50){
            var color_change = ((100 - (health_percentage - 50) * 2) / 100);
            var start = COLORS['green'];
            var end = COLORS['yellow'];

        }
        else {
            var color_change = 1 - ((health_percentage) * 2 / 100);
            var start = COLORS['yellow'];
            var end = COLORS['red'];
        }
        var position = [
            start[0] - (color_change * (start[0] - end[0])),
            start[1] - (color_change * (start[1] - end[1])),
            start[2] - (color_change * (start[2] - end[2])),
        ]
        health_color = 'rgb('+position[0]+','+position[1]+','+position[2]+')';

        var health_parts = [];
        if (this.props.show_bar){
            health_parts.push(<Rect
                x={this.props.x + health_width}
                y={this.props.y - bar_height}
                width={pixel_grid_size * scale_multiplier - health_width}
                height={bar_height}
                fill={'white'}
                opacity={0.5}
            />);
            health_parts.push(<Rect
                x={this.props.x}
                y={this.props.y - bar_height}
                width={health_width}
                height={bar_height}
                fill={'red'}
                opacity={0.5}
            />);
            health_parts.push(<Text
                x={this.props.x}
                y={this.props.y - bar_height}
                text={this.props.icon.current_hp + '/' + this.props.icon.max_hp}
                width={pixel_grid_size * scale_multiplier}
                fontSize={bar_height}
                align={'center'}
            />);
        }

        health_parts.push(<Line
            x={0} y={0}
            points={[
                icon_size, icon_size,
                icon_size - corner_size, icon_size,
                icon_size, icon_size - corner_size
            ]}
            fill={health_color}
            closed={true}
            opacity={1}
        />);

        return (health_parts);
    }
}
