import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class Embed extends React.Component {
    static component_name = 'Embed';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'File Url'} name={'src'} />,
                <CSSInput label={'style'} name={'style'} default={{}} />,
                <TextInput label={'Header Height'} name={'headerHeight'} />,
                <TextInput label={'Width Percent'} name={'widthPercent'} />,
            ],
            can_have_children: false,
        }

        this.state = {
            width: "0px",
            height: "0px"
        };
    }

    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    updateDimensions() {
        var height = String(window.innerHeight - this.props.headerHeight) + "px";
        var width = String(window.innerWidth * (this.props.widthPercent/100)) + "px";
        this.setState({
            height: height,
            width: width
        });
    }

    render() {
        var style = Object.assign({}, {}, this.props.style);

        return (
            <object data={this.props.src} style={style} type="application/pdf"
                width={"100%"} height={this.state.height} >
              <p>Your browser does not support viewing PDF's. <a href={this.props.src}>Download it instead</a></p>
            </object>
        );
    }
}


export default Embed;
