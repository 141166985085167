import React, { Component } from 'react';
import {resolveVariables} from 'functions';
import {TextInput, CSSInput} from 'library';

class Image extends React.Component {
    static component_name = 'Image';
    constructor(props) {
        super(props);
        this.config = {
            form_components: [
                <TextInput label={'Image Url'} name={'src'} />,
                <CSSInput label={'style'} name={'style'} default={{}} />,
            ],
            can_have_children: false,
        }
    }

    render() {
        var style = Object.assign({}, {'width':'100%'}, this.props.style);
        return (
            <img style={style} src={this.props.src} />
        );
    }
}


export default Image;
