import React, { Component } from 'react';
import { Stage, Layer, Line, Rect, Group, Shape, Text } from 'react-konva';
import {is_right_click} from 'functions';
import CanvasImage from 'projectLibrary/canvas/image.js';
import Ping from 'projectLibrary/canvas/ping.js';
import SocketRouter from 'projectLibrary/sockets/socket_router.js';
import get_scaled_position from 'projectLibrary/canvas/get_scaled_position.js';

const zoom_multiplier = 1.1;


export default class CanvasMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
            image_width: 0,
            image_height: 0,

            mouse_position: { x: 0, y: 0 },

            zoom: 0.5,
            zoom_control: 0,

            position: [0,0],
            start_draw_position: null,
            end_draw_position: null,
            center_map_timestamp: null,

            pings: [],
        };

        this.start_draw = this.start_draw.bind(this);
        this.move_draw = this.move_draw.bind(this);
        this.end_draw = this.end_draw.bind(this);
        this.on_drag_end = this.on_drag_end.bind(this);

        this.handle_key_press = this.handle_key_press.bind(this);
        this.handle_scroll = this.handle_scroll.bind(this);
        this.zoom_in = this.zoom_in.bind(this);
        this.zoom_out = this.zoom_out.bind(this);

        this.map_reference = React.createRef();
        this.fog_reference = React.createRef();

        this.screen_size = this.screen_size;
        this.update_size = this.update_size.bind(this);

        this.handle_ping_map = this.handle_ping_map.bind(this);
    }

    update_size() {
        var ref_bounding = this.map_reference.current.getBoundingClientRect();
        var width = ref_bounding.width;
        var height = window.innerHeight - ref_bounding.top - 5;

        var location_image_size = this.props.location.location_image_size;
        if (this.state.width != width || this.state.height != height || this.state.image_width != location_image_size[0] || this.state.image_height != location_image_size[1]){
            this.setState({
                width: width,
                height: height,
                image_width: location_image_size[0],
                image_height: location_image_size[1],
            });
            this.screen_size(width,height);

            window.cmState.setGlobalState('screen_size', {'width':width, 'height':height});
        }
    }

    componentDidMount() {
        var socket_mapping = {
            ping_map: 'ping_map',
        };

        var chat_socket = new SocketRouter(this, socket_mapping, this.props.group_id);
        this.setState({chat_socket: chat_socket});

        this.update_size();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.update_size();

        var new_position = this.props.center_map_position;
        var current_position = this.layer_node.absolutePosition();

        if (prevProps.location.id != this.props.location.id){
            console.log('Location change');
        }
        else if (new_position && new_position.x && new_position.y && this.props.center_map_timestamp != this.state.center_map_timestamp){
            if (isNaN(new_position.x)){
                new_position.x = 0;
            }
            if (isNaN(new_position.y)){
                new_position.y = 0;
            }

            //Convert desired position on map to the proper offset of the upper left corner of map to center of screen

            var map_width = this.state.width;
            if (this.props.center_sidebar_width){
                map_width -= map_width * (this.props.center_sidebar_width / 100);
            }

            var position = [
                (-1 * new_position.x * this.state.zoom) + map_width/2,
                (-1 * new_position.y * this.state.zoom) + this.state.height/2
            ];
            position = this.check_and_fix_bounds(position);

            this.layer_node.absolutePosition({
                x: position[0],
                y: position[1]
            });

            this.layer_node.draw();

            this.setState({
                position: position,
                center_map_timestamp: this.props.center_map_timestamp
            });

            if (this.props.center_map_ping == true){
                this.handle_ping_map({
                    ping_map:{
                        position: [new_position.x, new_position.y],
                    }
                });
            }
        }


        var new_zoom = this.props.zoom_control;
        var current_zoom = this.state.zoom_control;
        if (new_zoom && new_zoom != current_zoom){
            if (new_zoom >= current_zoom){
                this.zoom_in();
            }
            else {
                this.zoom_out();
            }

            this.setState({
                zoom_control: new_zoom,
            });
        }
    }

    screen_size(width, height) {
        var location = this.props.location;
        var image_size = location.location_image_size;

        var width_zoom = (width*.9)/image_size[0]
        var height_zoom = (height*.9)/image_size[1]
        if (width_zoom < height_zoom) {
            var offset = width * .1;
            var zoom = width_zoom;
        }
        else {
            var offset = height * .1;
            var zoom = height_zoom;
        }

        this.setState({zoom:zoom});
        if (this.props.screen_size) {
            this.props.screen_size(zoom, 0);
        }
    }

    on_drag_end(e) {
        var position = [
            e.currentTarget.attrs.x,
            e.currentTarget.attrs.y,
        ];

        position = this.check_and_fix_bounds(position);

        this.setState({position: position});
        window.cmState.setGlobalState('map_position', position);
        if (this.props.on_drag_end){
            this.props.on_drag_end(position);
        }
    }

    check_and_fix_bounds(position){
        var map_width = this.props.location.location_image_size[0];
        var map_height = this.props.location.location_image_size[1];

        var out_of_bounds = false;
        if (position[0] >= this.state.width - 30){
            position[0] = this.state.width - 60;
            out_of_bounds = true;
        }
        if (position[0] <= -1 * map_width * this.state.zoom + 30){
            position[0] = (-1 * map_width * this.state.zoom) + 60;
            out_of_bounds = true;
        }
        if (position[1] >= this.state.height - 30){
            position[1] = this.state.height - 60;
            out_of_bounds = true;
        }
        if (position[1] <= -1 * map_height * this.state.zoom + 30){
            position[1] = (-1 * map_height * this.state.zoom) + 60;
            out_of_bounds = true;
        }

        if (out_of_bounds){
            this.layer_node.absolutePosition({
                x: position[0],
                y: position[1]
            });
        }

        return position;
    }

    start_draw(e) {
        var is_right = is_right_click(e.evt);

        var event_position = [e.evt.clientX, e.evt.clientY];
        if (e.evt.type == 'touchstart') {
            event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
        }

        var position = get_scaled_position(
            event_position,
            this.state.position,
            this.state.zoom
        );

        this.setState({
            start_draw_position: position,
            start_draw_target: e.target
        });

        if (is_right){
            return false;
        }
        else if (this.props.start_draw){
            this.props.start_draw(position);
        }
    }

    move_draw(e) {
        if (this.state.start_draw_position){
            var event_position = [e.evt.clientX, e.evt.clientY];
            if (e.evt.type == 'touchmove') {
                event_position = [e.evt.touches[0].clientX, e.evt.touches[0].clientY];
            }

            var position = get_scaled_position(
                event_position,
                this.state.position,
                this.state.zoom
            );

            this.setState({end_draw_position: position});
            if (this.props.move_draw){
                this.props.move_draw(position);
            }
        }
    }

    end_draw(e) {
        if (!(e.evt)){
            return false;
        }
        console.log("End Draw", e)
        console.log("Start Draw Target", this.state.start_draw_target)

        var position = get_scaled_position(
            [e.evt.clientX, e.evt.clientY],
            this.state.position,
            this.state.zoom
        );
        if (isNaN(position[0]) || isNaN(position[0])) {
            position = this.state.end_draw_position;
        }

        var distance = 0;
        if (this.state.start_draw_position && position) {
            var x_1 = this.state.start_draw_position[0];
            var y_1 = this.state.start_draw_position[1];
            var x_2 = position[0];
            var y_2 = position[1];
            distance = (Math.sqrt(Math.pow(x_1 - x_2, 2) + Math.pow(y_1 - y_2, 2))).toFixed(1);
        }

        var is_right = is_right_click(e.evt);

        //if you don't drag far enough, doesn't count as end_draw and instead a click
        if (distance < 40 && this.state.start_draw_target == e.target){
            if (this.props.click_map){
                this.props.click_map(position, is_right, e);
            }
        } else {
            if (is_right){
                return false;
            }
            else if (this.props.end_draw){
                this.props.end_draw(position, e);
            }
        }

        this.setState({
            start_draw_position: null,
            end_draw_position: null,
            start_draw_target: null
        });
    }

    handle_key_press(event){
        if (event.key == '=' || event.key == '+'){
            this.zoom_in();
        }
        else if (event.key == '-' || event.key == '_' ){
            this.zoom_out();
        }
        else if (this.props.handle_key_press) {
            this.props.handle_key_press(event);
        }
    }

    handle_scroll(e) {
        //var delta = e.nativeEvent.wheelDelta;
        var delta = e.deltaY;

        if (delta < 0){
            this.zoom_in();
        }
        else {
            this.zoom_out();
        }
    }

    zoom_in() {
        var zoom = this.state.zoom;
        var original_zoom = zoom;
        zoom *= zoom_multiplier;
        //if (zoom > 2.5){zoom -= .05;}

        var position = this.keep_viewport_centered(original_zoom, zoom);
        position = this.check_and_fix_bounds(position);

        this.setState({
            zoom:zoom,
            position: position
        });

        if (this.props.on_zoom){
            this.props.on_zoom(zoom);
        }
    }

    zoom_out() {
        var zoom = this.state.zoom;
        var original_zoom = zoom;
        zoom /= zoom_multiplier;
        //if (zoom < .15){zoom += .05;}

        var position = this.keep_viewport_centered(original_zoom, zoom);
        position = this.check_and_fix_bounds(position);

        this.setState({
            zoom:zoom,
            position: position
        });

        if (this.props.on_zoom){
            this.props.on_zoom(zoom);
        }
    }

    keep_viewport_centered(start_zoom, end_zoom) {
        var map_width = this.state.width;
        if (this.props.center_sidebar_width){
            map_width -= map_width * (this.props.center_sidebar_width / 100);
        }

        var position = this.state.position;

        var center_position_on_map_x = (map_width / 2 - this.state.position[0]) / start_zoom;
        var center_position_on_map_y = (this.state.height / 2 - this.state.position[1]) / start_zoom;

        var new_center_position_on_map_x = (map_width / 2 - this.state.position[0]) / end_zoom;
        var new_center_position_on_map_y = (this.state.height / 2 - this.state.position[1]) / end_zoom;

        var map_width_distance_change = (new_center_position_on_map_x - center_position_on_map_x) * end_zoom;
        var map_height_distance_change = (new_center_position_on_map_y - center_position_on_map_y) * end_zoom;

        this.layer_node.move({
            x: map_width_distance_change,
            y: map_height_distance_change
        });

        position[0] += map_width_distance_change;
        position[1] += map_height_distance_change;

        return position;
    }

    handle_ping_map(message_data){
        var pings = this.state.pings;
        var ping = message_data['ping_map'];
        ping['timestamp'] = new Date().valueOf();

        pings.push(ping);

        this.setState({pings: pings});
    }

    render() {
        var onMouseDown = this.start_draw;
        var onMouseUp = this.end_draw;
        var onMouseMove = this.move_draw;

        var pings = [];
        for (var ping of this.state.pings){
            pings.push(<Ping position={ping['position']} colors={ping['colors']} timestamp={ping['timestamp']} zoom={this.state.zoom} />);
        }

        var map_style = {
            outline: 'none',
            marginRight: this.props.sidebar_width + '%',
            cursor: this.props.cursor_style || 'default',
        };

        return (
            <div ref={this.map_reference} style={map_style} onWheel={this.handle_scroll} onKeyDown={this.handle_key_press} tabIndex="0" >
                <Stage width={this.state.width} height={this.state.height} ref={node => {
                  this.stageRef = node;
                }}>
                    <Layer ref={node => {this.layer_node = node;}} draggable={this.props.is_map_draggable} x={0} y={0}
                        onDragEnd={this.on_drag_end}
                        onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}
                        onTouchStart={onMouseDown} onTouchEnd={onMouseUp} >

                        <CanvasImage src={this.props.location.location_image} x={0} y={0} zoom={this.state.zoom} draggable={false} />

                        {this.props.children}

                        {pings}
                    </Layer>
                </Stage>
            </div>
        );
    }
}
