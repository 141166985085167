import React, { Component } from 'react';
import { Button } from 'library';


export default class Monster extends React.Component {
    constructor(props) {
        super(props);

        this.select_monster = this.select_monster.bind(this);
        this.unselect_monster = this.unselect_monster.bind(this);
        this.edit_monster = this.edit_monster.bind(this);
    }

    select_monster() {
        this.props.select_monster('selected_monster', this.props.monster.id);
    }

    edit_monster() {
        this.props.select_monster('edit_monster', this.props.monster.id);
    }

    unselect_monster() {
        this.props.unselect_monster(this.props.monster.id);
    }

    render() {
        var monster_button = <Button key={this.props.monster.name + '_button'}
            onClick={this.select_monster} text={'Select'} type='primary' />;

        if (this.props.selected_monster && this.props.selected_monster == this.props.monster.id) {
            monster_button = <Button key={this.props.monster.name + '_buttonu'}
                onClick={this.unselect_monster} text={'Deselect'} type='danger' />;
        }

        var monster_select = <tr>
            <td><img style={{height:'40px', width:'auto'}} src={this.props.monster.image} /></td>
            <td>{this.props.monster.name}</td>
            <td>{this.props.monster.challenge_rating}</td>
            <td>{monster_button}</td>
            <td><Button text='Edit' type='primary' onClick={this.edit_monster} /></td>
        </tr>;

        return (
            monster_select
        )
    }
}
